export const PHOTO_TYPE_CLEANING = 'cleaning';
export const PHOTO_TYPE_CLEANING_NAME = '作業状況';
export const PHOTO_TYPE_DEFECT = 'defect';
export const PHOTO_TYPE_DEFECT_NAME = '異常箇所';

export const cleaningPhotoTypeOptions = (): Array<{ text: string; value: string }> => {
  return [
    { text: PHOTO_TYPE_CLEANING_NAME, value: PHOTO_TYPE_CLEANING },
    { text: PHOTO_TYPE_DEFECT_NAME, value: PHOTO_TYPE_DEFECT },
  ];
};
