


import {
  defineComponent,
  PropType,
  reactive,
  onMounted,
} from '@vue/composition-api';
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';
import johaisetsuReportApi from '@/apis/johaisetsu/settouSagyouReport/johaisetsu_report';
import {
  STATUS_RUNNING,
  CAR_NEW_DATA,
  statusDispMap,
  createOrUpdateJohaisetsuCar,
} from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';
import { JohaisetsuReport } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import {
  JohaisetsuCarCreateOrUpdateResponse,
  JohaisetsuCar,
} from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import { CreateOrUpdateJohaisetsuCarParam } from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import useSettouSagyou from '@/components/Sp/Johaisetsu/SettouSagyou/composables/useSettouSagyou';
import { Location } from '@/models/index';

interface State {
  displayJohaisetsuHan: string;
  sanpuNum: number;
  bikou1: string;
  isRequesting: boolean;
}

export default defineComponent({
  props: {
    displayJohaisetsuHan: {
      type: String,
      required: true,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      displayJohaisetsuHan: props.displayJohaisetsuHan,
      sanpuNum: 1,
      bikou1: '',
      isRequesting: false,
    });
    const store = useStore();
    const userState = store.state.user;
    const { route } = useRoute();
    const {
      state: useSettouSagyouState,
      johaisetsuTypeGroupName,
      johaisetsuTypeNameDisp,
      johaisetsuTypes,
      showSanpuNum,
    } = useSettouSagyou({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const maxSanpuNum = 30;
    const sanpuNums = Array.from({ length: maxSanpuNum }, (_, i) => i + 1);
    const startMoving = async() => {
      state.isRequesting = true;
      if (!state.sanpuNum && showSanpuNum) {
        emit('showErrorOnCommon', '散布回数を設定してください。');
        return;
      }
      const carData = await updateJohaisetsuCar();
      if (!carData) {
        emit('showErrorOnCommon', '走行開始に失敗しました。再度操作を行ってください');
        return;
      }
      const reportData = await createJohaisetsuReport(carData);
      if (!reportData) {
        emit('showErrorOnCommon', '走行開始に失敗しました。再度操作を行ってください');
        return;
      }
      emit('startMovingOnCommon', reportData, carData, useSettouSagyouState.selectedJohaisetsuType);
      state.isRequesting = false;
    };
    const updateJohaisetsuCar = async(): Promise<JohaisetsuCar | null> => {
      if (!props.currentLocation) return null;
      if (!useSettouSagyouState.selectedJohaisetsuType) return null;
      const obj: CreateOrUpdateJohaisetsuCarParam = {
        currentLocation: props.currentLocation,
        status: STATUS_RUNNING,
        statusDisp: statusDispMap[STATUS_RUNNING],
        selectedJohaisetsuType: useSettouSagyouState.selectedJohaisetsuType,
      };
      const data: JohaisetsuCar = await createOrUpdateJohaisetsuCar(obj, CAR_NEW_DATA);
      return data;
    };
    const createJohaisetsuReport = async(
      carData?: JohaisetsuCarCreateOrUpdateResponse,
    ): Promise<JohaisetsuReport | null> => {
      if (!carData?.id) return null;
      if (!carData.device_id) return null;
      try {
        const johaisetsuRoleMap = window.master.johaisetsuRoles.vals ?? '';
        const reqObj = {
          device_id: carData.device_id,
          base_departure_ts: new Date(),
          johaisetsu_company_id: johaisetsuRoleMap.filter(e => e.key === userState.johaisetsu_role)[0].id,
          johaisetsu_han_name: props.displayJohaisetsuHan,
          johaisetsu_type: johaisetsuTypeGroupName,
          work_type: johaisetsuTypeNameDisp.value ?? '',
          data_value: showSanpuNum && state.sanpuNum ? state.sanpuNum.toString() : '',
          bikou1: state.bikou1,
        };
        const { data: reportData } = await johaisetsuReportApi.createJohaisetsuReport(reqObj);
        return reportData;
      } catch (e) {
        emit('showErrorOnCommon', '報告書の作成に失敗しました。');
        return null;
      }
    };
    const saveLocalStorage = () => {
      const obj = { selectedJohaisetsuType: useSettouSagyouState.selectedJohaisetsuType };
      const localStorageKey = route.value.name;
      if (!localStorageKey) return;
      localStorage.setItem(localStorageKey, JSON.stringify(obj));
    };
    onMounted(async() => {
      if (!showSanpuNum) return;
      try {
        state.isRequesting = true;
        state.sanpuNum = sanpuNums[0];
      } catch (error) {
        emit('showErrorOnCommon', '散布回数の取得に失敗しました。');
      } finally {
        state.isRequesting = false;
      }
    });

    return {
      state,
      useSettouSagyouState,
      sanpuNums,
      showSanpuNum,
      johaisetsuTypes,
      saveLocalStorage,
      startMoving,
    };
  },
});
