
import videojs, { VideoJsPlayer } from 'video.js';

function registerVideojsDownloadButton(): void {
  class DownloadButton extends videojs.getComponent('Button') {
    constructor(player: VideoJsPlayer, options: videojs.ComponentOptions) {
      super(player, options);
      this.controlText('Download');
      const div = videojs.dom.createEl('i', {}, { class: 'fa fa-download' }, '');
      videojs.dom.appendContent(this.el(), div);
    }

    buildCSSClass() {
      return 'vjs-download-button vjs-control vjs-button';
    }
  }

  videojs.registerComponent('downloadButton', DownloadButton);
}
export function registerVideojsComponents(): void {
  registerVideojsDownloadButton();
}
