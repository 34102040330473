import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { AdminGetDeviceResponse,
  DeviceLiveStreamInfoResponse,
  AdminGetLatestMTXsResponse,
  AdminGetGetActivitiesResponse } from '@/models/apis/device/adminDeviceResponse';
import { AdminGetActivitiesParams } from '@/models/apis/device/adminDeviceRequest';

export default {
  startStream(deviceId: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(`/devices/${deviceId}/start_stream`));
  },
  stopStream(deviceId: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(`/devices/${deviceId}/stop_stream`));
  },
  getDeviceLiveStreamInfo(deviceId: string): Promise<AxiosResponse<DeviceLiveStreamInfoResponse>> {
    return axios.get(utils.getApiUrl(`/devices/${deviceId}/live_stream_info`));
  },
  getSafieDeviceLiveStreamInfo(deviceId: string): Promise<AxiosResponse<DeviceLiveStreamInfoResponse>> {
    return axios.get(utils.getApiUrl(`/devices/safie/${deviceId}/live_stream_info`));
  },
  adminGetDevices(): Promise<AxiosResponse<AdminGetDeviceResponse>> {
    return axios.get(utils.getApiUrl('/admin/devices'));
  },
  adminGetLatestMTXs(deviceId: number): Promise<AxiosResponse<AdminGetLatestMTXsResponse>> {
    return axios.get(utils.getApiUrl(`/admin/devices/${deviceId}/latest_mtxs`));
  },
  adminGetActivities(deviceId: number, params: AdminGetActivitiesParams): Promise<AxiosResponse<AdminGetGetActivitiesResponse>> {
    return axios.get(utils.getApiUrl(`/admin/devices/${deviceId}/activities`), {
      params: params || {},
    });
  },
};
