import { Feature } from 'ol';
import { Vector } from 'ol/layer';
import { Icon, Style, Stroke, Circle, Fill } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MultiLineString, Point } from 'ol/geom';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { CarExt } from '@/models/index';
import { FeatureExt } from 'src/lib/OlMapManager';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';

import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';

interface NormalCarIconPath {
  carIconPath: string;
  selectedFrameIconPath: string;
}

interface Opts {
  width?: number;
  color?: string;
  pos?: Location;
  hideCarIcons?: boolean;
}

interface CarPointStyleParams {
  progress: number;
  zIndex: number;
}
export default class ExtremeMapCarLayerManager extends ExtremeMapAbstractLayerManager {
  opts: Opts;
  extremeMap: InstanceType<typeof ExtremeMap> | null;
  carKindsWithSpecialImg: Record<string, string>;
  johaisetsuCarKindsWithSpecialImg: Record<string, string>;
  constructor(opts: Opts = {}) {
    super();
    this.layerInfo.onLayerClick = () => {};
    this.opts = opts;
    this.extremeMap = null;
    this.carKindsWithSpecialImg = {
      '2': 'kipato', // 黄パト
      '19': 'hyosiki_ye', // 標識車
      '20': 'kara-damp_ye', // 資材車
      '29': 'kipato', // ランクル
      '30': 'kipato', // ランクル(標識付)
      '33': 'sanpu_ye', // 散布車
      '200': 'bicycle', // 自転車
      '201': 'smartphone', // スマートフォン
    };
    this.johaisetsuCarKindsWithSpecialImg = {
      '301': 'sanpu_ye', // 散水車(黄色)
      '302': 'sanpu_bl', // 散水車(青色)
      '303': 'sanpu_wt', // 散水車(白色)
      '304': 'kara-damp_ye', // ダンプ(黄色)
      '305': 'hyosiki_ye', // 標識車(黄色)
      '306': 'bulldozer_ye', // ブル
      '307': 'shovel_ye', // ショベル
      '501': 'settou_patrol_ltbl', // 雪凍パトロール報告(青)
      '502': 'shintyokukanri_or', // 進捗管理専任者(橙)
      '503': 'genbashiki_gr', // 現場指揮者(緑)
    };
  }

  getMultiLinesLayer_(arrOfLineStringCoords: Coordinate[][], opts: Opts = {}): Vector {
    const strokeColor = opts.color || '#000000';
    const strokeWidth = opts.width || 4;

    const multiLineString = new MultiLineString(arrOfLineStringCoords);
    const feature = new Feature(multiLineString);
    const vectorSource = new VectorSource({
      features: [feature],
    });

    return new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth,
        }),
      }),
    });
  }

  getNormalCarIconPath(car: CarExt): NormalCarIconPath {
    const carIconPrefix = '/static/img/car_';
    const statusPrefix = car.isMoving ? 'moving_' : 'stopped_';
    const imgCarKind =
      this.carKindsWithSpecialImg[car.device?.car_kind || ''] || 'default';
    const carIconPath = `${carIconPrefix}${statusPrefix}${imgCarKind}.png`;
    const selectedFrameIconPath = `${carIconPrefix}selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getExtCyzenCarIconPath(car: CarExt): NormalCarIconPath {
    // TODO 多分アイコン変える
    const carIconPrefix = '/static/img/car_';
    const statusPrefix = car.isMoving ? 'moving_' : 'stopped_';
    const carIconPath = `${carIconPrefix}${statusPrefix}default.png`;
    const selectedFrameIconPath = `${carIconPrefix}selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getExtSafieCarIconPath(car: CarExt): NormalCarIconPath {
    const carIconPrefix = '/static/img/car_';
    const statusPrefix = car.isMoving ? 'moving_' : 'stopped_';
    const carIconPath = `${carIconPrefix}${statusPrefix}safie.png`;
    const selectedFrameIconPath = `${carIconPrefix}selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getExtJohaisetsuCarIconPath(car: CarExt): NormalCarIconPath {
    const carIconPrefix = '/static/img/car_';
    const statusPrefix = car.isMoving ? 'moving_' : 'stopped_';
    const imgCarKind =
      this.johaisetsuCarKindsWithSpecialImg[car.device?.car_kind || ''] || 'default';
    const carIconPath = `${carIconPrefix}${statusPrefix}${imgCarKind}.png`;
    const selectedFrameIconPath = `${carIconPrefix}selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getExternalCarIconPath(car: CarExt): NormalCarIconPath {
    let ret = { carIconPath: '', selectedFrameIconPath: '' };
    if (car.external_type === 'cyzen') {
      ret = this.getExtCyzenCarIconPath(car);
    } else if (car.external_type === 'safie') {
      ret = this.getExtSafieCarIconPath(car);
    } else if (car.external_type === 'johaisetsu') {
      ret = this.getExtJohaisetsuCarIconPath(car);
    }
    return ret;
  }

  getCarStyles(car: CarExt, zIndex: number): Style[] | null {
    if (!car.shouldShow) { return null; }

    const ret: Style[] = [];
    const { carIconPath, selectedFrameIconPath } =
      !car.is_external_car
        ? this.getNormalCarIconPath(car)
        : this.getExternalCarIconPath(car);

    ret.push(new Style({
      image: new Icon({
        src: carIconPath,
        anchor: [0.5, 0.95],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
      zIndex,
    }));
    if (car.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFrameIconPath,
          anchor: [0.5, 0.95],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
        zIndex: zIndex + 1,
      }));
    }
    if (car.isWorking) {
      ret.push(new Style({
        image: new Icon({
          src: '/static/img/car_is_working.png',
          anchor: [2.5, 6],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.02,
          opacity: 1.0,
        }),
        zIndex: zIndex + 2,
      }));
    }
    if (car.device?.is_publishing) {
      ret.push(new Style({
        image: new Icon({
          src: '/static/img/car_is_publishing.png',
          anchor: [1.72, 4.7],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.02,
          opacity: 1.0,
        }),
        zIndex: zIndex + 2,
      }));
    }

    return ret;
  }

  getCarFeature(car: CarExt, zIndex: number): Feature {
    const feat = new Feature({
      geometry: car.pos ? new Point(car.pos) : undefined,
    });
    feat.setId(car.device_id);
    feat.setStyle(this.getCarStyles(car, zIndex));
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCar(car);
      }
    };
    return feat;
  }

  getCarPointStyle({ progress, zIndex } : CarPointStyleParams): Style {
    return new Style({
      image: new Circle({
        radius: 6 * progress,
        fill: new Fill({
          color: [102, 194, 255, 0.8 * progress],
        }),
        stroke: new Stroke({
          color: [0, 122, 204, 0.8 * progress],
          width: 1,
        }),
      }),
      zIndex: zIndex,
    });
  }

  getTrailingPointFeatures(car: CarExt, zIndexStart: number): Feature[] {
    // 車に近い方から遠ざかっていく方に並んでいるので、逆にする
    // (車に近い方を上にしたいので)
    const points = car.trailingPoints?.slice().reverse() || [];
    points.push(this.convCoord({lon: car.lon, lat: car.lat}));
    const stepMargin = 3;
    const step = 1 / (points.length + stepMargin);
    let zIndex = zIndexStart;

    const feats = points.slice(0, -1).map((point, i) => {
      const progress = (i + stepMargin + 1) * step;
      const feat = new Feature({
        geometry: new Point(point),
      });
      feat.setStyle(this.getCarPointStyle({ progress, zIndex }));
      zIndex++;
      return feat;
    });

    const lastFeat = new Feature({
      geometry: new Point(points.slice(-1)[0]),
    });
    const lastFeatStyles = [this.getCarPointStyle({ progress: 1.0, zIndex })];
    if (car.isMoving && car.angle !== null) {
      lastFeatStyles.push(new Style({
        image: new Icon({
          src: '/static/img/arrow-small1.png',
          anchor: [0.5, 0.5 + 1.34], // anchorの計算がされてからrotateされる
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          rotation: car.angle / 180.0 * Math.PI,
        }),
        zIndex: zIndex++,
      }));
    }
    lastFeat.setStyle(lastFeatStyles);
    (lastFeat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCar(car);
      }
    };
    feats.push(lastFeat);
    return feats;
  }

  createCarLayer_(cars: CarExt[], { hideCarIcons }: Opts): void {
    const carFeats: Feature[] = [];
    const trailingPointFeats: Feature[] = [];
    let trailZIndex = 1;
    let carZIndex = 200001;
    cars.forEach(car => {
      const trailFeats = this.getTrailingPointFeatures(car, trailZIndex);
      trailZIndex += trailFeats.length + 1;
      trailingPointFeats.push(...trailFeats);
      if (!car.shouldShow) { return; }

      if (!hideCarIcons) {
        carFeats.push(this.getCarFeature(car, carZIndex++));
        carZIndex += 5; // styleが複数の場合が有りえるので適当に増やす
      }

      if (car.color && car.pos) {
        const feat = new Feature({
          geometry: new Point(car.pos),
        });
        feat.setStyle(new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({
              color: [car.color[0], car.color[1], car.color[2], 1.0],
            }),
            stroke: new Stroke({
              color: [car.color[0], car.color[1], car.color[2], 1.0],
              width: 1,
            }),
          }),
          zIndex: carZIndex++,
        }));
        carFeats.push(feat);
      }

      // 配列の先頭側にある方を上にする
      carZIndex -= 50;
    });

    const features = [...trailingPointFeats, ...carFeats];
    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: features as Feature[]}),
    });
    layer.name = 'cars';
    const carPosCoords = cars.map(e => e.pos || []);
    const extent = carPosCoords.length > 0
      ? boundingExtent(carPosCoords) : null;

    this.layer = layer;
    this.layerInfo.extent = extent;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (feature) {
        (feature as FeatureExt).onClickFunc();
      }
    };
  }

  prepareLayer(extremeMap: InstanceType<typeof ExtremeMap>, cars: CarExt[], opts: Opts = {}): LayerWithInfo {
    const carsConv = cars.map(car => {
      car.pos = this.convCoord({lon: car.lon, lat: car.lat});
      if (!car.trailingPoints) {
        car.trailingPoints = (car.tail || []).map(pos => {
          return this.convCoord({lon: pos.lon, lat: pos.lat});
        });
      }
      return car;
    });
    this.extremeMap = extremeMap;
    this.createCarLayer_(carsConv, opts);
    return this.getLayer();
  }
}
