import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { AdminSetTairyuIgnitionInfosParams } from '@/models/apis/cars/tairyuIgnitionRequest';
import { AdminTairyuIgnitionInfo, AdminGetTairyuIgnitionInfosResponse } from '@/models/apis/cars/tairyuIgnitionResponse';

export default {
  adminGetTairyuIgnitionInfos(): Promise<AxiosResponse<AdminGetTairyuIgnitionInfosResponse>> {
    return axios.get(utils.getApiUrl('/admin/tairyu_ignition_infos'));
  },
  adminSetTairyuIgnitionInfos(data: AdminSetTairyuIgnitionInfosParams): Promise<AxiosResponse<AdminTairyuIgnitionInfo>> {
    return axios.post(utils.getApiUrl('/admin/tairyu_ignition_infos'), data);
  },
  adminInvalidateTairyuIgnitionInfos(): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/admin/tairyu_ignition_infos/invalidate'));
  },
};
