import { Style, Stroke } from 'ol/style';
import { hslToRgb } from '@/lib/colorHelper';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class salinityHelper extends AbstractGeoItem<number, number, number, number[]> {
  dataStep(): number {
    return 1; // %
  }
  dataToObjKey(inputData: number): number {
    // 過渡期のため、スカラーもしくはオブジェクトの両方がありうる.
    // 落ち着いたら全部オブジェクトになるので消す.
    // (2018/11/18 なぜオブジェクトがあり得るのか忘れた. スカラーでよいのでは???)
    let data: DataContent = { v: 0 };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    const step = this.dataStep();
    return Math.floor(data.v as number / step) * step;
  }
  objKeyToColor(objKey: number): number[] {
    let x = objKey;
    // 散布する塩水の最高濃度は確か20%程度
    const min = 0;
    const max = 25;
    // 0 <= x <= 25
    x = Math.min(Math.max(x, min), max);
    let rgb = [0, 0, 0];
    if (x === 0) {
      rgb = hslToRgb(0.4, 1.0, 0.5);
    } else {
      const dispStep = 5;
      x = Math.floor(x / dispStep) * dispStep;
      // 0.2 >= h >= 0.1
      const h = 0.2 - x / max * 0.2;
      rgb = hslToRgb(h, 1.0, 0.5);
    }
    // 0.8 <= alpha <= 1.0
    const alpha = 0.8 + x / max * 0.2;
    return [...rgb, alpha];
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
