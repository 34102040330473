import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import { EmptyResponse } from '@/models/index';
import { TaskForceResponse, TaskForce } from '@/models/apis/taskForce/taskForceResponse';

export default {
  index(): Promise<AxiosResponse<TaskForceResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_task_forces'));
  },
  create(params: TaskForce): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_task_forces'), params || {});
  },
  update(id: number, params: TaskForce): Promise<AxiosResponse<EmptyResponse>> {
    return axios.put(utils.getApiUrl(`/johaisetsu_task_forces/${id}`), params || {});
  },
  destroy(id: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.delete(utils.getApiUrl(`/johaisetsu_task_forces/${id}`));
  },
};
