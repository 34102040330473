import { reactive } from '@vue/composition-api';

import johaisetsuReportApi from '@/apis/johaisetsu/settouSagyouReport/johaisetsu_report';

import { ReportState, SearchState } from '@/models/johaisetsu/johaisetsuMap';
import {
  deselectReportItems,
  convSearchParamTsTo,
  convReport,
  loadJohaisetsuMtxs,
} from '@/components/Johaisetsu/JohaisetsuMap/utils';
import { createInjection } from '@/lib/createInjection';
import { JOHAISETSU_REPORT_MAX_MTXS } from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import {
  filterReportsByJohaisetsuType,
  getReportsWithBaseArrivalTimestamp,
} from '@/lib/johaisetsu/johaisetsuHelper';

interface InjectedState {
  reportState: ReportState;
  searchState: SearchState;
}

export interface UseJohaisetsuReportsResult {
  reportState: ReportState;
  searchReports: () => Promise<void>;
  selectReport: (reportId: number) => Promise<void>;
  deselectReport: (reportId: number) => boolean;
  getSelectedReport: () => JohaisetsuReportExt | null;
  deselectSelectedReports: () => void;
  clearReports: () => void;
}

const { provide, inject } = createInjection<InjectedState>('johaisetsuMap/useJohaisetsuReports');

export function provideJohaisetsuReports(searchState: SearchState): InjectedState {
  const reportState = reactive<ReportState>({
    reports: [],
    filteredReports: [],
  });
  const injectedState = { reportState, searchState };
  provide(injectedState);
  return injectedState;
}

export function useJohaisetsuReports(defaultInjectedState?: InjectedState): UseJohaisetsuReportsResult {
  const injectedState = defaultInjectedState ?? inject();
  const reportState = injectedState.reportState;
  const searchState = injectedState.searchState;

  const searchReports = async(): Promise<void> => {
    // 管轄を選んだ時には、その管轄の除排雪協力会社のみを検索対象にする
    const johaisetsuCompanyIds: number[] = [];
    if (searchState.searchConds.johaisetsuCompanyId) {
      johaisetsuCompanyIds.push(searchState.searchConds.johaisetsuCompanyId);
    } else if (searchState.searchConds.johaisetsuArea) {
      const companyIds = searchState.johaisetsuCompanyAreaMap[searchState.searchConds.johaisetsuArea];
      if (companyIds) {
        johaisetsuCompanyIds.push(...companyIds);
      }
    }
    await fetchReports(johaisetsuCompanyIds);
    filterReports(johaisetsuCompanyIds);
  };

  const fetchReports = async(johaisetsuCompanyIds: number[]): Promise<void> => {
    searchState.decidedSearchConds = { ...searchState.searchConds };
    const reqParams = {
      ts_from: searchState.decidedSearchConds.dateFrom,
      ts_to: convSearchParamTsTo(searchState.decidedSearchConds.dateTo),
      johaisetsu_company_ids: johaisetsuCompanyIds,
      johaisetsu_han_name: searchState.searchConds.johaisetsuHanName,
      johaisetsu_type: searchState.johaisetsuType,
      work_type: searchState.workType,
    };
    const { data: johaisetsuReports } = await johaisetsuReportApi.index(reqParams);
    const reportsWithArrivalTimestamp = getReportsWithBaseArrivalTimestamp(johaisetsuReports);
    const filteredReports = filterReportsByJohaisetsuType(reportsWithArrivalTimestamp);
    reportState.reports = filteredReports.map(report => convReport(report, searchState.johaisetsuCompanies));
  };

  const filterReports = (johaisetsuCompanyIds: number[]): void => {
    let filteredReports = [...reportState.reports];
    if (searchState.johaisetsuType) {
      filteredReports = filteredReports.filter(report => report.johaisetsu_type === searchState.johaisetsuType);
    }
    if (searchState.workType) {
      filteredReports = filteredReports.filter(report => report.work_type === searchState.workType);
    }
    if (searchState.searchConds.johaisetsuHanName) {
      filteredReports = filteredReports.filter(report => {
        return report.johaisetsu_han_name === searchState.searchConds.johaisetsuHanName;
      });
    }
    if (johaisetsuCompanyIds.length) {
      const companyMap = window.johaisetsuMaster.companyList;
      const areaKeys = window.master.johaisetsuRoles.vals
        .filter(role => johaisetsuCompanyIds.includes(role.id))
        .map(role => role.key);
      filteredReports = filteredReports.filter(report => {
        const company = companyMap.find(company => company.id === report.johaisetsu_company_id);
        if (!company) return false;

        const johaisetsuRoles = company.johaisetsu_company_role.map(e => e.johaisetsu_role);
        if (!johaisetsuRoles) return false;

        return areaKeys.some(key => johaisetsuRoles.includes(key));
      });
    }
    reportState.filteredReports = filteredReports;
  };

  const getSelectedReport = (): JohaisetsuReportExt | null => {
    return reportState.reports.find(e => e.isSelected) ?? null;
  };

  const selectReport = async(reportId: number): Promise<void> => {
    for (const report of reportState.reports) {
      report.isSelected = report.id === reportId;
      if (!report.isSelected) continue;

      if (!report.isMtxsLoaded) {
        await loadJohaisetsuMtxs([report], JOHAISETSU_REPORT_MAX_MTXS);
      }
    }
  };

  const deselectReport = (reportId: number): boolean => {
    const report = getSelectedReport();
    if (!report || report.id !== reportId) return false;

    report.isSelected = false;
    deselectReportItems(report);
    return true;
  };

  const deselectSelectedReports = (): void => {
    const report = getSelectedReport();
    if (!report) return;

    deselectReportItems(report);
  };

  const clearReports = (): void => {
    reportState.reports = [];
    reportState.filteredReports = [];
  };

  return {
    reportState,
    searchReports,
    getSelectedReport,
    selectReport,
    deselectReport,
    deselectSelectedReports,
    clearReports,
  };
}
