import movieViewStatusApi from '@/apis/movie_view_status';
import { Item } from '@/models/apis/movie/movieRequest';
import { Movie } from '@/models/apis/movie/movieResponse';
import { CarExt, MovieCandidate } from '@/models/index';

const MOVIE_TYPE_LIVE = 'live';
const MOVIE_TYPE_VOD = 'vod';

export interface MovieDialogInfoOption {
  left: number;
  top: number;
  width: number;
  height: number;
}
export interface MovieDialogInfo {
  kind: string;
  dialogId: string;
  car: CarExt;
  deviceId: string;
  movie: Movie;
  options: MovieDialogInfoOption;
  candidacies: MovieCandidate[];
  candidacyIdx: number;
}

export default class MovieViewStatusManager {
  movieViewStatusUuid: string | null;
  movieViewStatusMap: Record<string, Item>;
  movieViewStatusUpdateTimer: number | null;
  constructor() {
    this.movieViewStatusUuid = null;
    // { [windowNumber]: { windowNumber: number, movieType: string, movieId: string, serverHostPort: string } }
    this.movieViewStatusMap = {} as Record<string, Item>;
    this.movieViewStatusUpdateTimer = null;
  }

  async start(): Promise<void> {
    this.movieViewStatusUuid = await this._getUuid();
    window.clearRequestTimeout(this.movieViewStatusUpdateTimer);
    this._loop().then(() => {});
  }

  async _getUuid(): Promise<string> {
    return (await movieViewStatusApi.getUuid()).data.uuid;
  }

  // 15秒ごとに、現在の再生状況を送信
  async _loop(): Promise<void> {
    await this._sendMovieViewStatuses();
    this.movieViewStatusUpdateTimer = window.requestTimeout(() => this._loop(), 15000);
  }

  async _sendMovieViewStatuses(filterWindowNumbers: number[] = []): Promise<void> {
    const params = Object.values(this.movieViewStatusMap)
      .filter(obj => {
        // 指定無い場合は全て
        if (filterWindowNumbers.length === 0) { return true; }
        // 指定有る場合はそれらだけ
        return filterWindowNumbers.includes(obj.window_number);
      });
    if (params.length === 0) {
      return;
    }

    try {
      await movieViewStatusApi.sendStats(
        this.movieViewStatusUuid,
        params,
      );
    } catch (e) {}
  }

  _movieDialogInfosToMovieViewStatusItems(movieDialogInfos: MovieDialogInfo[]): Item[] {
    return movieDialogInfos.map(m => {
      if (m.kind === 'realtime') {
        const tmpMatch = m.dialogId.match(/^live-(\d+)$/);
        if (!tmpMatch || !m.car.device?.live_stream) { return null; }
        return {
          window_number: parseInt(tmpMatch[1]) + 1,
          movie_type: MOVIE_TYPE_LIVE,
          movie_id: m.deviceId.toString(),
          server_hostport: m.car.device.live_stream.https_streaming_server_hostport,
        };
      } else if (m.kind === 'stored') {
        const tmpMatch = m.dialogId.match(/^stored-(\d+)$/);
        if (!tmpMatch) { return null; }
        return {
          window_number: parseInt(tmpMatch[1]) + 1,
          movie_type: MOVIE_TYPE_VOD,
          movie_id: m.movie.id.toString(),
          server_hostport: m.movie.https_streaming_server_hostport,
        };
      } else {
        return null;
      }
    }).filter(e => e !== null) as Item[];
  }

  // 動画ウィンドウが追加されたことを通知するリクエストを送信する.
  async _setMovieViewStatuses(items: Item[]): Promise<void> {
    if (!this.movieViewStatusUuid) { return; }
    // ステートに追加
    items.forEach(item => {
      this.movieViewStatusMap[item.window_number] = item;
    });
    // 追加の旨を送信
    const windowNumbers = items.map(item => item.window_number);
    await this._sendMovieViewStatuses(windowNumbers);
  }

  // movieDialogInfosから内部的に利用する形式にデータ変換して、内部メソッドを呼び出す.
  async setMovieViewStatuses(movieDialogInfos: MovieDialogInfo[]): Promise<void> {
    const movieViewStatusItems = this._movieDialogInfosToMovieViewStatusItems(movieDialogInfos);
    await this._setMovieViewStatuses(movieViewStatusItems);
  }

  // 動画ウィンドウが削除されたことを通知するリクエストを送信する.
  async _deleteMovieViewStatus(windowNumber: number): Promise<void> {
    if (!this.movieViewStatusUuid) { return; }
    // ステートから削除
    delete this.movieViewStatusMap[windowNumber];
    // 削除の旨を送信
    try {
      await movieViewStatusApi.delete(this.movieViewStatusUuid, windowNumber);
    } catch (e) {}
  }

  // movieDialogInfoからwindowNumberに変換して、内部メソッドを呼び出す.
  async deleteMovieViewStatus(movieDialogInfo: MovieDialogInfo): Promise<void> {
    const movieViewStatusItems = this._movieDialogInfosToMovieViewStatusItems([movieDialogInfo]);
    await this._deleteMovieViewStatus(movieViewStatusItems[0].window_number);
  }

  stop(): void {
    window.clearRequestTimeout(this.movieViewStatusUpdateTimer);
  }

  async notifyStartLiveStream(): Promise<void> {
    try {
      await movieViewStatusApi.notifyStartLiveStream();
    } catch (e) {}
  }
}
