









































import { computed, defineComponent, onMounted } from '@vue/composition-api';
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';
import { Settings } from '@/models/apis/user/userResponse';

import { waitForUserAndMasters } from '@/lib/masterHelper';

export default defineComponent({
  name: 'admin-top',
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const isAdmin = computed<boolean>(() => {
      return userState.has_role_admin;
    });
    const userSettings = computed<Settings>(() => {
      return userState.settings;
    });
    const shouldShowTairyuLink = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    const isCommentAvailable = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.is_comment_available;
    });
    const { router } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      if (!isAdmin) {
        await router.replace({ name: 'Login' });
      }
    });
    return {
      // computed
      isAdmin,
      userSettings,
      shouldShowTairyuLink,
      isCommentAvailable,
    };
  },
});
