import { computed, ComputedRef, reactive } from '@vue/composition-api';
import { JohaisetsuTypeMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { createInjection } from '@/lib/createInjection';
import { WORK_TYPE_NAME_DEFAULT } from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';
import { RoadNameDirection } from '@/models/index';

export interface SettouReportState {
  ability: number;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
  johaisetsuWorkTypeName?: string;
}

export interface UseSettouReportParams {
  selectedJohaisetsuType: string | null;
}

interface State {
  selectedJohaisetsuType: string | null;
}

const { provide, inject } = createInjection<SettouReportState>('settouReport/useSettouReport');

export function provideSettouReport(state: SettouReportState): SettouReportState {
  const injectedState = reactive<SettouReportState>(state);
  provide(injectedState);
  return injectedState;
}

export interface UseSettouReportResult {
  state: State;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
  johaisetsuTypeNameDisp: ComputedRef<string>;
  johaisetsuTypeMap: { [key: string]: JohaisetsuTypeMap };
  workType: string;
  johaisetsuTypes: JohaisetsuTypeMap[];
  roadNames: RoadNameDirection[];
  isShowJohaisetsuTypeSelect: boolean;
  johaisetsuWorkTypeName: string;
  workStartLabel: string;
  restartWorkingLabel: string;
  pauseWorkingLabel: string;
  stopWorkingLabel: string;
}

export default function useSettouReport(params: UseSettouReportParams): UseSettouReportResult {
  const state = reactive<State>({
    selectedJohaisetsuType: params.selectedJohaisetsuType,
  });
  const settouReport = inject();
  if (!settouReport) {
    throw new Error('SettouReport is not provided');
  }
  const johaisetsuTypeGroup = settouReport.johaisetsuTypeGroup;
  const johaisetsuTypeGroupName = settouReport.johaisetsuTypeGroupName;
  const johaisetsuTypeMap = window.johaisetsuMaster.johaisetsuTypeMap;
  const johaisetsuTypeObj = johaisetsuTypeMap[state.selectedJohaisetsuType || ''];
  const workType = johaisetsuTypeObj.work_type ?? '';
  const johaisetsuTypes = window.johaisetsuMaster.johaisetsuTypes.filter(e => {
    return e.key.endsWith(settouReport.johaisetsuTypeGroup);
  });
  const roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
  const isShowJohaisetsuTypeSelect = johaisetsuTypes.length > 1;
  const johaisetsuTypeNameDisp = computed<string>(() => {
    let ret = '';
    if (isShowJohaisetsuTypeSelect) {
      ret = `(${workType})`;
    }
    return ret;
  });
  const johaisetsuWorkTypeName = settouReport.johaisetsuWorkTypeName ?? WORK_TYPE_NAME_DEFAULT;
  const workStartLabel = `${johaisetsuWorkTypeName}開始`;
  const restartWorkingLabel = `${johaisetsuWorkTypeName}再開`;
  const pauseWorkingLabel = `${johaisetsuWorkTypeName}一時停止`;
  const stopWorkingLabel = `${johaisetsuWorkTypeName}終了`;

  return {
    state,
    johaisetsuTypeGroup,
    johaisetsuTypeGroupName,
    johaisetsuTypeNameDisp,
    workType,
    johaisetsuTypeMap,
    johaisetsuTypes,
    roadNames,
    isShowJohaisetsuTypeSelect,
    johaisetsuWorkTypeName,
    workStartLabel,
    restartWorkingLabel,
    pauseWorkingLabel,
    stopWorkingLabel,
  };
}
