
































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { Error, Location, Position, GeolocationPositionError } from '@/models/index';
import { GeolocationOpts } from '@/models/geoItem';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { dtFormat } from '@/lib/dateHelper';

interface DevDebugGeolocationState {
  // nosleep
  isWakeLockEnabled: boolean;

  // geolocation
  mapBaseURL: string;
  mapURL: string;

  currentLocation: Location;
  histories: Location[];

  errors: Error[];

  watchPositionId: number | null;
  intervalId1: number | null;
  intervalSec1: number; // seconds

  isGettingCurrentLocation: boolean;

  geolocationOpts: GeolocationOpts;

  currentTime?: Date;
}
export default defineComponent({
  name: 'dev-debug-geolocation',
  setup() {
    const mapBaseURL = 'https://maps.google.co.jp/maps?output=embed';
    const state = reactive<DevDebugGeolocationState>({
      // nosleep
      isWakeLockEnabled: false,

      // geolocation
      mapBaseURL: mapBaseURL,
      mapURL: mapBaseURL,

      currentLocation: {} as Location,
      histories: [],

      errors: [],

      watchPositionId: null,
      intervalId1: null,
      intervalSec1: 1, // seconds

      isGettingCurrentLocation: false,

      geolocationOpts: {
        enableHighAccuracy: true,
        timeout: 5 * 1000,
        maximumAge: 0,
      },
    });
    onMounted(() => {
      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
    });

    const enableWakeLock = () => {
      enableNoSleep();
      state.isWakeLockEnabled = true;
    };
    const disableWakeLock = () => {
      disableNoSleep();
      state.isWakeLockEnabled = false;
    };
    const onGeolocationError = (evt: GeolocationPositionError) => {
      const { code, message } = evt;
      state.errors.push({ code, message });
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      // 1 PERMISSION_DENIED
      // 2 POSITION_UNAVAILABLE
      // 3 TIMEOUT
    };
    const refreshMapURL = (location: Location) => {
      console.log('refreshMapURL');
      let url = state.mapBaseURL;
      const { lat, lon } = location || state.currentLocation;
      if (!!lat && !!lon) {
        url += `&q=${lat},${lon}`;
      }
      state.mapURL = url;
    };
    const setCurrentLocation = (position: Position) => {
      console.log('lat: ', position.coords.latitude, 'lon: ', position.coords.longitude);
      clearErrors();
      let { latitude, longitude } = position.coords;
      // 小数点第6位まで
      const base = 1000000;
      latitude = Math.floor(latitude * base) / base;
      longitude = Math.floor(longitude * base) / base;
      state.currentLocation = { lat: latitude, lon: longitude };
    };
    const storeCurrentLocation = () => {
      const { lat, lon } = state.currentLocation;
      const now = new Date();
      const currentLocation = { ts: now, lat: lat, lon: lon };
      state.currentTime = now;
      state.histories.push(currentLocation);
    };
    const clearErrors = () => {
      state.errors = [];
    };
    const clearTimers = () => {
      if (state.intervalId1) {
        clearInterval(state.intervalId1);
      }
    };
    const clearWatchPosition = () => {
      if (state.watchPositionId) {
        navigator.geolocation.clearWatch(state.watchPositionId);
      }
    };
    const clearAll = () => {
      clearErrors();
      state.currentLocation = {} as Location;
      state.histories = [];
      clearTimers();
      clearWatchPosition();
    };
    const startGettingCurrentLocation = async() => {
      clearAll();
      state.watchPositionId = navigator.geolocation.watchPosition(
        setCurrentLocation,
        onGeolocationError,
        state.geolocationOpts,
      );
      state.intervalId1 = setInterval(storeCurrentLocation, state.intervalSec1 * 1000);
      state.isGettingCurrentLocation = true;
    };
    const stopGettingCurrentLocation = () => {
      clearErrors();
      clearTimers();
      clearWatchPosition();
      state.isGettingCurrentLocation = false;
    };

    onUnmounted(() => { // from destroyed
      clearTimers();
      clearWatchPosition();
    });

    return {
      ...toRefs(state),
      // methods
      enableWakeLock,
      disableWakeLock,
      refreshMapURL,
      startGettingCurrentLocation,
      stopGettingCurrentLocation,
      dtFormat,
    };
  },
});
