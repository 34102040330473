










// const LAYOUT_HORIZONTAL = 'horizontal'

import {
  defineComponent,
  reactive,
  computed,
} from '@vue/composition-api';

const LAYOUT_VERTICAL = 'vertical';
interface EvtInfo {
  pane: HTMLElement;
  resizer: HTMLElement;
  initialPageX: number;
  initialPageY: number;
  resizeFunc: (offset: number) => void;
}
interface MultipaneState {
  isResizing: boolean;
  tmpEvtInfo: EvtInfo | null;
}
export default defineComponent({
  name: 'multipane',
  props: {
    layout: {
      type: String,
      default: LAYOUT_VERTICAL,
    },
  },
  setup(props, { root, emit }) {
    const state = reactive<MultipaneState>({
      isResizing: false,
      tmpEvtInfo: null,
    });
    const classnames = computed(() => {
      return [
        'multipane',
        'layout-' + props.layout.slice(0, 1),
        state.isResizing ? 'is-resizing' : '',
      ];
    });
    const styleCursor = computed(() => {
      return state.isResizing
        ? (props.layout === LAYOUT_VERTICAL ? 'col-resize' : 'row-resize')
        : '';
    });
    const styleUserSelect = computed(() => {
      return state.isResizing ? 'none' : '';
    });
    const onMouseDown = (evt: MouseEvent | TouchEvent) => {
      const eventObj: MouseEvent | Touch = evt.type.indexOf('touch') !== -1
        ? (evt as TouchEvent).changedTouches[0]
        : evt as MouseEvent;
      const resizer = evt.target as HTMLElement;
      if (!resizer.className || !resizer.className.match('multipane-resizer')) {
        return;
      }
      const initialPageX = eventObj.pageX;
      const initialPageY = eventObj.pageY;

      const multipaneElement = root.$el;
      const pane = resizer.previousElementSibling as HTMLElement;
      const {
        offsetWidth: initialPaneWidth,
        offsetHeight: initialPaneHeight,
      } = pane;

      const usePercentage = !!(pane.style.width + '').match('%');

      let resizeFunc;
      if (props.layout === LAYOUT_VERTICAL) {
        // boxは横方向
        resizeFunc = (offset = 0, baseWidth = initialPaneWidth) => {
          const containerWidth = multipaneElement.clientWidth;
          const paneWidth = baseWidth + offset;
          pane.style.width = usePercentage
            ? paneWidth / containerWidth * 100 + '%'
            : paneWidth + 'px';
          return pane.style.width;
        };
      } else {
        // boxは縦方向
        resizeFunc = (offset = 0, baseHeight = initialPaneHeight) => {
          const containerHeight = multipaneElement.clientHeight;
          const paneHeight = baseHeight + offset;
          pane.style.height = usePercentage
            ? paneHeight / containerHeight * 100 + '%'
            : paneHeight + 'px';
          return pane.style.height;
        };
      }

      // This adds is-resizing class to container
      state.isResizing = true;
      state.tmpEvtInfo = { pane, resizer, initialPageX, initialPageY, resizeFunc };

      // Resize once to get current computed size
      const size = resizeFunc();
      emit('paneResizeStart', pane, resizer, size);

      document.documentElement.addEventListener('mousemove', onMouseMove, true);
      document.documentElement.addEventListener('mouseup', onMouseUp, true);
      // touch events
      document.documentElement.addEventListener('touchmove', onMouseMove, true);
      document.documentElement.addEventListener('touchend', onMouseUp, true);
      document.documentElement.addEventListener('touchcancel', onMouseUp, true);
      // 2本目タップされたら終了させたい
      document.documentElement.addEventListener('touchstart', onMouseUp, true);
    };
    const onMouseMove = (evt: MouseEvent | TouchEvent) => {
      if (!state.tmpEvtInfo) { return; }
      const eventObj: MouseEvent | Touch = evt.type.indexOf('touchmove') !== -1
        ? (evt as TouchEvent).changedTouches[0]
        : evt as MouseEvent;
      const pageX = eventObj.pageX;
      const pageY = eventObj.pageY;

      const resizeFunc = state.tmpEvtInfo.resizeFunc;
      const size = props.layout === LAYOUT_VERTICAL
        ? resizeFunc(pageX - state.tmpEvtInfo.initialPageX)
        : resizeFunc(pageY - state.tmpEvtInfo.initialPageY);
      emit('paneResize', state.tmpEvtInfo.pane, state.tmpEvtInfo.resizer, size);
    };
    const onMouseUp = () => {
      if (!state.tmpEvtInfo) { return; }
      // This removes is-resizing class to container
      state.isResizing = false;

      document.documentElement.removeEventListener('mousemove', onMouseMove, true);
      document.documentElement.removeEventListener('mouseup', onMouseUp, true);
      // touch events
      document.documentElement.removeEventListener('touchmove', onMouseMove, true);
      document.documentElement.removeEventListener('touchend', onMouseUp, true);
      document.documentElement.removeEventListener('touchcancel', onMouseUp, true);
      document.documentElement.removeEventListener('touchstart', onMouseUp, true);

      emit('paneResizeStop', state.tmpEvtInfo.pane, state.tmpEvtInfo.resizer);
      state.tmpEvtInfo = null;
    };
    return {
      // computed:
      classnames,
      styleCursor,
      styleUserSelect,
      // methods:
      onMouseDown,
    };
  },
});
