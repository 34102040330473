













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'load-wait-spinner',
  props: {
    message: {
      type: String,
      default: 'データロード中...',
    },
  },
});
