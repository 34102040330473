


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import weatherObservatoryApi from '@/apis/weather_observatory';
import { WeatherObservatoryWithDataPoints } from '@/models/apis/weatherObservatory/weatherObservatoryResponse';
import { WeatherObservatoryIndexRequest } from '@/models/apis/weatherObservatory/weatherObservatoryRequest';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { downloadObjectUrl } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { useRoute } from '@/hooks/useRoute';
import DataPointsChartModal from './components/DataPointsChartModal/index.vue';
import {
  ConvWeatherObservatory,
  ConvWeatherObservatoriesByArea,
  WeatherObservatorydDataPointsTableState,
  origAreas,
  ONE_DAY_IN_SECONDS,
  MAX_SEARCH_DATE_RANGE,
} from './utils';
import { getInitDataPoint } from '@/lib/weatherObservatoryHelper';
import Encoding from 'encoding-japanese';

export default defineComponent({
  name: 'weather-observatory-data-points-table',
  setup() {
    const now = new Date();
    const state = reactive<WeatherObservatorydDataPointsTableState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,

      searchParams: {
        tsToDate: now,
        tsToTime: dtFormat(now, 'HH:MM').substring(0, 4) + '0',
      },

      searchTimeChoices: [],

      areas: ['t-west', 't-east', 'kanagawa'],
      observatories: [],
      observatoriesByArea: {},
      selectedObservatoryIds: [],
      timestamps: [],
      timeLabels: [],

      showChartModal: false,
      showErrorModal: false,
      errorBody: '',
    });
    const store = useStore();
    const userState = store.state.user;
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });
    const filteredObservatories = computed<ConvWeatherObservatoriesByArea>(() => {
      return state.areas.reduce((acc: ConvWeatherObservatoriesByArea, area) => {
        acc[area] = state.observatoriesByArea[area];
        return acc;
      }, {});
    });
    const tsToDatetime = computed<Date>(() => {
      const paramsTsTo = state.searchParams.tsToDate;
      const [hour, minute] = state.searchParams.tsToTime.split(':');
      paramsTsTo.setHours(Number(hour), Number(minute), 0, 0);
      return paramsTsTo;
    });
    const isValidSearchDateParams = computed<boolean>(() => {
      const now = new Date();
      const timeDiffFromNowMSec = now.getTime() - tsToDatetime.value.getTime();
      const searchDateRangeThres = (MAX_SEARCH_DATE_RANGE - 1) * ONE_DAY_IN_SECONDS * 1000;
      const isWithinSearchDateRange = timeDiffFromNowMSec <= searchDateRangeThres;
      return (tsToDatetime.value <= now) && isWithinSearchDateRange;
    });
    const selectedObservatories = computed<WeatherObservatoryWithDataPoints[]>(() => {
      return state.observatories.filter(e => {
        return state.selectedObservatoryIds.find(id => id === e.id);
      });
    });
    const canShowChartModal = computed<boolean>(() => {
      return state.selectedObservatoryIds.length > 0;
    });
    const initSearchTimeChoices = (): string[] => {
      const times = [];
      for (let hour = 0; hour <= 23; hour++) {
        for (let minute = 0; minute < 60; minute += 10) {
          const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          times.push(timeString);
        }
      }
      return times;
    };
    const initSearchParams = (): void => {
      state.searchParams = {
        tsToDate: now,
        tsToTime: dtFormat(now, 'HH:MM').substring(0, 4) + '0',
      };
    };
    const getSearchParamsForReq = (): WeatherObservatoryIndexRequest => {
      const tsFrom = new Date(tsToDatetime.value.getTime() - 12 * 60 * 60 * 1000);
      const reqObj: WeatherObservatoryIndexRequest = {
        ts_from: tsFrom,
        ts_to: tsToDatetime.value,
      };
      return reqObj;
    };
    const getTimestamps = (tsToDate: Date, tsToTime: string): Date[] => {
      // tsFrom から tsTo までの10分おきのタイムスタンプを作成
      const timestamps: Date[] = [];
      const tsToDt = new Date(tsToDate.getTime());
      const [hour, minute] = tsToTime.split(':');
      tsToDt.setHours(Number(hour), Number(minute), 0, 0);
      const tsFrom = new Date(new Date(tsToDt.getTime() - 12 * 60 * 60 * 1000));
      const interval = 10 * 60 * 1000;
      for (let ts = tsFrom.getTime(); ts <= tsToDatetime.value.getTime(); ts += interval) {
        timestamps.push(new Date(ts));
      }
      return timestamps;
    };
    const getTimeLabels = (): string[] => {
      // 10分おきのタイムスタンプから1時間おきのラベルを作成
      const timeLabels: string[] = [];
      for (let i = 0; i < state.timestamps.length; i++) {
        if (i % 6 !== 0) continue;
        timeLabels.push(dtFormat(state.timestamps[i], 'HH:MM'));
      }
      return timeLabels;
    };
    const getObservatories = async(): Promise<void> => {
      if (!isValidSearchDateParams.value) return;

      state.isRequesting = true;
      try {
        const reqObj = getSearchParamsForReq();
        state.timestamps = getTimestamps(state.searchParams.tsToDate, state.searchParams.tsToTime);
        state.timeLabels = getTimeLabels();
        const { data } = await weatherObservatoryApi.index(reqObj);
        state.observatories = data;
        state.observatoriesByArea = convData(data);
        state.selectedObservatoryIds = [];
        state.isRequesting = false;
      } catch (e) {
        console.error('error', e);
        state.isRequesting = false;
      }
    };
    const convData = (observatories: WeatherObservatoryWithDataPoints[]): ConvWeatherObservatoriesByArea => {
      const converted: ConvWeatherObservatory[] = observatories.map(observatory => {
        const dataPointsByHour = state.timeLabels.map(timeLabel => {
          const dataPoint = observatory.weather_observatory_data_points.find(e =>
            dtFormat(e.timestamp, 'HH:MM') === timeLabel,
          );
          return dataPoint ??
            getInitDataPoint(observatory.id, `${dtFormat(state.searchParams.tsToDate, 'yyyy-MM-dd')} ${timeLabel}:00`);
        });
        const areaName = origAreas.find(e => e.id === observatory.area)?.name || '';
        return {
          ...observatory,
          areaName,
          dataPointsByHour,
          showAirTemperature: dataPointsByHour.some(e => e.air_temperature !== null),
          showHumidity: dataPointsByHour.some(e => e.humidity !== null),
          showRoadTemperature: dataPointsByHour.some(e => e.road_temperature !== null),
          showTenMinAccumulatedRainfall: dataPointsByHour.some(e => e.ten_min_accumulated_rainfall !== null),
          showSnowDepth: dataPointsByHour.some(e => e.snow_depth !== null),
        };
      });
      return converted.reduce((acc: { [key: string]: ConvWeatherObservatory[] }, observatory) => {
        if (!observatory.showAirTemperature &&
          !observatory.showHumidity &&
          !observatory.showRoadTemperature &&
          !observatory.showTenMinAccumulatedRainfall &&
          !observatory.showSnowDepth) {
          return acc;
        }
        const area = observatory.area;
        if (!acc[area]) {
          acc[area] = [];
        }
        acc[area].push(observatory);
        return acc;
      }, {});
    };
    const downloadCSV = async(): Promise<void> => {
      state.isDownloading = true;
      const headerRow = ['管轄', '計測地点', '計測種別'];
      state.timestamps.forEach(ts => {
        headerRow.push(dtFormat(ts, 'yyyy/mm/dd HH:MM:SS'));
      });
      const dataRows: string[] = [];
      Object.values(filteredObservatories.value).forEach((observatories) => {
        if (!observatories) return;
        observatories.forEach(observatory => {
          const dataPoints = observatory.weather_observatory_data_points;
          const airTemperatureRow = [observatory.areaName, observatory.name, '気温(℃)'];
          const roadTemperatureRow = [observatory.areaName, observatory.name, '路面温度(℃)'];
          const humidityRow = [observatory.areaName, observatory.name, '湿度(%)'];
          const rainfallRow = [observatory.areaName, observatory.name, '積雨量(mm)'];
          const snowDepthRow = [observatory.areaName, observatory.name, '積雪量(cm)'];
          state.timestamps.forEach(ts => {
            const dataPoint = dataPoints.find(e => new Date(e.timestamp).getTime() === ts.getTime());
            airTemperatureRow.push(
              dataPoint && dataPoint.air_temperature !== null
                ? dataPoint.air_temperature.toString()
                : '');
            roadTemperatureRow.push(
              dataPoint && dataPoint.road_temperature !== null
                ? dataPoint.road_temperature.toString()
                : '');
            humidityRow.push(dataPoint && dataPoint.humidity !== null ? dataPoint.humidity.toString() : '');
            rainfallRow.push(
              dataPoint && dataPoint.ten_min_accumulated_rainfall !== null
                ? dataPoint.ten_min_accumulated_rainfall.toString()
                : '');
            snowDepthRow.push(dataPoint && dataPoint.snow_depth !== null ? dataPoint.snow_depth.toString() : '');
          });
          dataRows.push(airTemperatureRow.join(','));
          dataRows.push(roadTemperatureRow.join(','));
          dataRows.push(humidityRow.join(','));
          dataRows.push(rainfallRow.join(','));
          dataRows.push(snowDepthRow.join(','));
        });
      });
      const csvString = [headerRow, ...dataRows].join('\n');
      const unicodeArray = Encoding.stringToCode(csvString);
      const sjisArray = Encoding.convert(unicodeArray, { to: 'SJIS', from: 'UNICODE' });
      const csvData = new Uint8Array(sjisArray);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const objectURL = URL.createObjectURL(blob);
      const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
      const filename = `気象観測履歴_${timestamp}`;
      try {
        await downloadObjectUrl(objectURL, filename);
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
      URL.revokeObjectURL(objectURL);
      state.isDownloading = false;
    };
    const changeShowArea = (area: string): void => {
      // 管轄の表示を切り替えた時、その管轄の観測所の選択を全て外す
      const targetObservatoryIds = state.observatoriesByArea[area].map(e => e.id);
      state.selectedObservatoryIds = state.selectedObservatoryIds.filter(id => !targetObservatoryIds.includes(id));
    };
    const openChartModal = (): void => {
      state.showChartModal = true;
    };

    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      state.searchTimeChoices = initSearchTimeChoices();
      initSearchParams();
      await getObservatories();

      state.isReady = true;
    });

    return {
      state,
      // const
      origAreas,
      // computed
      showWaitSpinner,
      filteredObservatories,
      isValidSearchDateParams,
      selectedObservatories,
      tsToDatetime,
      canShowChartModal,
      // methods
      initSearchParams,
      getSearchParamsForReq,
      getObservatories,
      downloadCSV,
      changeShowArea,
      openChartModal,
    };
  },
  components: {
    DataPointsChartModal,
  },
});
