import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { MovieSendStatsParams } from '@/models/apis/movie/movieRequest';
import { MovieSendStatsResponse, AdminMovieViewStatusesResponse } from '@/models/apis/movie/movieResponse';

export default {
  getUuid(): Promise<AxiosResponse<MovieSendStatsResponse>> {
    return axios.get(utils.getApiUrl('/movie_view_statuses/uuid'));
  },
  sendStats(uuid: string | null, params: MovieSendStatsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/movie_view_statuses'), {
      uuid,
      items: params,
    });
  },
  delete(uuid: string, windowNumber: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.delete(utils.getApiUrl(`/movie_view_statuses/${uuid}/${windowNumber}`));
  },
  notifyStartLiveStream(): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/movie_view_statuses/notify_start_live_stream'));
  },
  adminGetMovieViewStatuses(): Promise<AxiosResponse<AdminMovieViewStatusesResponse>> {
    return axios.get(utils.getApiUrl('/admin/movie_view_statuses'));
  },
};
