// https://gist.github.com/joelambert/1002116

export const requestAnimFrame = (function() {
  return window.requestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    };
})();

export type IntervalID = { value: number };

export const requestInterval = function(fn: () => void, delay: number): IntervalID {
  if (
    !window.requestAnimationFrame) {
    return { value: window.setInterval(fn, delay) };
  }

  let start = new Date().getTime();
  const handle = { value: -1 };

  function loop() {
    const current = new Date().getTime();
    const delta = current - start;

    if (delta >= delay) {
      fn();
      start = new Date().getTime();
    }

    handle.value = requestAnimFrame(loop);
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};

/* eslint-disable indent, operator-linebreak */
export const clearRequestInterval = function(handle?: IntervalID | null): void {
  if (!handle) { return; }
  window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
  window.clearInterval(handle.value);
};
/* eslint-enable */

export type TimeoutID = { value: number };

const requestTimeout = function(fn: () => void, delay: number): TimeoutID {
  if (!window.requestAnimationFrame) {
    return { value: window.setTimeout(fn, delay) };
  }

  const start = new Date().getTime();
  const handle = { value: -1 };

  function loop() {
    const current = new Date().getTime();
    const delta = current - start;

    delta >= delay ? fn() : handle.value = requestAnimFrame(loop);
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};

/* eslint-disable indent, operator-linebreak */
const clearRequestTimeout = function(handle?: TimeoutID | null): void {
  if (!handle) { return; }
  window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
  window.clearTimeout(handle.value);
};
/* eslint-enable */

export default {
  requestAnimFrame,
  requestInterval,
  clearRequestInterval,
  requestTimeout,
  clearRequestTimeout,
};
