import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';
import { store as localStorageStore } from './modules/localStorage';
import { store as userStore } from './modules/user';
import { RootState } from 'src/hooks/useStore';

Vue.use(Vuex);

const modules: ModuleTree<RootState> = {};
modules.localStorage = localStorageStore;
modules.user = userStore;

export default new Vuex.Store({
  modules,
});
