import { JohaisetsuSettouReport } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { JohaisetsuCarReportExt } from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import { SettouReportState } from '@/models/spSettouReport';
import {
  initJohaisetsuReportCommonState,
  initJohaisetsuCarReportExt,
  toJohaisetsuReportCommonExt,
  initJohaisetsuReportCommonExt,
} from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';

export const initSettouReportState = (): SettouReportState => {
  return {
    ...initJohaisetsuReportCommonState(),
    roadNameDisp: null,
    workArea: null,
    isCreatingReport: false,
    johaisetsuCar: {
      id: null,
      deviceId: null,
      report: {
        ...initJohaisetsuCarReportExt(),
        managerName: null,
        phoneNumber: null,
      },
    },
  };
};

export const initJohaisetsuReportExt = (): JohaisetsuCarReportExt => {
  return {
    ...initJohaisetsuReportCommonExt(),
    workType: null,
    managerName: null,
    phoneNumber: null,
    roadNameDisp: null,
    workArea: null,
  };
};

export const toJohaisetsuReportExt = (johaisetsuSettouReport: JohaisetsuSettouReport): JohaisetsuCarReportExt => {
  return {
    ...toJohaisetsuReportCommonExt(johaisetsuSettouReport),
    workType: johaisetsuSettouReport.work_type,
    managerName: johaisetsuSettouReport.manager_name,
    phoneNumber: johaisetsuSettouReport.phone_number,
    roadNameDisp: johaisetsuSettouReport.road_name_disp,
    workArea: johaisetsuSettouReport.work_area,
  };
};
