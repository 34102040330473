import {
  SpSettouReportState,
  CreateReportState,
  OnsiteManagementReportUpdateStateExt,
} from '@/models/spSettouReport';

export const MAX_SAVABLE_PHOTOS_COUNT = 4;
export const SETTOU_PATROL_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD = '雪凍パトロール報告書';
export const SETTOU_ONSITE_MANAGEMENT_REPORT_FILE_NAME_PREFIX_FOR_DOWNLOAD = '進捗管理専任者・現場指揮者報告書';
export const SETTOU_PATROL_REPORT_WEATHERS = [
  '晴れ',
  'くもり',
  '雨',
  '小雨',
  '雪',
  '小雪',
  'みぞれ',
];

export const initCreateReportState = (): CreateReportState => {
  return {
    errorModalMsg: '',
    showErrorModal: false,
    showConfirmCancelModal: false,
    showCompleteCreateModal: false,
    showConfirmCreateModal: false,
  };
};

export const initOnsiteManagementReportUpdateState = (): OnsiteManagementReportUpdateStateExt => {
  return {
    lat: null,
    lon: null,
    work_progress: null,
    imageCandidates: [],
    showCompleteCreateModal: false,
    isRequesting: false,
    images: [],
    work_type: null,
  };
};

export const initSpSettouReportState = (): SpSettouReportState => {
  return {
    isRequesting: false,

    roadNames: [],
    roadNameMap: {},
    directions: [],
    roadConditions: [],
    roadConditionMap: {},

    settouPatrolReport: {
      lat: null,
      lon: null,
      roadNameDisp: null,
      direction: null,
      kp: null,
      roadCondition: null,
      snowHeight: null,
      weather: null,
      temperature: null,
      roadTemperature: null,
      bikou1: null,
      imageCandidates: [],
    },
    settouOnsiteManagementReport: {
      lat: null,
      lon: null,
      work_type: null,
      road_name_disp: null,
      work_area: null,
      work_progress: null,
      imageCandidates: [],
    },
    errorObj: {},
    errorMessage: null,

    showCompleteCreateModal: false,
  };
};
