












import {
  defineComponent,
} from '@vue/composition-api';
import SnowMountainHelper from '@/lib/geo_item/snow_mountainHelper';
import { DispDataAtypical } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-snow-mountain',
  setup() {
    const snowMountainHelper = new SnowMountainHelper();
    const dispData: DispDataAtypical[] = [
      { val: true, label: 'あり' },
    ];
    dispData.forEach(e => {
      e.color = snowMountainHelper.objKeyToColor(snowMountainHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
