









import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'manual-download-links',
  setup() {
    return {};
  },
});
