import { MapViewState, PaneState, PaneStyle } from '@/models/cleaningMap';
import { computed, onMounted, onUnmounted, reactive, Ref, ref, UnwrapRef } from '@vue/composition-api';
import {
  LIST_HARD_MAX_HEIGHT,
  PANE_SIDE_HARD_MAX_WIDTH,
  PANE_SIDE_MAX_WIDTH_RATE,
  PANE_SIDE_MIN_WIDTH_RATE,
  VERTICAL_PANE_PADDING_NUMBER,
} from '@/components/CleaningMap/consts/cleaning_map';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForCleaningMasters } from '@/lib/cleaningHelper';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { useStore } from '@/hooks/useStore';
import { Settings as UserSettings } from '@/models/apis/user/userResponse';
import { CleaningReportExt, CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningCarExt } from '@/models';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';

interface RedrawCarLayerOptions {
  fitToExtent?: boolean;
  setSelectedCarToCenter?: boolean;
}
export interface UseExtremeMapResult {
  extremeMapRef: Ref;
  mapViewState: UnwrapRef<MapViewState>;
  paneStyle: PaneStyle;
  loadExtremeMapEssentials: () => Promise<void>;
  forceResizeExtremeMap: () => void;
  redrawCarLayer: (options: RedrawCarLayerOptions) => void;
  showCleaningReportLayer: (selectedReport: CleaningReportExt, fitToExtent: boolean) => void;
  removeCleaningReportLayer: () => void;
  showCleaningCarPopup: (car: CleaningCarExt) => void;
  showCleaningReportPhotoPopup: (photo: CleaningReportPhotoExt) => void;
  showCleaningMtxPopup: (mtx: CleaningMtxExt) => void;
  hidePopup: () => void;
}

export function useExtremeMap(paneState: PaneState): UnwrapRef<UseExtremeMapResult> {
  const store = useStore();
  const userState = store.state.user;
  const userSettings = computed<UserSettings>(() => {
    return userState.settings;
  });

  const extremeMapRef = ref<InstanceType<typeof ExtremeMap>>();

  // FIXME ここで定義しているのは微妙
  const paneStyle = reactive<PaneStyle>({
    paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
    paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
    listMinHeight: LIST_HARD_MAX_HEIGHT,
    listMaxHeight: LIST_HARD_MAX_HEIGHT,
  });

  const mapViewState = reactive<MapViewState>({
    extremeMapEssentials: null,
    onResizeFunc: () => {},
  });

  const loadExtremeMapEssentials = async(): Promise<void> => {
    await Promise.all([waitForUserAndMasters(), waitForCleaningMasters()]);
    mapViewState.extremeMapEssentials = {
      userSettings: userSettings.value,
      kpMap: window.master.kpMap,
    };
  };

  const resizeMap = (mapHeight: number) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.setMapHeight(mapHeight);
    extremeMapRef.value.triggerResize();
  };

  const initResizeFunc = () => {
    mapViewState.onResizeFunc = () => {
      const headerH = 57;
      const searchBarH = 96;
      const h = window.innerHeight - headerH - searchBarH;
      const w = window.innerWidth - VERTICAL_PANE_PADDING_NUMBER;
      paneStyle.paneSideMinWidth = Math.min(Math.floor(w * PANE_SIDE_MIN_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.paneSideMaxWidth = Math.min(Math.floor(w * PANE_SIDE_MAX_WIDTH_RATE), PANE_SIDE_HARD_MAX_WIDTH);
      paneStyle.listMinHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMinHeightRate || 1.0)).toString());
      paneStyle.listMaxHeight = parseInt((h * (paneState.paneStyleLimitMap?.listMaxHeightRate || 1.0)).toString());
      resizeMap(h);
    };
    mapViewState.onResizeFunc();
    window.addEventListener('resize', mapViewState.onResizeFunc);
  };

  const forceResizeExtremeMap = (): void => {
    mapViewState.onResizeFunc();
  };

  const redrawCarLayer = ({ fitToExtent, setSelectedCarToCenter }: RedrawCarLayerOptions = {}) => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.redrawCleaningCarLayer({
      hideCarIcons: !paneState.showCarIcons,
      hideCleaningPhotoIcons: !paneState.showCleaningPhotoIcons,
      hideDefectPhotoIcons: !paneState.showDefectPhotoIcons,
      fitToExtent: fitToExtent || false,
      setSelectedCarToCenter,
    });
  };

  const showCleaningReportLayer = (selectedReport: CleaningReportExt, fitToExtent: boolean): void => {
    if (!extremeMapRef.value) return;

    extremeMapRef.value.showCleaningReportLayer(selectedReport, {
      hideCleaningPhotoIcons: !paneState.showCleaningPhotoIcons,
      hideDefectPhotoIcons: !paneState.showDefectPhotoIcons,
      fitToExtent: fitToExtent,
    });
  };

  const removeCleaningReportLayer = (): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.removeCleaningReportLayer();
  };

  const showCleaningCarPopup = (car: CleaningCarExt): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.showCleaningCarPopup(car);
  };

  const showCleaningReportPhotoPopup = (photo: CleaningReportPhotoExt): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.showCleaningReportPhotoPopup(photo);
  };

  const showCleaningMtxPopup = (mtx: CleaningMtxExt): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.showCleaningMtxPopup(mtx);
  };

  const hidePopup = (): void => {
    if (!extremeMapRef.value) return;
    extremeMapRef.value.hidePopup();
  };

  onMounted(async() => {
    initResizeFunc();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', mapViewState.onResizeFunc);
  });

  return {
    extremeMapRef,
    mapViewState,
    paneStyle,
    loadExtremeMapEssentials,
    forceResizeExtremeMap,
    redrawCarLayer,
    showCleaningReportLayer,
    removeCleaningReportLayer,
    showCleaningCarPopup,
    showCleaningReportPhotoPopup,
    showCleaningMtxPopup,
    hidePopup,
  };
}
