
export function dateToTimeInteger(dt: Date): number {
  const h = dt.getHours();
  const m = dt.getMinutes();
  const s = dt.getSeconds();
  return h * 10000 + m * 100 + s;
}
export function ensureTimeInteger(t: Date | number): number {
  if (t instanceof Date) {
    t = dateToTimeInteger(t);
  }
  if (isNaN(t)) { return NaN; }
  return t;
}
export function packToTimeInteger(h: string, m: string, s: string): number {
  return parseInt(h) * 10000 + parseInt(m) * 100 + parseInt(s);
}
export function unpackTimeIntegerToString(timeInt: number): string[] {
  const [h, m, s] = unpackTimeInteger(timeInt);
  const hStr = ('0' + h).slice(-2);
  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return [hStr, mStr, sStr];
}
export function unpackTimeInteger(timeInt: number): number[] {
  let tmp = parseInt(timeInt.toString());
  const h = parseInt((timeInt / 10000).toString());
  tmp -= h * 10000;
  const m = parseInt((tmp / 100).toString());
  const s = tmp % 100;
  return [h, m, s];
}
export function timeDifferenceInSeconds(t1: Date | number, t2: Date | number): number {
  const t1Num = ensureTimeInteger(t1);
  const t2Num = ensureTimeInteger(t2);
  const [h1, m1, s1] = unpackTimeInteger(t1Num);
  const [h2, m2, s2] = unpackTimeInteger(t2Num);
  const sumSec1 = h1 * 3600 + m1 * 60 + s1;
  const sumSec2 = h2 * 3600 + m2 * 60 + s2;
  return sumSec2 - sumSec1;
}
export function secondsToTimeInteger(seconds: number): number {
  // 一旦正に戻してから計算
  const sign = seconds < 0 ? -1 : 1;
  seconds *= sign;

  let h = parseInt((seconds / 3600).toString());
  h = Math.min(h, 99); // digit guard
  const tmp = seconds % 3600;
  const m = parseInt((tmp / 60).toString());
  const s = tmp % 60;

  const hStr = ('0' + h).slice(-2);
  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return parseInt(`${hStr}${mStr}${sStr}`) * sign;
}
export function timeIntegerToSeconds(tm: number): number {
  // 一旦正に戻してから計算
  const sign = tm < 0 ? -1 : 1;
  tm *= sign;

  const h = parseInt((tm / 10000).toString());
  const tmp = tm % 10000;
  const m = parseInt((tmp / 100).toString());
  const s = tmp % 100;

  return (h * 3600 + m * 60 + s) * sign;
}
export function timeIntegerAdd(tm1: number, tm2: number):number {
  const sec1 = timeIntegerToSeconds(tm1);
  const sec2 = timeIntegerToSeconds(tm2);
  return secondsToTimeInteger(sec1 + sec2);
}

export const timeInteger = (timeInt: number | null, format = 'HH:MM'): string => {
  if (timeInt === null) { return ''; }
  let tmp = parseInt(timeInt.toString());
  if (isNaN(timeInt)) { return ''; }
  const h = parseInt((timeInt / 10000).toString());
  tmp -= h * 10000;
  const min = parseInt((tmp / 100).toString());
  const s = tmp % 100;

  return format
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h.toString())
    .replace(/MM/g, ('0' + min).slice(-2))
    .replace(/M/g, min.toString())
    .replace(/SS/g, ('0' + s).slice(-2))
    .replace(/S/g, s.toString());
};
