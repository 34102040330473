import { CompanyState } from '@/models/johaisetsu/companyHanCommon';

export const initCompanyState = (): CompanyState => {
  const dateTo = new Date();
  const dateFrom = new Date(dateTo);
  dateFrom.setMonth(dateFrom.getMonth() - 1);
  dateFrom.setHours(0, 0, 0, 0);
  dateTo.setHours(23, 59, 59, 999);
  return {
    areaChoices: [],
    johaisetsuCompanies: [],
    johaisetsuCompanyAreaMap: {},
    johaisetsuHanMasters: [],
    searchConds: {
      johaisetsuArea: null,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
    johaisetsuType: '',
    workType: '',
    selectedTaskForce: null,
    taskForces: [],
    showTaskForceSelect: false,
    reports: [],
    groupedReports: null,
    johaisetsuRoles: [],
    showWaitSpinner: false,
    taskForceId: '',
    showErrorModal: false,
    errorBody: '',
  };
};
