import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  SettouMessageBoardResponse,
  BoardTab,
  BoardMaster,
  HanMaster,
  BoardEntry,
} from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
export default {
  index(boardMasterId: number): Promise<AxiosResponse<SettouMessageBoardResponse>> {
    return axios.get(utils.getApiUrl(`/board_entries/${boardMasterId}`));
  },
  create(params: BoardEntry): Promise<AxiosResponse<BoardEntry>> {
    return axios.post(utils.getApiUrl('/board_entries'), params);
  },
  update(id: number, params: BoardEntry): Promise<AxiosResponse<BoardEntry>> {
    return axios.put(utils.getApiUrl(`/board_entries/${id}`), params || {});
  },
  reply(id: number, params: BoardEntry): Promise<AxiosResponse<BoardEntry>> {
    return axios.put(utils.getApiUrl(`/board_entries/reply/${id}`), params || {});
  },
  destroy(id: number): Promise<AxiosResponse<BoardEntry>> {
    return axios.delete(utils.getApiUrl(`/board_entries/${id}`));
  },
  getBoardTabs(): Promise<AxiosResponse<BoardTab[]>> {
    return axios.get(utils.getApiUrl(`/board_entries/board_tabs`));
  },
  getBoardMasters(): Promise<AxiosResponse<BoardMaster[]>> {
    return axios.get(utils.getApiUrl(`/board_entries/board_masters`));
  },
  getBoardHanMasters(): Promise<AxiosResponse<HanMaster[]>> {
    return axios.get(utils.getApiUrl('/board_entries/board_han_masters'));
  },
};
