type UseSessionStorageParameterStoreArgs<T, T2> = {
  storageKey: string;
  initParams: T;
  beforeSerialize: (params: T) => T2;
  afterDeserialize: (params: T2) => T;
};

type UseSessionStorageParameterStoreResult<T> = {
  getStoredParams: () => T;
  storeParams: (params: T) => void;
};

export default function useSessionStorageParameterStore<T, T2>({
  storageKey,
  initParams,
  beforeSerialize,
  afterDeserialize,
}: UseSessionStorageParameterStoreArgs<T, T2>): UseSessionStorageParameterStoreResult<T> {
  const getStoredParams = (): T => {
    const storedParamsRaw = sessionStorage.getItem(storageKey);
    if (!storedParamsRaw) {
      return initParams;
    }
    const storedParams = JSON.parse(storedParamsRaw);
    return afterDeserialize(storedParams);
  };

  const storeParams = (params: T): void => {
    sessionStorage.setItem(storageKey, JSON.stringify(beforeSerialize(params)));
  };

  return {
    getStoredParams,
    storeParams,
  };
}
