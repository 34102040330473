import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Style, Stroke, Fill, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Point, LineString } from 'ol/geom';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import { History } from '@/models/apis/cyzenHistory/adminCyzenHistoryResponse';

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}
interface StrokeColor {
  stroke: string;
}
interface ColorMap {
  line: StrokeColor;
}

export interface HistoryExt extends Omit<History, 'kp' | 'lat' | 'lon'> {
  kp: number;
  lat: number;
  lon: number;
}
interface TextDisp {
  num: number;
  history: HistoryExt;
  pt: Coordinate;
  groupNumDisp: string;
  showTs?: boolean;
}

export interface HistoryGroup {
  groupNumDisp: string;
  historyGroupId: string;
  statusType: string;
  startTs: Date;
  endTs: Date;
  points: HistoryExt[];
  workTimeDisp?: string;
  kpsDisp?: string;
}

export default class ExtremeMapDebugCyzenLayerManager extends ExtremeMapAbstractLayerManager {
  opts: Options;
  layerName: string;
  colorMap: ColorMap[];
  constructor(opts = {}) {
    super();
    this.opts = opts;
    this.layerName = 'debugCyzen';
    this.colorMap = [
      { line: { stroke: '#bb00bb' } },
      { line: { stroke: '#00bb00' } },
      { line: { stroke: '#0000bb' } },
      { line: { stroke: '#bbbb00' } },
      { line: { stroke: '#00bbbb' } },
      { line: { stroke: '#bb0000' } },
      { line: { stroke: '#4400bb' } },
      { line: { stroke: '#44bb00' } },
      { line: { stroke: '#0044bb' } },
      { line: { stroke: '#bb4400' } },
      { line: { stroke: '#00bb44' } },
      { line: { stroke: '#bb0044' } },
    ];
  }

  getColorMap(colorNum: number): ColorMap {
    const cnt = this.colorMap.length;
    return this.colorMap[colorNum % cnt];
  }

  formatDt(inputDt: string): string {
    const dt = new Date(inputDt);
    const y = dt.getFullYear();
    const mon = ('0' + (dt.getMonth() + 1)).slice(-2);
    const d = ('0' + dt.getDate()).slice(-2);
    const h = ('0' + dt.getHours()).slice(-2);
    const min = ('0' + dt.getMinutes()).slice(-2);
    const s = ('0' + dt.getSeconds()).slice(-2);
    const ts = `${y}-${mon}-${d} ${h}:${min}:${s}`;
    return ts;
  }

  getHistoryGroupFeatures(historyGroup: HistoryGroup, colorNum: number): Feature[] {
    const line: Coordinate[] = [];
    const texts: TextDisp[] = [];
    const hgPointsLen = historyGroup.points.length;
    const groupNumDisp = historyGroup.groupNumDisp;
    let lastTextTs: number | null = null;
    historyGroup.points.forEach((history, i) => {
      const ts = new Date(history.ts);
      const lon = parseFloat(history.lon.toString());
      const lat = parseFloat(history.lat.toString());
      if (lon < 0.000001 || lat < 0.000001) { return; }
      const pt = this.coordFromLonLat(lon, lat);
      line.push(pt);
      const textsElem: TextDisp = { num: i + 1, history, pt, groupNumDisp };
      if (
        i % 5 === 0 ||
        i === hgPointsLen - 1 ||
        (lastTextTs && ts.getTime() - lastTextTs > 5 * 60 * 1000)
      ) {
        textsElem.showTs = true;
        lastTextTs = ts.getTime();
      }
      texts.push(textsElem);
    });

    const ret: Feature[] = [];
    const colorMap = this.getColorMap(colorNum);

    const feat = new Feature(new LineString(line));
    feat.setStyle(new Style({
      stroke: new Stroke({ color: colorMap.line.stroke, width: 4 }),
    }));
    ret.push(feat);

    const feats = texts.map(({ num, history, pt, groupNumDisp, showTs }) => {
      const feat = new Feature(new Point(pt));
      const styles = [];
      styles.push(new Style({
        text: new Text({
          text: num.toString(),
          font: '8px sans-serif',
          fill: new Fill({ color: '#eeeeee' }),
          stroke: new Stroke({ color: [50, 50, 50, 0.8], width: 3 }),
        }),
      }));
      if (showTs) {
        const ts = this.formatDt(history.ts).slice(-8);
        const text = `${groupNumDisp}@${ts}`;
        styles.push(new Style({
          text: new Text({
            text: text,
            font: '8px sans-serif',
            fill: new Fill({ color: '#eeeeee' }),
            stroke: new Stroke({ color: [50, 50, 50, 0.8], width: 3 }),
            offsetX: 0,
            offsetY: 6,
          }),
        }));
      }
      feat.setStyle(styles);
      return feat;
    });
    ret.push(...feats);

    return ret;
  }

  createLayer_({ historyGroups } : { historyGroups: HistoryGroup[] }): void {
    const features: Feature[] = [];
    historyGroups.forEach((historyGroup, i) => {
      features.push(...this.getHistoryGroupFeatures(historyGroup, i));
    });
    const layer: NamedVectorLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({features: features}),
    });
    layer.name = this.layerName;
    this.layer = layer;
  }

  prepareLayer(data: { historyGroups: HistoryGroup[] }): LayerWithInfo {
    this.createLayer_(data);
    return this.getLayer();
  }

  getDebugLayerName(): string {
    return this.layerName;
  }
}
