


import {
  defineComponent,
  PropType,
} from '@vue/composition-api';

import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { dtFormat } from '@/lib/dateHelper';
import { useRoute } from '@/hooks/useRoute';
import { PaneStyle } from '@/models/johaisetsu/johaisetsuMap';
import {
  PANE_SIDE_HARD_MAX_WIDTH,
  LIST_HARD_MAX_HEIGHT,
} from '@/components/Johaisetsu/JohaisetsuMap/consts/johaisetsu_map';
import { useJohaisetsuReports } from '@/components/Johaisetsu/JohaisetsuMap/composables/useJohaisetsuReports';

export default defineComponent({
  name: 'report-mode-pane',
  props: {
    paneStyle: {
      type: Object as PropType<PaneStyle>,
      default: () => ({
        paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
        paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
        listMinHeight: LIST_HARD_MAX_HEIGHT,
        listMaxHeight: LIST_HARD_MAX_HEIGHT,
      }),
    },
  },
  setup(props, { emit }) {
    const { reportState } = useJohaisetsuReports();
    const baseArrivalTsDisp = (report: JohaisetsuReportExt): string => {
      const baseDepartureDate = dtFormat(report.base_departure_ts, 'yyyy/mm/dd');
      const baseArrivalDate = dtFormat(report.base_arrival_ts, 'yyyy/mm/dd');
      if (baseDepartureDate === baseArrivalDate) {
        return dtFormat(report.base_arrival_ts, 'HH:MM:SS');
      }
      return dtFormat(report.base_arrival_ts, 'yyyy/mm/dd HH:MM:SS');
    };
    const onClickReportOnReportList = (report: JohaisetsuReportExt) => {
      emit('click-report-on-report-list', report);
    };
    const { router } = useRoute();
    const onClickReportPrintBtn = (event: MouseEvent, johaisetsuReportId: number) => {
      // 一覧の印刷ボタンを押した時に、報告書の選択イベントが発生しないようにする
      event.stopPropagation();
      const routeObj = {
        name: 'PrintJohaisetsuReport',
        params: {
          johaisetsuReportId: johaisetsuReportId.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };
    return {
      // refs
      reportState,
      // methods
      baseArrivalTsDisp,
      onClickReportOnReportList,
      onClickReportPrintBtn,
      dtFormat,
    };
  },
});
