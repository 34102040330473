


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { AdminTairyuIgnitionInfo } from '@/models/apis/cars/tairyuIgnitionResponse';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { AdminDevice } from '@/models/apis/device/adminDeviceResponse';
import tairyuIgnitionApi from '@/apis/tairyu_ignition';
import deviceApi from '@/apis/device';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { dtFormat } from '@/lib/dateHelper';

import {
  TAIRYU_IGNITION_FILTER_TYPE_ALL,
  TAIRYU_IGNITION_FILTER_TYPE_DEVICE_ID, TAIRYU_IGNITION_FILTER_TYPE_GEO_AREA,
  TAIRYU_IGNITION_FILTER_TYPE_ROAD_AREA,
} from '@/consts/tairyu';

interface TairyuIgnitionFilterType {
  all: string;
  device_id: string;
  road_area: string;
  geo_area: string;
}

interface AdminTairyuIgnitionState {
  isReady: boolean;
  activeTairyuIgnitionInfo?: AdminTairyuIgnitionInfo | null;

  roadNames: string[];
  devices: AdminDevice[];
  tairyuIgnitionInfos: AdminTairyuIgnitionInfo[];

  tairyuIgnitionFilterType: TairyuIgnitionFilterType;
}

export default defineComponent({
  name: 'admin-tairyu-ignition',
  setup() {
    const state = reactive<AdminTairyuIgnitionState>({
      isReady: false,
      activeTairyuIgnitionInfo: null,

      roadNames: [],
      devices: [],
      tairyuIgnitionInfos: [],

      tairyuIgnitionFilterType: {
        all: TAIRYU_IGNITION_FILTER_TYPE_ALL,
        device_id: TAIRYU_IGNITION_FILTER_TYPE_DEVICE_ID,
        road_area: TAIRYU_IGNITION_FILTER_TYPE_ROAD_AREA,
        geo_area: TAIRYU_IGNITION_FILTER_TYPE_GEO_AREA,
      },
    });

    const store = useStore();
    const userState = store.state.user;
    const isAdmin = computed<boolean>(() => {
      return userState.has_role_admin;
    });
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const username = computed<string>(() => {
      return userState.username;
    });
    const isTairyuAdmin = computed<boolean>(() => {
      if (!isAdmin) { return false; }

      // 「管理者」にAI計測の実行権限を与えたい場合はここで指定する.
      return ['stk-tairyu-admin-rvKz4'].includes(username.value);
    });
    const canStartTairyuCompute = computed<boolean>(() => {
      return state.isReady && (isSuperAdmin || isTairyuAdmin) && !state.activeTairyuIgnitionInfo;
    });
    const canStopTairyuCompute = computed<boolean>(() => {
      return state.isReady && (isSuperAdmin || isTairyuAdmin) && state.activeTairyuIgnitionInfo !== null;
    });
    const convTairyuIgnitionInfo = (data: AdminTairyuIgnitionInfo): AdminTairyuIgnitionInfo => {
      const ret = Object.assign(data);
      ret.start_ts = new Date(ret.start_ts);
      if (ret.end_ts) { ret.end_ts = new Date(ret.end_ts); }
      ret.created_at = new Date(ret.created_at);
      ret.updated_at = new Date(ret.updated_at);
      return ret;
    };
    const getDevices = async() => {
      const { data } = await deviceApi.adminGetDevices();
      state.devices = data;
    };
    const getTairyuIgnitionInfos = async() => {
      const { data } = await tairyuIgnitionApi.adminGetTairyuIgnitionInfos();
      state.tairyuIgnitionInfos = data.map(e => convTairyuIgnitionInfo(e));
      // 0個か1個なはず
      state.activeTairyuIgnitionInfo = data.find(e => !e.is_done);
    };
    const startTairyuCompute = async() => {
      try {
        const { data: result } = await tairyuIgnitionApi.adminSetTairyuIgnitionInfos({
          filter_type: TAIRYU_IGNITION_FILTER_TYPE_ALL,
        });
        state.activeTairyuIgnitionInfo = convTairyuIgnitionInfo(result);
      } catch (e) {}
    };
    const stopTairyuCompute = async() => {
      try {
        await tairyuIgnitionApi.adminInvalidateTairyuIgnitionInfos();
        state.activeTairyuIgnitionInfo = null;
      } catch (e) {}
    };

    const { router } = useRoute();
    onMounted(async() => {
      const p1 = waitForUserAndMasters();
      const p2 = getDevices();
      const p3 = getTairyuIgnitionInfos();
      await Promise.all([p1, p2, p3]);
      if (!isAdmin) {
        await router.replace({ name: 'Login' });
      }

      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections));
      state.isReady = true;
    });

    return {
      ...toRefs(state),
      // computed
      isAdmin,
      isSuperAdmin,
      username,
      isTairyuAdmin,
      canStartTairyuCompute,
      canStopTairyuCompute,
      // methods
      convTairyuIgnitionInfo,
      getDevices,
      getTairyuIgnitionInfos,
      startTairyuCompute,
      stopTairyuCompute,
      dtFormat,
    };
  },
});
