import { Style, Stroke, RegularShape, Fill } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class snow_mountainHelper extends AbstractGeoItem<boolean, number, number, string> {
  getFeatureMode = (): string => {
    return 'point';
  };
  dataToObjKey(inputData: boolean): number {
    let data: DataContent = { v: false };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    return parseInt(data.v.toString());
  }
  objKeyToColor(intputObjKey: number): string {
    let ret = '';
    const objKey = parseInt(intputObjKey.toString());
    if (!objKey) {
      ret = ''; // [0, 0, 0, 0] // なしは表示しない
    } else {
      ret = '#ffffff';
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    // 個数によってなんか変えるかも??
    const color = this.objKeyToColor(objKey);
    const radius = 9;

    const style = new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({ color: '#000000', width: 2 }),
        points: 3,
        radius: radius,
        rotation: 0,
        angle: 0,
      }),
    });
    return style;
  }
}
