import { Style, Stroke } from 'ol/style';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class JhWorkStatusHelper extends AbstractGeoItem<number, number, number, string> {
  dataStep(): number {
    return 10;
  }
  dataToObjKey(data: number): number {
    return data;
  }
  objKeyToColor(intputObjKey: number): string {
    const objKey = parseInt(intputObjKey.toString());
    let ret = '';
    if (objKey === 0) {
      ret = ''; // なしは表示しない
    } else if (objKey === 10) {
      ret = '#000000';
    } else if (objKey === 20) {
      ret = '#ff0000';
    } else if (objKey === 30) {
      ret = '#feff00';
    } else if (objKey === 40) {
      ret = '#99cb00';
    } else if (objKey === 50) {
      ret = '#34cccd';
    } else if (objKey === 60) {
      ret = '#cccccc';
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
