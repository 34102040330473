
import {
  ENSUI_SANPU_STATUS_NONE,
  ENSUI_SANPU_STATUS_SANPU_CHU,
  ENSUI_SANPU_STATUS_SANPU_KANRYOU,
  JUTEN_SANPU_STATUS_NONE,
  JUTEN_SANPU_STATUS_SANPU_CHU,
  JUTEN_SANPU_STATUS_SANPU_KANRYOU,
} from '@/consts/johaisetsu';

import {
  getJohaisetsuEnsuiSanpuBlocks,
  getJohaisetsuJutenSanpuBlocks,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  SanpuBlock,
  JoukyouInfoRaw,
  StatusMap,
  JohaisetsuJoukyouDetail,
} from '@/models/apis/johaisetsu/johaisetsuCommon';
import { JoukyouInputMap,
  JoukyouInputMapRaw,
  TableCommonInfo,
  JoukyouTableInputRow,
  EnsuiSanpuJoukyouInputInfo,
  DateTimeError,
  SagyouJoukyouInputError,
  BlockCodeNumInfo } from '@/models/johaisetsu/johaisetsuCommon';
import { ensureDate, dateStrToDate, dtFormat } from '@/lib/dateHelper';
import { convertStrToTime, splitByLineBreak } from '@/lib/utils';
import { unpackTimeIntegerToString } from '@/lib/dateTimeUtil';

export const DATA_TYPE_ENSUI_SANPU = 'ensui_sanpu';
export const DATA_TYPE_JUTEN_SANPU = 'juten_sanpu';
export const DATA_TYPE_OTHER = 'other';
interface Status {
  none: number;
  sagyouChu: number;
  sagyouKanryou: number;
}

export const STATUS_MAP: Record<string, Status> = {
  [DATA_TYPE_ENSUI_SANPU]: {
    none: ENSUI_SANPU_STATUS_NONE,
    sagyouChu: ENSUI_SANPU_STATUS_SANPU_CHU,
    sagyouKanryou: ENSUI_SANPU_STATUS_SANPU_KANRYOU,
  },
  [DATA_TYPE_JUTEN_SANPU]: {
    none: JUTEN_SANPU_STATUS_NONE,
    sagyouChu: JUTEN_SANPU_STATUS_SANPU_CHU,
    sagyouKanryou: JUTEN_SANPU_STATUS_SANPU_KANRYOU,
  },
  [DATA_TYPE_OTHER]: {} as Status,
};
const BLOCK_AREA_DISP_MAP1: Record<string, string> = {
  't-west': '東京西局',
  't-east': '東京東局',
  'kanagawa': '神奈川管理局',
};

export function filterBlocksByRole(blocks: SanpuBlock[], role: string): SanpuBlock[] {
  // ユーザーが本社の場合は全部、局の場合は見れる路線を絞り込む
  if (role === 'honsha') { return blocks; }
  if (!['t-west', 't-east', 'kanagawa'].includes(role)) { return []; }
  return blocks.filter(e => e.area === role);
}

export function filterJoukyouDetailsByRole(joukyouDetails: JohaisetsuJoukyouDetail[], role: string): JohaisetsuJoukyouDetail[] {
  // ユーザーが本社の場合は全部、局の場合は見れるデータを絞り込む
  if (role === 'honsha') { return joukyouDetails; }
  if (!['t-west', 't-east', 'kanagawa'].includes(role)) { return []; }
  return joukyouDetails.filter(e => e.block?.area === role);
}

export function filterBlocksBySelectedJoukyouInfo(blocks: SanpuBlock[], selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail> | null): SanpuBlock[] {
  let ts: Date = new Date();
  if (selectedJoukyouInfo && selectedJoukyouInfo.header) {
    ts = dateStrToDate(selectedJoukyouInfo.header.timestamp) || ts;
  }

  return blocks.filter(e => {
    if (!e.use_start_date || !e.use_end_date) { return false; }
    const endTs = new Date(e.use_end_date.valueOf() + 86400 * 1000);
    return e.use_start_date <= ts && ts < endTs;
  });
}

function getEmptyJoukyouDetail(blockId: number, dispOrder: number): JohaisetsuJoukyouDetail {
  const defaultStatusId = 0;
  const detailObj = {
    block_id: blockId,
    status: defaultStatusId,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    bikou1: null,
    disp_order: dispOrder,
  } as JohaisetsuJoukyouDetail;
  return detailObj;
}

export function convProps(joukyouDetail: JoukyouInputMapRaw): JoukyouInputMap {
  const startTime = unpackTimeIntegerToString(joukyouDetail.start_time);
  const endTime = unpackTimeIntegerToString(joukyouDetail.end_time);
  return {
    ...joukyouDetail,
    start_date: ensureDate(joukyouDetail.start_date),
    start_time_h: startTime[0],
    start_time_m: startTime[1],
    end_date: ensureDate(joukyouDetail.end_date),
    end_time_h: endTime[0],
    end_time_m: endTime[1],
  };
}

export function updateStatusDependentParams(inputMap: JoukyouInputMap, statusMasterMap: { [key: string]: StatusMap }, dataType: string = DATA_TYPE_OTHER): void {
  if (!inputMap.status) { return; }
  const st = statusMasterMap[inputMap.status];
  inputMap.statusName = st.name;
  inputMap.statusColor = st.color;
  inputMap.statusBgColor = st.bg_color;
  updateDateTimes(inputMap, dataType);
}

function updateDateTimes(inputMap: JoukyouInputMap, dataType: string) {
  const today = new Date();
  const hour = ('0' + today.getHours()).slice(-2);
  const minute = ('0' + today.getMinutes()).slice(-2);
  today.setHours(0);
  today.setMinutes(0);
  today.setMilliseconds(0);
  const { sagyouChu, sagyouKanryou } = STATUS_MAP[dataType];
  if (inputMap.status >= sagyouChu) {
    if (!inputMap.start_date) {
      inputMap.start_date = today;
      inputMap.start_time_h = hour;
      inputMap.start_time_m = minute;
    }
  }
  if (inputMap.status && inputMap.status >= sagyouKanryou) {
    if (
      dataType === DATA_TYPE_ENSUI_SANPU ||
      dataType === DATA_TYPE_JUTEN_SANPU
    ) {
      if (!inputMap.end_date) {
        inputMap.end_date = today;
        inputMap.end_time_h = hour;
        inputMap.end_time_m = minute;
      }
    }
  }
}

function getBlocksByDataType(dataType: string): SanpuBlock[] {
  let ret: SanpuBlock[] = [];
  if (dataType === DATA_TYPE_ENSUI_SANPU) {
    ret = getJohaisetsuEnsuiSanpuBlocks();
  } else if (dataType === DATA_TYPE_JUTEN_SANPU) {
    ret = getJohaisetsuJutenSanpuBlocks();
  }
  return ret;
}

export function prepareBlocks(
  selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail> | null,
  johaisetsuRole: string,
  dataType: string = DATA_TYPE_OTHER,
): SanpuBlock[] {
  let blocks = getBlocksByDataType(dataType);
  blocks = filterBlocksByRole(blocks, johaisetsuRole);
  blocks = filterBlocksBySelectedJoukyouInfo(
    blocks,
    selectedJoukyouInfo,
  );

  blocks.forEach(e => {
    const areaDisp = BLOCK_AREA_DISP_MAP1[e.area];
    const groupName = e.group_name;
    const groupNumDisp = e.group_num_disp;
    e.nameDisp = `${areaDisp} ${groupName} ${groupNumDisp}`;
  });
  return blocks;
}

export function getTableInputInfo(
  selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail> | null,
  selectedBlock: SanpuBlock,
  statusMasterMap: { [key: string]: StatusMap },
  dataType = DATA_TYPE_OTHER,
) : EnsuiSanpuJoukyouInputInfo | null {
  if (!selectedJoukyouInfo) { return null; }
  const detailMap = selectedJoukyouInfo.details.reduce((acc: Record<string, JohaisetsuJoukyouDetail>, e) => {
    acc[`${e.block_id}#${e.disp_order}`] = e; return acc;
  }, {});
  // blockについて、対応するdetails(10個)と紐付ける.
  const maxInputRowNum = 10;
  const tableInputRows = [];
  for (let i = 0; i < maxInputRowNum; i++) {
    const dispOrder = i + 1;
    const detailMapKey = `${selectedBlock.id}#${dispOrder}`;
    if (!detailMap[detailMapKey]) {
      detailMap[detailMapKey] = getEmptyJoukyouDetail(selectedBlock.id, dispOrder);
    }
    const inputMap = JSON.parse(JSON.stringify(detailMap[detailMapKey]));
    const joukyouInputMap = convProps(inputMap);
    updateStatusDependentParams(joukyouInputMap, statusMasterMap);

    const row = {
      id: dispOrder,
      inputMap: joukyouInputMap,
      isFirstRow: dispOrder === 1,
      isLastRow: dispOrder === maxInputRowNum,
    };
    tableInputRows.push(row);
  }

  const blockCode = selectedBlock.block_code;
  const blockImgSrc = `/static/img/johaisetsu/${dataType}_blocks/${blockCode}.png`;

  const blockNameDisp = selectedBlock.nameDisp;

  const groupAliasName = selectedBlock.group_alias_name;
  const groupNumDisp = selectedBlock.group_num_disp;
  const groupNameDisp = `${groupAliasName}${groupNumDisp}`;

  const kukanNameStr = selectedBlock.kukan_name;
  const kukanNameDispArr = splitByLineBreak(kukanNameStr);

  const tableCommonInfo = {} as TableCommonInfo;
  tableCommonInfo.groupNameDisp = groupNameDisp;
  tableCommonInfo.dispColor = selectedBlock.disp_color;
  tableCommonInfo.kukanNameDispArr = kukanNameDispArr;

  return {
    blockImgSrc,
    blockNameDisp,
    tableCommonInfo,
    tableInputRows,
  };
}

export function getMapUpdateInfos(selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail> | null, dataType: string = DATA_TYPE_OTHER): BlockCodeNumInfo[] | null {
  const { sagyouKanryou } = STATUS_MAP[dataType];
  const status = sagyouKanryou;
  if (!selectedJoukyouInfo) { return null; }
  const mapUpdateInfoMap = selectedJoukyouInfo.details.filter(e => {
    let shouldShow = true;
    if (dataType === DATA_TYPE_ENSUI_SANPU) {
      // 塩水散布の場合
      // 「本線に該当する区間無し」となっているものは、原則地図上に表示されない
      shouldShow = !e.block?.kukan_name.startsWith('本線に該当する区間無し');
      // t-west-019だけ例外として地図上に表示される
      shouldShow = shouldShow || e.block?.block_code === 't-west-019';
    }
    return shouldShow;
  }).filter(e => {
    return e.status === status;
  }).reduce((acc: Record<number, BlockCodeNumInfo>, e) => {
    if (e.block_id && !acc[e.block_id]) {
      acc[e.block_id] = {
        blockCode: e.block?.block_code || '',
        num: 0,
      };
    }
    const cnt = 1;
    if (e.block_id) {
      acc[e.block_id].num += cnt;
    }
    return acc;
  }, {});
  return Object.values(mapUpdateInfoMap).map(e => e);
}

export function getClearedErrorMap(items: JoukyouTableInputRow[]): Record<string, SagyouJoukyouInputError> {
  const errorMap: Record<string, SagyouJoukyouInputError> = {};
  items.forEach(item => {
    errorMap[item.id] = {
      start: {} as DateTimeError,
      end: {} as DateTimeError,
    };
  });
  return errorMap;
}

export function checkItems(items: JoukyouTableInputRow[], errorMap: Record<string, SagyouJoukyouInputError>, dataType: string = DATA_TYPE_OTHER): boolean {
  const dtParamPfxs = [
    'start',
    'end',
  ];

  let isOk = true;
  items.forEach(item => {
    const inputMap = item.inputMap;
    const errorObj = errorMap[item.id];
    dtParamPfxs.forEach(paramPfx => {
      const dateParam = paramPfx + '_date';
      const hourParam = paramPfx + '_time_h';
      const minuteParam = paramPfx + '_time_m';
      // date-pickerはreadonlyにしてるのでこれ単体でエラーにはならない
      const date = false;
      const hourParamKey = hourParam as keyof JoukyouInputMap;
      const minuteParamKey = minuteParam as keyof JoukyouInputMap;
      const hour = !!(inputMap[hourParamKey] &&
        !(inputMap[hourParamKey] as string).match(/^\d*$/));
      const minute = !!(inputMap[minuteParamKey] &&
        !(inputMap[minuteParamKey] as string).match(/^\d*$/));

      // 全て空か、全て入っているか、でなければエラーとしたい
      const dateParamKey = dateParam as keyof JoukyouInputMap;
      const tmpVals = [inputMap[dateParamKey], inputMap[hourParamKey], inputMap[minuteParamKey]];
      const tmpCount = tmpVals.reduce((acc: number, e) => {
        const num = (e !== null && e !== '') ? 1 : 0;
        return acc + num;
      }, 0);
      const all = !(tmpCount === 0 || tmpCount === tmpVals.length);
      const paramErrorObj: DateTimeError = {
        date,
        hour,
        minute,
        all,
      };
      const paramPfxKey = paramPfx as keyof SagyouJoukyouInputError;
      (errorObj[paramPfxKey] as DateTimeError) = paramErrorObj;

      isOk = isOk &&
        !paramErrorObj.date &&
        !paramErrorObj.hour &&
        !paramErrorObj.minute &&
        !paramErrorObj.all;
    });

    const { sagyouChu, sagyouKanryou } = STATUS_MAP[dataType];
    if (inputMap.status >= sagyouChu) {
      if (!inputMap.start_date && errorObj.start) {
        isOk = false;
        errorObj.start.all = true;
      }
    }
    if (inputMap.status >= sagyouKanryou) {
      if (
        dataType === DATA_TYPE_ENSUI_SANPU ||
        dataType === DATA_TYPE_JUTEN_SANPU
      ) {
        if (!inputMap.end_date && errorObj.end) {
          isOk = false;
          errorObj.end.all = true;
        }
      }
    }
  });
  return isOk;
}

export function deconvProps(joukyouDetail: JoukyouInputMap, dataType: string): JohaisetsuJoukyouDetail {
  organizeDateTimeProps(joukyouDetail, dataType);

  return {
    ...joukyouDetail,
    // status: convertStrToNumber(joukyouDetail.status),
    start_date: dtFormat(joukyouDetail.start_date, 'yyyy-mm-dd'),
    start_time: convertStrToTime(joukyouDetail.start_time_h, joukyouDetail.start_time_m),
    end_date: dtFormat(joukyouDetail.end_date, 'yyyy-mm-dd'),
    end_time: convertStrToTime(joukyouDetail.end_time_h, joukyouDetail.end_time_m),
  };
}

function organizeDateTimeProps(joukyouDetail: JoukyouInputMap, dataType = DATA_TYPE_OTHER) {
  const { sagyouChu, sagyouKanryou } = STATUS_MAP[dataType];
  if (joukyouDetail.status && joukyouDetail.status < sagyouKanryou) {
    joukyouDetail.end_date = null;
    joukyouDetail.end_time_h = null;
    joukyouDetail.end_time_m = null;
  }
  if (joukyouDetail.status && joukyouDetail.status < sagyouChu) {
    joukyouDetail.start_date = null;
    joukyouDetail.start_time_h = null;
    joukyouDetail.start_time_m = null;
  }
}
