


import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import geoItemGroupApi from '@/apis/geo_item_group';
import { RoadNameDirection } from '@/models/index';
import { GeoItemGroup } from '@/models/apis/geoItemGroup/geoItemGroupResponse';
import { dtFormat } from '@/lib/dateHelper';

interface GeoItemGroupsState {
  headerHeight: number;
  records: GeoItemGroup[];
  isShowingNetworkErrorModal: boolean;
  showWaitSpinner: boolean;
  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
}
export default defineComponent({
  name: 'spGeoItemGroups',
  setup() {
    const state = reactive<GeoItemGroupsState>({
      headerHeight: 105,
      records: [],
      isShowingNetworkErrorModal: false,
      showWaitSpinner: true,
      roadNames: [],
      roadNameMap: {},
    });

    onMounted(async() => {
      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      window.master.$promise.then(() => {
        state.roadNames =
          JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));

        state.roadNameMap = state.roadNames.reduce(
          (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
        state.showWaitSpinner = false;
      }).catch(err => {
        state.isShowingNetworkErrorModal = true;
        state.showWaitSpinner = false;
        console.log('Network Error on mounted', err);
      });

      geoItemGroupApi.index().then((e) => {
        state.records = e.data;
      }).catch(err => {
        console.log('Network Error on sendGeoItems', err);
      });
    });

    const getRoadNameDisp = (real: string) => {
      if (state.roadNameMap[real]) {
        return state.roadNameMap[real].roadNameDisp;
      } else {
        return '';
      }
    };
    const dataTypeToStr = (dataType: string) => {
      if (dataType.indexOf('snowfall') >= 0) {
        return '積雪';
      } else if (dataType.indexOf('frozen') >= 0) {
        return '凍結';
      } else if (dataType.indexOf('work_confirmed') >= 0) {
        return '作業完了';
      } else if (dataType.indexOf('snow_mountain') >= 0) {
        return '雪山';
      }
      return dataType;
    };
    const onNetworkErrorModalCloseClick = () => {
      state.isShowingNetworkErrorModal = false;
    };
    return {
      ...toRefs(state),
      // methods
      getRoadNameDisp,
      dataTypeToStr,
      onNetworkErrorModalCloseClick,
      dtFormat,
    };
  },
});
