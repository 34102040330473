


import {
  computed,
  reactive,
  defineComponent,
  PropType,
  toRefs,
  onMounted,
} from '@vue/composition-api';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { dtFormat } from '@/lib/dateHelper';

interface PhotoModalState {
  selectedPhoto : CleaningReportPhotoExt | null;
}
export default defineComponent({
  name: 'photo-modal',
  props: {
    photoType: {
      type: String,
      default: '作業状況',
      required: true,
    },
    photos: {
      type: Array as PropType<CleaningReportPhotoExt[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<PhotoModalState>({
      selectedPhoto: null,
    });
    const closePhotoModal = () => {
      emit('close-photo-modal');
    };
    const isSelectedPhoto = (photo: CleaningReportPhotoExt): boolean => {
      return photo.id === state.selectedPhoto?.id;
    };
    const onClickPhoto = (photo: CleaningReportPhotoExt): void => {
      state.selectedPhoto = photo;
    };
    const photoIndex = computed<number>(() => {
      if (!state.selectedPhoto) {
        return -1;
      }
      return props.photos.indexOf(state.selectedPhoto);
    });
    const isHead = computed<boolean>(() => {
      return photoIndex.value <= 0;
    });
    const isTail = computed<boolean>(() => {
      return photoIndex.value === props.photos.length - 1;
    });
    const showPrevious = () => {
      state.selectedPhoto = props.photos[photoIndex.value - 1];
    };
    const showNext = () => {
      state.selectedPhoto = props.photos[photoIndex.value + 1];
    };
    onMounted(async() => {
      state.selectedPhoto = props.photos[0];
    });
    return {
      ...toRefs(state),
      isHead,
      isTail,
      closePhotoModal,
      isSelectedPhoto,
      onClickPhoto,
      showPrevious,
      showNext,
      dtFormat,
    };
  },
});
