import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { GetMovieResponse } from '@/models/apis/movie/movieResponse';

export default {
  getMovie(id: number): Promise<AxiosResponse<GetMovieResponse>> {
    return axios.get(utils.getApiUrl(`/movies/${id}`));
  },
  getFile(id: number): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/movies/${id}/file`), {
      responseType: 'blob',
    });
  },
};
