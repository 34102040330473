


import {
  defineComponent,
  computed,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';

import { CleaningReportExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { dtFormat } from '@/lib/dateHelper';
import {
  PHOTO_TYPE_CLEANING,
  PHOTO_TYPE_DEFECT,
  PHOTO_TYPE_CLEANING_NAME,
  PHOTO_TYPE_DEFECT_NAME,
} from '@/components/CleaningMap/consts/cleaning_photo';
import { useRoute } from '@/hooks/useRoute';
import { PaneStyle } from '@/models/cleaningMap';
import { PANE_SIDE_HARD_MAX_WIDTH, LIST_HARD_MAX_HEIGHT } from '@/components/CleaningMap/consts/cleaning_map';
import { useCleaningReports } from '@/components/CleaningMap/composables/useCleaningReports';

export default defineComponent({
  name: 'report-mode-pane',
  props: {
    showCleaningPhotoIcons: {
      type: Boolean,
      default: true,
    },
    showDefectPhotoIcons: {
      type: Boolean,
      default: true,
    },
    paneStyle: {
      type: Object as PropType<PaneStyle>,
      default: () => ({
        paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
        paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
        listMinHeight: LIST_HARD_MAX_HEIGHT,
        listMaxHeight: LIST_HARD_MAX_HEIGHT,
      }),
    },
  },
  setup(props, { emit }) {
    const { reportState, getSelectedReport } = useCleaningReports();
    const checkedShowCleaningPhotoIcons = ref(true);
    const checkedShowDefectPhotoIcons = ref(true);

    // モード変更をして表示のチェックボックスが初期化された際に
    // propsの値を正しく反映させるために、watchで監視する
    watch(() => props.showCleaningPhotoIcons, () => {
      checkedShowCleaningPhotoIcons.value = props.showCleaningPhotoIcons;
    });
    watch(() => props.showDefectPhotoIcons, () => {
      checkedShowDefectPhotoIcons.value = props.showDefectPhotoIcons;
    });

    const selectedReportCleaningPhotosCount = computed<number>(() => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) {
        return 0;
      }
      return selectedReport.photos.filter(e => e.photo_type === PHOTO_TYPE_CLEANING).length;
    });
    const selectedReportDefectPhotosCount = computed<number>(() => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) {
        return 0;
      }
      return selectedReport.photos.filter(e => e.photo_type === PHOTO_TYPE_DEFECT).length;
    });

    const baseArrivalTsDisp = (report: CleaningReportExt): string => {
      const baseDepartureDate = dtFormat(report.base_departure_ts, 'yyyy/mm/dd');
      const baseArrivalDate = dtFormat(report.base_arrival_ts, 'yyyy/mm/dd');
      if (baseDepartureDate === baseArrivalDate) {
        return dtFormat(report.base_arrival_ts, 'HH:MM:SS');
      }
      return dtFormat(report.base_arrival_ts, 'yyyy/mm/dd HH:MM:SS');
    };
    const onClickReportOnReportList = (report: CleaningReportExt) => {
      emit('click-report-on-report-list', report);
    };
    const onChangeShowCleaningPhotoIcons = () => {
      emit('change-show-cleaning-photo-icons');
    };
    const onChangeShowDefectPhotoIcons = () => {
      emit('change-show-defect-photo-icons');
    };
    const { router } = useRoute();
    const onClickReportPrintBtn = (event: MouseEvent, cleaningReportId: number) => {
      // 一覧の印刷ボタンを押した時に、報告書の選択イベントが発生しないようにする
      event.stopPropagation();
      const routeObj = {
        name: 'PrintCleaningReport',
        params: {
          cleaningReportId: cleaningReportId.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };
    const showCleaningPhotos = () => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) {
        return;
      }
      const photos = selectedReport.photosExt.filter(photo => photo.photo_type === PHOTO_TYPE_CLEANING);
      emit('show-photo-modal', PHOTO_TYPE_CLEANING_NAME, photos);
    };
    const showDefectPhotos = () => {
      const selectedReport = getSelectedReport();
      if (!selectedReport) {
        return;
      }
      const photos = selectedReport.photosExt.filter(photo => photo.photo_type === PHOTO_TYPE_DEFECT);
      emit('show-photo-modal', PHOTO_TYPE_DEFECT_NAME, photos);
    };
    return {
      // refs
      reportState,
      checkedShowCleaningPhotoIcons,
      checkedShowDefectPhotoIcons,
      // computed
      selectedReportCleaningPhotosCount,
      selectedReportDefectPhotosCount,
      // methods
      baseArrivalTsDisp,
      onClickReportOnReportList,
      onChangeShowCleaningPhotoIcons,
      onChangeShowDefectPhotoIcons,
      onClickReportPrintBtn,
      showCleaningPhotos,
      showDefectPhotos,
      dtFormat,
    };
  },
});
