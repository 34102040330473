

import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  onUnmounted,
} from '@vue/composition-api';
import Vue from 'vue';
import VueKonva from 'vue-konva';
import Konva from 'konva';
import geoItemGroupApi from '@/apis/geo_item_group';
import { isKpIncreaseDirection } from '@/lib/kilopostHelper';
import { KindChoice,
  KpMap,
  RoadNameDirection,
  Direction } from '@/models/index';
import { useRoute } from '@/hooks/useRoute';
import { dtFormat } from '@/lib/dateHelper';

Vue.use(VueKonva);
const modeSnowfall = 'snowfall';
const modeFrozen = 'frozen';
const modeWorkConfirmed = 'work_confirmed';
const modeSnowMountain = 'snow_mountain';

interface History {
  id?: string;
  roadName: string;
  direction: string;
  ts: string | null;
  dataType: string;
}
interface SearchParams {
  roadName: string;
  direction: string;
}
interface Mode {
  snowfall: string;
  frozen: string;
}

interface TopRecord {
  start: number;
  end: number;
  selectedValue: string;
  preSelectedValue?: string;
  ts: Date;
}
interface Records {
  records: TopRecord[];
}

interface Scale {
  position: number;
  label: string;
}

interface SpTopState {
  history: History;
  mode: Mode;
  search: SearchParams;
  searchBeforeChange: SearchParams;
  selectModes: KindChoice[];
  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  directions: Direction[];
  width: number;
  height: number;
  selectedMode: string;
  selectedMode_: string;
  isDragging: boolean;
  dragstart: number | null;
  draggingLeftKm: number | null;
  draggingRightKm: number | null;
  records: TopRecord[];
  editingRecord: TopRecord | null;
  isEditted: boolean;
  kpStart: number | null;
  kpEnd: number | null;
  displayStart: number | null;
  displayEnd: number | null;
  displayLength: number;
  displayMoveLength: number;
  graphHeight: number;
  headerHeight: number;
  spContainerHeight: number;
  heightSpan: number;
  kpMap: KpMap;
  showWaitSpinner: boolean;
  showSaveWaitSpinner: boolean;
  isShowingModal: boolean;
  isShowingSaveModal: boolean;
  isShowingSavedModal: boolean;
  isShowingNetworkErrorModal: boolean;
  isShowingFatalErrorModal: boolean;
  isShowingEditModeWithdrawalModal: boolean;
  editModeWithdrawalDest: string | null;
  modalCallback: null | (() => void);
  // konvaの部分の左右margin合計
  marginKonva: number;
  editHistory: Records[];
}
export default defineComponent({
  name: 'spTop',
  setup() {
    const state = reactive<SpTopState>({
      history: {
        roadName: '-',
        direction: '-',
        ts: null,
        dataType: '-',
      },
      mode: {
        snowfall: modeFrozen,
        frozen: modeFrozen,
      },
      search: {
        roadName: '-',
        direction: '-',
      },
      searchBeforeChange: {
        roadName: '-',
        direction: '-',
      },
      selectModes: [
        // {value: modeSnowfall, text: '積雪'}, // バックエンドのモードがない為
        {value: modeFrozen, text: '凍結'},
        // {value: modeWorkConfirmed, text: '作業完了'}, // バックエンドのモードがない為
        {value: modeSnowMountain, text: '雪山'},
      ],
      roadNames: [],
      roadNameMap: {},
      directions: [],
      width: window.innerWidth,
      height: window.innerHeight,
      selectedMode: '-',
      selectedMode_: '-',
      isDragging: false,
      dragstart: null,
      draggingLeftKm: null,
      draggingRightKm: null,
      records: [],
      editingRecord: null,
      isEditted: false,
      kpStart: null,
      kpEnd: null,
      displayStart: null,
      displayEnd: null,
      displayLength: 10,
      displayMoveLength: 5,
      graphHeight: 60,
      headerHeight: 105,
      spContainerHeight: 0,
      heightSpan: 0,
      kpMap: {} as KpMap,
      showWaitSpinner: true,
      showSaveWaitSpinner: false,
      isShowingModal: false,
      isShowingSaveModal: false,
      isShowingSavedModal: false,
      isShowingNetworkErrorModal: false,
      isShowingFatalErrorModal: false,
      isShowingEditModeWithdrawalModal: false,
      editModeWithdrawalDest: null,
      modalCallback: null,
      // konvaの部分の左右margin合計
      marginKonva: 80,
      editHistory: [{
        records: [],
      }],
    });
    const { route, router } = useRoute();
    onMounted(async() => {
      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      window.master.$promise.then(() => {
        state.roadNames =
          JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
        state.roadNameMap = state.roadNames.reduce(
          (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
        state.kpMap = window.master.kpMap;
        state.showWaitSpinner = false;
        if (route.value.name === 'SpTopWithParam') {
          loadHistory();
        }
      }).catch(err => {
        state.isShowingNetworkErrorModal = true;
        state.showWaitSpinner = false;
        console.log('Network Error on mounted', err);
      });
      window.addEventListener('resize', handleResize);
      onChangeHeight();
    });

    const isModeSnowfall = computed<boolean>(() => {
      return state.selectedMode === modeSnowfall;
    });
    const isModeFrozen = computed<boolean>(() => {
      return state.selectedMode === modeFrozen;
    });
    const isModeWorkConfirmed = computed<boolean>(() => {
      return state.selectedMode === modeWorkConfirmed;
    });
    const isModeSnowMountain = computed<boolean>(() => {
      return state.selectedMode === modeSnowMountain;
    });
    const isHistory = () => {
      return state.history.roadName !== '-';
    };
    /*
     * 履歴閲覧
     */
    const loadHistory = () => {
      state.history.id = route.value.params.id;
      geoItemGroupApi.show(route.value.params.id).then((items) => {
        state.directions = [{direction: state.history.direction}];
        state.history.ts = items.data.ts;
        state.history.dataType = items.data.data_type;
        state.search.roadName = state.history.roadName = items.data.road_name;
        state.search.direction = state.history.direction = items.data.direction;
        state.selectedMode_ = state.selectedMode = state.history.dataType;
        updateKp();
        if (!items.data.geo_items) { return; }
        state.records = items.data.geo_items.map(item => {
          // KPが増加する進路の場合、start < end（kp1の昇順）で
          // KPが減少する進路の場合、start > end（kp1の降順）で
          // データが入っている.
          // 後者の場合このままだと画面表示が細切れになるので、
          // start < endとなるよう並べ替える.
          let [start, end] = [Number(item.start), Number(item.end)];
          if (start > end) {
            [start, end] = [end, start];
          }
          return {
            start: start,
            end: end,
            selectedValue: item.selectedValue,
            ts: item.ts,
          };
        });
        initEditHistory();
      }).catch((e) => {
        console.log('error', e);
      });
    };
    const updateGeoItems = () => {
      state.showSaveWaitSpinner = true;
      try {
        geoItemGroupApi.updateGeoItems({id: state.history.id, geo_items: constructGeoItemsData()}).then(() => {
          initRecords();
          state.showSaveWaitSpinner = false;
          state.isShowingSavedModal = true;
          router.push({
            path: '/sp/geo_item_groups/',
          });
        }).catch(err => {
          state.isShowingNetworkErrorModal = true;
          state.showSaveWaitSpinner = false;
          console.log('Network Error on sendGeoItems', err);
        });
      } catch (e) {
        state.isShowingNetworkErrorModal = true;
        state.showSaveWaitSpinner = false;
        console.log('Fatal Error on sendGeoItems', e);
      }
    };
    /*
     * 新規
     */
    const createGeoItems = () => {
      state.showSaveWaitSpinner = true;
      try {
        const geoItems = constructGeoItemsData();
        geoItemGroupApi.create({geo_items: geoItems}).then(() => {
          initRecords();
          state.showSaveWaitSpinner = false;
          state.isShowingSavedModal = true;
        }).catch(err => {
          state.isShowingNetworkErrorModal = true;
          state.showSaveWaitSpinner = false;
          console.log('Network Error on sendGeoItems', err);
        });
      } catch (e) {
        state.isShowingNetworkErrorModal = true;
        state.showSaveWaitSpinner = false;
        console.log('Fatal Error on sendGeoItems', e);
      }
    };
    //
    // events
    //
    // events: ヘッダ部
    const onChangeSelectedMode = () => {
      const mode = state.selectedMode_;
      if (isHistory()) { return; }
      if (state.selectedMode === mode) { return; }
      clearDraggingState();
      if (state.records.length > 0) {
        state.modalCallback = () => {
          initRecords();
          state.selectedMode = mode;
          state.modalCallback = null;
        };
        state.isShowingModal = true;
      } else {
        state.selectedMode = mode;
      }
    };
    const roadNameChanged = () => {
      clearDraggingState();
      if (state.records.length > 0) {
        state.modalCallback = () => {
          roadNameChangeAfterModal();
          state.modalCallback = null;
        };
        state.isShowingModal = true;
      } else {
        roadNameChangeAfterModal();
      }
    };
    const directionChanged = () => {
      clearDraggingState();
      if (state.records.length > 0) {
        state.modalCallback = () => {
          directionChangedAfterModal();
          state.modalCallback = null;
        };
        state.isShowingModal = true;
      } else {
        directionChangedAfterModal();
      }
    };
    // events: データ入力UI内
    const onClickBtn = (value: string) => {
      state.isEditted = true;
      if (state.draggingLeftKm === null || state.draggingRightKm === null) { return; }
      const record = {
        start: Number(state.draggingLeftKm),
        end: Number(state.draggingRightKm),
        selectedValue: value,
        ts: new Date(),
      };
      state.editingRecord = null;
      if (state.selectedMode_ === modeSnowMountain || state.draggingLeftKm !== state.draggingRightKm) {
        state.records.push(record);
        addEditHistory();
      }
      clearDraggingState();
    };
    const onClickRightBtn = () => {
      state.displayEnd = Math.min((state.displayEnd || 0) + state.displayMoveLength, (state.kpEnd || 0));
      state.displayStart = Math.max(0, state.displayEnd - 10);
    };
    const onClickLeftBtn = () => {
      state.displayStart = Math.max((state.displayStart || 0) - state.displayMoveLength, (state.kpStart || 0));
      state.displayEnd = Math.min(state.displayStart + 10, (state.kpEnd || 0));
    };
    const onClickUndo = () => {
      state.isEditted = true;
      if (state.dragstart !== null || state.draggingLeftKm !== null || state.draggingRightKm !== null) {
        clearDraggingState();
        restoreEditHistory();
      } else {
        undoEditHistory();
      }
    };
    const onClickSave = () => {
      state.isShowingSaveModal = true;
    };
    const onClickDelete = () => {
      state.isEditted = true;
      state.editingRecord = null;
      clearDraggingState();
      addEditHistory();
    };
    const stage = ref<Konva.Stage>();
    const layer = ref<Konva.Layer>();
    const handleDragstart = () => {
      if (!stage.value || stage.value?.getStage().getPointerPosition().y > state.graphHeight) { return; }
      const tapKm = calcKmFromPosition(stage.value?.getStage().getPointerPosition().x);
      let editingRecordIndex = getSelectRecordIndex(tapKm);
      if (editingRecordIndex !== null) {
        if (state.editingRecord !== null) {
          // 既にrecordが選択されていた場合
          deselectRecord();
        }
        // インデックスがずれるため再計算
        editingRecordIndex = getSelectRecordIndex(tapKm);

        if (editingRecordIndex) {
          state.editingRecord = state.records[Number(editingRecordIndex)];
          // 選択前の数値を保存しておく
          state.editingRecord.preSelectedValue = state.editingRecord.selectedValue;
          state.records.splice(Number(editingRecordIndex), 1);
          state.draggingLeftKm = Number(Number(state.editingRecord.start).toFixed(2));
          state.draggingRightKm = Number(Number(state.editingRecord.end).toFixed(2));
        }
      } else {
        if (state.editingRecord !== null) {
          // 既にrecordが選択されていた場合
          deselectRecord();
          state.isDragging = false;
          state.dragstart = null;
          state.draggingLeftKm = null;
          state.draggingRightKm = null;
        } else {
          state.isDragging = true;
          state.dragstart = snapDragstart(tapKm);
          state.draggingLeftKm = state.dragstart;
          state.draggingRightKm = state.dragstart;
          state.draggingLeftKm = Number(Number(state.draggingLeftKm).toFixed(2));
          state.draggingRightKm = Number(Number(state.draggingRightKm).toFixed(2));
        }
      }
    };
    const handleDragmove = () => {
      if (stage.value && state.isDragging && state.selectedMode !== modeSnowMountain) {
        let km = calcKmFromPosition(stage.value?.getStage().getPointerPosition().x);
        const snapped = snapDragging(state.dragstart || 0, km);
        updateDisplayStartEnd(snapped);
      }
    };
    const handleDragend = () => {
      if (stage.value && state.isDragging && state.selectedMode !== modeSnowMountain) {
        const km = calcKmFromPosition(stage.value?.getStage().getPointerPosition().x);
        const snapped = snapDragging(state.dragstart || 0, km);
        updateDisplayStartEnd(snapped);
        state.isDragging = false;
      }
    };
    const onChangeDraggingKm = () => {
      if (state.draggingLeftKm !== null && isNaN(state.draggingLeftKm)) {
        state.draggingLeftKm = null;
      }
      if (state.draggingRightKm !== null && isNaN(state.draggingRightKm)) {
        state.draggingRightKm = null;
      }
      if (state.draggingLeftKm === null || state.draggingRightKm === null) {
        return;
      }
      state.draggingLeftKm = snapDragstart(state.draggingLeftKm);
      state.draggingRightKm = snapDragging(state.draggingLeftKm, state.draggingRightKm);
      if ((state.draggingLeftKm || 0) < (state.displayStart || 0)) {
        state.draggingLeftKm = state.displayStart;
      }
      if ((state.draggingRightKm || 0) > (state.displayEnd || 0)) {
        state.draggingRightKm = state.displayEnd;
      }
      if ((state.draggingLeftKm || 0) > (state.draggingRightKm || 0)) {
        state.draggingRightKm = state.draggingLeftKm;
      }
    };
    // events: modals
    const onModalCloseClick = () => {
      state.isShowingModal = false;
      if (state.modalCallback) {
        state.modalCallback();
      }
    };
    const onModalDismissClick = () => {
      state.search.roadName = state.searchBeforeChange.roadName;
      state.search.direction = state.searchBeforeChange.direction;
      state.selectedMode_ = state.selectedMode;
      state.isShowingModal = false;
    };
    const onSaveModalCloseClick = () => {
      state.isShowingSaveModal = false;
      if (isHistory()) {
        updateGeoItems();
      } else {
        createGeoItems();
      }
    };
    const onSaveModalDismissClick = () => {
      state.isShowingSaveModal = false;
    };
    const onSavedModalCloseClick = () => {
      state.isShowingSavedModal = false;
      state.search.roadName = '-';
      state.search.direction = '-';
    };
    const onNetworkErrorModalCloseClick = () => {
      state.isShowingNetworkErrorModal = false;
    };
    const onFatalErrorModalCloseClick = () => {
      state.isShowingFatalErrorModal = false;
    };
    const onEditModeWithdrawalModalCloseClick = () => {
      state.isShowingEditModeWithdrawalModal = false;
      if (state.editModeWithdrawalDest === 'sp') {
        // リロードの必要有り
        window.location.href = '/sp';
      } else if (state.editModeWithdrawalDest === 'geo_item_groups') {
        window.location.href = '/sp/geo_item_groups';
      }
    };
    const onEditModeWithdrawalModalDismissClick = () => {
      state.isShowingEditModeWithdrawalModal = false;
    };
    const roadNameChangeAfterModal = () => {
      const roadNameObj = state.roadNameMap[state.search.roadName];
      state.directions = roadNameObj.directions;
      state.search.direction = state.directions[0].direction;
      state.selectedMode_ = modeFrozen;
      state.selectedMode = modeFrozen;
      state.searchBeforeChange.direction = state.search.direction;
      if (state.search.direction !== '-') {
        updateKp();
      }
      initRecords();
      state.searchBeforeChange.roadName = state.search.roadName;
    };
    const directionChangedAfterModal = () => {
      updateKp();
      initRecords();
      state.searchBeforeChange.direction = state.search.direction;
    };
    const onClickSearchAfterModal = () => {
      updateKp();
      initRecords();
    };
    // events: other
    const onChangeHeight = () => {
      state.spContainerHeight = state.height - state.headerHeight + 50;
      state.heightSpan = (state.height - (36 + 100 + 45 + 40 + state.headerHeight) + 50) / 4;
      if (state.heightSpan < 0) {
        state.heightSpan = 0;
      }
    };
    const handleResize = () => {
      state.width = window.innerWidth;
      state.height = window.innerHeight;
      if (!stage.value) {
        return;
      }
      const stageTmp = stage.value?.getStage();
      stageTmp.width(state.width - state.marginKonva);
      stageTmp.draw();
      onChangeHeight();
    };
    //
    // データ変換
    //
    const formatDt = (dt: Date | string) => {
      return dtFormat(dt, 'yyyy-mm-dd HH:MM:SS');
    };
    const formatTime = (dt: Date) => {
      return dtFormat(dt, 'H時M分');
    };
    const constructGeoItemsData = () => {
      const sendArr: Record<string, any>[] = [];
      const kps = getMainlineKps();
      // 選択中のデータがあった場合recordsに戻す
      if (state.editingRecord && state.editingRecord.preSelectedValue !== undefined) {
        onClickBtn(state.editingRecord.preSelectedValue);
      }

      validateGeoItemsData();

      const isKpIncDirection =
        isKpIncreaseDirection(state.search.roadName, state.search.direction);
      for (const record of state.records) {
        const ts = state.history.ts ? formatDt(state.history.ts) : formatDt(record.ts);
        if (state.selectedMode === modeSnowMountain) {
          const startKp = calcNearestKp(record.start);
          const i = startKp.kpIndex;
          const obj: Record<string, any> = {};
          obj['ts'] = ts;
          obj['data_type'] = state.selectedMode;
          obj['data'] = record.selectedValue;
          obj['road_name1'] = state.search.roadName;
          obj['direction1'] = state.search.direction;
          obj['place_name1'] = null;
          if (kps) {
            const kp1 = kps[i];
            obj['kp1'] = kp1.kp.toFixed(2);
            obj['kp2'] = kp1.kp.toFixed(2);
          }
          obj['road_name2'] = state.search.roadName;
          obj['direction2'] = state.search.direction;
          obj['place_name2'] = null;

          sendArr.push(obj);
        } else {
          const startKp = calcNearestKp(Math.min(Number(record.start), Number(record.end)));
          const endKp = calcNearestKp(Math.max(Number(record.end), Number(record.start)));
          if (startKp.kpIndex === endKp.kpIndex) {
            // 選択範囲に差がない場合はデータ登録しない(5.4〜5.4のような場合)
            continue;
          }
          for (let i = startKp.kpIndex; i < endKp.kpIndex; i++) {
            const obj: Record<string, any> = {};
            obj['ts'] = ts;
            obj['data_type'] = state.selectedMode;
            obj['data'] = record.selectedValue;
            obj['road_name1'] = state.search.roadName;
            obj['direction1'] = state.search.direction;
            obj['place_name1'] = null;
            if (kps) {
              const kp1 = isKpIncDirection ? kps[i] : kps[i + 1];
              obj['kp1'] = kp1.kp.toFixed(2);
              const kp2 = isKpIncDirection ? kps[i + 1] : kps[i];
              obj['kp2'] = kp2.kp.toFixed(2);
            }
            obj['road_name2'] = state.search.roadName;
            obj['direction2'] = state.search.direction;
            obj['place_name2'] = null;
            sendArr.push(obj);
          }
        }
      }
      return sendArr;
    };
    const validateGeoItemsData = () => {
      // 重なってしまっている場合（android pixel3のみで確認）先に入力されているものを優先する
      const records = state.records;
      state.records = [];
      for (const i of records) {
        i.start = snapDragstart(i.start);
        i.end = snapDragging(i.start, i.end);
        state.records.push(i);
      }
    };
    const getMainlineKps = () => {
      const k1 = state.search.roadName + '#' + state.search.direction;
      const k2 = 'main_line';
      return state.kpMap?.get(k1)?.get(k2);
    };
    const calcNearestKp = (km: number) => {
      // 最近傍のkpとkpのインデックスを取得する
      let kps = getMainlineKps();

      let nearestKp = kps ? kps[0] : undefined;
      let nearestKpIndex = 0;
      let nearestDist = Math.abs(km - (nearestKp?.kp || 0));

      if (kps) {
        for (let i = 1; i < kps.length; i++) {
          let dist = Math.abs(km - kps[i].kp);
          if (dist < nearestDist) {
            nearestKp = kps[i];
            nearestKpIndex = i;
            nearestDist = Math.abs(km - (nearestKp?.kp || 0));
          }
        }
      }
      return {
        kp: nearestKp,
        kpIndex: nearestKpIndex,
      };
    };
    //
    // データ入力UI
    //
    const clearDraggingState = () => {
      state.dragstart = null;
      state.draggingLeftKm = null;
      state.draggingRightKm = null;
    };
    const initRecords = () => {
      state.records = [];
      state.editHistory = [{
        records: [],
      }];
    };
    const updateKp = () => {
      let roadName = state.search.roadName + '#' + state.search.direction;
      for (const kpKey of state.kpMap) {
        if (kpKey[0] === roadName) {
          let road = kpKey[1];
          let mainLine = road.get('main_line');
          if (mainLine) {
            state.kpEnd = Number(mainLine[mainLine.length - 1].kp.toFixed(2));
            state.kpStart = Number(mainLine[0].kp.toFixed(2));
          }
          if (state.kpEnd !== null && state.kpStart !== null && (state.kpEnd - state.kpStart) < state.displayLength) {
            state.displayStart = state.kpStart;
            state.displayEnd = state.kpEnd;
          } else {
            state.displayStart = state.kpStart;
            state.displayEnd = (state.displayStart || 0) + state.displayLength;
          }
        }
      }
    };
    const deselectRecord = () => {
      // recordの選択を解除
      restoreEditHistory();
      state.editingRecord = null;
    };
    const calcKmFromPosition = (x: number) => {
      const displayStart = state.displayStart || 0;
      const displayEnd = state.displayEnd || 0;
      return x / (state.width - state.marginKonva) * (displayEnd - displayStart) + displayStart;
    };
    const calcPositionFromKm = (km: number) => {
      const displayStart = state.displayStart || 0;
      const displayEnd = state.displayEnd || 0;
      return (km - displayStart) / (displayEnd - displayStart) * (state.width - state.marginKonva);
    };
    const snapDragstart = (km: number) => {
      // 既にrecordが存在している場合recordが存在しない最近傍に移動する
      const margin = 0.01;
      let left = Number(km) - margin;
      let right = Number(km) + margin;
      let cont = true;
      while (cont) {
        cont = false;
        for (const record of state.records) {
          const recordStart = Number(record.start);
          const recordEnd = Number(record.end);
          if (recordStart <= left && left <= recordEnd) {
            left = recordStart - margin;
            cont = true;
          }
          if (recordStart <= right && right <= recordEnd) {
            right = recordEnd + margin;
            cont = true;
          }
        }
      }

      if (Math.abs(left - km) < Math.abs(right - km)) {
        return Number((left + margin).toFixed(2));
      } else {
        return Number((right - margin).toFixed(2));
      }
    };
    const snapDragging = (startKm: number, km: number) => {
      // 既にrecordが存在している場合recordが存在しない最近傍に移動する
      let rs = km;
      if (rs >= startKm) {
        for (const record of state.records) {
          if (startKm <= record.start && rs >= record.start) {
            rs = record.start;
          }
        }
      } else {
        for (const record of state.records) {
          if (startKm >= record.end && rs <= record.end) {
            rs = record.end;
          }
        }
      }
      return rs;
    };
    const updateDisplayStartEnd = (nowKm: number) => {
      const dragstart = state.dragstart || 0;
      const displayEnd = state.displayEnd || 0;
      const displayStart = state.displayStart || 0;
      if (dragstart < nowKm) {
        if (state.displayEnd !== null) {
          state.draggingRightKm = Math.min(displayEnd, nowKm);
        }
        state.draggingLeftKm = dragstart;
      } else {
        state.draggingRightKm = dragstart;
        state.draggingLeftKm = Math.max(displayStart, nowKm);
      }
      state.draggingLeftKm = Number(Number(state.draggingLeftKm).toFixed(2));
      state.draggingRightKm = Number(Number(state.draggingRightKm).toFixed(2));
    };
    const getDraggingRect = () => {
      if (state.draggingLeftKm === null || state.draggingRightKm === null) { return null; }
      return getRect({
        start: state.draggingLeftKm,
        end: state.draggingRightKm,
        selectedValue: '-',
        ts: new Date(),
      });
    };
    const getDraggingCircle = () => {
      if (state.draggingLeftKm === null || state.draggingRightKm === null) { return null; }
      return getCircle({
        start: state.draggingLeftKm,
        end: state.draggingRightKm,
        selectedValue: '-',
        ts: new Date(),
      });
    };
    const getSelectRecordIndex = (km: number) => {
      // 既にrecordを保存した位置をタップした場合はrecordのindexを返す
      // そうでない場合はnullを返す

      // 誤選択を防ぐ為のマージン（km単位）
      const margin = state.selectedMode === modeSnowMountain ? -0.1 : 0.0;
      for (const recordIndex in state.records) {
        let record = state.records[recordIndex];
        if (Number(record.start) + margin <= km && km <= Number(record.end) - margin) {
          return recordIndex;
        }
      }
      return null;
    };
    //
    // 画面遷移
    //
    const moveToSp = () => {
      if (checkEditted()) {
        state.editModeWithdrawalDest = 'sp';
        state.isShowingEditModeWithdrawalModal = true;
      } else {
        router.push({ name: 'SpTop' });
      }
    };
    const moveToGeoItemGroups = () => {
      if (checkEditted()) {
        state.editModeWithdrawalDest = 'geo_item_groups';
        state.isShowingEditModeWithdrawalModal = true;
      } else {
        router.push({ name: 'SpHistory' });
      }
    };
    //
    // 見た目
    //
    // 見た目: Konva
    const configKonva = () => {
      return {
        width: state.width - state.marginKonva,
        height: 100,
      };
    };
    const getVRectKey = (index: number) => {
      return 'rect' + index.toString();
    };
    const getRects = () => {
      const records = state.records;
      const ret = records.map(record => { return getRect(record); });
      return ret;
    };
    const getRect = (record: TopRecord) => {
      if (record.selectedValue === '-') {
        const strokeColor = state.editingRecord !== null ? '#a33fbb' : '#ff7702';
        return {
          x: calcPositionFromKm(record.start),
          y: 1,
          width: calcPositionFromKm(record.end) - calcPositionFromKm(record.start),
          height: state.graphHeight - 2,
          fill: getColor(record.selectedValue),
          strokeWidth: 2,
          opacity: 0.7,
          stroke: strokeColor,
        };
      } else {
        return {
          x: calcPositionFromKm(record.start),
          y: 0,
          width: calcPositionFromKm(record.end) - calcPositionFromKm(record.start),
          height: state.graphHeight,
          fill: getColor(record.selectedValue),
          opacity: 0.7,
          strokeWidth: 0,
        };
      }
    };
    const getCircles = () => {
      const records = state.records;
      return records.map(record => { return getCircle(record); });
    };
    const getCircle = (record: TopRecord) => {
      if (record.selectedValue === '-') {
        const strokeColor = state.editingRecord !== null ? '#a33fbb' : '#ff7702';
        return {
          x: calcPositionFromKm(record.start),
          y: 30,
          radius: 20,
          fill: getColor(record.selectedValue),
          strokeWidth: 2,
          opacity: 0.7,
          stroke: strokeColor,
        };
      } else {
        return {
          x: calcPositionFromKm(record.start),
          y: 30,
          radius: 20,
          fill: getColor(record.selectedValue),
          opacity: 0.7,
          strokeWidth: 0,
        };
      }
    };
    // 見た目: 道路の下のスケール
    const configScale = (scale: Scale) => {
      return {
        x: scale.position,
        y: state.graphHeight,
        text: scale.label + 'km',
        fontFamily: 'Calibri',
        fill: 'transparent',
      };
    };
    const getScales = () => {
      if (state.displayEnd === null || state.displayStart === null) {
        return [];
      } else {
        let result: Scale[] = [];
        result.push({
          label: getScaleLabel(state.displayStart),
          position: 0,
        });
        let start = Math.ceil(state.displayStart / 5) * 5;
        // state.displayEnd - 1 -> 最後のkpより1kmより後は目盛りを表示しない（重なるから）
        // startについても同様
        for (let i = start; i <= state.displayEnd - 1; i = i + 5) {
          if (i < state.displayStart + 1) { continue; }
          result.push({
            label: i.toString(),
            position: Math.min(
              Math.max((i - state.displayStart) / (state.displayEnd - state.displayStart) * (state.width - state.marginKonva) - 20, 0),
              state.width - 60,
            ),
          });
        }
        // 目盛りの文字がはみ出ないように調整
        let scaleOffset = (state.displayEnd.toString().length - 2) * 6 + 34;
        result.push({
          label: getScaleLabel(state.displayEnd),
          position: state.width - state.marginKonva - scaleOffset,
        });
        return result;
      }
    };
    const getScaleLabel = (num: number) => {
      // 普通にtoFixedとNumberを組み合わせると小数点第14位に誤差がでることがあるので面倒なことをしている
      if (num.toString().length < 4) {
        return num.toString();
      } else {
        return num.toFixed(1);
      }
    };
    const calcScalePosition = (scale: Scale) => {
      let position = scale.position;
      if (state.displayStart !== null && state.kpStart !== null && state.displayStart > state.kpStart) {
        position += 15;
      }
      return position;
    };
    // 見た目: その他
    const calcRoadTableWidth = () => {
      let width = state.width - state.marginKonva;
      if (state.displayStart !== null && state.kpStart !== null && state.displayStart > state.kpStart) {
        width += 15;
      }
      if (state.displayEnd !== null && state.kpEnd !== null && state.displayEnd < state.kpEnd) {
        width += 15;
      }
      return width;
    };
    const getColor = (selectedValue: string) => {
      if (state.selectedMode === modeSnowfall) {
        if (selectedValue === '-') {
          return '#f0f0f0';
        } else if (Number(selectedValue) === 0) {
          return '#b1b1b1';
        } else if (Number(selectedValue) === 5) {
          return '#B2EBF2';
        } else if (Number(selectedValue) === 10) {
          return '#81D4FA';
        } else if (Number(selectedValue) === 15) {
          return '#42A5F5';
        } else if (Number(selectedValue) === 20) {
          return '#413EC4';
        } else if (Number(selectedValue) === 25) {
          return '#A001C3';
        } else if (Number(selectedValue) === 30) {
          return '#FFFF00';
        } else if (Number(selectedValue) === 35) {
          return '#FF0000';
        }
      } else if (state.selectedMode === modeFrozen) {
        if (selectedValue === '-') {
          return '#f0f0f0';
        } else if (Number(selectedValue) === 0) {
          return '#b1b1b1';
        } else {
          return '#a33fbb';
        }
      } else if (state.selectedMode === modeWorkConfirmed) {
        if (selectedValue === '-') {
          return '#f0f0f0';
        } else if (Number(selectedValue) === 0) {
          return '#b1b1b1';
        } else {
          return '#008E74';
        }
      } else if (state.selectedMode === modeSnowMountain) {
        if (selectedValue === '-') {
          return '#f0f0f0';
        } else if (Number(selectedValue) === 0) {
          return '#b1b1b1';
        } else {
          return '#74c7c6';
        }
      }
      return '';
    };
    const getRoadNameDisp = (real: string) => {
      if (state.roadNameMap[real]) {
        return state.roadNameMap[real].roadNameDisp;
      } else {
        return '';
      }
    };
    const selectModeStr = (v: string) => {
      for (const item of state.selectModes) {
        if (item.value === v) {
          return item.text;
        }
      }
      return '';
    };
    //
    // 編集履歴関連
    //
    const initEditHistory = () => {
      state.editHistory = [{ records: Array.from(state.records) }];
    };
    const addEditHistory = () => {
      state.editHistory.push({
        records: Array.from(state.records),
      });
    };
    const undoEditHistory = () => {
      if (state.editHistory.length <= 1) {
        restoreEditHistory();
        return;
      }
      state.editHistory.pop();
      restoreEditHistory();
    };
    // 最新状態に戻す
    const restoreEditHistory = () => {
      const history = state.editHistory[state.editHistory.length - 1];
      state.records = Array.from(history.records);
    };
    const checkEditted = () => {
      if (isHistory()) {
        return state.isEditted || getDraggingRect();
      } else {
        return state.records.length > 0 || getDraggingRect();
      }
    };

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });
    return {
      ...toRefs(state),
      stage,
      layer,
      // computed
      isModeSnowfall,
      isModeFrozen,
      isModeWorkConfirmed,
      isModeSnowMountain,
      // methods
      isHistory,
      loadHistory,
      updateGeoItems,
      createGeoItems,
      onChangeSelectedMode,
      roadNameChanged,
      directionChanged,
      onClickBtn,
      onClickRightBtn,
      onClickLeftBtn,
      onClickUndo,
      onClickSave,
      onClickDelete,
      handleDragstart,
      handleDragmove,
      handleDragend,
      onChangeDraggingKm,
      onModalCloseClick,
      onModalDismissClick,
      onSaveModalCloseClick,
      onSaveModalDismissClick,
      onSavedModalCloseClick,
      onNetworkErrorModalCloseClick,
      onFatalErrorModalCloseClick,
      onEditModeWithdrawalModalCloseClick,
      onEditModeWithdrawalModalDismissClick,
      roadNameChangeAfterModal,
      directionChangedAfterModal,
      onClickSearchAfterModal,
      onChangeHeight,
      handleResize,
      formatTime,
      constructGeoItemsData,
      validateGeoItemsData,
      getMainlineKps,
      calcNearestKp,
      clearDraggingState,
      initRecords,
      updateKp,
      deselectRecord,
      calcKmFromPosition,
      calcPositionFromKm,
      snapDragstart,
      snapDragging,
      updateDisplayStartEnd,
      getDraggingRect,
      getDraggingCircle,
      getSelectRecordIndex,
      moveToSp,
      moveToGeoItemGroups,
      configKonva,
      getVRectKey,
      getRects,
      getRect,
      getCircles,
      getCircle,
      configScale,
      getScales,
      getScaleLabel,
      calcScalePosition,
      calcRoadTableWidth,
      getColor,
      getRoadNameDisp,
      selectModeStr,
      initEditHistory,
      addEditHistory,
      undoEditHistory,
      restoreEditHistory,
      checkEditted,
    };
  },
});
