export function emptyToNull(str: string | null): string | null {
  if (str === undefined) { return null; }
  if (str === null) { return null; }
  if (str === '') { return null; }
  return str;
}

export function mixStrNumToNum(strNum?: string | number): number {
  if (typeof strNum === 'string') {
    return Number(strNum);
  }
  if (strNum === undefined) {
    return 0;
  }
  return strNum;
}

export function mixStrNumToString(strNum?: string | number): string {
  if (typeof strNum === 'number') {
    return String(strNum);
  }
  if (strNum === undefined) {
    return '';
  }
  return strNum;
}

export function isNumber(str: string): boolean {
  const pattern = /^\d(\.\d+)?$/;
  return pattern.test(str);
}
