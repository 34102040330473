import { ComputedRef, Ref } from '@vue/composition-api';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { WeatherObservatoryState } from '@/models/extremeMap';
import { WeatherObservatoryExt, DeselectFuncMap } from '@/models';

export interface UseWeatherObservatoryState {
  showWeatherObservatories: boolean;
  weatherObservatoryAreaChoices: { key: string; val: string }[];
  weatherObservatoryAreaFilter: string[];
}
export interface UseWeatherObservatoryParams {
  deselectLayers: (obj: { excludes: Array<keyof DeselectFuncMap> }) => void;
  refExtremeMap: Ref<InstanceType<typeof ExtremeMap> | undefined>;
  state: WeatherObservatoryState;
}
export interface UseWeatherObservatoryResult {
  state: UseWeatherObservatoryState;
  canUseWeatherObservatory: ComputedRef<boolean>;
  onClickWeatherObservatory: (observatory: WeatherObservatoryExt) => Promise<void>;
  deselectAllWeatherObservatory: () => void;
  toggleWeatherObservatoryLayer: () => Promise<void>;
}

export const weatherObservatoryAreaChoices = [
  { key: 't-west', val: '西' },
  { key: 't-east', val: '東' },
  { key: 'kanagawa', val: '神' },
];
