















import {
  defineComponent,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import TemperatureHelper from '@/lib/geo_item/temperatureHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-temperature',
  setup() {
    const store = useStore();
    const userGroupInfo = store.state.user.g_info;
    const temperatureHelper = new TemperatureHelper();
    const arr: DispData[] = [];
    let min = -9;
    let max = 21;
    let step = 3;
    if (userGroupInfo && userGroupInfo.g1_name.startsWith('首都高')) {
      min = -6;
      max = 12;
      step = 2;
    }
    for (let i = min; i <= max; i += step) {
      arr.push({
        val: i,
        isLast: i === max,
        showLabel: i % step === 0,
        strWidth: i.toString().length,
      });
    }
    arr.forEach(e => {
      const arr = temperatureHelper.objKeyToColor(
        temperatureHelper.dataToObjKey(e.val, userGroupInfo),
        userGroupInfo);
      e.color = `rgb(${arr[0]},${arr[1]},${arr[2]})`;
    });
    return { dispData: arr };
  },
});
