



















import {
  defineComponent,
  computed,
} from '@vue/composition-api';
import { PageItem } from '@/models';

export default defineComponent({
  name: 'pagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const maxPage = computed<number>(() => {
      return Math.ceil(props.totalItems / props.itemsPerPage);
    });
    const shouldHide = computed<boolean>(() => {
      if (!props.autoHide) { return false; }
      return maxPage.value < 2;
    });
    const pageItems = computed<PageItem[]>(() => {
      const fromPage = 1;
      const toPage = maxPage.value;
      const ret: PageItem[] = [];
      for (let n = fromPage; n <= toPage; n++) {
        ret.push({ pageNum: n });
      }
      return ret;
    });

    const pageClick = (pageNum: number) => {
      emit('page-click', { pageNum });
    };
    const prevPageClick = () => {
      if (props.currentPage <= 1) { return; }
      pageClick(props.currentPage - 1);
    };
    const nextPageClick = () => {
      if (props.currentPage >= maxPage.value) { return; }
      pageClick(props.currentPage + 1);
    };

    return {
      maxPage,
      shouldHide,
      pageItems,
      pageClick,
      prevPageClick,
      nextPageClick,
    };
  },
});
