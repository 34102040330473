








import Vue from 'vue';
import Calendar from '@/components/lib/Calendar.vue';
import { calendarDefaults } from '@/consts/calendar';
import {
  defineComponent,
  onMounted,
  watch,
  reactive,
  computed,
  PropType,
} from '@vue/composition-api';
import { dtFormat, ensureDate } from '@/lib/dateHelper';

interface MyCalenderState {
  isDateValueSet: boolean;
  dateValue?: Date | string;
  givenTextValue: string;
}

export default defineComponent({
  name: 'my-calendar',
  props: {
    dateValue: {
      type: [String, Date],
    },
    format: {
      type: String,
      default: calendarDefaults.format,
    },
    inputClassObj: {
      type: Object as PropType<Record<string, string>>,
      default: () => {
        return {};
      },
    },
    inputClearButton: {
      type: Boolean,
      default: calendarDefaults.inputClearButton,
    },
    clearButton2Disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: calendarDefaults.readonly,
    },
    placeholder: {
      type: String,
    },
    lang: {
      type: String,
      default: calendarDefaults.lang,
    },
    pane: {
      type: Number,
      default: calendarDefaults.pane,
    },
    dayHeaderFormat: {
      type: String,
      default: calendarDefaults.dayHeaderFormat,
    },
    disableSwitchMonthView: {
      type: Boolean,
      default: calendarDefaults.disableSwitchMonthView,
    },
    onDayClick: {
      type: Function as PropType<(date: Date, dateString: string) => void>,
      default: () => { },
    },
    changePane: {
      type: Function as PropType<(year: number, month: number, pane: number) => void>,
      default: () => { },
    },
    rangeBus: {
      type: Function as PropType<() => Vue>,
      default: () => undefined,
    },
    dateInputBgColor: {
      type: String,
    },
    dateInputPadding: {
      type: String,
    },
    setDateValueOnce: {
      type: Boolean,
      default: false,
    },
    xOffsetCalcParent: {
      type: HTMLElement,
    },
    yOffsetCalcParent: {
      type: HTMLElement,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive<MyCalenderState>({
      isDateValueSet: false,
      dateValue: props.dateValue,
      givenTextValue: '',
    });

    const inputValue = computed<string>({
      get: () => {
        if (!state.dateValue) { return ''; }
        return dtFormat(new Date(state.dateValue), props.format);
      },
      set: (val: string) => {
        // 一応textの状態も保持するだけしておこう
        state.givenTextValue = val;
        if (val && val.length !== props.format.length) {
          return;
        }
        // 桁数が正常なら、日付として評価してみる
        const dt = ensureDate(val);
        if (dt && !isNaN(dt.getTime())) {
          // dtが正常なら、その時点で同期しておく
          state.dateValue = dt;
        }

        emit('date-input', dt);
      },
    });

    const trySetDateValue = () => {
      if (!props.dateValue) { return; }
      if (props.setDateValueOnce && state.isDateValueSet) { return; }
      state.isDateValueSet = true;
      state.dateValue = props.dateValue;
    };

    onMounted(() => {
      trySetDateValue();
    });
    watch(() => props.dateValue, trySetDateValue);
    const onKeyup = ($event: KeyboardEvent) => {
      emit('keyup', $event);
    };
    return {
      inputValue,
      onKeyup,
    };
  },
  components: { Calendar },
});
