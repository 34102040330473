import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { AdminCommentTypeIndexResponse } from '@/models/apis/commentType/adminCommentTypeResponse';
import { CommentType } from '@/models/apis/master/masterResponse';
import { AdminCommentTypeCreateParams, AdminCommentTypeUpdateParams } from '@/models/apis/commentType/adminCommentTypeRequest';

export default {
  adminIndex(): Promise<AxiosResponse<AdminCommentTypeIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/comment_types'));
  },
  adminCreate(params: AdminCommentTypeCreateParams): Promise<AxiosResponse<CommentType>> {
    return axios.post(utils.getApiUrl('/admin/comment_types'), params);
  },
  adminUpdate(id: number, params: AdminCommentTypeUpdateParams): Promise<AxiosResponse<CommentType>> {
    return axios.put(utils.getApiUrl(`/admin/comment_types/${id}`), params || {});
  },
  adminDestroy(id: number): Promise<AxiosResponse<CommentType>> {
    return axios.delete(utils.getApiUrl(`/admin/comment_types/${id}`));
  },
};
