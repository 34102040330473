import { reactive, UnwrapRef } from '@vue/composition-api';
import { createInjection } from '@/lib/createInjection';

export interface WaitSpinnerState {
  showWaitSpinner: boolean;
}

const { provide, inject } = createInjection<UnwrapRef<WaitSpinnerState>>('cleaningMap/useWaitSpinner');

export function provideWaitSpinner(): WaitSpinnerState {
  const waitSpinnerState = reactive<WaitSpinnerState>({
    showWaitSpinner: false,
  });
  provide(waitSpinnerState);
  return waitSpinnerState;
}
export function useWaitSpinner(): WaitSpinnerState {
  return inject();
}
