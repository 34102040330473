import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { AdminGroup3IndexParams } from '@/models/apis/group3/adminGroup3Request';
import { AdminGroup3IndexResponse } from '@/models/apis/group3/adminGroup3Response';

export default {
  adminIndex(params?: AdminGroup3IndexParams): Promise<AxiosResponse<AdminGroup3IndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/groups3'), {
      params: params || {},
    });
  },
};
