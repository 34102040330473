























import Vue from 'vue';
import axios from 'axios';
import MobileDetect from 'mobile-detect';
import GlobalHeader from '@/components/lib/GlobalHeader.vue';
import GlobalFooter from '@/components/lib/GlobalFooter.vue';
import PrintPageWrapper from '@/components/lib/PrintPageWrapper.vue';
import { LocalStorageActionTypes, LocalStorageGetterTypes } from '@/store/modules/localStorage';
import { UserActionTypes } from '@/store/modules/user';

import {
  computed,
  defineComponent,
  onBeforeMount,
} from '@vue/composition-api';
import { useRoute } from './hooks/useRoute';
import { useStore } from './hooks/useStore';
import { registerVideojsComponents } from '@/lib/videojsHelper';
import { loadUserAndMasters } from '@/lib/masterHelper';
import { ExtParams } from './models';

export default defineComponent({
  name: 'App',
  setup() {
    onBeforeMount(() => {
      setEnv();
      parseExtParams();
      setAxios();
      setVideojs();
      prepareMobileDetections();
      setDebugControlFlags();
      loadUserAndMasters();
    });
    const showHeader = computed(() => {
      return !isGuestPage() && !isSpPage();
    });
    const { route, router } = useRoute();
    const isGuestPage = () => {
      const arr = [ 'Login' ];
      if (!route.value.name) {
        return false;
      }
      return arr.indexOf(route.value.name) !== -1;
    };
    const isPrintPage = computed(() => {
      return !!route.value.name && route.value.name.startsWith('Print');
    });
    const isSpPage = () => {
      return !!route.value.name && route.value.path.indexOf('/sp') === 0;
    };
    const setEnv = () => {
      const envElement: HTMLMetaElement | null = document.querySelector('meta[name=environment]');
      if (!envElement) {
        return;
      }
      const environment = envElement.attributes.getNamedItem('content')?.value;
      Vue.prototype.$isProduction = () => {
        return environment === 'production';
      };
      Vue.prototype.$isStaging = () => {
        return environment === 'staging' || environment === 'stg';
      };
      Vue.prototype.$isDevelopment = () => {
        return !Vue.prototype.$isProduction() && !Vue.prototype.$isStaging();
      };
    };
    const store = useStore();
    const parseExtParams = () => {
      const params = new URLSearchParams(window.location.search);
      if (Array.from(params.entries()).length === 0) { return; }
      const val: ExtParams = {};
      const commentId = params.get('comment_id');
      if (commentId) {
        val.commentId = commentId;
      }
      store.dispatch(LocalStorageActionTypes.SET, { key: 'extParams', val });
    };
    const setAxios = () => {
      // base url
      axios.defaults.baseURL = '/api/v1';

      // token
      const token = store.getters[LocalStorageGetterTypes.SERVER_TOKEN];
      axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      if (!isGuestPage()) {
        store.dispatch(UserActionTypes.GET_ME)
          .then(() => {
            // nothing to do
          })
          .catch(() => {
            router.push({name: 'Login'});
          });
      }
    };
    const setVideojs = () => {
      registerVideojsComponents();
    };
    const prepareMobileDetections = () => {
      Vue.prototype.$getMobileDetector = (ua: string) => {
        const md = new MobileDetect(ua);
        return {
          isMobile() {
            return !!md.mobile();
          },
          isIOS() {
            return md.is('iOS');
          },
          isAndroid() {
            return md.is('AndroidOS');
          },
        };
      };
    };
    const setDebugControlFlags = () => {
      const locSearch = location.search;
      // 路線名#方向が入ってない動画も表示する
      Vue.prototype.$isDebugShowNoKpMovies =
        locSearch.indexOf('debug_show_no_kp_movies') !== -1;
      // 接続部分を青く表示する
      Vue.prototype.$isDebugShowGeoConnections =
        locSearch.indexOf('debug_show_geo_connections') !== -1;
      // KP(全て)のレイヤーを表示する
      Vue.prototype.$isDebugShowKpAllLayer =
        locSearch.indexOf('debug_show_kp_all_layer') !== -1;
    };
    return {
      showHeader,
      // methods
      setEnv,
      setAxios,
      setVideojs,
      prepareMobileDetections,
      setDebugControlFlags,
      isPrintPage,
    };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
    PrintPageWrapper,
  },
});
