



















































import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import GeoItemSearchCommon from './GeoItemSearchCommon.vue';
import useMaster from '@/composables/useMaster';
import { CommentIndexParams } from 'src/models/apis/comment/commentRequest';

interface SearchParams {
  share_scope: number | null;
  only_current_user: boolean;
}

interface SearchType {
  key: string;
  val: string;
}

type SearchTypeMap = Record<string, {
  val: string;
  disp_order: number;
}>;

interface GeoItemSearchCommentState {
  showShareScope: boolean;
  showOnlyCurrentUser: boolean;
  search: SearchParams;
  searchTypes: SearchType[];
  searchTypeMap: SearchTypeMap;
}

export default defineComponent({
  name: 'geo-item-search-comment',
  setup(_, { emit }) {
    const initSearchState = (): SearchParams => {
      return {
        share_scope: null,
        only_current_user: false,
      };
    };
    const state = reactive<GeoItemSearchCommentState>({
      showShareScope: false,
      showOnlyCurrentUser: false,
      search: initSearchState(),
      searchTypes: [],
      searchTypeMap: {},
    });

    const { state: msts } = useMaster();
    const setInitialSearchParams = () => {
      const params = {
        share_scope: msts.shareScopes[0].key,
        only_current_user: false,
      };
      state.search = params;
    };
    watch(() => msts.commentTypes, () => {
      state.searchTypes = msts.commentTypes.map(e => {
        return {'key': e.comment_type, 'val': e.comment_type_disp};
      });
      msts.commentTypes.forEach(e => {
        state.searchTypeMap[e.comment_type] = {'val': e.comment_type_disp, 'disp_order': e.disp_order};
      });
      setInitialSearchParams();
    });

    const selectedShareScopeDisp = computed<string>(() => {
      if (!state.search.share_scope) { return ''; }
      const ret = msts.shareScopeMap[state.search.share_scope].val_alt;
      return `（${ret}）`;
    });
    const onlyCurrentUserDisp = computed<string>(() => {
      const ret = state.search.only_current_user ? '自分のみ' : '全て';
      return `（${ret}）`;
    });
    const refSearchCommon = ref<InstanceType<typeof GeoItemSearchCommon>>();
    const getSearchParams = (): CommentIndexParams | null => {
      if (!refSearchCommon.value) { return null; }
      const { search_types: commentTypes, ...other } = refSearchCommon.value.getSearchParams();
      return {
        comment_types: commentTypes as string[],
        share_scope: state.search.share_scope,
        only_current_user: state.search.only_current_user ? 1 : 0,
        ts_from: other.ts_from,
        ts_to: other.ts_to,
        road_name_disps: other.road_name_disps,
        directions: other.directions,
        kp_calc_from: other.kp_calc_from,
        kp_calc_to: other.kp_calc_to,
      };
    };
    const onChange = () => {
      emit('change');
    };

    const checkboxKeyPrefix = 'comment';
    return {
      ...toRefs(state),
      msts,
      // refs
      refSearchCommon,
      // computed
      selectedShareScopeDisp,
      onlyCurrentUserDisp,
      // methods
      getSearchParams,
      onChange,
      // others
      checkboxKeyPrefix,
    };
  },
  components: { GeoItemSearchCommon },
});
