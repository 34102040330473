





















import {
  defineComponent,
  computed,
} from '@vue/composition-api';
import { calendarDefaults } from '@/consts/calendar';
export default defineComponent({
  name: 'date-time-input',
  props: {
    date: {
      type: [String, Date],
    },
    hour: {
      type: [String, Number],
    },
    minute: {
      type: [String, Number],
    },
    format: {
      type: String,
      default: calendarDefaults.format,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    xOffsetCalcParent: {
      type: HTMLElement,
    },
    yOffsetCalcParent: {
      type: HTMLElement,
    },
    errorObj: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dateIsError = computed<boolean>(() => {
      return props.errorObj && (props.errorObj.all || props.errorObj.date);
    });
    const hourIsError = computed<boolean>(() => {
      return props.errorObj && (props.errorObj.all || props.errorObj.hour);
    });
    const minuteIsError = computed<boolean>(() => {
      return props.errorObj && (props.errorObj.all || props.errorObj.minute);
    });

    const onDateInput = ($event: Event) => {
      emit('update:date', $event);
    };
    const onHourInput = ($event: Event) => {
      emit('update:hour', ($event.target as HTMLInputElement).value);
    };
    const onMinuteInput = ($event: Event) => {
      emit('update:minute', ($event.target as HTMLInputElement).value);
    };
    return {
      // computed
      dateIsError,
      hourIsError,
      minuteIsError,
      // methods
      onDateInput,
      onHourInput,
      onMinuteInput,
    };
  },
});
