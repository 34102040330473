
















import {
  defineComponent,
} from '@vue/composition-api';
import TairyuHelper from '@/lib/geo_item/tairyuHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-tairyu',
  setup() {
    const tairyuHelper = new TairyuHelper();
    let min = 1;
    let max = 400;
    const vals = [min, 50, 100, 200, max];
    const arr: DispData[] = vals.map(val => {
      return {
        val,
        showLabel: true,
        strWidth: val.toString().length,
      };
    });
    arr.forEach(e => {
      const colorArr = tairyuHelper.objKeyToColor(tairyuHelper.dataToObjKey({
        numVehiclesSumPerKilometer: e.val,
        numVehicles: e.val, // 何か入れないと0として認識されてしまうので
      }));
      e.color = `rgb(${colorArr[0]},${colorArr[1]},${colorArr[2]})`;
    });
    return { dispData: arr };
  },
});
