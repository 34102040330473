import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class work_confirmedHelper extends AbstractGeoItem<boolean, number, number, number[]> {
  dataToObjKey(inputData: boolean): number {
    let data: DataContent = { v: false };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    return data.v ? 1 : 0;
  }
  objKeyToColor(intputObjKey: number): number[] {
    let ret;
    const objKey = parseInt(intputObjKey.toString());
    if (!objKey) {
      ret = [80, 80, 80, 0.5];
    } else {
      ret = [0, 255, 0, 1];
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style[] {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = [];
    if (parseInt(objKey.toString())) {
      style.push(new Style({
        stroke: new Stroke({
          color: '#000000',
          width: width,
        }),
      }));
      style.push(new Style({
        stroke: new Stroke({
          color: color,
          width: width - 2,
        }),
      }));
    } else {
      style.push(new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }));
    }
    return style;
  }
}
