












import {
  defineComponent,
} from '@vue/composition-api';
import { getFeatureColor } from '@/lib/other_data_layer/stallRiskPointHelper';
import { DispDataString } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-stall-risk-point',
  setup() {
    const dispData: DispDataString[] = [
      { val: 'main_line', label: '本線' },
      { val: 'jct', label: 'JCT' },
      { val: 'gate', label: '出入口' },
    ];
    dispData.forEach(e => {
      const { fillColor, strokeColor } = getFeatureColor(e.val);
      e.fillColor =
        `rgb(${fillColor[0]},${fillColor[1]},${fillColor[2]})`;
      e.strokeColor =
        `rgb(${strokeColor[0]},${strokeColor[1]},${strokeColor[2]})`;
    });
    return { dispData };
  },
});
