import { reactive, UnwrapRef } from '@vue/composition-api';
import { PhotoModalState } from '@/models/cleaningMap';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';

export interface UsePhotoModalResult {
  photoModalState: UnwrapRef<PhotoModalState>;
  showPhotoModal: (photoType: string, photos: CleaningReportPhotoExt[]) => void;
  hidePhotoModal: () => void;
}
export function usePhotoModal(): UsePhotoModalResult {
  const photoModalState = reactive<PhotoModalState>({
    shouldShowPhotoModal: false,
    photoType: '',
    photos: [],
  });

  const showPhotoModal = (photoType: string, photos: CleaningReportPhotoExt[]) => {
    photoModalState.photoType = photoType;
    photoModalState.photos = photos;
    photoModalState.shouldShowPhotoModal = true;
  };
  const hidePhotoModal = () => {
    photoModalState.shouldShowPhotoModal = false;
  };

  return {
    photoModalState,
    showPhotoModal,
    hidePhotoModal,
  };
}
