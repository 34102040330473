import { Style, Circle, Fill, Stroke, Text } from 'ol/style';
import { Feature } from 'ol';
import { Point, MultiLineString } from 'ol/geom';
import VectorLayer, { Options as VectorLayerOptions } from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { containsCoordinate, Extent } from 'ol/extent';
import { Settings as UserSettings } from 'src/models/apis/user/userResponse';
import { GeoConnection, KpMap } from 'src/models';
import { GIKilopost } from '@/models/geoItem';
import { Coordinate } from 'ol/coordinate';
import { Options as StrokeOptions } from 'ol/style/Stroke';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import ExtremeMapAbstractLayerManager from '@/lib/ExtremeMapAbstractLayerManager';

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}

interface PointColor {
  fill: string;
  stroke: string;
}

interface LineColor {
  roadLine1: {
    color: string;
    width: number;
  };
  roadLine2: {
    color: string;
    width: number;
  };
  debugConn1: {
    color: string;
    width: number;
  };
}

interface KpColorMap {
  point: {
    strong: PointColor;
    weak: PointColor;
  };
  line: {
    strong: LineColor;
    weak: LineColor;
  };
}

interface KpLayers {
  roadLayer: NamedVectorLayer | null;
  kpLayerFiltered: NamedVectorLayer | null;
  kpLayerAll: NamedVectorLayer | null;
}

type KeyOfLineColor = 'roadLine1' | 'roadLine2' | 'debugConn1';

export default class ExtremeMapKilopostLayerManager extends ExtremeMapAbstractLayerManager {
  opts: Options;
  userSettings: UserSettings;
  kpMap: KpMap;
  roadLayer: NamedVectorLayer | null;
  kpLayerFiltered: NamedVectorLayer | null;
  kpLayerAll: NamedVectorLayer | null;
  colorType: 'strong' | 'weak';
  pointFeatsFiltered: Feature[];

  isDebugShowGeoConnections? = false;
  isDebugShowKpAllLayer? = false;

  constructor(opts = {}) {
    super();
    this.opts = opts;
    this.userSettings = {} as UserSettings;
    this.kpMap = {} as KpMap;
    this.roadLayer = null;
    this.kpLayerFiltered = null;
    this.kpLayerAll = null;
    this.colorType = 'strong'; // strong|weak
    this.pointFeatsFiltered = [];

    // デバッグ用に接続部を青く表示するかどうか
    this.isDebugShowGeoConnections = this.opts.isDebugShowGeoConnections;
    // KP(全て)のレイヤーを表示するかどうか
    this.isDebugShowKpAllLayer = this.opts.isDebugShowKpAllLayer;
  }

  getKpColorMap_(): KpColorMap {
    return {
      point: {
        strong: {
          fill: '#ff7f00',
          stroke: '#b85b00',
        },
        weak: {
          // 使ってない
          fill: '#ff7f00',
          stroke: '#b85b00',
        },
      },
      line: {
        strong: {
          roadLine1: { color: '#e67200', width: 6 },
          roadLine2: { color: '#ff8c1a', width: 4 },
          debugConn1: { color: '#4286f4', width: 4 },
        },
        weak: {
          roadLine1: { color: '#f2b77d', width: 6 },
          roadLine2: { color: '#ffc48a', width: 4 },
          debugConn1: { color: '#9ec1f9', width: 4 },
        },
      },
    };
  }

  getNewKpPointStyle_({ colorType, text }: { colorType: 'strong' | 'weak'; text: string }): Style {
    const colorMap = this.getKpColorMap_().point[colorType];
    const fillColor = colorMap.fill;
    const strokeColor = colorMap.stroke;
    const ret = new Style({
      image: new Circle({
        radius: 3,
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: 1,
        }),
      }),
    });
    if (text) {
      ret.setText(new Text({
        text: text,
        font: 'bold 12px sans-serif',
        fill: new Fill({
          color: 'black',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 0.8)',
          width: 3,
        }),
        offsetX: 0,
        offsetY: 12,
      }));
    }
    return ret;
  }

  getNewKpPointFeature_(coord: Coordinate, info: { id: string; colorType: 'strong' | 'weak'; text: string }): Feature {
    const feat = new Feature({
      geometry: new Point(coord),
    });
    feat.setId(info.id);
    const style = this.getNewKpPointStyle_({
      colorType: info.colorType,
      text: info.text,
    });
    feat.setStyle(style);
    return feat;
  }

  getNewKpPointFeatureByKpObj_({kp, fixDigits}: { kp: GIKilopost; fixDigits: number }): Feature {
    const coord = this.coordFromLonLat(kp.lon, kp.lat);
    const kpText = [
      kp.kp_prefix || kp.road_name_disp.slice(0, 1),
      `(${kp.direction})`,
      kp.place_name === 'main_line' ? '' : kp.place_name,
      Number(kp.kp).toFixed(fixDigits),
    ].filter(e => !!e).join(' ');
    const info = { id: kp.kp_uid, colorType: this.colorType, text: kpText };
    return this.getNewKpPointFeature_(coord, info);
  }

  getKpMultiLinesFeature_(arrOfLineStringCoords: Coordinate[][], opts: StrokeOptions = {}): Feature {
    const strokeColor = opts.color;
    const strokeWidth = opts.width;

    const multiLineString = new MultiLineString(arrOfLineStringCoords);
    const feat = new Feature(multiLineString);
    const style = new Style({
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
    });
    feat.setStyle(style);
    return feat;
  }

  binSearch_(arr: GIKilopost[], num: number): number {
    // numを見つける. arrは昇順前提.
    let low = 0;
    let high = arr.length - 1;
    let mid, guess;
    while (low <= high) {
      mid = parseInt(((low + high) / 2).toString());
      guess = arr[mid];
      if (Math.abs(guess.kp_calc - num) < Number.EPSILON) {
        return mid;
      } else if (guess.kp_calc > num) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
    return -1;
  }

  findKpsFromArr(arr: GIKilopost[], num1: number, num2: number): GIKilopost[] {
    const ret: GIKilopost[] = [];
    // num1を見つける. arrは昇順前提.
    let idx = this.binSearch_(arr, num1);
    if (idx === -1) { return ret; }

    if (num1 <= num2) {
      const len = arr.length;
      while (idx < len) {
        const elem = arr[idx];
        if (elem.kp_calc > num2) { break; }
        ret.push(arr[idx++]);
      }
    } else {
      while (idx >= 0) {
        const elem = arr[idx];
        if (elem.kp_calc < num2) { break; }
        ret.push(arr[idx--]);
      }
    }
    return ret;
  }

  findKpFromArr(arr: GIKilopost[], num: number): GIKilopost | null {
    const result = this.findKpsFromArr(arr, num, num);
    return result.length > 0 ? result[0] : null;
  }

  getBranches(srcRoadNameDirection: string, geoConnections: GeoConnection): Array<GIKilopost[]> {
    const ret: Array<GIKilopost[]> = [];
    const conns = geoConnections[srcRoadNameDirection];
    if (!conns) { return ret; }
    for (const [dstK1, obj1] of Object.entries(conns)) {
      const kParts = dstK1.split('#');
      // 2か3のはず
      if (kParts.length === 2) {
        let tmpArr = this.kpMap.get(srcRoadNameDirection)?.get('main_line');
        const kp1 = this.findKpFromArr(tmpArr ?? [], obj1.src_kp_end);
        tmpArr = this.kpMap.get(dstK1)?.get('main_line');
        const kp2 = this.findKpFromArr(tmpArr ?? [], obj1.dst_kp_start);
        if (kp1 && kp2) {
          ret.push([kp1, kp2]);
        }
      } else {
        const dstK2 = obj1.next_key;
        // 開始
        let tmpArr = this.kpMap.get(srcRoadNameDirection)?.get('main_line');
        const kp1 = this.findKpFromArr(tmpArr ?? [], obj1.src_kp_end);
        // 経由
        const obj2 = obj1[dstK2];
        tmpArr = this.kpMap.get(`${kParts[0]}#${kParts[1]}`)?.get(kParts[2]);
        const viaKps = this.findKpsFromArr(
          tmpArr ?? [], obj1.dst_kp_start, obj2.src_kp_end);
        // 終了
        tmpArr = this.kpMap.get(dstK2)?.get('main_line');
        const kp2 = this.findKpFromArr(tmpArr ?? [], obj2.dst_kp_start);
        if (kp1 && kp2) {
          ret.push([kp1, ...viaKps, kp2]);
        }
      }
    }
    return ret;
  }

  createKpLayers_(): void {
    const kpSetName = this.userSettings.kp_set_name;
    let geoConnections: GeoConnection | null = {} as GeoConnection;
    try {
      geoConnections = require(`@/data/geo_connections_${kpSetName}.json`);
    } catch (e) {
      console.warn(`failure requiring geo_connections for ${this.userSettings.g1name}`);
      console.warn(e);
    }

    const arrOfLineCoords: Coordinate[][] = [];
    const debugArrOfLineCoords: Coordinate[][] = [];
    const pointFeatsFiltered: Feature[] = [];
    const pointFeatsAll: Feature[] = [];
    for (const [k1, obj1] of this.kpMap) {
      // 高速道路を表す太線と、1kmごとの点
      const lines = [];
      const mainLine = obj1.get('main_line');
      if (!mainLine || !geoConnections) { continue; }
      lines.push(mainLine);
      lines.push(...this.getBranches(k1, geoConnections));

      lines.forEach((line, idx) => {
        const coords: Coordinate[] = [];
        const arrLen = line.length;
        line.forEach((kp, idx) => {
          const coord = this.coordFromLonLat(kp.lon, kp.lat);
          coords.push(coord);
          if (!kp.kp_uid) { return; }

          // 1km (プラス始点と終点) ごと
          const isFirst = idx === 0;
          const isLast = idx === arrLen - 1;
          const tmpVal = parseInt((kp.kp_calc * 10000000).toString());
          const showAnyway = tmpVal === parseInt((tmpVal / 10000000).toString()) * 10000000;
          if (isFirst || isLast || showAnyway) {
            const feat = this.getNewKpPointFeatureByKpObj_({ kp, fixDigits: 1 });
            pointFeatsFiltered.push(feat);
          }
        });
        arrOfLineCoords.push(coords);
        if (idx > 0) {
          // 本線以外を別配列に保存
          debugArrOfLineCoords.push(coords);
        }
      });

      // KP全て
      if (this.isDebugShowKpAllLayer) {
        for (const lineEnt of obj1) {
          lineEnt[1].forEach(kp => {
            if (!kp.kp_uid) { return; }
            const feat = this.getNewKpPointFeatureByKpObj_({ kp, fixDigits: 2 });
            pointFeatsAll.push(feat);
          });
        }
      }
    }
    geoConnections = null;

    const colorMap = this.getKpColorMap_().line[this.colorType];
    {
      const multiLineFeat1 = this.getKpMultiLinesFeature_(
        arrOfLineCoords,
        colorMap.roadLine1,
      );
      multiLineFeat1.setId('roadLine1');
      const multiLineFeat2 = this.getKpMultiLinesFeature_(
        arrOfLineCoords,
        colorMap.roadLine2,
      );
      multiLineFeat2.setId('roadLine2');
      const features = [multiLineFeat1, multiLineFeat2];
      if (this.isDebugShowGeoConnections) {
        const multiLineFeat3 = this.getKpMultiLinesFeature_(
          debugArrOfLineCoords,
          colorMap.debugConn1,
        );
        multiLineFeat3.setId('debugConn1');
        features.push(multiLineFeat3);
      }
      // titleが必要のため、強制型変換
      // https://openlayers.org/en/latest/apidoc/module-ol_layer_Vector-VectorLayer.html
      const layer: NamedVectorLayer = new VectorLayer({
        title: '道路',
        visible: true,
        source: new VectorSource({ features: features }),
      } as VectorLayerOptions);
      layer.name = kpSetName;
      this.roadLayer = layer;
    }

    {
      const layer: NamedVectorLayer = new VectorLayer({
        title: 'KP',
        visible: false,
        source: new VectorSource({ features: pointFeatsFiltered }),
      } as VectorLayerOptions);
      layer.name = 'kiloposts_filtered';
      this.kpLayerFiltered = layer;
      this.pointFeatsFiltered = pointFeatsFiltered;
    }

    if (this.isDebugShowKpAllLayer) {
      const layer: NamedVectorLayer = new VectorLayer({
        title: 'KP (全て)',
        visible: false,
        source: new VectorSource({ features: pointFeatsAll }),
      } as VectorLayerOptions);
      layer.name = 'kiloposts_all';
      this.kpLayerAll = layer;
    }
  }

  getLayers(): KpLayers {
    return {
      kpLayerFiltered: this.kpLayerFiltered,
      kpLayerAll: this.kpLayerAll,
      roadLayer: this.roadLayer,
    };
  }

  prepareLayers(userSettings: UserSettings, kpMap: KpMap): KpLayers {
    this.userSettings = userSettings;
    this.kpMap = kpMap;
    this.createKpLayers_();
    return this.getLayers();
  }

  refreshRoadLayerColor(): void {
    if (!this.roadLayer) {
      return;
    }
    const colorMap = this.getKpColorMap_().line[this.colorType];
    const featIds: KeyOfLineColor[] = ['roadLine1', 'roadLine2', 'debugConn1'];
    const vectorSource = this.roadLayer.getSource();
    for (const featId of featIds) {
      const feat = vectorSource?.getFeatureById(featId);
      if (!feat) { continue; }
      const stroke = (feat.getStyle() as Style)?.getStroke();
      stroke.setColor(colorMap[featId].color);
    }
    vectorSource.refresh();
  }

  setDefaultColor(): void {
    this.colorType = 'strong';
    this.refreshRoadLayerColor();
  }

  setWeakColor(): void {
    this.colorType = 'weak';
    this.refreshRoadLayerColor();
  }

  refreshLayersOnZoomChange({ zoom, oldZoom }: { zoom?: number; oldZoom?: number }): void {
    if (!this.kpLayerFiltered) {
      return;
    }
    if (oldZoom && zoom && oldZoom >= 17 && zoom < 17) {
      // 17(縮尺100mぐらい)以上のズームをしている状態から17未満にズームアウトしたとき、デフォルトの表示に戻す.
      this.kpLayerFiltered.getSource().clear();
      this.kpLayerFiltered.getSource().addFeatures(this.pointFeatsFiltered);
    }
  }

  refreshLayersOnMoveEnd({ zoom, extent }: { zoom?: number; extent?: Extent }): void {
    // 17よりズームアウトしている状態の場合はすることが無い.
    if ((zoom && zoom < 17) || !this.kpLayerFiltered || !extent) {
      return;
    }
    const pointFeatsAdditional = this.getPointFeatsAdditional_(this.kpMap, extent);
    this.kpLayerFiltered.getSource().addFeatures(pointFeatsAdditional);
  }

  getPointFeatsAdditional_(kpMap: KpMap, extent: Extent): Feature[] {
    const kps = [...kpMap.values()].map(e => [...e.values()]).flat(2);
    const additionalKps = kps.filter(kp => {
      return containsCoordinate(extent, this.convCoord({
        lat: parseFloat(kp.lat.toString()),
        lon: parseFloat(kp.lon.toString()),
      })) && !this.kpLayerFiltered?.getSource().getFeatureById(kp.kp_uid);
    });
    return additionalKps.map(kp => this.getNewKpPointFeatureByKpObj_({ kp, fixDigits: 2 }));
  }
}
