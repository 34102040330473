
import { PHOTO_TYPE_CLEANING, cleaningPhotoTypeOptions } from '@/components/CleaningMap/consts/cleaning_photo';
import {
  CLEANING_CAR_STATUS_RUNNING,
  CLEANING_CAR_STATUS_WORKING,
  CLEANING_CAR_STATUS_STOPPED,
} from '@/components/CleaningMap/consts/cleaning_car';
import { CleaningReportState,
  EditCleaningReportState,
  CleaningReportExt,
} from '@/models/spCleaningReport';
import { CleaningReportCleaningStartUpdateParams,
  CleaningReportCleaningEndUpdateParams,
  CleaningReportBaseArrivalUpdateParams,
  CleaningReportContentAndRoadParams,
} from '@/models/apis/cleaning/cleaningReportRequest';
import { CleaningReport } from '@/models/apis/cleaning/cleaningReportResponse';
import {
  timeIntegerToSeconds,
  unpackTimeInteger,
} from '@/lib/dateTimeHelper';
import cleaningReportApi from '@/apis/cleaning_report';
import cleaningCarApi from '@/apis/cleaning_car';
import { CleaningCarShowResponse } from '@/models/apis/cleaning/cleaningCarResponse';
import { computed } from '@vue/composition-api';

export const initCleaningReportState = (): CleaningReportState => {
  return {
    displayCleaningHan: '',
    isReady: false,
    isRequesting: false,

    isMoving: false,
    isWorking: false,
    isSuspending: false,
    isBacking: false,
    isContentAndRoadEditing: false,
    isImagesEditing: false,
    photoCount: 0,
    pendingPhotosCountBeforeSave: 0,

    cleaningKinds: [],

    cleaningCar: {
      id: null,
      report: {
        cleaningCarId: -1,
        cleaningStartTs: null,
        cleaningEndTs: null,
        baseDepartureTs: null,
        baseArrivalTs: null,
        han: null,
        contents: null,
        otherContent: null,
        roadNames: null,
      },
    },

    cleaningReportId: -1,
    status: '',

    errorObj: {},

    baseDepartureTime: null,
    currentTime: null,
    workStartTime: null,
    workEndTime: null,

    cleaningMtxs: [],
    lastCleaningMtxTs: null,

    errorModalMsg: '',
    showCommonErrorModal: false,
    showPhotoSaveErrorModal: false,
    showConfirmBaseDepartureModal: false,
    showConfirmContentAndRoadModal: false,
    showRevertEditContentAndRoadConfirmModal: false,
    showConfirmStartWorkingModal: false,
    showConfirmStopWorkingModal: false,
    showConfirmTemporarilyStopWorkingModal: false,
    showConfirmBaseArrivalModal: false,
    showSavePhotosConfirmModal: false,
    showRevertSavePhotosConfirmModal: false,

    cleaningCarUpdateTimer: null,
    workElapsedTimeNotifyTimer: null,
    cleaningMtxStoreTimer: null,
    cleaningMtxCreateTimer: null,

    roadNames: [],
  };
};

export const initEditCleaningReportState = (): EditCleaningReportState => {
  const state: EditCleaningReportState = {
    selectedCleaningType: 'cleaning',
    otherCleaningType: null,
    selectedCleaningKind: [],
    selectedRoadNames: [],
    imageCandidates: [],
    photoType: PHOTO_TYPE_CLEANING,
    photoTypeOptions: cleaningPhotoTypeOptions(),
    photoTypeDisp: computed<string>(() => {
      const selectedPhotoTypeOption = state.photoTypeOptions.find(item => item.value === state.photoType);
      return selectedPhotoTypeOption?.text ?? '';
    }),
    isChanged: false,
  };
  return state;
};

export const initCleaningReportExt = (): CleaningReportExt => {
  return {
    cleaningCarId: -1,
    cleaningStartTs: null,
    cleaningEndTs: null,
    baseDepartureTs: null,
    baseArrivalTs: null,
    han: null,
    contents: null,
    otherContent: null,
    roadNames: null,
  };
};

export const notifyWorkElapsedTimeSpeech = (workElapsedTimeInt: number, doSpeech: ((text: string) => void)): void => {
  // 15分毎に経過時間を通知する
  const thres = 900; // seconds
  const workElapsedTimeSec = timeIntegerToSeconds(workElapsedTimeInt);
  if (workElapsedTimeSec === 0) { return; }
  if (workElapsedTimeSec % thres > 0) { return; }

  const [h, m, s] = unpackTimeInteger(workElapsedTimeInt);
  let text = '作業開始から';
  if (h > 0) {
    text += `${h}時間`;
  }
  if (m > 0) {
    text += `${m}分`;
  }
  if (s > 0) {
    text += `${s}秒`;
  }
  text += 'が経過しました。';
  doSpeech(text);
};

export const isStatusWorking = (status: string): boolean => {
  return status === CLEANING_CAR_STATUS_WORKING;
};
export const isStatusRunning = (status: string): boolean => {
  return status === CLEANING_CAR_STATUS_RUNNING;
};
export const isStatusStopped = (status: string): boolean => {
  return status === CLEANING_CAR_STATUS_STOPPED;
};

export const updateCleaningStart = async(cleaningReportId: number, cleaningStartTs: Date): Promise<CleaningReport | null> => {
  const reqObj: CleaningReportCleaningStartUpdateParams = {
    cleaning_start_ts: cleaningStartTs,
  };
  const { data } = await cleaningReportApi.updateCleaningStart(cleaningReportId, reqObj);
  return data;
};
export const updateCleaningEnd = async(cleaningReportId: number, cleaningEndTs: Date): Promise<CleaningReport | null> => {
  const reqObj: CleaningReportCleaningEndUpdateParams = {
    cleaning_end_ts: cleaningEndTs,
  };
  const { data } = await cleaningReportApi.updateCleaningEnd(cleaningReportId, reqObj);
  return data;
};
export const updateBaseArrival = async(cleaningReportId: number): Promise<CleaningReport | null> => {
  const reqObj: CleaningReportBaseArrivalUpdateParams = {
    base_arrival_ts: new Date(),
  };
  const { data } = await cleaningReportApi.updateBaseArrival(cleaningReportId, reqObj);
  return data;
};
export const updateCleaningContentAndRoad = async(cleaningReportId: number, updateParams: CleaningReportContentAndRoadParams): Promise<CleaningReport | null> => {
  const { data } = await cleaningReportApi.updateCleaningContentAndRoad(cleaningReportId, updateParams);
  return data;
};

export const setViewport = (document: Document): void => {
  const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
  if (envElement) {
    envElement.setAttribute(
      'content',
      'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
    );
  }
};

export const getMyCleaningCar = async(): Promise<CleaningCarShowResponse | null> => {
  return cleaningCarApi.getMyCleaningCar();
};

export const convCleaningReport = (data: CleaningReport): CleaningReportExt => {
  const obj: CleaningReportExt = {
    cleaningCarId: data.cleaning_car_id,
    cleaningStartTs: data.cleaning_start_ts ? new Date(data.cleaning_start_ts) : null,
    cleaningEndTs: data.cleaning_end_ts ? new Date(data.cleaning_end_ts) : null,
    baseDepartureTs: data.base_departure_ts ? new Date(data.base_departure_ts) : null,
    baseArrivalTs: data.base_arrival_ts ? new Date(data.base_arrival_ts) : null,
    han: data.han,
    contents: data.contents,
    otherContent: data.other_content,
    roadNames: data.road_names,
  };

  return obj;
};
