import { onMounted, reactive } from '@vue/composition-api';
import { ensureUserAndMasters } from '@/lib/masterHelper';
import { useStore } from '@/hooks/useStore';
import { MasterData } from '@/models';
import { CommentType } from 'src/models/apis/master/masterResponse';
import { ShareScopeLov } from 'src/models/apis/user/userResponse';
import { UserState } from '@/store/modules/user';

export type MasterState = Pick<MasterData,
  'kpMap' | 'kpMapByKpUid' | 'commentTypeIconPaths' |
  'roadNameDisps' | 'roadNames' | 'roadNameDispMap' |
  'geoConnections' | 'roadNameToRoadNameDispMap'> & {
    commentTypes: CommentType[];
    commentTypeMap: Record<string, CommentType>;
    shareScopes: ShareScopeLov[];
    shareScopeMap: Record<string, ShareScopeLov>;
    user: UserState;
  };

export default function useMaster(): { state: MasterState } {
  const state = reactive<MasterState>({
    kpMap: new Map(),
    kpMapByKpUid: new Map(),
    commentTypeIconPaths: [],
    roadNames: [],
    roadNameDisps: [],
    roadNameDispMap: {},
    geoConnections: {},
    roadNameToRoadNameDispMap: {},
    commentTypes: [],
    commentTypeMap: {},
    shareScopes: [],
    shareScopeMap: {},
    user: {} as UserState,
  });
  const store = useStore();
  onMounted(() => {
    ensureUserAndMasters(store).then(({ user, masters }) => {
      state.user = user;
      state.geoConnections = masters.geoConnections;
      state.kpMap = masters.kpMap;
      state.roadNameDispMap = masters.roadNameDispMap;
      state.roadNameToRoadNameDispMap = masters.roadNameToRoadNameDispMap;
      state.commentTypes = masters.commentType.vals;
      state.commentTypeMap = masters.commentType.map;
      state.shareScopes = masters.lovs.share_scope.vals;
      state.shareScopeMap = masters.lovs.share_scope.map;
    });
  });

  return { state };
}
