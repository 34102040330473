










import {
  defineComponent,
} from '@vue/composition-api';

export default defineComponent({
  props: {
    workType: {
      type: String,
      default: '',
    },
  },
  setup() {
  },
});
