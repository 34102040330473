













import {
  defineComponent,
} from '@vue/composition-api';
import SalinityHelper from '@/lib/geo_item/salinityHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-salinity',
  setup() {
    const salinityHelper = new SalinityHelper();
    const arr: DispData[] = [];
    for (let i = 0; i <= 25; i++) {
      arr.push({
        val: i,
        showLabel: i % 5 === 0,
        strWidth: i.toString().length,
      });
    }
    arr.forEach(e => {
      const arr = salinityHelper.objKeyToColor(salinityHelper.dataToObjKey(e.val));
      e.color = `rgba(${arr[0]},${arr[1]},${arr[2]},${arr[3]})`;
    });
    return { dispData: arr };
  },
});
