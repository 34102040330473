


import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
} from '@vue/composition-api';
import settouOnsiteManagementReportApi from '@/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsu_settou_onsite_management_report';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { SettouOnsiteManagementReportDetailState } from '@/components/Johaisetsu/SettouReport/composables/report';
import {
  SettouOnsiteManagementReport,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReportsResponse';
import {
  SettouOnsiteManagementReportRaw,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReport';

export default defineComponent({
  setup() {
    const state = reactive<SettouOnsiteManagementReportDetailState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,
      onsiteManagementReport: null,
      roadNames: [],
      roadNameMap: {},
      roadConditions: [],
      roadConditionMap: {},
      showErrorModal: false,
      errorBody: '',
    });

    const store = useStore();
    const userState = store.state.user;
    const { route } = useRoute();
    const onsiteManagementReportId = computed<number>(() => {
      return Number(route.value.params.id);
    });
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });

    const convOnsiteManagementReports = async(data: SettouOnsiteManagementReport): Promise<SettouOnsiteManagementReportRaw> => {
      let roadNameDisp = data.road_name_disp;
      const roadNameObj = state.roadNameMap[data.road_name_disp];
      if (roadNameObj) {
        roadNameDisp = roadNameObj.roadNameDisp;
      }
      const workArea = data.work_area ?? '';
      const imageObjectURLPromises: Promise<(string | null)>[] = data.photos.map(e => {
        return fetchImageAsObjectURL(e.image_path);
      });
      const savedImages = await Promise.all(imageObjectURLPromises);
      return {
        ...data,
        ts: new Date(data.ts),
        roadNameDisp: roadNameDisp,
        workArea: workArea,
        hasImage: data.photos.length > 0,
        savedImages: savedImages,
        shouldShow: true,
      };
    };
    const fetchReport = async(): Promise<void> => {
      state.isRequesting = true;
      try {
        const { data } = await settouOnsiteManagementReportApi.show(onsiteManagementReportId.value);
        state.onsiteManagementReport = await convOnsiteManagementReports(data);
        state.isRequesting = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'データの取得に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isRequesting = false;
      }
    };
    const downloadImage = async(image: Blob, idx: number): Promise<void> => {
      state.isDownloading = true;
      try {
        const reportId = onsiteManagementReportId.value;
        const dispOrder = idx + 1;
        const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
        const filename = `${state.onsiteManagementReport?.work_type}_${reportId}_写真_${dispOrder}_${timestamp}.jpg`;
        await downloadBlob(image, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '写真のダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const releaseImageObjectURLs = (): void => {
      const report = state.onsiteManagementReport;
      if (!report || !report.savedImages) {
        return;
      }
      report.savedImages.forEach(e => {
        if (e != null) {
          URL.revokeObjectURL(e);
        }
      });
    };

    onMounted(async(): Promise<void> => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      await waitForJohaisetsuMasters();
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce<Record<string, RoadNameDirection>>((acc, e) => {
        acc[e.roadNameReal] = e;
        return acc;
      }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      await fetchReport();

      state.isReady = true;
    });

    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      state,
      // computed
      showWaitSpinner,
      // methods
      downloadImage,
      dtFormat,
    };
  },
});
