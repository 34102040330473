













































































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { NumByServer } from '@/models/devDebug';
import { AdminMovieViewStatus } from '@/models/apis/movie/movieResponse';
import movieViewStatusApi from '@/apis/movie_view_status';

interface MovieViewStatusEx extends AdminMovieViewStatus {
  host: string;
}
interface Summary {
    numLoginUsers: number;
    numClients: number;
    numWindows: number;
    numWindowsLive: number;
    numWindowsVod: number;
    numWindowsByServers: NumByServer[];
}
interface DevDebugMovieViewStatusListState {
  isSearched: boolean;
  movieViewStatuses: MovieViewStatusEx[];
  summary: Summary;
  tooltipMongonNumClients: string;
  tooltipMongonConnectionCount: string;
  tooltipMongonWindowNumber: string;
}
export default defineComponent({
  name: 'dev-debug-movie-view-status-list',
  setup() {
    const state = reactive<DevDebugMovieViewStatusListState>({
      isSearched: false,
      movieViewStatuses: [],
      summary: {
        numLoginUsers: 0,
        numClients: 0,
        numWindows: 0,
        numWindowsLive: 0,
        numWindowsVod: 0,
        numWindowsByServers: [],
      },
      tooltipMongonNumClients: 'view_client_idはタブごとに生成されるので複数タブ開いていた場合はそれぞれ計上されてしまうが、ほぼそういう使い方はしないだろう',
      tooltipMongonConnectionCount: 'SUM(接続数) == 閲覧動画プレイヤー数',
      tooltipMongonWindowNumber: 'windowは開き直したり新しい動画を再生するたびにインクリメントされるので1-4の範囲にはならない',
    });
    onMounted(async() => {
      await getMovieViewStatuses();
    });
    const getMovieViewStatuses = async() => {
      state.isSearched = false;
      state.movieViewStatuses = [];
      const { data } = await movieViewStatusApi.adminGetMovieViewStatuses();
      state.isSearched = true;
      if (!data || data.length === 0) { return; }
      state.movieViewStatuses = convData(data);

      // サマリ情報計算
      const userIdMap: Record<string, number> = {};
      const viewClientUuidMap: Record<string, number> = {};
      const numWindowsByServerMap: Record<string, number> = {};
      let numWindowsLive = 0;
      let numWindowsVod = 0;
      state.movieViewStatuses.forEach(e => {
        if (!userIdMap[e.user_id]) { userIdMap[e.user_id] = 0; }
        userIdMap[e.user_id]++;

        if (!viewClientUuidMap[e.view_client_uuid]) { viewClientUuidMap[e.view_client_uuid] = 0; }
        viewClientUuidMap[e.view_client_uuid]++;

        if (e.host !== undefined) {
          if (!numWindowsByServerMap[e.host]) { numWindowsByServerMap[e.host] = 0; }
          numWindowsByServerMap[e.host]++;
        }

        if (e.movie_type === 'live') {
          numWindowsLive++;
        } else {
          numWindowsVod++;
        }
      });
      const numWindowsByServers = Object.entries(numWindowsByServerMap)
        .map(([host, num]) => ({ host, num }))
        .sort((a, b) => {
          return a.host < b.host ? -1 : 1;
        });

      state.summary = {
        numLoginUsers: Object.keys(userIdMap).length,
        numClients: Object.keys(viewClientUuidMap).length,
        numWindows: numWindowsLive + numWindowsVod,
        numWindowsLive,
        numWindowsVod,
        numWindowsByServers,
      };
    };
    const convData = (arr: AdminMovieViewStatus[]) => {
      const ret = arr.map(e => {
        return {
          ...e,
          host: e.server_hostport.split(':')[0],
          isContinuedViewClient: false,
        };
      }).sort((a, b) => {
        const [valA1, valB1] = a.user_id < b.user_id
          ? [0, 10000]
          : (a.user_id > b.user_id ? [10000, 0] : [0, 0]);
        // view_client_uuidは {user_id}__{uuid} のようになっている
        const tmpUuid1 = a.view_client_uuid.match(/^\d+__(.+)$/) || '';
        const tmpUuid2 = b.view_client_uuid.match(/^\d+__(.+)$/) || '';
        const [valA2, valB2] = tmpUuid1 < tmpUuid2
          ? [0, 100]
          : (tmpUuid1 > tmpUuid2 ? [100, 0] : [0, 0]);
        const [valA3, valB3] = a.window_number < b.window_number
          ? [0, 1]
          : (a.window_number > b.window_number ? [1, 0] : [0, 0]);

        return valA1 + valA2 + valA3 < valB1 + valB2 + valB3 ? -1 : 1;
      });

      // 表示上、同一view_clientの2行目以降の色を変えたいのでなんか入れる.
      let prevElem: MovieViewStatusEx | null = null;
      ret.forEach(e => {
        if (!prevElem) {
          prevElem = e;
          return;
        }
        e.isContinuedViewClient = prevElem.user_id === e.user_id && prevElem.view_client_uuid === e.view_client_uuid;
        prevElem = e;
      });

      return ret;
    };
    const refreshData = async() => {
      await getMovieViewStatuses();
    };

    return {
      ...toRefs(state),
      // methods
      refreshData,
    };
  },
});
