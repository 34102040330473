import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  CleaningReportIndexParams,
  CleaningReportCreateParams,
  CleaningReportCleaningStartUpdateParams,
  CleaningReportCleaningEndUpdateParams,
  CleaningReportBaseArrivalUpdateParams,
  CleaningReportContentAndRoadParams,
  CleaningReportPhotoParams,
} from '@/models/apis/cleaning/cleaningReportRequest';
import {
  CleaningReportIndexResponse,
  CleaningReportCreateOrUpdateResponse,
  CleaningReportShowResponse,
} from '@/models/apis/cleaning/cleaningReportResponse';
import { EmptyResponse } from '@/models/index';

export default {
  index(params: CleaningReportIndexParams): Promise<AxiosResponse<CleaningReportIndexResponse>> {
    return axios.get(utils.getApiUrl('/cleaning_reports'), {
      params: params || {},
    });
  },
  createCleaningReport(
    params: CleaningReportCreateParams): Promise<AxiosResponse<CleaningReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/cleaning_report`), params);
  },
  updateCleaningStart(
    id: number,
    params: CleaningReportCleaningStartUpdateParams): Promise<AxiosResponse<CleaningReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/update_cleaning_start/${id}`), params);
  },
  updateCleaningEnd(
    id: number,
    params: CleaningReportCleaningEndUpdateParams): Promise<AxiosResponse<CleaningReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/update_cleaning_end/${id}`), params);
  },
  updateBaseArrival(
    id: number,
    params: CleaningReportBaseArrivalUpdateParams): Promise<AxiosResponse<CleaningReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/update_base_arrival/${id}`), params);
  },
  updateCleaningContentAndRoad(
    id: number,
    params: CleaningReportContentAndRoadParams): Promise<AxiosResponse<CleaningReportCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/update_cleaning_content_and_road/${id}`), params);
  },
  addPhoto(id: number, params: CleaningReportPhotoParams): Promise<AxiosResponse<EmptyResponse>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl(`/cleaning_report/${id}/photos`), formData);
  },
  getReportPhotoCount(id: number, photo_type: string): Promise<AxiosResponse<number>> {
    return axios.get(utils.getApiUrl(`/cleaning_report_photo_count/${id}/${photo_type}`));
  },
  get(id: number): Promise<AxiosResponse<CleaningReportShowResponse>> {
    return axios.get(utils.getApiUrl(`/cleaning_report/${id}`));
  },
};
