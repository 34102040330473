import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { GetGeoItemsParams } from '@/models/apis/geoItem/geoItemRequest';
import { GeoItemResponse } from '@/models/apis/geoItem/geoItemResponse';

export default {
  getGeoItems(params: GetGeoItemsParams): Promise<AxiosResponse<GeoItemResponse>> {
    return axios.get(utils.getApiUrl('/geo_items'), {
      params: params || {},
    });
  },
};
