import imageApi from '@/apis/image';
import { ImageCandidateBase as ImageCandidate } from '@/models/apis/image/imageRequest';

export const fetchImageAsObjectURL = async(imagePath: string): Promise<string | null> => {
  if (!imagePath) {
    return null;
  }
  const { data: blob } = await imageApi.getFile({ path: imagePath });
  return URL.createObjectURL(blob);
};

export type ResizeImageMaxSize = {
  maxWidth: number;
  maxHeight: number;
};

function getResizedSize(
  image: HTMLImageElement,
  maxSize: ResizeImageMaxSize,
): { width: number; height: number } {
  const width = image.width;
  const height = image.height;
  const ratio = Math.min(Math.min(maxSize.maxWidth / width, maxSize.maxHeight / height), 1.0);

  return {
    width: width * ratio,
    height: height * ratio,
  };
}

export async function getResizedImage(file: File, maxSize: ResizeImageMaxSize): Promise<Blob | null> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  return new Promise(resolve => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const { width, height } = getResizedSize(image, maxSize);
      canvas.width = width;
      canvas.height = height;
      console.log(canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      URL.revokeObjectURL(image.src);

      const mimeTypeJpeg = 'image/jpeg';
      const jpegQuality = 0.92;
      canvas.toBlob(blob => {
        resolve(blob);
      }, mimeTypeJpeg, jpegQuality);
    };
  });
}

export async function getImageCandidate(file: File): Promise<ImageCandidate | null> {
  // リサイズ後は確実に10MBを切るのでサイズ確認はしなくてよい.
  const resizedImage = await getResizedImage(file, { maxWidth: 1000, maxHeight: 1000 });
  if (!resizedImage) return null;

  return {
    file: resizedImage,
    src: URL.createObjectURL(resizedImage),
  };
}
