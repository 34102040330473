import { Style, Stroke } from 'ol/style';
import { TairyuData } from '@/models/geoItem';
import { getDistanceInMeters } from '@/lib/geoCalcUtil';
import { RoadGeoItemData, GeoItemEntity, TairyuGeoItemEntityData } from 'src/models/apis/geoItem/geoItemResponse';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class TairyuHelper extends AbstractGeoItem<TairyuData, number, number, number[]> {
  dataToAggregatedMap(data: RoadGeoItemData): Record<string, GeoItemEntity[]> {
    // KP1kmごと: エントリ[] にまとめる
    const aggrMap: Record<string, GeoItemEntity[]> = {};
    for (const [roadNameDirection, values1] of Object.entries(data)) {
      for (const [roadPlaceName, values2] of Object.entries(values1)) {
        for (const [srcKp, values3] of Object.entries(values2)) {
          for (const [, objArr] of Object.entries(values3)) {
            if (!objArr) { continue; }
            // 小数点以下を寄せて、1km単位でグループにする.
            // 厳密に言えば下り系はfloorで上り系はceilになるべきな気がするが、結果に大きな差は与えないだろう.
            const srcKpRounded = Math.floor(parseFloat(srcKp));
            const aggrMapKey = `${roadNameDirection}.${roadPlaceName}.${srcKpRounded}`;
            if (!aggrMap[aggrMapKey]) { aggrMap[aggrMapKey] = []; }
            aggrMap[aggrMapKey].push(...objArr);
          }
        }
      }
    }
    return aggrMap;
  }

  tweakData = (data: RoadGeoItemData): RoadGeoItemData => {
    const aggrMap = this.dataToAggregatedMap(data);
    for (const entries of Object.values(aggrMap)) {
      entries.forEach(e => {
        try {
          const orgObj = typeof e.data === 'string' ? JSON.parse(e.data.toString()) as TairyuGeoItemEntityData : e.data;
          const obj = orgObj as TairyuGeoItemEntityData;
          e.data = obj;
          (e.data as TairyuGeoItemEntityData).numVehicles = (obj?.left.car || 0) + (obj?.left.truck || 0) + (obj?.right.car || 0) + (obj?.right.truck || 0);
          e.isDataParsed = true;
        } catch (e) {}
      });
      // 直線距離合計を算出
      const distanceSumInKilometers = entries.map(e => getDistanceInMeters(
        { lat: parseFloat(e.lat1), lon: parseFloat(e.lon1) },
        { lat: parseFloat(e.lat2), lon: parseFloat(e.lon2) },
      )).reduce((acc, e) => acc + e, 0) / 1000;
      // 台数を合算し、1kmあたりに直す
      const numVehiclesSum = entries.map(e => (e.data as TairyuGeoItemEntityData).numVehicles).reduce((acc: number, e: number) => acc + e, 0);
      const numVehiclesSumPerKilometer = Math.floor(distanceSumInKilometers > 0 ? numVehiclesSum / distanceSumInKilometers : 0);
      // 同じグループは同じ色表示になるようにする
      entries.forEach(e => {
        if (e.data) {
          (e.data as TairyuGeoItemEntityData).distanceSumInKilometers = distanceSumInKilometers; // for debug
          (e.data as TairyuGeoItemEntityData).numVehiclesSum = numVehiclesSum; // for debug
          (e.data as TairyuGeoItemEntityData).numVehiclesSumPerKilometer = numVehiclesSumPerKilometer;
        }
      });
    }
    return data;
  };
  dataToObjKey(data: TairyuData): number {
    // このデータポイントが属する1km単位グループについて算出された結果に基づく色を表示するが、
    // このデータポイントに車両がなかった場合は表示したくない
    return data.numVehicles > 0 ? data.numVehiclesSumPerKilometer : 0;
  }
  featureSorter = (objKeyA: string, objKeyB: string): number => {
    return objKeyA < objKeyB ? -1 : 1;
  };
  /*
    * 車は全長5mぐらい
    * (追い越せる数とは関係ないが、)
    * 通常時、車間が80mとして、1km内にいる車は車線あたり12台と置く
    * 渋滞(100%)時、車間が2mとして、1km内にいる車は車線あたり140台と置く
    *
    * 災害等の滞留時を考察する.
    * 渋滞に近い状態で滞留しているケースは、左右にそれぞれ渋滞している
    * 車線が1つずつあると考える事が可能. 真に満杯ではそもそも移動できない
    * だろうから、渋滞率は80%程度と仮に置くと、1kmあたり140*2*0.8=224台
    * これがつめつめの場合だから、2車線あたり100台程度が一つの分水嶺になるのでは.
    *
    * 1kmあたり  100mあたり  10mあたり
    *     50台         5台       0.5台
    *    100台        10台         1台
    *    200台        20台         2台
    *    400台        40台         4台
    */
  objKeyToColorDefault(inputObjKey: number): number[] {
    const objKey = parseInt(inputObjKey.toString());
    const x = objKey;
    let rgba = [0, 0, 0, 1.0];
    if (x === 0) {
      // 透明
      rgba = [0, 0, 0, 0.0];
    } else if (x < 50) {
      // #33ffbd
      rgba = [51, 255, 189, 1.0];
    } else if (x < 100) {
      // #dbff33
      rgba = [219, 255, 51, 1.0];
    } else if (x < 200) {
      // #ff5733
      rgba = [255, 87, 51, 1.0];
    } else if (x < 400) {
      // #c70039
      rgba = [199, 0, 57, 1.0];
    } else {
      // #581845
      rgba = [88, 24, 69, 1.0];
    }
    return rgba;
  }
  objKeyToColor(objKey: number): number[] {
    return this.objKeyToColorDefault(objKey);
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;
    return new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
  }
}
