import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class FrozenHelper extends AbstractGeoItem<boolean, number, number, string> {
  dataToObjKey(inputData: boolean): number {
    // 過渡期のため、スカラーもしくはオブジェクトの両方がありうる.
    // 落ち着いたら全部オブジェクトになるので消す.
    // (2018/11/18 なぜオブジェクトがあり得るのか忘れた. スカラーでよいのでは???)
    let data: DataContent = { v: false };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    return data.v ? 1 : 0;
  }
  objKeyToColor(objKey: number): string {
    let ret;
    objKey = parseInt(objKey.toString());
    if (!objKey) {
      ret = ''; // [0, 0, 0, 0]; // なしは表示しない
    } else {
      ret = '#c100ec';
    }
    return ret;
  }
  objKeyToFeatureStyle(inputObjKey: number): Style {
    const color = this.objKeyToColor(inputObjKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
