


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { getGeoItems } from '@/lib/geoItemHelper';
import { getRoadNameIgnores_, isKpIncreaseDirection } from '@/lib/kilopostHelper';
import RouteChart from '@/components/lib/RouteChart.vue';
import { getDistanceInMeters } from '@/lib/geoCalcUtil';
import { dtFormat } from '@/lib/dateHelper';

import { useStore } from '@/hooks/useStore';
import { Settings } from '@/models/apis/user/userResponse';
import { KindChoice,
  KindChoiceWithKey,
  RoadNameDirection,
  KpMap,
  Location,
  Direction,
} from '@/models/index';
import { GIKilopost } from '@/models/geoItem';
import { GetGeoItemsParamsRaw, RoadTemperaturesByTimeRange, CellData } from '@/models/route';
import { RoadGeoItemData, KpGeoItemData, DataType } from '@/models/apis/geoItem/geoItemResponse';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { useRoute } from '@/hooks/useRoute';
import { getGeoItemSearchTimestamps, getInitTimeChoices } from '@/lib/utils';

const roadLabelsRaw = require('@/data/road_labels.json');

const KP_STEP = 1000; // in meters

interface LastKp {
  kp: number;
  xOffsetPercentage: number;
  kmXOffsetPercentage: number;
}

interface Elem {
  currentKpStep: number;
  labels: string[];
  showKm?: boolean;
  lastKp?: LastKp;
  showLastTick?: boolean;
  hideMidTick?: boolean;
  hideBeginKpDisp?: boolean;
}
interface KpStepMapByTimeRange {
  t: Date;
  v: number | string;
  kp: string;
  pt1: Location;
  pt2: Location;
}
interface SearchParams {
  roadName: string | null;
  direction: string | null;
  timeFromOffset: number;
  timeToOffset: number;
}

type GraphDataPoint = number | null;
interface GraphDataPointObj {
  data: GraphDataPoint;
}
interface GraphDataRaw {
  temperatures_by_time_range: RoadTemperaturesByTimeRange[];
  temperature: GraphDataPointObj[];
  salinity: GraphDataPointObj[];
}
interface GraphData {
  labels: string[];
  roadLabels?: Elem[];
  roadTemperatures: GraphDataPoint[];
  roadTemperaturesByTimeRange: RoadTemperaturesByTimeRange[];
  salinities: GraphDataPoint[];
}

interface GeoItemSearchTime {
    start: Date | null;
    end: Date | null;
}
interface GeoItemConvData {
  kp: number;
  data: number | CellData | null;
}

interface RouteState {
  search: SearchParams;
  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  directions: Direction[];
  timeChoices: KindChoiceWithKey[];

  selectedRoadNames: string[];
  roadNameIgnores: string[];

  kpMap: KpMap | null;
  currentRoadNameDirection: string;

  scale: number;
  scaleOptions: KindChoice[];

  graphData: GraphData;
  judgementResults: number[];

  geoItemRawData: Record<string, RoadGeoItemData>; // vue管理下に置かない
  geoItemConvDataForGraph: GraphDataRaw;
  geoItemConvData: Record<string, GeoItemConvData[]>;
  geoItemSearchTime: GeoItemSearchTime;

  currentKpMin: number;
  currentKpMax: number;

  showWaitSpinner: boolean;

  roadTemperatureDispModes: Record<string, string>;
  roadTemperatureDispMode: string;
}

const getInitialGeoItemRawData = (): RouteState['geoItemRawData'] => {
  // 基本は問い合わせて返ってきたものを入れればよいのだが、以下の廃止済みDataTypeについては問い合わせるとサーバ側でエラーで返すので
  // 問い合わせずに勝手に空のエントリを作っておく.
  return {
    salinity: {},
    frozen: {},
    snow_mountain: {},
  };
};

export default defineComponent({
  name: 'route',
  setup() {
    const state = reactive<RouteState>({
      search: {
        roadName: null,
        direction: null,
        timeFromOffset: -3600 * 1000 * 1,
        timeToOffset: 0,
      },
      roadNames: [],
      roadNameMap: {},
      directions: [],
      timeChoices: [],

      selectedRoadNames: [],
      roadNameIgnores: [],

      kpMap: null,
      currentRoadNameDirection: '',

      scale: 1,
      scaleOptions: [
        {text: '最大', value: 1},
        {text: '大', value: 2},
        {text: '中', value: 3},
        {text: '小', value: 4},
      ],

      graphData: {
        labels: [],
        roadTemperatures: [],
        roadTemperaturesByTimeRange: [],
        salinities: [],
      },
      judgementResults: [],

      geoItemRawData: {},
      geoItemConvDataForGraph: {} as GraphDataRaw,
      geoItemConvData: {},
      geoItemSearchTime: {
        start: null,
        end: null,
      },

      currentKpMin: 0,
      currentKpMax: 0,

      showWaitSpinner: true,

      roadTemperatureDispModes: {
        'SINGLE': 'single',
        'MULTIPLE': 'multiple',
      },
      roadTemperatureDispMode: 'single',
    });

    const store = useStore();
    const userState = store.state.user;
    const userSettings = computed<Settings>(() => {
      return userState.settings;
    });
    const johaisetsuRole = computed<string>(() => {
      return userState.johaisetsu_role;
    });

    const isRoadTemperatureDispModeSingle = computed<boolean>(() => {
      return state.roadTemperatureDispMode === state.roadTemperatureDispModes.SINGLE;
    });
    const isRoadTemperatureDispModeMultiple = computed<boolean>(() => {
      return state.roadTemperatureDispMode === state.roadTemperatureDispModes.MULTIPLE;
    });
    const filteredRoadTemperaturesByTimeRange = computed<RoadTemperaturesByTimeRange[]>(() => {
      return state.graphData.roadTemperaturesByTimeRange.filter(e => e.show);
    });
    const isRoadTemperatureTimeRangesAllSelected = computed<boolean>(() => {
      const selectedTimeRanges =
        state.graphData.roadTemperaturesByTimeRange.filter(e => e.isValid && !e.show);
      return selectedTimeRanges.length === 0;
    });
    const shouldShowTairyu = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    const shouldShowJohaisetsu = computed<boolean>(() => {
      return johaisetsuRole.value !== null;
    });
    const geoItemDataTypes = computed<DataType[]>(() => {
      const ret: DataType[] = [
        'temperature',
        'salinity', // 廃止済
      ];
      if (shouldShowJohaisetsu) {
        ret.push(
          'snowfall2',
          'frozen', // 廃止済
          'snow_mountain', // 廃止済
          'jh_work_status',
        );
      }
      if (shouldShowTairyu) {
        ret.push('tairyu');
      }
      return ret;
    });
    const geoItemDataTypesForRequest = computed<DataType[]>(() => {
      const abolishedDataTypes = ['salinity', 'frozen', 'snow_mountain'];
      return geoItemDataTypes.value.filter(e => !abolishedDataTypes.includes(e));
    });
    const { route } = useRoute();
    onMounted(async() => {
      state.geoItemRawData = getInitialGeoItemRawData();

      state.timeChoices = getInitTimeChoices();

      await window.master.$promise;
      redirectIfNoAbility(userState, route.value);
      state.showWaitSpinner = false;

      state.roadNames =
        JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce(
        (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
      state.kpMap = window.master.kpMap;
      state.roadNameIgnores = getRoadNameIgnores_(window.master.kpSetName);
    });
    const fetchGeoItems = async() => {
      state.showWaitSpinner = true;
      const reqObj: GetGeoItemsParamsRaw = getGeoItemSearchTimestamps(state.search);
      reqObj.roadNames = state.selectedRoadNames;
      reqObj.direction = state.search.direction;
      reqObj.dataTypes = geoItemDataTypesForRequest.value;
      reqObj.optByDataType =
        {
          temperature: { merge_leafs: !isRoadTemperatureDispModeMultiple.value },
        };
      const resultMap = await getGeoItems(reqObj);
      state.showWaitSpinner = false;

      for (const [dataType, data] of Object.entries(resultMap)) {
        state.geoItemRawData[dataType] = data;
      }
      state.geoItemSearchTime.start = reqObj.startTs;
      state.geoItemSearchTime.end = reqObj.endTs;
    };
    const getCellDataForTemperature = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      // 平均値
      const sum = arr.reduce((acc, e) => {
        return acc + (e.v as number);
      }, 0);
      return parseFloat((sum / arr.length).toFixed(1));
    };
    const getCellDataForSalinity = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      // 平均値
      const sum = arr.reduce((acc, e) => {
        return acc + (e.v as number);
      }, 0);
      return parseFloat((sum / arr.length).toFixed(2));
    };
    const getDataFlagsForCellData = (arr: KpStepMapByTimeRange[]) => {
      // kpごとにまとめて、各種フラグを計算する
      const tmpMap = arr.reduce((acc: Record<string, KpStepMapByTimeRange[]>, e: KpStepMapByTimeRange) => {
        if (!acc[e.kp]) { acc[e.kp] = []; }
        acc[e.kp].push(e);
        return acc;
      }, {});

      let hasTrueSegment = false;
      let hasFalseSegment = false;
      let isAllTrueSegment = true;
      let isAllFalseSegment = true;
      for (const ent of Object.entries(tmpMap)) {
        const lastElem = ent[1].sort((a, b) => {
          return a.t < b.t ? -1 : 1;
        }).slice(-1)[0];
        if (!lastElem.v) {
          hasFalseSegment = true;
          isAllTrueSegment = false;
        } else {
          hasTrueSegment = true;
          isAllFalseSegment = false;
        }
      }

      return {
        hasTrueSegment,
        hasFalseSegment,
        isAllTrueSegment,
        isAllFalseSegment,
      };
    };
    const getCellDataForSnowfall2 = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      const ts = arr.map(e => e.t);
      const vs = arr.map(e => e.v as number);
      const tsMin = tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); })));
      const tsMax = tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); })));
      const valMin = Math.min(...vs);
      const valMax = Math.max(...vs);
      const valClass = Math.floor(valMax / 5) * 5;
      return { tsMin, tsMax, valMin, valMax, valClass };
    };
    const getCellDataForFrozen = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      const ts = arr.map(e => e.t);
      const tsMin = tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); })));
      const tsMax = tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); })));
      const { hasTrueSegment } = getDataFlagsForCellData(arr);
      const val = hasTrueSegment;
      return { tsMin, tsMax, val, valDisp: val ? 'あり' : 'なし' };
    };
    const getCellDataForSnowMountain = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      const ts = arr.map(e => e.t);
      const tsMin = tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); })));
      const tsMax = tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); })));
      const { hasTrueSegment } = getDataFlagsForCellData(arr);
      const val = hasTrueSegment;
      return { tsMin, tsMax, val, valDisp: val ? 'あり' : 'なし' };
    };
    const getCellDataForJhWorkStatus = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      const ts = arr.map(e => e.t);
      const vs = arr.map(e => e.v as number);
      const tsMin = tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); })));
      const tsMax = tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); })));
      const valMin = Math.min(...vs);
      const valMax = Math.max(...vs);
      const valClass = Math.floor(valMax / 5) * 5;
      return { tsMin, tsMax, valMin, valMax, valClass };
    };
    const getCellDataForEnsuiJutenKaradanpuCommon = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }
      const ts = arr.map(e => e.t);
      const vs = arr.map(e => e.v as number);
      const tsMin = tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); })));
      const tsMax = tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); })));
      const valMin = Math.min(...vs);
      const valMax = Math.max(...vs);
      return { tsMin, tsMax, valMin, valMax };
    };
    const getCellDataForEnsuiSanpu = (arr: KpStepMapByTimeRange[]) => {
      return getCellDataForEnsuiJutenKaradanpuCommon(arr);
    };
    const getCellDataForJutenSanpu = (arr: KpStepMapByTimeRange[]) => {
      return getCellDataForEnsuiJutenKaradanpuCommon(arr);
    };
    const getCellDataForKaradanpu = (arr: KpStepMapByTimeRange[]) => {
      return getCellDataForEnsuiJutenKaradanpuCommon(arr);
    };
    const getCellDataForTairyu = (arr: KpStepMapByTimeRange[]) => {
      if (arr.length === 0) { return null; }

      let numLeftCars = 0;
      let numLeftTrucks = 0;
      let numRightCars = 0;
      let numRightTrucks = 0;
      let distanceSumInMeters = 0;
      arr.forEach(e => {
        let obj = { left: { car: 0, truck: 0 }, right: { car: 0, truck: 0 } };
        try {
          obj = JSON.parse(e.v.toString());
        } catch (e) {}
        numLeftCars += obj.left.car;
        numLeftTrucks += obj.left.truck;
        numRightCars += obj.right.car;
        numRightTrucks += obj.right.truck;
        // 直線距離合計を算出
        distanceSumInMeters += getDistanceInMeters(e.pt1, e.pt2);
      });
      const numCars = numLeftCars + numRightCars;
      const numTrucks = numLeftTrucks + numRightTrucks;
      const numVehicles = numCars + numTrucks;
      // // 1kmあたりに直す
      const distanceSumInKilometers = distanceSumInMeters / 1000;
      const numCarsPerKilometer = Math.floor(distanceSumInKilometers > 0 ? numCars / distanceSumInKilometers : 0);
      const numTrucksPerKilometer = Math.floor(distanceSumInKilometers > 0 ? numTrucks / distanceSumInKilometers : 0);
      const numVehiclesPerKilometer = numCarsPerKilometer + numTrucksPerKilometer;

      let cssCls = '';
      if (numVehiclesPerKilometer === 0) {
        cssCls = 'zero';
      } else if (numVehiclesPerKilometer < 50) {
        cssCls = 'lt50';
      } else if (numVehiclesPerKilometer < 100) {
        cssCls = 'lt100';
      } else if (numVehiclesPerKilometer < 200) {
        cssCls = 'lt200';
      } else if (numVehiclesPerKilometer < 400) {
        cssCls = 'lt400';
      } else {
        cssCls = 'gte400';
      }

      const ts = arr.map(e => e.t);
      const tooltipStr = numVehiclesPerKilometer > 0
        ? `<div>1kmあたり換算台数: 普通${numCarsPerKilometer}台、大型${numTrucksPerKilometer}台</div>` +
          `<div>(延べ距離:${distanceSumInKilometers.toFixed(1)}km)</div>`
        : '';
      return {
        tsMin: tsToHHMM(Math.min(...ts.map(x => { return x.getTime(); }))),
        tsMax: tsToHHMM(Math.max(...ts.map(x => { return x.getTime(); }))),
        numCars,
        numTrucks,
        numVehicles,
        numVehiclesPerKilometer,
        cssCls,
        tooltipStr,
      };
    };
    const convGeoItemRawData = () => {
      const now = new Date();
      for (const dataType of geoItemDataTypes.value) {
        const dataByDataType = state.geoItemRawData[dataType];
        if (!dataByDataType) { continue; }
        const { kpStepMap, step } = getKpStepMap(state.currentKpMin, state.currentKpMax);
        const { kpStepMapByTimeRange } = getKpStepMapByTimeRange(kpStepMap);
        state.selectedRoadNames.forEach(selectedRoadName => {
          const k1 = selectedRoadName + '#' + state.search.direction;
          const k2 = 'main_line';
          const isKpIncDirection = isKpIncreaseDirection(selectedRoadName, state.search.direction || '');
          let dataByKp: KpGeoItemData = {};
          if (dataByDataType[k1] && dataByDataType[k1][k2]) {
            dataByKp = dataByDataType[k1][k2];
          }

          for (const [kp, v] of Object.entries(dataByKp)) {
            const dataArr = v[1]; // 1が本線to本線
            if (!dataArr || dataArr.length === 0) { continue; }
            const origKpVal = parseFloat(kp);
            // 1.00から2.00km区間を例に取ると、
            // KPが増加する進路の場合、1.00 <= x < 2.00 の範囲を1.00のグループに
            // KPが減少する進路の場合、1.00 < x <= 2.00 の範囲を1.00のグループに
            // 所属させる. したがって前者はkpを単にfloorし、後者はkpから十分に
            // 小さな数を引いてからfloorする.
            const kpVal = isKpIncDirection ? origKpVal : origKpVal - 0.000001;
            const alignedKp = parseInt((kpVal * step).toString()) / step;
            // ガード.
            // 上りの場合の左端(最小kp)もしくは下りの場合の右端(最大kp)が
            // x.0になっていると、端のkpに紐づくデータはstepMapの範囲外となる.
            // 例: 横羽(下)はmaxが19.0
            if (!kpStepMap[alignedKp]) { continue; }

            if (dataType === 'temperature' && isRoadTemperatureDispModeMultiple.value) {
              // 路温が時間帯別表示モードの場合、時間帯毎にデータをまとめる.
              dataArr.forEach(e => {
                const t = Math.floor((now.getTime() - new Date(e.ts).getTime()) / 3600 / 1000);
                kpStepMapByTimeRange[t][alignedKp].push({
                  t: new Date(e.ts),
                  v: e.data as number,
                  kp: kp,
                  pt1: { lat: parseFloat(e.lat1), lon: parseFloat(e.lon1) },
                  pt2: { lat: parseFloat(e.lat2), lon: parseFloat(e.lon2) },
                });
              });
            } else {
              kpStepMap[alignedKp].push(...dataArr.map(e => {
                return {
                  t: new Date(e.ts),
                  v: e.data as number,
                  kp: kp,
                  pt1: { lat: parseFloat(e.lat1), lon: parseFloat(e.lon1) },
                  pt2: { lat: parseFloat(e.lat2), lon: parseFloat(e.lon2) },
                };
              }));
            }
          }
        });

        if (dataType === 'temperature' && isRoadTemperatureDispModeMultiple.value) {
          // 路温が時間帯別表示モードの場合、時間帯毎にデータをまとめる.
          const dataByTimeRange = Object.keys(kpStepMapByTimeRange).map(k1 => {
            const kpStepMap = kpStepMapByTimeRange[Number(k1)];
            const items = Object.keys(kpStepMap).map(k2 => {
              const cellData = getCellDataFor(dataType, kpStepMap[Number(k2)]) as ReturnType<typeof getCellDataForTemperature>;
              return { kp: parseFloat(k2), data: cellData };
            }).sort((a, b) => a.kp < b.kp ? -1 : 1).map(e => e.data);
            const isValid = items.filter(e => e !== null).length > 0;
            return { t: parseInt(k1), data: items, isValid: isValid, show: isValid };
          });
          dataByTimeRange.sort((a, b) => a.t < b.t ? -1 : 1);
          state.geoItemConvDataForGraph.temperatures_by_time_range = dataByTimeRange;
          return;
        }

        const items = Object.keys(kpStepMap).map(k => {
          const cellData = getCellDataFor(dataType, kpStepMap[Number(k)]);
          return { kp: parseFloat(k), data: cellData };
        });
        items.sort((a, b) => a.kp < b.kp ? -1 : 1);

        if (dataType === 'temperature') {
          state.geoItemConvDataForGraph.temperature = items.map(e => {
            return { data: e.data as ReturnType<typeof getCellDataForTemperature> };
          });
        } else if (dataType === 'salinity') {
          state.geoItemConvDataForGraph.salinity = items.map(e => {
            return { data: e.data as ReturnType<typeof getCellDataForSalinity> };
          });
        } else {
          state.geoItemConvData[dataType] = items;
        }
      }
    };
    const getCellDataFor = (dataType: string, arr: KpStepMapByTimeRange[]): number | CellData | null => {
      let ret: number | CellData | null = null;
      switch (dataType) {
        case 'temperature': ret = getCellDataForTemperature(arr); break;
        case 'salinity': ret = getCellDataForSalinity(arr); break;
        case 'snowfall2': ret = getCellDataForSnowfall2(arr); break;
        case 'frozen': ret = getCellDataForFrozen(arr); break;
        case 'snow_mountain': ret = getCellDataForSnowMountain(arr); break;
        case 'jh_work_status': ret = getCellDataForJhWorkStatus(arr); break;
        case 'ensui_sanpu': ret = getCellDataForEnsuiSanpu(arr); break;
        case 'juten_sanpu': ret = getCellDataForJutenSanpu(arr); break;
        case 'karadanpu': ret = getCellDataForKaradanpu(arr); break;
        case 'tairyu': ret = getCellDataForTairyu(arr); break;
      }
      return ret;
    };
    const getKpStepMap = (min: number, max: number) => {
      // floatの計算がいやなのでintで計算してfloatに戻す.
      const denom = 10;
      const lower = parseInt(Math.floor(min).toString()) * denom;
      let upper = parseInt(Math.ceil(max).toString()) * denom;

      const step = KP_STEP / 1000;
      const internalStep = step * denom;

      let current = lower;
      const kpStepMap: Record<number, KpStepMapByTimeRange[]> = {};
      while (current < upper) {
        const k = current / denom;
        kpStepMap[k] = [];
        current += internalStep;
      }

      return { kpStepMap, step, lastKp: max };
    };
    const getKpStepMapByTimeRange = (kpStepMap: Record<number, KpStepMapByTimeRange[]>) => {
      const kpStepMapByTimeRange: Record<number, Record<number, KpStepMapByTimeRange[]>> = {};
      const { timeFromOffset, timeToOffset } = state.search;
      const timeRange = [timeFromOffset * -1, timeToOffset * -1];
      const min = Math.min(...timeRange) / 3600 / 1000;
      const max = Math.max(...timeRange) / 3600 / 1000;
      let len = Math.ceil(max - min);
      Array.from({ length: len }, (e, i) => {
        kpStepMapByTimeRange[i + parseInt(min.toString())] =
          JSON.parse(JSON.stringify(kpStepMap));
      });
      return { kpStepMapByTimeRange };
    };
    const refreshGraphData = async() => {
      await fetchGeoItems();
      convGeoItemRawData();
      setGraphData();
    };
    const getSelectedRoadNames = () => {
      // 選択された路線名(road_name_disp)に属するroad_nameの配列を取得する.
      const ret: string[] = [];
      const selectedRoadNameDisp = state.search.roadName;
      const selectedDirection = state.search.direction;
      if (!state.kpMap) { return ret; }
      for (const kpMapKey of Array.from(state.kpMap.keys())) {
        const [roadName, direction] = kpMapKey.split('#');
        // 実際はJCTや出入口だがデータ上は本線扱いとなっている路線名は除外する.
        if (state.roadNameIgnores.indexOf(roadName) !== -1) { continue; }
        // roadNameDispはroadNameのprefixとなっているはず.
        if (
          roadName.indexOf(selectedRoadNameDisp || '') !== 0 ||
          direction !== selectedDirection
        ) { continue; }

        ret.push(roadName);
      }
      return ret;
    };
    const onClickSubmit = async() => {
      if (!state.kpMap) { return; }

      state.selectedRoadNames = getSelectedRoadNames();
      const kps = state.selectedRoadNames.reduce((acc: GIKilopost[], selectedRoadName: string) => {
        const kpMapKey = selectedRoadName + '#' + state.search.direction;

        const kps = state.kpMap?.get(kpMapKey)?.get('main_line');
        if (kps) {
          acc.push(...kps);
        }

        return acc;
      }, []).sort((a, b) => {
        const v1 = a.kp_calc;
        const v2 = b.kp_calc;
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
      });
      state.currentRoadNameDirection = state.search.roadName + '#' + state.search.direction;
      state.currentKpMin = kps[0].kp;
      state.currentKpMax = kps[kps.length - 1].kp;

      initGraphDisp();

      await refreshGraphData();
    };
    const selectAllRoadTemperatureTimeRanges = (e: Event) => {
      if ((e.target as HTMLInputElement).checked) {
        state.graphData.roadTemperaturesByTimeRange.forEach(e => {
          e.show = e.isValid;
        });
      } else {
        state.graphData.roadTemperaturesByTimeRange.forEach(e => {
          e.show = false;
        });
      }
    };
    const getRoadLabels = (kpStepArr: number[], lastKp: number) => {
      const masterData = roadLabelsRaw[state.currentRoadNameDirection] || [];

      const ret = [];
      let stepWidth = 0;
      let tmpIdx = 0;
      const masterDataLen = masterData.length;
      for (let i = 0, len = kpStepArr.length; i < len; i++) {
        const currentKpStep = kpStepArr[i];
        let nextKpStep = kpStepArr[i + 1];
        // 最後の要素は次がないので一個前と同じ幅を取る
        if (nextKpStep === undefined) {
          nextKpStep = currentKpStep + stepWidth;
        }
        stepWidth = nextKpStep - currentKpStep;

        const elem: Elem = { currentKpStep, labels: [] };
        elem.showKm = i === 0 || i === len - 1;
        // JCTとか出入口
        while (tmpIdx < masterDataLen) {
          const mElem = masterData[tmpIdx];
          if (mElem.kp < currentKpStep || mElem.kp >= nextKpStep) {
            break;
          }
          tmpIdx++;
          const decimalDiff = mElem.kp - parseInt(mElem.kp);
          const xOffsetPercentage = parseInt((decimalDiff / stepWidth * 100).toString());
          elem.labels.push({
            ...mElem,
            xOffsetPercentage: xOffsetPercentage,
          });
        }
        ret.push(elem);
      }
      // 右端のKPの値を表示させるためとか
      const lastElem = ret[ret.length - 1];
      if (lastElem.currentKpStep < lastKp) {
        let percent = parseFloat((lastKp - lastElem.currentKpStep).toFixed(2));
        percent = Math.min(100, parseInt((percent * 100).toString()));

        lastElem.showLastTick = true;
        lastElem.lastKp = {
          kp: lastKp,
          // 目盛り表示位置 (100だと微妙に右側にずれて見える)
          xOffsetPercentage: Math.min(99, percent),
          // km表示位置
          kmXOffsetPercentage: Math.min(90, percent - 3),
        };
        // x.1とかx.2で終わってる場合は手前のkm表示を隠したい
        lastElem.hideBeginKpDisp = percent <= 20;
        // 上野みたいに4.4が終点の場合に4.5の目盛りが見えるとかっこ悪いので
        lastElem.hideMidTick = percent < 50;
      }

      return ret;
    };
    const initGraphDisp = () => {
      const { kpStepMap, lastKp } =
        getKpStepMap(state.currentKpMin, state.currentKpMax);
      const kpStepArr = Object.keys(kpStepMap).map(k => parseFloat(k));
      kpStepArr.sort((a, b) => a < b ? -1 : 1);

      const graphXLabels: string[] = [];
      kpStepArr.forEach((kp, i) => {
        const nextKp = i < kpStepArr.length - 1 ? kpStepArr[i + 1] : lastKp;
        graphXLabels.push(`${kp.toFixed(1)}-${nextKp.toFixed(1)} km`);
      });
      state.graphData.labels = graphXLabels;

      state.graphData.roadLabels = getRoadLabels(kpStepArr, lastKp);

      state.graphData.roadTemperatures = kpStepArr.map(() => null);
      state.graphData.roadTemperaturesByTimeRange = [];
      state.graphData.salinities = kpStepArr.map(() => null);
      state.judgementResults = kpStepArr.map(() => 0);
    };
    const setGraphData = () => {
      state.graphData.roadTemperatures = (state.geoItemConvDataForGraph.temperature || []).map(e => e.data);
      if (isRoadTemperatureDispModeMultiple.value) {
        state.graphData.roadTemperaturesByTimeRange = state.geoItemConvDataForGraph.temperatures_by_time_range || [];
      }
      state.graphData.salinities = (state.geoItemConvDataForGraph.salinity || []).map(e => e.data);
    };
    const tsToHHMM = (ts: number) => {
      const date = new Date(ts);
      if (isNaN(date.valueOf())) { return ''; }
      const hh = ('0' + date.getHours()).slice(-2);
      const mm = ('0' + date.getMinutes()).slice(-2);
      return `${hh}:${mm}`;
    };
    const roadNameChanged = () => {
      const roadNameObj = state.roadNameMap[state.search.roadName || ''];
      state.directions = roadNameObj.directions;
      state.search.direction = state.directions[0].direction;
    };

    return {
      ...toRefs(state),
      // computed
      isRoadTemperatureDispModeSingle,
      isRoadTemperatureDispModeMultiple,
      filteredRoadTemperaturesByTimeRange,
      isRoadTemperatureTimeRangesAllSelected,
      shouldShowTairyu,
      shouldShowJohaisetsu,
      // methods
      refreshGraphData,
      onClickSubmit,
      selectAllRoadTemperatureTimeRanges,
      roadNameChanged,
      dtFormat,
    };
  },
  components: {
    RouteChart,
  },
});
