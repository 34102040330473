import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import {
  SettouOnsiteManagementReportsParams,
  SpSettouOnsiteManagementReportExt,
  SettouOnsiteManagementGetCSVParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReportsRequest';
import {
  SettouOnsiteManagementReports,
  SettouOnsiteManagementReport,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReportsResponse';

export default {
  index(params: SettouOnsiteManagementReportsParams): Promise<AxiosResponse<SettouOnsiteManagementReports>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_onsite_management_reports'), {
      params: params || {},
    });
  },
  show(id: number): Promise<AxiosResponse<SettouOnsiteManagementReport>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_settou_onsite_management_reports/${id}`));
  },
  create(params: SpSettouOnsiteManagementReportExt): Promise<AxiosResponse<SettouOnsiteManagementReport>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl('/johaisetsu_settou_onsite_management_reports'), formData);
  },
  downloadCSV(params: SettouOnsiteManagementGetCSVParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_settou_onsite_management_reports/csv`), {
      params: params,
      responseType: 'blob',
    });
  },
};
