



























































import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { PageItem } from '@/models';

export interface AdminListTableField {
  label: string;
  name: string;
  class?: string;
  type?: string;
}

export interface CustomButton {
  isVisible: (e: any) => boolean;
  label: string;
  clickHandler: (e: any) => void;
}

interface AdminListTableState {
  paginatedData: any[];
  pager: {
    currentPage: number;
    itemsPerPage: number;
  };
}

export default defineComponent({
  name: 'admin-list-table',
  props: {
    displayData: {
      type: Array as PropType<any[]>,
      default: () => { return []; },
    },
    fields: {
      type: Array as PropType<AdminListTableField[]>,
      default: () => { return []; },
    },
    customButtons: {
      type: Array as PropType<CustomButton[]>,
      default: () => { return []; },
    },
    canDelete: {
      type: Function as PropType<(e: any) => boolean>,
      default: () => true,
    },
    canEdit: {
      type: Function as PropType<(e: any) => boolean>,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<AdminListTableState>({
      paginatedData: [],
      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },
    });
    const filterByPage = () => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      state.paginatedData = props.displayData.filter((e, i: number) => {
        return i >= minIdx && i < nextPageIdx;
      });
    };
    const onPageClick = (pageItem: PageItem) => {
      state.pager.currentPage = pageItem.pageNum;
      filterByPage();
    };

    onMounted(filterByPage);
    watch(() => props.displayData, filterByPage);

    const edit = (obj: any) => {
      emit('start-edit', obj);
    };
    const destroy = (obj: any) => {
      emit('start-destroy', obj);
    };
    const visibleCustomButtons = (obj: any) => {
      return props.customButtons.filter(customButton => {
        const isVisible = customButton.isVisible || (() => true);
        return isVisible(obj);
      });
    };

    return {
      ...toRefs(state),
      // methods
      edit,
      destroy,
      onPageClick,
      visibleCustomButtons,
    };
  },
});
