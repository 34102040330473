


import { AxiosError } from 'axios';
import commentTypeApi from '@/apis/commentType';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { CommentType } from '@/models/apis/master/masterResponse';
import { getErrorMessages } from '@/lib/errMsgHelper';
import { refreshCommentTypeMaster } from '@/lib/masterHelper';

interface AdminCommentTypeEdit {
  id: number;
  comment_type: string;
  comment_type_disp: string;
  disp_order: number;
  icon_path: string;
}

interface AdminCommentTypeState {
  showEditModal: boolean;
  editMode: 'edit' | 'create';
  commentTypes: CommentType[];
  editting: AdminCommentTypeEdit;
  httpErrorMsgs: string[];
  iconPaths: string[];
  showDestroyModal: boolean;
  showErrorModal: boolean;
  errorModalMsg: string;
}

export default defineComponent({
  name: 'admin-comment-types',
  setup() {
    const initEdittingState = (): AdminCommentTypeEdit => ({
      id: 0,
      comment_type: '',
      comment_type_disp: '',
      disp_order: 0,
      icon_path: '',
    });
    const state = reactive<AdminCommentTypeState>({
      showEditModal: false,
      editMode: 'edit',
      commentTypes: [],
      editting: initEdittingState(),
      httpErrorMsgs: [],
      iconPaths: [],
      showDestroyModal: false,
      showErrorModal: false,
      errorModalMsg: '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。',
    });

    const canSave = computed<boolean>(() => {
      const hasCommentType = !!state.editting.comment_type;
      const hasCommentTypeDisp = !!state.editting.comment_type_disp;
      const hasIconPath = !!state.editting.icon_path;
      const hasNumericDispOrder = !!state.editting.disp_order &&
        /^\d+$/.test(state.editting.disp_order.toString());
      return hasCommentType && hasCommentTypeDisp && hasIconPath && hasNumericDispOrder;
    });
    const editModalTitle = computed<string>(() => {
      return state.editMode === 'create' ? '新規作成' : '編集';
    });

    onMounted(async() => {
      await window.master.$promise;
      state.iconPaths = window.master.commentTypeIconPaths;
      doSearch();
    });

    const doSearch = async() => {
      const { data } = await commentTypeApi.adminIndex();
      state.commentTypes = data;
    };
    const selectIcon = (path: string) => {
      state.editting.icon_path = path;
    };
    const edit = (obj: CommentType) => {
      state.editting = { ...obj };
      state.httpErrorMsgs = [];
      state.editMode = 'edit';
      state.showEditModal = true;
    };
    const create = () => {
      const unusedIconPaths = state.commentTypes.reduce((iconPaths: string[], commentType) => {
        return iconPaths.filter(iconPath => iconPath !== commentType.icon_path);
      }, state.iconPaths.slice());
      const maxDispOrder = state.commentTypes.reduce((max, curr) => {
        return curr.disp_order > max ? curr.disp_order : max;
      }, 0);
      state.editting = {
        id: 0,
        comment_type: '',
        comment_type_disp: '',
        icon_path: unusedIconPaths.length > 0 ? unusedIconPaths[0] : state.iconPaths[0],
        disp_order: maxDispOrder + 1,
      };
      state.editMode = 'create';
      state.showEditModal = true;
    };
    const save = async() => {
      try {
        if (state.editMode === 'edit') {
          await commentTypeApi.adminUpdate(state.editting.id, state.editting);
        } else {
          await commentTypeApi.adminCreate(state.editting);
        }
        state.showEditModal = false;
        searchAndRefreshCommentTypeMaster();
      } catch (err) {
        state.httpErrorMsgs = getErrorMessages(err as AxiosError);
      }
    };
    const tryDestroy = (obj: CommentType) => {
      state.editting = obj;
      state.showDestroyModal = true;
    };
    const doDestroy = async() => {
      try {
        await commentTypeApi.adminDestroy(state.editting.id);
        state.showDestroyModal = false;
        searchAndRefreshCommentTypeMaster();
      } catch (err) {
        const errMsgs = getErrorMessages(err as AxiosError);
        if (errMsgs.includes('has comments')) {
          state.errorModalMsg = '1件以上の付箋が登録されているので削除できません。';
        } else if (errMsgs.includes('last comment_type')) {
          state.errorModalMsg = '最後の付箋種別は削除できません。';
        } else {
          state.errorModalMsg = '不明なエラーです。ネットワークの状態をご確認いただき再度お試し下さい。';
        }
        state.showDestroyModal = false;
        state.showErrorModal = true;
      }
    };
    const searchAndRefreshCommentTypeMaster = async() => {
      await doSearch();
      refreshCommentTypeMaster(state.commentTypes);
    };

    const fields = [
      { name: 'comment_type', label: '種別名' },
      { name: 'comment_type_disp', label: '表示名' },
      { name: 'icon_path', label: 'アイコン', type: 'icon' },
      { name: 'disp_order', label: '表示順' },
    ];
    const destroyModalTitle = '削除';
    const destroyModalMsg = 'を削除してもよろしいですか？';
    const errorModalTitle = 'エラー';

    return {
      ...toRefs(state),
      // computed
      canSave,
      editModalTitle,
      // methods
      doSearch,
      selectIcon,
      edit,
      create,
      save,
      tryDestroy,
      doDestroy,
      searchAndRefreshCommentTypeMaster,
      // other
      fields,
      destroyModalTitle,
      destroyModalMsg,
      errorModalTitle,
    };
  },
});
