import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { UpdateGeoItemsParams, CreateGeoItemsParams } from '@/models/apis/geoItemGroup/geoItemGroupRequest';
import { GeoItemGroupResponse, GeoItemGroupShowResponse } from '@/models/apis/geoItemGroup/geoItemGroupResponse';

export default {
  // geoItemGroupに属するgeoItemsを更新する
  updateGeoItems(params: UpdateGeoItemsParams): Promise<AxiosResponse<number>> {
    return axios.post(utils.getApiUrl('/geo_item_groups/update_geo_items'), params);
  },
  index(): Promise<AxiosResponse<GeoItemGroupResponse>> {
    return axios.get(utils.getApiUrl('/geo_item_groups'));
  },
  show(id: string): Promise<AxiosResponse<GeoItemGroupShowResponse>> {
    return axios.get(utils.getApiUrl(`/geo_item_groups/${id}`));
  },
  create(params: CreateGeoItemsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/geo_item_groups'), params);
  },
};
