




























































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { NumByServer } from '@/models/devDebug';
import { AdminLiveStream } from '@/models/apis/movie/movieResponse';

import liveStreamApi from '@/apis/live_stream';

interface LiveStreamEx extends AdminLiveStream {
  host: string;
  mpegDashUrl: string;
  mpegDashPlayer1Url: string;
}
interface Summary {
  numByServers: NumByServer[];
  numLiveStreams: number;
}
interface DevDebugLiveStreamListState {
  isSearched: boolean;
  liveStreams: LiveStreamEx[];
  summary: Summary;
}

export default defineComponent({
  name: 'dev-debug-live-stream-list',

  setup() {
    const initSummary = () => {
      return {
        numLiveStreams: 0,
        numByServers: [],
      };
    };
    const state = reactive<DevDebugLiveStreamListState>({
      isSearched: false,
      liveStreams: [],
      summary: initSummary(),
    });

    onMounted(async() => {
      await getLiveStreams();
    });

    const getLiveStreams = async() => {
      state.isSearched = false;
      state.liveStreams = [];
      const { data } = await liveStreamApi.adminGetLiveStreams();
      state.isSearched = true;
      if (!data || data.length === 0) { return; }
      state.liveStreams = convData(data);

      // サマリ情報計算
      const numLiveStreams = state.liveStreams.length;
      const numByServerMap: Record<string, number> = {};
      state.liveStreams.forEach(e => {
        if (!e.host) {
          return;
        }
        if (!numByServerMap[e.host]) { numByServerMap[e.host] = 0; }
        numByServerMap[e.host]++;
      });
      const numByServers = Object.entries(numByServerMap)
        .map(([host, num]) => ({ host, num }))
        .sort((a, b) => {
          return a.host < b.host ? -1 : 1;
        });
      state.summary = { numLiveStreams, numByServers };
    };
    const convData = (arr: AdminLiveStream[]) => {
      return arr.map(e => {
        const host = e.streaming_server_hostport.split(':')[0];
        const httpsPort = 443; // hostportについてるポートは昔使ってたが今は443で統一したため使ってない
        const appName = 'live';
        const testPlayerPort = 22346;
        const mpegDashUrl = `https://${host}:${httpsPort}/${appName}`;
        const encodedStreamUrl = encodeURIComponent(`${mpegDashUrl}/${e.stream_name}?t=${e.auth_token}`);
        return {
          ...e,
          host: host,
          mpegDashUrl: mpegDashUrl,
          mpegDashPlayer1Url: `https://${host}:${testPlayerPort}/videojs/mpegdash.html?url=${encodedStreamUrl}`,
        };
      }).sort((a, b) => {
        return a.device_id < b.device_id ? -1 : 1;
      });
    };
    const refreshData = async() => {
      await getLiveStreams();
    };
    return {
      ...toRefs(state),
      // methods
      refreshData,
    };
  },
});
