





























import { defineComponent, reactive, toRefs } from '@vue/composition-api';
interface DevDebugTopState {
  msg: string;
}
export default defineComponent({
  name: 'dev-debug-top',
  setup() {
    const state = reactive<DevDebugTopState>({
      msg: '開発者用画面',
    });
    return {
      ...toRefs(state),
    };
  },
});
