import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import {
  JohaisetsuSettouPatrolCreateParams,
  JohaisetsuSettouPatrolUpdateParams,
  JohaisetsuSettouPatrolStartUpdateParams,
  JohaisetsuSettouPatrolEndUpdateParams,
  JohaisetsuSettouPatrolBaseArrivalUpdateParams,
  JohaisetsuSettouPatrolGetCurrentParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolRequest';
import {
  JohaisetsuSettouPatrolResponse,
  SettouPatrolReportShowResponse,
} from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolResponse';
import { SettouReportsGetByCarIdsParams } from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';

export default {
  create(
    params: JohaisetsuSettouPatrolCreateParams): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_settou_patrol'), params);
  },
  update(
    params: JohaisetsuSettouPatrolUpdateParams): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_patrol'), params);
  },
  updateStart(
    params: JohaisetsuSettouPatrolStartUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_patrol_start'), params);
  },
  updateEnd(
    params: JohaisetsuSettouPatrolEndUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_patrol_end'), params);
  },
  updateBaseArrival(
    params: JohaisetsuSettouPatrolBaseArrivalUpdateParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.post(utils.getApiUrl('/update_johaisetsu_settou_patrol_base_arrival'), params);
  },
  getCurrent(
    params: JohaisetsuSettouPatrolGetCurrentParams,
  ): Promise<AxiosResponse<JohaisetsuSettouPatrolResponse>> {
    return axios.get(utils.getApiUrl('/johaisetsu_settou_patrol/current'), { params });
  },
  getByCarIds(
    params: SettouReportsGetByCarIdsParams,
  ): Promise<AxiosResponse<SettouPatrolReportShowResponse[]>> {
    return axios.get(utils.getApiUrl('johaisetsu_settou_patrol/get_by_car_ids'), { params });
  },
};
