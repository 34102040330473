import { Feature } from 'ol';
import { Icon, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { IconPath, WeatherObservatoryExt } from '@/models/index';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}
export default class ExtremeMapWeatherObservatoryLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  extremeMap: InstanceType<typeof ExtremeMap> | null;
  opts: Options;
  resourceMap: Record<string, WeatherObservatoryExt>;
  constructor(opts: Options = {}) {
    super();
    this.layerName = 'weather_observatory';
    this.extremeMap = null;
    this.opts = opts;
    this.resourceMap = {};
  }

  getIconPath_(): IconPath {
    const iconPath = '/static/img/kishoukansoku_icon.png';
    const selectedFramePath = `/static/img/square_selected.png`;
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(data: WeatherObservatoryExt): Style[] {
    const ret: Style[] = [];
    const { iconPath, selectedFramePath } = this.getIconPath_();
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
    }));
    if (data.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
      }));
    }
    return ret;
  }

  getResourceFeatures_(data: WeatherObservatoryExt): Feature[] {
    const coord = this.convCoord({
      lon: parseFloat(data.lon.toString()),
      lat: parseFloat(data.lat.toString()),
    });
    const feat = new Feature(new Point(coord));
    feat.setId(data.id);
    feat.setStyle(this.getResourceStyles_(data));
    return [feat];
  }

  onClickFeature_(targetFeat: Feature): void {
    const targetFeatId = targetFeat.getId() || '';
    const targetResource = this.resourceMap[targetFeatId];

    // 地図上の見た目を調整
    const layerSource = this.layer?.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected =
        tmpResource.id === targetResource.id &&
        !tmpResource.isSelected;
      const hasGeoLocation = tmpResource.lat && tmpResource.lon;
      if (currentIsSelected !== tmpResource.isSelected && hasGeoLocation) {
        const feat = layerSource?.getFeatureById(tmpResource.id);
        if (feat) {
          feat.setStyle(this.getResourceStyles_(tmpResource));
        }
      }
    }
    if (this.extremeMap) {
      this.extremeMap.onClickWeatherObservatory(targetResource);
    }
  }

  createLayer_(resources: WeatherObservatoryExt[]): void {
    const feats: Feature[] = [];
    resources.filter(e => e.lat && e.lon).forEach(resource => {
      feats.push(...this.getResourceFeatures_(resource));
    });
    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: feats}),
    });
    layer.name = this.layerName;
    this.layer = layer;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented || !feature) return;
      event.preventDefault();
      this.onClickFeature_(feature as Feature);
    };
  }

  prepareLayer(extremeMap: InstanceType<typeof ExtremeMap>, resources: WeatherObservatoryExt[]): LayerWithInfo {
    this.resourceMap = resources.reduce((acc: Record<string, WeatherObservatoryExt>, e) => {
      acc[e.id] = e; return acc;
    }, {});
    this.extremeMap = extremeMap;
    this.createLayer_(resources);

    return this.getLayer();
  }

  deselectAll(): void {
    const layerSource = this.layer?.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected = false;
      if (currentIsSelected !== tmpResource.isSelected) {
        const feat = layerSource?.getFeatureById(tmpResource.id);
        if (!feat) { continue; }
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }
  }

  getResourceMap(): Record<string, WeatherObservatoryExt> {
    return this.resourceMap;
  }
}
