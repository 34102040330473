


import {
  defineComponent,
} from '@vue/composition-api';
import SettouReportCommon from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/index.vue';
import { ABILITY_SETTOU_ONSITE_MANAGEMENT_REPORT_INPUT } from '@/consts/ability';
import { JOHAISETSU_TYPE_GROUP_SETTOU_ONSITE_MANAGEMENT } from '@/consts/johaisetsu_car';
import {
  provideSettouReport,
  SettouReportState,
} from '@/components/Sp/Johaisetsu/SettouPatrol/composables/useSettouReport';
import CreateSettouOnsiteManagementReport from '@/components/Sp/Johaisetsu/SettouPatrol/SettouOnsiteManagement/CreateSettouOnsiteManagementReport/index.vue';
import StartWorkComponent from '@/components/Sp/Johaisetsu/SettouPatrol/SettouOnsiteManagement/StartWork/index.vue';
import SaveWorkSituation from '@/components/Sp/Johaisetsu/SettouPatrol/SettouOnsiteManagement/SaveWorkSituation/index.vue';
import johaisetsuSettouOnsiteManagementApi from '@/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsu_settou_onsite_management';
import { JohaisetsuSettouPatrolDetailApi } from '@/models/johaisetsu/johaisetsuCommon';
import {
  updateJohaisetsuStart,
  updateJohaisetsuEnd,
  updateBaseArrival,
} from '@/lib/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagement';
import { WORK_TYPE_NAME_DEFAULT } from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';

export default defineComponent({
  setup() {
    const state: SettouReportState = provideSettouReport({
      ability: ABILITY_SETTOU_ONSITE_MANAGEMENT_REPORT_INPUT,
      johaisetsuTypeGroup: JOHAISETSU_TYPE_GROUP_SETTOU_ONSITE_MANAGEMENT,
      johaisetsuTypeGroupName: '進捗管理専任者・現場指揮者',
      johaisetsuWorkTypeName: WORK_TYPE_NAME_DEFAULT,
    });

    const detailApis: JohaisetsuSettouPatrolDetailApi = {
      create: johaisetsuSettouOnsiteManagementApi.create,
      update: johaisetsuSettouOnsiteManagementApi.update,
      getCurrent: johaisetsuSettouOnsiteManagementApi.getCurrent,
      updateJohaisetsuStart: updateJohaisetsuStart,
      updateJohaisetsuEnd: updateJohaisetsuEnd,
      updateBaseArrival: updateBaseArrival,
    };

    return {
      state,
      detailApis,
      createReportComponent: CreateSettouOnsiteManagementReport,
      startWorkComponent: StartWorkComponent,
      saveWorkSituationComponent: SaveWorkSituation,
    };
  },
  components: { SettouReportCommon },
});
