import axios, { AxiosResponse } from 'axios';
import { GetFileRequestParams } from 'src/models/apis/commentFile/commentFileRequest';
import * as utils from './apiUtils';

export default {
  getFile(params: GetFileRequestParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl('/files'), {
      params: params || {},
      responseType: 'blob',
    });
  },
};
