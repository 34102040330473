






import { defineComponent,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';

/**
 */
export default defineComponent({
  name: 'PrintPageWrapper',
  setup() {
    onMounted(() => {
      document.body.classList.add('print-page');
    });

    onUnmounted(() => {
      document.body.classList.remove('print-page');
    });
  },
});
