


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  ref,
  nextTick,
  onUnmounted,
} from '@vue/composition-api';
import Vue from 'vue';
import carApi from '@/apis/car';
import deviceApi from '@/apis/device';
import movieApi from '@/apis/movie';
import MoviePlayerDialog from '@/components/lib/MoviePlayerDialog.vue';
import dataLayerLegends from '@/components/lib/dataLayerLegend';
import mapElemInfoComponents from '@/components/lib/mapElemInfoComponents';
import geoItemSearchComponents from '@/components/lib/geoItemSearchComponents';
import TabPaneComponent from '@/components/lib/TabPaneComponent/index.vue';
import TopDebugComponent from '@/components/Top/TopDebugComponent/index.vue';
import { MovieDialogInfo } from '@/lib/MovieViewStatusManager';
import { parseDateTimeString } from '@/lib/dateTimeUtil';

import {
  RoadNameDirectionShortcut,
  RoadNameDirectionShortcutName,
  CarExt,
  SelectMovieCandidateEvent,
} from '@/models/index';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { Car } from '@/models/apis/cars/carResponse';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { Settings as UserSettings } from 'src/models/apis/user/userResponse';
import { getGeoItems } from '@/lib/geoItemHelper';
import { dtFormat } from '@/lib/dateHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { LocalStorageActionTypes, LocalStorageGetterTypes, CarFilterGroupScope } from '@/store/modules/localStorage';
import { RoadGeoItemData } from '@/models/apis/geoItem/geoItemResponse';
import { Movie, MInfoPos } from '@/models/apis/movie/movieResponse';
import { GetGeoItemsParamsRaw } from '@/models/route';
import { OptByDataType } from '@/models/apis/geoItem/geoItemRequest';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import {
  CandidacyInfo,
  CarInfo,
  GeoItemLayerOrder,
  GeoItemLayerShow,
  MStateCar,
  MStateMovie,
  Opts,
  TopState,
} from '@/models/top';
import {
  convData,
  dialogInfoToMInfo,
  getMoviePlayerDimensions,
  getMoviePlayersPositions,
  initTopState,
  saveMInfosToUrlParams,
  urlParamsToMInfos,
} from './utils';
import { useComment } from '@/composables/useComment';
import {
  getGeoItemSearchTimestamps,
  getInitTimeChoices,
  getRoadDirectionFilteredGeoItemLayerData,
  calculatePaneWidths,
} from '@/lib/utils';
import ManualDownloadLinks from '@/components/lib/ManualDownloadLinks/index.vue';
import { useWeatherObservatory } from '@/composables/useWeatherObservatory';
import { DeselectFuncMap } from '@/models';

// 60日以内を最近のデータとする
const RECENT_DAYS = 60;

export default defineComponent({
  name: 'top',
  setup() {
    const state = reactive<TopState>(initTopState());
    const store = useStore();
    const userState = store.state.user;
    const extremeMap = ref<InstanceType<typeof ExtremeMap>>();
    const resizeMap = () => {
      if (!refGeneralSearchBarRow.value ||
        !refMapTopMiscBarLeft.value ||
        !refMapTopMiscBarRight.value ||
        !refMapSelectedElemInfoArea.value ||
        !extremeMap.value
      ) {
        return;
      }
      // windowの高さ - window内での地図より上の部分の高さ - window内での地図より下の部分の高さ = 地図の高さ
      const headerH = 57;
      const searchBarH = refGeneralSearchBarRow.value.clientHeight;
      // 地図の上部分の高さは固定値だが、スクロールしたら表示されている高さは減るから、それを考慮に入れてやる
      // (下にスクロールした状態で付箋を表示すると、スクロール下分だけ地図の高さは増える)
      const aboveMapH =
        Math.max(0, headerH + searchBarH - window.scrollY) +
        Math.max(refMapTopMiscBarLeft.value.clientHeight, refMapTopMiscBarRight.value.clientHeight);

      // 地図下部領域の高さは可変だが、地図の高さを決める時は最大でもこの数値までしかその領域の高さを考慮しないこととする.
      const mapSelectedElemInfoMaxH = 150;
      const belowMapH = Math.min(refMapSelectedElemInfoArea.value.clientHeight, mapSelectedElemInfoMaxH);

      const margin = 0;
      const mapHeight = window.innerHeight - aboveMapH - belowMapH - margin;
      extremeMap.value.setMapHeight(mapHeight);
      extremeMap.value.triggerResize();
    };
    const resizePanes = () => {
      if (!refPaneLeft.value ||
        !refPaneCenter.value ||
        !refPaneRight.value
      ) {
        return;
      }

      if (!refPaneCenter.value || !refPaneRight.value) return;
      const windowWidth = window.innerWidth - 36;
      const showComment = commentState.geoItemMetaContext.show['comment'];
      const paneWidths = calculatePaneWidths(windowWidth, showComment);
      state.styles.paneSideMinWidth = paneWidths.paneSideMinWidth;
      state.styles.paneSideMaxWidth = paneWidths.paneSideMaxWidth;
      state.styles.paneCenterMinWidth = paneWidths.paneCenterMinWidth;
      state.styles.paneCenterMaxWidth = paneWidths.paneCenterMaxWidth;
      refPaneLeft.value.style.width = paneWidths.paneSideInitialWidth;
      refPaneCenter.value.style.width = paneWidths.paneCenterInitialWidth;
      refPaneRight.value.style.width = paneWidths.paneRightSideInitialWidth;
      refPaneRight.value.style.display = showComment ? 'block' : 'none';

      nextTick(() => {
        resizeMap();
      });
    };
    const { state: commentState, ...commentUtils } = useComment({ resizeMap, resizePanes, refExtremeMap: extremeMap });
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const userSettings = computed<UserSettings>(() => {
      return userState.settings;
    });
    const g2id = computed<number>(() => {
      return userState.g_info.g2_id;
    });
    const g3id = computed<number>(() => {
      return userState.g_info.g3_id;
    });
    const movieDialogs = computed<MovieDialogInfo[]>(() => {
      return Object.entries(state.movieDialogMap).map(e => e[1]);
    });
    const movingCarsCount = computed<number>(() => {
      return state.cars.filter(car => car.isMoving).length;
    });
    const stoppedCarsCount = computed<number>(() => {
      return state.cars.filter(car => !car.isMoving).length;
    });
    const preferences = store.getters[LocalStorageGetterTypes.PREFERENCES];
    const prefCarFilterGroupScope = computed({
      get: () => {
        return preferences.car_filter_group_scope;
      },
      set: (val: CarFilterGroupScope) => {
        store.dispatch(LocalStorageActionTypes.SET_PREFERENCE,
          { key: 'car_filter_group_scope', val: val });
      },
    });
    const numVisibleGeoItemLayers = computed<number>(() => {
      return Object.entries(state.geoItemLayer.show).filter(ent => !!ent[1]).length;
    });
    const numVisibleLegends = computed<number>(() => {
      return numVisibleGeoItemLayers.value;
    });
    const isMaxVisibleGeoItemLayersReached = computed<boolean>(() => {
      return numVisibleGeoItemLayers.value >= 1;
    });
    const shouldShowMap = computed<boolean>(() => {
      return !!state.extremeMapEssentials;
    });
    const shouldShowStallRiskLayerUI = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    const shouldShowTairyuLayerUI = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    const shouldShowManualDownloadLinks = computed<boolean>(() => {
      if (!userSettings.value.g1name) { return false; }
      return userSettings.value.g1name.indexOf('首都高') !== -1;
    });
    let geoItemLayerData: Record<string, RoadGeoItemData | null> = {};
    onMounted(async() => {
      onResize();
      window.addEventListener('resize', onResize);

      geoItemLayerData = {};
      state.geoItemTimeChoices = getInitTimeChoices();

      await waitForUserAndMasters().then(() => {
        redirectIfNoAbility(userState, route.value);
        state.roadNameDirections =
          JSON.parse(JSON.stringify(window.master.roadNameDirections));
        state.roadNameDirectionShortcuts =
          JSON.parse(JSON.stringify(window.master.roadNameDirectionShortcuts));
        state.roadNameDirectionShortcutMap =
          state.roadNameDirectionShortcuts.reduce((acc: Record<string, RoadNameDirectionShortcut>, e) => {
            acc[e.key] = e; return acc;
          }, {});

        state.roadNameDirections.forEach(e1 => {
          if (e1.isDummy) { return; }
          e1.directions.forEach(e2 => {
            e2.selected = true;
          });
        });
        state.roadNameDirectionShortcuts.forEach(e1 => {
          e1.arr.forEach(e2 => {
            e2.selected = true;
          });
        });

        state.extremeMapEssentials = {
          userSettings: userSettings.value,
          kpMap: window.master.kpMap,
        };
      });

      state.carFilterGroupScope = prefCarFilterGroupScope.value || null;
      await restartCarUpdateInterval({ isInitial: true });

      await restoreMoviePlayerState();
      // 動画再生状況取得APIスタート
      await state.movieViewStatusMgr.start();
      state.movieViewStatusMgr.setMovieViewStatuses(movieDialogs.value).then(() => {});

      state.showWaitSpinner = false;
    });

    const restartCarUpdateInterval = async({ isInitial }: {isInitial: boolean} = {isInitial: true}) => {
      window.clearRequestInterval(state.carUpdateTimer);
      await refreshCars({ isInitial });
      state.carUpdateTimer = window.requestInterval(() => {
        refreshCars();
      }, 10000);
    };
    const refreshCars = async({ isInitial }: {isInitial: boolean} = {isInitial: false}) => {
      const { data } = await carApi.getCars();
      const recentTs = new Date().setDate(new Date().getDate() - RECENT_DAYS);
      const recentData = data.filter(e => {
        try {
          const tsDate = parseDateTimeString(e.ts);
          return tsDate.getTime() > recentTs;
        } catch (e) {
          return false;
        }
      });

      // 初回は固定値、2回目以降は自分で持ってるやつ
      const currentCarInfoMapSourceArr: CarExt[] = isInitial
        ? recentData.map(e => {
          return {
            ...e,
            isSelected: false,
            color: null,
            colorIdx: null,
          };
        }) : state.cars;
      const currentCarInfoMap = currentCarInfoMapSourceArr.reduce((acc: Record<string, CarInfo>, e) => {
        acc[e.device_id] = {
          isSelected: e.isSelected,
          color: e.color,
          colorIdx: e.colorIdx,
        };
        return acc;
      }, {});

      const { carsExt, carKindChoices } = convData(recentData, state.carKindChoices, { currentCarInfoMap });
      carsExt.forEach(e => {
        e.shouldShow = shouldShowCar(e);
      });
      state.cars = carsExt;
      state.carMap = carsExt.reduce((acc: Record<string, CarExt>, e) => {
        acc[e.device_id] = e; return acc;
      }, {});
      state.carKindChoices = carKindChoices;
      state.carsUpdatedAt = new Date();
      nextTick(() => {
        redrawCarLayer(isInitial);
      });
    };
    const onCarFilterGroupScopeChange = async() => {
      filterCars();
      prefCarFilterGroupScope.value = state.carFilterGroupScope;
    };
    const redrawCarLayer = (isInitial = false) => {
      if (extremeMap.value) {
        extremeMap.value.redrawCarLayer({
          hideCarIcons: !state.showCarIcons,
          fitToExtent: isInitial,
        });
      }
    };
    const onClickCarOnCarList = (car: CarExt) => {
      deselectLayerItemsExludingCars();
      // 既に選択されていたものをクリックした場合、選択解除
      if (car.isSelected) {
        deselectAllCars();
        if (extremeMap.value) {
          extremeMap.value.hidePopup();
        }
      } else {
        deselectAllCars(false);
        car.isSelected = true;
        if (extremeMap.value) {
          extremeMap.value.showCarPopup(car);
        }
        redrawCarLayer();
      }
    };
    const filterCars = () => {
      state.decidedSearchConds.carName = state.searchConds.carName;
      state.decidedSearchConds.status = state.searchConds.status;

      for (let i = 0; i < state.cars.length; i++) {
        state.cars[i].shouldShow = shouldShowCar(state.cars[i]);
      }
      redrawCarLayer();
    };
    const deselectAllCars = (shouldRefresh = true) => {
      state.cars.forEach(car => {
        car.isSelected = false;
      });
      if (shouldRefresh) {
        redrawCarLayer();
      }
    };
    const deselectLayerItemsExludingCars = (): void => {
      deselectLayers({ excludes: ['cars'] });
    };
    const deselectLayers = (obj: { excludes: Array<keyof DeselectFuncMap> }) => {
      Object.entries(deselectFuncMap).forEach(([k, func]) => {
        if (obj.excludes.includes(k as keyof DeselectFuncMap)) return;
        func();
      });
    };
    const { state: weatherObservatoryState, ...weatherObservatoryUtils } = useWeatherObservatory({
      deselectLayers,
      refExtremeMap: extremeMap,
      state,
    });
    const deselectFuncMap: DeselectFuncMap = {
      cars: deselectAllCars,
      settouPatrolReports: () => {},
      ensuiPlants: () => {},
      weatherObservatories: weatherObservatoryUtils.deselectAllWeatherObservatory,
    };
    const shouldShowCar = (car: Car | CarExt) => {
      // 検索条件に照らして車を表示すべきか判定
      if (state.decidedSearchConds.carName !== '') {
        if (car.device?.car_name.indexOf(state.decidedSearchConds.carName) === -1) {
          return false;
        }
      }
      if (state.carKindChoices.length > 0) {
        const selectedCarKinds = state.carKindChoices.filter(e => e.selected);
        if (selectedCarKinds.map(e => e.key).indexOf(car.device?.car_kind || '') === -1) {
          return false;
        }
      }
      if (state.decidedSearchConds.status !== '' && state.decidedSearchConds.status !== '全て') {
        if (car.status_disp.indexOf(state.decidedSearchConds.status) === -1) {
          return false;
        }
      }
      // 車両表示範囲
      if (state.carFilterGroupScope) {
        if (state.carFilterGroupScope === 'g2') {
          if (car.device?.g2id !== g2id.value) {
            return false;
          }
        } else if (state.carFilterGroupScope === 'g3') {
          if (car.device?.g3id !== g3id.value) {
            return false;
          }
        }
      }

      return true;
    };
    const playLiveMovie = (car: CarExt, opts: { position?: MInfoPos } = {}) => {
      if (movieDialogs.value.length >= state.moviePlayerDialogMax) {
        return;
      }

      const dim = opts.position || getMoviePlayerDimensions({
        moviePlayerDialogMax: state.moviePlayerDialogMax,
        moviePlayerDefaultSize: state.moviePlayerDefaultSize,
        movieDialogs: movieDialogs.value,
      });
      if (!car.color) {
        const iconColorObj = getNextIconColor();
        car.color = iconColorObj.color || null;
        car.colorIdx = iconColorObj.idx || null;
      }
      const dialogId = getNextLiveMovieDialogId();
      Vue.set(state.movieDialogMap, dialogId, {
        kind: 'realtime',
        dialogId: dialogId,
        car: car,
        deviceId: car.device_id,
        options: {
          width: dim.w,
          height: dim.h,
          left: dim.x,
          top: dim.y,
        },
      });

      car.isSelected = false;
      if (extremeMap.value) {
        extremeMap.value.hidePopup();
      }
      redrawCarLayer();
      updateMoviePlayerState(state.movieDialogMap[dialogId], { notifyMvs: true });

      // 集計用. live動画再生開始時に空リクエストを送る.
      state.movieViewStatusMgr.notifyStartLiveStream();
    };
    const getNextLiveMovieDialogId = () => {
      const ret = `live-${state.moviePlayerDialogIncrementer}`;
      state.moviePlayerDialogIncrementer += 1;
      return ret;
    };
    const getNextIconColor = (): { color: [number, number, number]; idx: number } => {
      const stop = state.colorIdxIncrementer + state.colors.length;
      let idx = 0;
      let color = state.colors[idx];
      for (; state.colorIdxIncrementer < stop; state.colorIdxIncrementer++) {
        idx = state.colorIdxIncrementer % state.colors.length;
        color = state.colors[idx];
        if (!state.usedColorIndexMap[idx]) {
          break;
        }
      }
      registerUsedIconColor(idx);
      return { color, idx };
    };
    const registerUsedIconColor = (colorIdx: number): void => {
      state.usedColorIndexMap[colorIdx] = 1;
    };
    const deregisterUsedIconColor = (colorIdx: number | null | undefined): void => {
      if (colorIdx === null || colorIdx === undefined) { return; }
      delete state.usedColorIndexMap[colorIdx];
    };
    const getNextStoredMovieDialogId = () => {
      const ret = `stored-${state.moviePlayerDialogIncrementer}`;
      state.moviePlayerDialogIncrementer += 1;
      return ret;
    };
    const playStoredMovie = async({ candidacies, candidacyIdx = 0, movie = null }: CandidacyInfo, opts: Opts = {}) => {
      if (movieDialogs.value.length >= state.moviePlayerDialogMax) {
        return;
      }

      if (!movie) {
        const movieId = candidacies[candidacyIdx].movie_id;
        movie = (await movieApi.getMovie(movieId)).data;
      }

      const dim = opts.position || getMoviePlayerDimensions({
        moviePlayerDialogMax: state.moviePlayerDialogMax,
        moviePlayerDefaultSize: state.moviePlayerDefaultSize,
        movieDialogs: movieDialogs.value,
      });
      movie.dialogId = getNextStoredMovieDialogId();
      movie.playStartMsec = candidacies[candidacyIdx].ts_msec_diff;
      if (!movie.color) {
        const iconColorObj = getNextIconColor();
        movie.color = iconColorObj.color;
        movie.colorIdx = iconColorObj.idx;
      }
      Vue.set(state.movieDialogMap, movie.dialogId, {
        kind: 'stored',
        dialogId: movie.dialogId,
        movie,
        candidacies,
        candidacyIdx,
        options: {
          width: dim.w,
          height: dim.h,
          left: dim.x,
          top: dim.y,
        },
      });
      state.playedMovies.push(movie);
      if (extremeMap.value) {
        extremeMap.value.hidePopup();
        extremeMap.value.redrawMovieLayer();
      }
      updateMoviePlayerState(state.movieDialogMap[movie.dialogId], { notifyMvs: true });
    };
    const changeStoredMovie = async(dialogId: string, evtObj: SelectMovieCandidateEvent) => {
      // 位置や色情報は現状のまま、プレイヤーを開き直す
      const candidacies = evtObj.candidacies;
      const candidacyIdx = evtObj.candidacyIdx;
      const movieId = candidacies[candidacyIdx].movie_id;
      const movie = (await movieApi.getMovie(movieId)).data;

      const currentDialogInfo = state.movieDialogMap[dialogId];
      movie.dialogId = getNextStoredMovieDialogId();
      movie.playStartMsec = candidacies[candidacyIdx].ts_msec_diff;
      movie.color = currentDialogInfo.movie.color?.slice();
      movie.colorIdx = currentDialogInfo.movie.colorIdx;
      const dialogInfo = {
        kind: 'stored',
        dialogId: movie.dialogId,
        movie,
        candidacies,
        candidacyIdx,
        options: { ...currentDialogInfo.options },
      };

      removeMoviePlayerDialog(dialogId);
      if (movie.colorIdx !== undefined) {
        registerUsedIconColor(movie.colorIdx); // 色を登録し直す
      }
      Vue.set(state.movieDialogMap, movie.dialogId, dialogInfo);
      state.playedMovies.push(movie);
      if (extremeMap.value) {
        extremeMap.value.hidePopup();
        extremeMap.value.redrawMovieLayer();
      }
      state.movieViewStatusMgr.deleteMovieViewStatus(currentDialogInfo).then(() => {});
      updateMoviePlayerState(state.movieDialogMap[movie.dialogId], { notifyMvs: true });
    };
    const removeMoviePlayerDialog = (dialogId: string) => {
      const dialogInfo = state.movieDialogMap[dialogId];
      if (dialogInfo.kind === 'realtime') {
        deregisterUsedIconColor(dialogInfo.car.colorIdx);
        const car = state.carMap[dialogInfo.car.device_id];
        if (car) {
          car.colorIdx = null;
          car.color = null;
        }
      } else {
        deregisterUsedIconColor(dialogInfo.movie.colorIdx);
      }

      if (dialogId.indexOf('live') === 0) {
        // 同じdevice_idが全部消えてたら色をリセット
        let deviceId: string;
        for (const [k, v] of Object.entries(state.movieDialogMap)) {
          if (v.car && k === dialogId) {
            deviceId = v.deviceId;
            break;
          }
        }
        const tmpArr = Object.entries(state.movieDialogMap)
          .map(ent => ent[1])
          .filter(v => v.car && v.deviceId === deviceId);
        if (tmpArr.length === 1) {
          // 同じ車載器IDの動画の最後の一個
          tmpArr.forEach(e => {
            e.car.color = null;
            e.car.colorIdx = null;
          });
        }
      } else {
        // これでリフレッシュされる
        state.playedMovies = state.playedMovies.filter(e => {
          return e.dialogId !== dialogId;
        });
      }

      Vue.delete(state.movieDialogMap, dialogId);
      redrawCarLayer();
      removeMoviePlayerState(dialogInfo);
    };
    const onClickCar = (car: CarExt) => {
      onClickCarOnCarList(car);
    };
    const roadNameDirectionListChanged = () => {
      for (const [dataType, data] of Object.entries(geoItemLayerData)) {
        if (!data || !extremeMap.value) { continue; }
        extremeMap.value.removeGeoItemsLayer(dataType);
        const filteredData = getRoadDirectionFilteredGeoItemLayerData(data, state.roadNameDirections);
        const dataTypeKey = dataType as keyof GeoItemLayerOrder;
        const zIndexOffset = state.geoItemLayer.order[dataTypeKey] || 0;
        extremeMap.value.addGeoItemsLayer(dataType, filteredData, zIndexOffset);
      }
    };
    const roadNameShortcutListChanged = (obj1: RoadNameDirectionShortcut, obj2: RoadNameDirectionShortcutName) => {
      let areaChanged = false;
      const areaObj: Record<string, number> = {};
      const directionObj: Record<string, number> = {};
      let selected = false;
      if (obj1.key === 'area') {
        areaChanged = true;
        areaObj[obj2.key] = 1;
        state.roadNameDirectionShortcutMap.direction.arr.forEach(e => {
          if (e.selected) {
            directionObj[e.key] = 1;
          }
        });
        selected = obj2.selected || false;
      } else {
        state.roadNameDirectionShortcutMap.area.arr.forEach(e => {
          if (e.selected) {
            areaObj[e.key] = 1;
          }
        });
        directionObj[obj2.key] = 1;
        selected = obj2.selected || false;
      }

      state.roadNameDirections.forEach(e1 => {
        if (e1.isDummy) { return; }
        if (!areaObj[e1.area]) { return; }
        e1.directions.forEach(e2 => {
          if (areaChanged && !selected) {
            // エリアが非選択にされたら、方向の選択肢にかかわらず
            // 当該エリアは全落とし
            e2.selected = false;
          } else {
            if (!directionObj[e2.direction]) { return; }
            e2.selected = selected;
          }
        });
      });

      roadNameDirectionListChanged();
    };
    const changeGeoItemTimeChoice = () => {
      // 時間範囲変えたら再取得してあげようかと思ったが、
      // ボタンをつけたので何もしない.
    };
    const changeGeoItemsLayer = async(dataType: string) => {
      const dataTypeKey = dataType as keyof GeoItemLayerShow;
      const flg = state.geoItemLayer.show[dataTypeKey];
      const orderDataTypeKey = dataType as keyof GeoItemLayerOrder;
      if (flg) {
        state.geoItemLayer.order[orderDataTypeKey] = numVisibleGeoItemLayers.value;
      } else {
        const deletedOrder = state.geoItemLayer.order[orderDataTypeKey];
        delete state.geoItemLayer.order[orderDataTypeKey];
        // 順番の付け替え
        for (const [k, v] of Object.entries(state.geoItemLayer.order)) {
          if (deletedOrder && v > deletedOrder) {
            const orderDataTypeKey = k as keyof GeoItemLayerOrder;
            state.geoItemLayer.order[orderDataTypeKey] = (state.geoItemLayer.order[orderDataTypeKey] || 0) - 1;
          }
        }
      }
      // 再取得、再表示
      await refreshGeoItemsLayer();
    };
    const refreshGeoItemsLayer = async() => {
      const reqDataTypes = [];
      const reqOptByDataType: Record<string, OptByDataType> = {};
      const dataTypeToReqDataType: Record<string, string> = {
        'snowfall': 'snowfall2', // 積雪量を新しいデータソースに切り替え
      };
      const reqDataTypeToDataType: Record<string, string> = {};

      for (const [dataType, show] of Object.entries(state.geoItemLayer.show)) {
        if (!show && extremeMap.value) {
          extremeMap.value.removeGeoItemsLayer(dataType);
          geoItemLayerData[dataType] = null;
          continue;
        }

        const reqDataType = dataTypeToReqDataType[dataType] || dataType;
        reqDataTypeToDataType[reqDataType] = dataType;

        if (
          dataType === 'sweeper_soukou' ||
          dataType === 'other_soukou' ||
          dataType === 'josetsu'
        ) {
          reqOptByDataType[dataType] = { merge_leafs: false };
        }

        // あとでまとめてリクエスト
        reqDataTypes.push(reqDataType);
      }

      if (reqDataTypes.length === 0) {
        state.geoItemSearchTime.start = null;
        state.geoItemSearchTime.end = null;
        return;
      }

      state.showWaitSpinner = true;
      const reqObj: GetGeoItemsParamsRaw = getGeoItemSearchTimestamps(state.geoItemSearchConds);
      reqObj.dataTypes = reqDataTypes;
      reqObj.optByDataType = reqOptByDataType;
      const resultMap = await getGeoItems(reqObj);
      state.showWaitSpinner = false;
      state.geoItemSearchTime.start = reqObj.startTs;
      state.geoItemSearchTime.end = reqObj.endTs;

      for (const [reqDataType, layerData] of Object.entries(resultMap)) {
        const dataType = reqDataTypeToDataType[reqDataType];
        geoItemLayerData[dataType] = layerData;
        const dataTypeKey = dataType as keyof RoadGeoItemData;
        const filteredData = getRoadDirectionFilteredGeoItemLayerData(
          geoItemLayerData[dataTypeKey],
          state.roadNameDirections,
        );
        const dataTypeOrderKey = dataType as keyof GeoItemLayerOrder;
        const zIndexOffset = state.geoItemLayer.order[dataTypeOrderKey] || 0;
        if (extremeMap.value) {
          extremeMap.value.addGeoItemsLayer(dataType, filteredData, zIndexOffset);
        }
      }
    };
    const refreshLayers = async() => {
      await refreshGeoItemsLayer();
    };
    const updateShowCarIcons = () => {
      redrawCarLayer();
    };
    const paneResizeStopped = () => {
      resizeMap();
    };
    const startLiveStream = (car: CarExt) => {
      if (!car.device) {
        return;
      }
      deviceApi.startStream(car.device.id)
        .then(() => {
          state.showModal = true;
          state.modalTitle = '配信開始要求完了';
          state.modalMsg = `配信開始を<b>車載器ID: ` +
            `${car.device_id}</b>に通知しました。<br>` +
            '配信開始まで1分ほどお待ちください。';

          deselectAllCars();
          if (extremeMap.value) {
            extremeMap.value.hidePopup();
          }
        })
        .catch(() => {
          state.showModal = true;
          state.modalTitle = 'エラー';
          state.modalMsg = '配信開始要求に失敗しました。';
        });
    };
    const stopLiveStream = (car: CarExt) => {
      if (!car.device) {
        return;
      }
      deviceApi.stopStream(car.device.id)
        .then(() => {
          state.showModal = true;
          state.modalTitle = '配信停止要求完了';
          state.modalMsg = `配信停止を<b>車載器ID: ` +
            `${car.device_id}</b>に通知しました。`;

          deselectAllCars();
          if (extremeMap.value) {
            extremeMap.value.hidePopup();
          }
        })
        .catch(() => {
          state.showModal = true;
          state.modalTitle = 'エラー';
          state.modalMsg = '配信停止要求に失敗しました。';
        });
    };
    const { route } = useRoute();
    const updateMoviePlayerState = (dialogInfo: MovieDialogInfo, opts: { notifyMvs?: boolean } = {}) => {
      const mInfos = urlParamsToMInfos();
      const newInfo = dialogInfoToMInfo(dialogInfo);
      const idx = mInfos.findIndex(e => {
        return e.mType === newInfo.mType && e.mId === newInfo.mId;
      });
      if (idx !== -1) {
        mInfos[idx] = newInfo;
      } else {
        mInfos.push(newInfo);
      }
      saveMInfosToUrlParams(mInfos, route.value);

      if (opts.notifyMvs) {
        state.movieViewStatusMgr.setMovieViewStatuses([dialogInfo]);
      }
    };
    const removeMoviePlayerState = (dialogInfo: MovieDialogInfo) => {
      const mInfos = urlParamsToMInfos();
      const newInfo = dialogInfoToMInfo(dialogInfo);
      const delIndex = mInfos.findIndex(e => {
        return e.mType === newInfo.mType && e.mId === newInfo.mId;
      });
      if (delIndex === -1) { return; }
      mInfos.splice(delIndex, 1);
      saveMInfosToUrlParams(mInfos, route.value);
      state.movieViewStatusMgr.deleteMovieViewStatus(dialogInfo);
    };
    const restoreMoviePlayerState = async() => {
      const mInfos = urlParamsToMInfos();
      const mStatesPromise: Array<Promise<MStateCar | MStateMovie>> = mInfos.map(mInfo => {
        // liveはcarsから取れるがstoredはリクエストが必要なので
        // ここでデータ形式を揃える.
        if (mInfo.mType === 'l') {
          let data: CarExt | null = null;
          for (const car of state.cars) {
            if (car.device_id === mInfo.mId && car.device?.is_publishing) {
              data = car;
              break;
            }
          }
          return Promise.resolve({ mInfo, data });
        } else {
          return movieApi.getMovie(Number(mInfo.mId))
            .then(({ data }) => {
              return { mInfo, data };
            })
            .catch(err => {
              console.error('restoreMoviePlayerState():', err);
              return { mInfo, data: null };
            });
        }
      });
      const mStates = await Promise.all(mStatesPromise);

      for (const { mInfo, data } of mStates) {
        if (!data) { continue; }
        // 色を復活
        const colorIdx = mInfo.colorIdx ?? 0;
        if (colorIdx < state.colors.length) {
          data.colorIdx = colorIdx;
          data.color = state.colors[colorIdx];
          registerUsedIconColor(colorIdx);
        }

        if (mInfo.mType === 'l') {
          playLiveMovie(data as CarExt, { position: mInfo.pos });
        } else {
          let candMgi = (data as Movie).movie_geo_indices[0];
          for (const mgi of (data as Movie).movie_geo_indices) {
            if (mgi.ts_msec_diff > (mInfo.playStartMsec || 0)) {
              break;
            }
            candMgi = mgi;
          }
          await playStoredMovie({
            candidacies: [candMgi],
            candidacyIdx: 0,
            movie: data as Movie,
          }, { position: mInfo.pos });
        }
      }
    };
    const alignMoviePlayers = async() => {
      const positions = getMoviePlayersPositions(state.moviePlayerDefaultSize);
      Object.entries(state.movieDialogMap).forEach(([, v], i) => {
        const posObj = positions[i % positions.length];
        Vue.set(v, 'options', {
          left: posObj.x,
          top: posObj.y,
          width: posObj.w,
          height: posObj.h,
        });
        updateMoviePlayerState(v);
      });
    };
    const onMovieDialogDragStart = () => {
      // nothing to do
    };
    const onMovieDialogDragEnd = (dialogId: string) => {
      updateMoviePlayerState(state.movieDialogMap[dialogId]);
    };
    const onMovieDialogResizeEnd = (dialogId: string) => {
      updateMoviePlayerState(state.movieDialogMap[dialogId]);
    };
    const downloadStoredMovieFile = async(dialogId: string) => {
      const dialogInfo = state.movieDialogMap[dialogId];
      if (
        !dialogInfo ||
        !dialogInfo.movie ||
        dialogInfo.kind === 'realtime'
      ) { return; }

      state.isDownloadingMovieFile = true;
      try {
        const movie = dialogInfo.movie;
        const { data: blob } = await movieApi.getFile(movie.id);
        const filename = dtFormat(movie.start_ts, 'yyyymmdd_HHMMSS') + '_' + movie.id + '.mp4';
        downloadBlob(blob, filename);
        state.isDownloadingMovieFile = false;
      } catch (e) {
        state.isDownloadingMovieFile = false;
      }
    };
    // refs
    const refGeneralSearchBarRow = ref<HTMLElement>();
    const refMapTopMiscBarLeft = ref<HTMLElement>();
    const refMapTopMiscBarRight = ref<HTMLElement>();
    const refMapSelectedElemInfoArea = ref<HTMLElement>();
    const refPaneCenter = ref<HTMLElement>();
    const refPaneRight = ref<HTMLElement>();
    const refPaneLeft = ref<HTMLElement>();
    const onResize = () => {
      resizePanes();
      resizeLists();
      resizeMoviePlayerSize();
    };
    const resizeMoviePlayerSize = () => {
      // update default player size stuff
      const iWidth = window.innerWidth;
      const movieWHRatio =
        state.moviePlayerDefaultSize.movieH / state.moviePlayerDefaultSize.movieW;
      const defaultSpan = iWidth < 1000 ? 20 : 30;
      // 画面端両側の隙間と動画の間の隙間を除いて計算
      let defaultWidth =
        parseInt(((iWidth - (defaultSpan * (state.moviePlayerDialogMax + 1))) /
          state.moviePlayerDialogMax).toString());
      defaultWidth = Math.max(defaultWidth, state.moviePlayerDefaultSize.minW);
      const defaultHeight = parseInt((defaultWidth * movieWHRatio).toString()) +
        state.moviePlayerDefaultSize.controlBarH;

      state.moviePlayerDefaultSize.span = defaultSpan;
      state.moviePlayerDefaultSize.w = defaultWidth;
      state.moviePlayerDefaultSize.h = defaultHeight;
    };
    const resizeLists = () => {
      const headerH = 57;
      const searchBarH = refGeneralSearchBarRow.value?.clientHeight || 0;
      const h = window.innerHeight - headerH - searchBarH;

      state.styles.carListMinHeight = Math.floor(h * 0.20) + 'px';
      state.styles.carListMaxHeight = Math.floor(h * 0.38) + 'px';
      state.styles.tabContainerMinHeight = Math.floor(h * 0.49) + 'px';
      state.styles.tabContentMaxHeight = Math.floor(h * 2.00) + 'px';
    };
    onUnmounted(() => {
      window.clearRequestInterval(state.carUpdateTimer);
      state.movieViewStatusMgr.stop();
      window.removeEventListener('resize', onResize);
    });
    return {
      ...toRefs(state),
      ...toRefs(commentState),
      weatherObservatoryState,
      // ref
      extremeMap,
      refGeneralSearchBarRow,
      refMapTopMiscBarLeft,
      refMapTopMiscBarRight,
      refMapSelectedElemInfoArea,
      refPaneCenter,
      refPaneRight,
      refPaneLeft,
      // computed
      isSuperAdmin,
      movieDialogs,
      movingCarsCount,
      stoppedCarsCount,
      numVisibleLegends,
      isMaxVisibleGeoItemLayersReached,
      shouldShowMap,
      shouldShowStallRiskLayerUI,
      shouldShowTairyuLayerUI,
      shouldShowManualDownloadLinks,
      // methods
      onCarFilterGroupScopeChange,
      onClickCarOnCarList,
      filterCars,
      deselectAllCars,
      deselectLayerItemsExludingCars,
      shouldShowCar,
      playLiveMovie,
      playStoredMovie,
      changeStoredMovie,
      removeMoviePlayerDialog,
      onClickCar,
      roadNameDirectionListChanged,
      roadNameShortcutListChanged,
      changeGeoItemTimeChoice,
      changeGeoItemsLayer,
      refreshLayers,
      paneResizeStopped,
      updateShowCarIcons,
      startLiveStream,
      stopLiveStream,
      alignMoviePlayers,
      onMovieDialogDragStart,
      onMovieDialogDragEnd,
      onMovieDialogResizeEnd,
      downloadStoredMovieFile,
      dtFormat,
      ...commentUtils,
      ...weatherObservatoryUtils,
    };
  },
  components: {
    MoviePlayerDialog,
    TopDebugComponent,
    ...dataLayerLegends,
    ...mapElemInfoComponents,
    ...geoItemSearchComponents,
    TabPaneComponent,
    ManualDownloadLinks,
  },
});
