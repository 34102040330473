


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
} from '@vue/composition-api';

import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsu_settou_patrol_report';
import settouOnsiteManagementReportApi from '@/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsu_settou_onsite_management_report';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models';
import { SettouPatrolReport } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/settouPatrolReportsResponse';
import { SettouPatrolReportRaw } from '@/models/johaisetsu/settouPatrol/settouPatrol/settouPatrol';
import {
  SettouOnsiteManagementReport,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReportsResponse';
import {
  SettouOnsiteManagementReportRaw,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/settouOnsiteManagementReport';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import useSessionStorageParameterStore from '@/composables/useSessionStorageParameterStore';
import {
  SettouPatrolReportListState,
  CommonSearchParams,
  CommonSearchParamsRaw,
} from '@/components/Johaisetsu/SettouReport/composables/report';
import { filterPaginatedReports } from './utils';
import {
  ONE_DAY_IN_MILLISECONDS,
  SETTOU_PATROL_DISPLAY_NAME,
  settouPatrolReports,
  THREE_DAYS_IN_MILLISECONDS,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { arrayUnique } from '@/lib/arrayUtil';

const SESSION_STORAGE_KEY = 'SettouReportListSearchParams';

type RequestObj = {
  ts_from: Date;
  ts_to: Date;
  work_type?: string;
  johaisetsu_han_name?: string;
  road_name_disp?: string[];
  direction?: string;
  road_condition?: string;
};
type ReportOperationInterface = {
  fetchData: (reqObj: RequestObj) => Promise<void>;
  fetchCsv: (reqObj: RequestObj) => Promise<Blob>;
  getSearchParamsForReq: () => RequestObj;
};

export default defineComponent({
  setup() {
    const { route } = useRoute();
    const getInitialCommonSearchParams = (): CommonSearchParams => {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return {
        dateFrom: new Date(now.getTime() - THREE_DAYS_IN_MILLISECONDS),
        dateTo: now,
        johaisetsuReportType: SETTOU_PATROL_DISPLAY_NAME,
      };
    };
    const state = reactive<SettouPatrolReportListState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,
      patrolReports: [],
      onsiteManagementReports: [],
      johaisetsuHanNames: [],
      roadNames: [],
      roadNameMap: {},
      roadConditions: [],
      roadConditionMap: {},
      showErrorModal: false,
      errorBody: '',
      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },
      workType: '',
      johaisetsuHanName: '',

      commonSearchParams: getInitialCommonSearchParams(),
      patrolSearchParams: {
        roadNames: [],
        directionItem: null,
        roadConditionText: null,
      },
      onsiteManagementSearchParams: {
        roadNames: [],
      },
    });
    const { getStoredParams, storeParams } = useSessionStorageParameterStore<CommonSearchParams, CommonSearchParamsRaw>({
      storageKey: SESSION_STORAGE_KEY,
      initParams: getInitialCommonSearchParams(),
      beforeSerialize: (params: CommonSearchParams) => {
        return {
          dateFrom: params.dateFrom.toISOString(),
          dateTo: params.dateTo.toISOString(),
          johaisetsuReportType: params.johaisetsuReportType ?? SETTOU_PATROL_DISPLAY_NAME,
        };
      },
      afterDeserialize: (params: CommonSearchParamsRaw) => {
        return {
          dateFrom: new Date(params.dateFrom),
          dateTo: new Date(params.dateTo),
          johaisetsuReportType: params.johaisetsuReportType,
        };
      },
    });

    const store = useStore();
    const userState = store.state.user;
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });
    const isPatrolReport = computed<boolean>(() => {
      return state.commonSearchParams.johaisetsuReportType === SETTOU_PATROL_DISPLAY_NAME;
    });
    const isReportLoaded = computed<boolean>(() => {
      return isPatrolReport.value ? state.patrolReports.length > 0 : state.onsiteManagementReports.length > 0;
    });

    const paginatedPatrolReports = computed<SettouPatrolReportRaw[]>(() => {
      return filterPaginatedReports(state.patrolReports, state.pager, 'shouldShow');
    });
    const paginatedOnsiteManagementReports = computed<SettouOnsiteManagementReportRaw[]>(() => {
      return filterPaginatedReports(state.onsiteManagementReports, state.pager, 'shouldShow');
    });

    const isDateParamOk = computed<boolean>(() => {
      const params = state.commonSearchParams;
      const maxDateRange = 31;
      return (
        Math.abs(params.dateTo.getTime() - params.dateFrom.getTime()) < (maxDateRange + 1) * ONE_DAY_IN_MILLISECONDS
      );
    });

    const reportSubTypes = computed<{id: string; label: string}[]>(() => {
      const reportTypeObj = settouPatrolReports.find(x => x.type === state.commonSearchParams.johaisetsuReportType);
      return reportTypeObj?.items ?? [];
    });

    const directionItems = computed<Array<{ id: string; label: string }>>(() => {
      const srcArray = isPatrolReport.value ? state.patrolSearchParams.roadNames : state.onsiteManagementSearchParams.roadNames;
      const directions = srcArray.flatMap(roadName => roadName.directions.map(e => e.direction));
      return arrayUnique(directions, e => e).map(direction => {
        return {
          id: direction,
          label: direction,
        };
      });
    });

    const initSearchParams = (): void => {
      state.commonSearchParams = getStoredParams();
    };

    const updateJohaisetsuHanNames = (): void => {
      // 検索結果から動的に作成せずに作業班管理でやっているような形で別途班の全量を取得すべきな気もする.
      // 動的に作成しようとすると、検索条件が厳しくなっても検索結果に追随して班の選択肢を絞り込んではならず、
      // 検索条件が緩くなった場合は検索結果に追随して班の選択肢を増やす必要がある. (そうしないとUI的にすごく違和感が出る)
      // したがって、&でどんどん足していくこととする.
      // 作業種別を明示的に変更された場合は別途明示的にクリアする.
      const reports = isPatrolReport.value ? paginatedPatrolReports.value : paginatedOnsiteManagementReports.value;
      const hanNames = reports.map(report => report.johaisetsu_han_name).filter((hanName): hanName is string => !!hanName);
      state.johaisetsuHanNames = arrayUnique([...state.johaisetsuHanNames, ...hanNames], e => e);
    };

    const convPatrolReports = (data: SettouPatrolReport[]): SettouPatrolReportRaw[] => {
      return data.map(report => {
        let roadNameDisp = report.road_name_disp;
        const roadNameObj = state.roadNameMap[report.road_name_disp];
        if (roadNameObj) {
          roadNameDisp = roadNameObj.roadNameDisp;
        }
        let roadConditionText = '';
        const roadConditionObj = state.roadConditionMap[report.road_condition];
        if (roadConditionObj) {
          roadConditionText = roadConditionObj.text;
          const conditionsWithCm = [7, 8];
          if (conditionsWithCm.includes(parseInt(report.road_condition))) {
            roadConditionText = roadConditionText.replace(/\$\$/, report.snow_height.toString());
          }
        }
        const ts = new Date(report.ts);
        const hasImage = report.photos.length > 0;
        const shouldShow = true;

        return {
          ...report,
          ts,
          roadNameDisp,
          roadConditionText,
          hasImage,
          shouldShow,
        };
      });
    };
    const convOnsiteManagementReports = (data: SettouOnsiteManagementReport[]): SettouOnsiteManagementReportRaw[] => {
      return data.map(report => {
        let roadNameDisp = report.road_name_disp;
        const roadNameObj = state.roadNameMap[report.road_name_disp];
        if (roadNameObj) {
          roadNameDisp = roadNameObj.roadNameDisp;
        }
        const workArea = report.work_area ?? '';
        const ts = new Date(report.ts);
        const hasImage = report.photos.length > 0;
        const shouldShow = true;

        return {
          ...report,
          ts,
          roadNameDisp,
          workArea,
          hasImage,
          shouldShow,
        };
      });
    };

    const getReportOperationInterfaceForSettouPatrolReport = (): ReportOperationInterface => {
      return {
        fetchData: async(reqObj: RequestObj) => {
          const { data } = await johaisetsuSettouPatrolReportApi.index(reqObj);
          state.patrolReports = convPatrolReports(data);
        },
        fetchCsv: async(reqObj: RequestObj): Promise<Blob> => {
          const { data } = await johaisetsuSettouPatrolReportApi.downloadCSV(reqObj);
          return data;
        },
        getSearchParamsForReq: () => {
          const params = state.commonSearchParams;
          const roadNameReals = state.patrolSearchParams.roadNames.map(e => e.roadNameReal);

          return {
            ts_from: params.dateFrom,
            ts_to: new Date(params.dateTo.getTime() + ONE_DAY_IN_MILLISECONDS - 1),
            johaisetsu_han_name: state.johaisetsuHanName,
            road_name_disp: roadNameReals,
            direction: state.patrolSearchParams.directionItem ?? '',
            road_condition: state.patrolSearchParams.roadConditionText ?? '',
          };
        },
      };
    };
    const getReportOperationInterfaceForSettouOnsiteManagementReport = (): ReportOperationInterface => {
      return {
        fetchData: async(reqObj: RequestObj) => {
          const { data } = await settouOnsiteManagementReportApi.index(reqObj);
          state.onsiteManagementReports = convOnsiteManagementReports(data);
        },
        fetchCsv: async(reqObj: RequestObj): Promise<Blob> => {
          const { data } = await settouOnsiteManagementReportApi.downloadCSV({
            ...reqObj,
            report_ids: state.onsiteManagementReports.filter(report => report.shouldShow).map(report => report.id),
          });
          return data;
        },
        getSearchParamsForReq: () => {
          const params = state.commonSearchParams;
          const roadNameReals = state.onsiteManagementSearchParams.roadNames.map(e => e.roadNameReal);

          return {
            ts_from: params.dateFrom,
            ts_to: new Date(params.dateTo.getTime() + ONE_DAY_IN_MILLISECONDS - 1),
            work_type: state.workType,
            johaisetsu_han_name: state.johaisetsuHanName,
            road_name_disp: roadNameReals,
          };
        },
      };
    };
    const reportOperationInterface = computed<ReportOperationInterface>(() => {
      return isPatrolReport.value
        ? getReportOperationInterfaceForSettouPatrolReport()
        : getReportOperationInterfaceForSettouOnsiteManagementReport();
    });

    const partialResetSearchParams = (): void => {
      state.workType = '';
      state.johaisetsuHanName = '';
      state.patrolSearchParams = {
        roadNames: [],
        directionItem: null,
        roadConditionText: null,
      };
      state.onsiteManagementSearchParams = {
        roadNames: [],
      };
    };

    const clearSearchResult = (): void => {
      state.patrolReports = [];
      state.onsiteManagementReports = [];
      state.johaisetsuHanNames = [];
    };

    const onChangeJohaisetsuReportType = (): void => {
      partialResetSearchParams();
      clearSearchResult();
    };

    const fetchReports = async(): Promise<void> => {
      try {
        state.isRequesting = true;
        const reqObj = reportOperationInterface.value.getSearchParamsForReq();
        await reportOperationInterface.value.fetchData(reqObj);
        updateJohaisetsuHanNames();
        state.pager.currentPage = 1;
        storeParams(state.commonSearchParams);
      } catch (e) {
        console.error('error', e);
      } finally {
        state.isRequesting = false;
      }
    };

    const downloadCSV = async(): Promise<void> => {
      try {
        state.isDownloading = true;
        const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
        const filename = `${state.commonSearchParams.johaisetsuReportType}_${timestamp}`;
        const reqObj = reportOperationInterface.value.getSearchParamsForReq();
        const data = await reportOperationInterface.value.fetchCsv(reqObj);
        await downloadBlob(data, filename);
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      } finally {
        state.isDownloading = false;
      }
    };

    onMounted(async(): Promise<void> => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      await waitForJohaisetsuMasters();
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce<Record<string, RoadNameDirection>>((acc, e) => {
        acc[e.roadNameReal] = e;
        return acc;
      }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      initSearchParams();
      await fetchReports();

      state.isReady = true;
    });

    return {
      state,
      // computed
      directionItems,
      showWaitSpinner,
      paginatedPatrolReports,
      paginatedOnsiteManagementReports,
      isDateParamOk,
      isPatrolReport,
      isReportLoaded,
      settouPatrolReports,
      reportSubTypes,
      // methods
      onChangeJohaisetsuReportType,
      fetchReports,
      downloadCSV,
      dtFormat,
    };
  },
});
