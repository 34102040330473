


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  PropType,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { UserActionTypes } from '@/store/modules/user';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import {
  timeDifferenceInSeconds,
  secondsToTimeInteger,
  timeInteger,
} from '@/lib/dateTimeHelper';
import useSpeech from '@/composables/useSpeech';
import johaisetsuCarApi from '@/apis/johaisetsu/johaisetsu_car';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { Position, GeolocationPositionError } from '@/models/index';
import { Ability } from '@/models/apis/user/userResponse';
import { dtFormat, ensureDate } from '@/lib/dateHelper';
import { JohaisetsuSettouPatrol } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolResponse';
import {
  JohaisetsuSettouPatrolCreateParamsCommon,
  JohaisetsuSettouPatrolUpdateParamsCommon,
} from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';
import { JohaisetsuCarShowResponse } from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import {
  STATUS_RUNNING,
  STATUS_WORKING,
  STATUS_STOPPED,
  CAR_NEW_DATA,
  CAR_OLD_DATA,
  WORK_TYPE_NAME_DEFAULT,
  statusDispMap,
  notifyWorkElapsedTimeSpeech,
  createOrUpdateJohaisetsuCar,
} from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';
import {
  initJohaisetsuReportExt,
  toJohaisetsuReportExt,
  initSettouReportState,
} from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/utils/index';
import StartWork from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/StartWork/index.vue';
import SaveWorkSituation from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/SaveWorkSituation/index.vue';
import CreateReport from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/CreateReport/index.vue';
import Working from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/Working/index.vue';
import Pause from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/Pause/index.vue';
import { SettouReportState } from '@/models/spSettouReport';
import { JohaisetsuSettouPatrolDetailApi } from '@/models/johaisetsu/johaisetsuCommon';

export default defineComponent({
  props: {
    ability: {
      type: Number,
      required: true,
    },
    johaisetsuTypeGroup: {
      type: String,
      required: true,
    },
    johaisetsuTypeGroupName: {
      type: String,
      required: true,
    },
    johaisetsuWorkTypeName: {
      type: String,
      default: WORK_TYPE_NAME_DEFAULT,
    },
    createReportComponent: {
      type: Object,
      required: true,
    },
    startWorkComponent: {
      type: Object,
    },
    saveWorkSituationComponent: {
      type: Object,
    },
    detailApis: {
      type: Object as PropType<JohaisetsuSettouPatrolDetailApi>,
      required: true,
    },
  },
  setup(props) {
    const { speechSynthesisVoices, doSpeech } = useSpeech();
    const state = reactive<SettouReportState>(initSettouReportState());
    const store = useStore();
    const userState = store.state.user;
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const currentComponent = computed(() => {
      if (state.isWorkSituationEditing) {
        return props.saveWorkSituationComponent ?? SaveWorkSituation;
      } else if (state.isCreatingReport) {
        return props.createReportComponent;
      } if (state.isSuspending) {
        return Pause;
      } else if (state.isWorking) {
        return Working;
      } else {
        return props.startWorkComponent ?? StartWork;
      }
    });
    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });
    const isGettingCurrentLocation = computed<boolean>(() => {
      return !!state.geolocationWatchHandler;
    });
    const workElapsedTimeInt = computed<number>(() => {
      if (!state.workStartTime || !state.currentTime) return 0;
      const workElapsedTimeSec = timeDifferenceInSeconds(state.workStartTime, state.currentTime);
      return secondsToTimeInteger(workElapsedTimeSec);
    });
    const johaisetsuHan = computed<string>(() => {
      if (!userState.johaisetsu_han || userState.johaisetsu_han.length === 0) return '';
      return userState.johaisetsu_han[0].name;
    });
    const isNewData = computed<number>(() => {
      return state.johaisetsuReportId <= 0 ? CAR_NEW_DATA : CAR_OLD_DATA;
    });
    const showWorkTime = computed<boolean>(() => {
      return state.isWorking || state.isSuspending;
    });

    const clearGeolocationErrors = () => {
      state.geolocationErrors = [];
    };
    const clearGeolocationWatch = () => {
      if (!state.geolocationWatchHandler) return;
      navigator.geolocation.clearWatch(state.geolocationWatchHandler);
      state.geolocationWatchHandler = null;
      clearGeolocationErrors();
      state.currentLocation = null;
    };
    const clearTimers = () => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.johaisetsuCarUpdateTimer = null;
      state.workElapsedTimeNotifyTimer = null;
    };
    const setCurrentLocation = (position: Position) => {
      const { latitude, longitude } = position.coords;
      // 小数点第6位まで
      const base = 1000000;
      state.currentLocation = { lat: Math.floor(latitude * base) / base, lon: Math.floor(longitude * base) / base };
      clearGeolocationErrors();
    };
    const saveWorkSituation = async(
      johaisetsuSettouPatrolUpdateParams: JohaisetsuSettouPatrolUpdateParamsCommon,
      selectedJohaisetsuType?: string,
    ) => {
      state.isRequesting = true;

      try {
        const { data: detail } = await props.detailApis.update(johaisetsuSettouPatrolUpdateParams);
        if (!detail) {
          showErrorMsg('保存に失敗しました。再度操作を行ってください');
        } else {
          if (selectedJohaisetsuType) {
            state.selectedJohaisetsuType = selectedJohaisetsuType;
          }
          setJohaisetsuReport(detail);
        }
      } catch (e) {
        showErrorMsg('保存に失敗しました。再度操作を行ってください');
      } finally {
        state.isRequesting = false;
        state.isWorkSituationEditing = false;
      }
    };
    const showErrorMsg = (errMsg: string) => {
      state.errorModalMsg = errMsg;
      state.showErrorModal = true;
    };
    const onGeolocationError = (evt: GeolocationPositionError) => {
      console.error('geolocation error', evt);
      const code = evt.code;
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      // 1 PERMISSION_DENIED
      // 2 POSITION_UNAVAILABLE
      // 3 TIMEOUT
      if (code === 1) {
        // 位置情報の利用が許可されていない場合
        stopGettingCurrentLocation();
        state.showGeolocationPermissionErrorModal = true;
      } else {
        // 位置情報の取得に失敗した場合
        // エラーの内容が1(PERMISSION_DENIED)以外であれば、
        // 位置情報の利用自体は許可されているはず.
        state.geolocationErrors.push(evt);
        if (state.geolocationErrors.length > 5) {
          // エラーが何回か続いたら、画面下にエラーメッセージ出す
          state.showGeolocationGeneralErrorMsg = true;
        }
      }
    };
    const startGettingCurrentLocation = () => {
      clearGeolocationWatch();
      state.geolocationWatchHandler = navigator.geolocation.watchPosition(
        setCurrentLocation,
        onGeolocationError,
        state.geolocationOpts,
      );
    };
    const stopGettingCurrentLocation = async() => {
      await stopAll();
      clearGeolocationWatch();
    };
    const initJohaisetsuReport = () => {
      state.workStartTime = null;
      state.workEndTime = null;
      state.johaisetsuCar.report = initJohaisetsuReportExt();
    };
    const updateJohaisetsuCar = async() => {
      if (!state.currentLocation) return;
      if (!state.selectedJohaisetsuType) return;
      const obj = {
        currentLocation: state.currentLocation,
        status: state.status,
        statusDisp: statusDispMap[state.status],
        selectedJohaisetsuType: state.selectedJohaisetsuType,
      };
      const data = await createOrUpdateJohaisetsuCar(obj, isNewData.value);
      state.johaisetsuCar.id = data.id;
      state.johaisetsuCar.deviceId = data.device_id;
    };
    const setJohaisetsuReport = (detail: JohaisetsuSettouPatrol) => {
      const reportExt = toJohaisetsuReportExt(detail);
      state.johaisetsuCar.report = reportExt;
      state.johaisetsuReportId = detail.id;
      state.workStartTime = ensureDate(reportExt.startTs);
      state.workEndTime = ensureDate(reportExt.endTs);
    };
    const notifyWorkElapsedTime = () => {
      state.currentTime = new Date();
      notifyWorkElapsedTimeSpeech(workElapsedTimeInt.value, props.johaisetsuWorkTypeName, doSpeech);
    };
    const restartJohaisetsuCarUpdateInterval = async() => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      state.johaisetsuCarUpdateTimer =
        setInterval(updateJohaisetsuCar, state.johaisetsuCarUpdateTimerSec * 1000, state.status);
    };
    const restartWorkElapsedTimeNotifyInterval = () => {
      state.workStartTime = new Date();
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer =
        setInterval(notifyWorkElapsedTime, state.workElapsedTimeNotifyTimerSec * 1000);
    };
    const startMoving = async(
      johaisetsuSettouPatrolCreateParams: JohaisetsuSettouPatrolCreateParamsCommon,
      selectedJohaisetsuType: string,
    ) => {
      state.isRequesting = true;
      try {
        state.status = STATUS_RUNNING;
        await updateJohaisetsuCar();
        if (!state.johaisetsuCar.id || !state.johaisetsuCar.deviceId) {
          state.isRequesting = false;
          showErrorMsg(`${props.johaisetsuWorkTypeName}開始に失敗しました。再度操作を行ってください`);
          return;
        }
        const detail = await createSettouPatrolDetails(johaisetsuSettouPatrolCreateParams);
        if (!detail?.id) {
          state.isRequesting = false;
          showErrorMsg(`${props.johaisetsuWorkTypeName}開始に失敗しました。再度操作を行ってください`);
          return;
        }

        clearTimers();
        state.selectedJohaisetsuType = selectedJohaisetsuType;
        setJohaisetsuReport(detail);
        await restartJohaisetsuCarUpdateInterval();
        state.isMoving = true;
        await startWorking();
      } catch (e) {
        console.error('error', e);
        showErrorMsg(`${props.johaisetsuWorkTypeName}開始に失敗しました。再度操作を行ってください`);
      } finally {
        state.isRequesting = false;
      }
    };
    const createSettouPatrolDetails = async(
      johaisetsuSettouPatrolCreateParams: JohaisetsuSettouPatrolCreateParamsCommon,
    ): Promise<JohaisetsuSettouPatrol | null> => {
      if (!state.johaisetsuCar.deviceId || !state.johaisetsuCar.id) return null;

      try {
        johaisetsuSettouPatrolCreateParams.device_id = state.johaisetsuCar.deviceId;
        const { data } = await props.detailApis.create(johaisetsuSettouPatrolCreateParams);
        return data;
      } catch (e) {
        return null;
      }
    };
    const startWorking = async() => {
      if (!state.johaisetsuCar.id) return;
      state.isRequesting = true;
      const isResumingWork = state.isSuspending;
      const opType = isResumingWork ? '再開' : '開始';
      try {
        state.workStartTime = new Date();
        state.status = STATUS_WORKING;
        await updateJohaisetsuCar();
        if (!isResumingWork) {
          const data = await props.detailApis.updateJohaisetsuStart(state.johaisetsuCar.id, state.workStartTime);
          if (data) {
            setJohaisetsuReport(data);
          }
        }
        state.isRequesting = false;
        restartWorkElapsedTimeNotifyInterval();
        doSpeech(`${props.johaisetsuWorkTypeName}を${opType}します`);
        state.isSuspending = false;
        state.isWorking = true;
      } catch (e) {
        state.isRequesting = false;
        showErrorMsg(`${props.johaisetsuWorkTypeName}${opType}に失敗しました。再度操作を行ってください。`);
      }
    };
    const stopWorking = async() => {
      if (!state.johaisetsuCar.id) return;

      state.isRequesting = true;
      try {
        state.workEndTime = new Date();
        const data = await props.detailApis.updateJohaisetsuEnd(state.johaisetsuCar.id, state.workEndTime);
        if (data) {
          setJohaisetsuReport(data);
        }
        await doStopWorking();
        doSpeech(`${props.johaisetsuWorkTypeName}を終了します`);
        await baseArrival();
      } catch (e) {
        showErrorMsg(`${props.johaisetsuWorkTypeName}終了に失敗しました。再度操作を行ってください。`);
      } finally {
        state.isRequesting = false;
      }
    };
    const baseArrival = async() => {
      if (!state.johaisetsuCar.id || !state.johaisetsuCar.report.id) return;

      state.isRequesting = true;
      try {
        await props.detailApis.updateBaseArrival(state.johaisetsuCar.id);
      } catch (e) {
        state.isRequesting = false;
        showErrorMsg('レポートデータの更新に失敗しました。');
        return;
      }
      try {
        await stopAll();
        initJohaisetsuReport();
      } catch (e) {
        showErrorMsg(`${props.johaisetsuWorkTypeName}終了に失敗しました。再度操作を行ってください。`);
      } finally {
        state.isRequesting = false;
        state.isMoving = false;
        state.isWorking = false;
        state.isSuspending = false;
      }
    };
    const temporarilyStopWorking = async() => {
      state.isRequesting = true;
      try {
        await suspendWork();
        state.isRequesting = false;
        doSpeech(`${props.johaisetsuWorkTypeName}を一時停止します`);
      } catch (e) {
        state.isRequesting = false;
        showErrorMsg(`${props.johaisetsuWorkTypeName}一時停止に失敗しました。再度操作を行ってください。`);
      }
    };
    const editWorkSituation = () => {
      state.isWorkSituationEditing = true;
    };
    const createReport = () => {
      state.isCreatingReport = true;
    };
    const doStopWorking = async() => {
      state.status = STATUS_RUNNING;
      await updateJohaisetsuCar();
      state.isSuspending = false;
      state.isWorking = false;
    };
    const doStopMoving = async() => {
      state.status = STATUS_STOPPED;
      await updateJohaisetsuCar();
      state.isMoving = false;
    };
    const stopAll = async() => {
      clearTimers();
      await doStopWorking();
      await doStopMoving();
    };
    const suspendWork = async() => {
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer = null;
      await doStopWorking();
      state.isSuspending = true;
    };
    const setMyJohaisetsuCarOnMounted = async(johaisetsuCar: JohaisetsuCarShowResponse) => {
      state.johaisetsuCar.id = johaisetsuCar.id;
      state.johaisetsuCar.deviceId = johaisetsuCar.device_id;
      state.status = johaisetsuCar.status ?? STATUS_STOPPED;
      const { data: settouPatrol } = await props.detailApis.getCurrent({
        johaisetsu_car_id: johaisetsuCar.id,
      });
      if (!settouPatrol.id) return;
      setJohaisetsuReport(settouPatrol);
      if (!state.johaisetsuCar.report) return;
      const currentReport = state.johaisetsuCar.report;
      restartJohaisetsuCarUpdateInterval();
      // 現在進行中の報告書がある場合はその情報をもとに画面を復旧させる
      if (currentReport.baseArrivalTs) {
        state.isMoving = false; // 最初からfalseだが一応
        return;
      }
      state.isMoving = true; // フラグが排他じゃないのが困るが、trueにしないといけないらしい
      state.status = STATUS_RUNNING;
      if (currentReport.startTs) {
        if (johaisetsuCar.status === STATUS_RUNNING) { //　一時停止中
          state.isSuspending = true;
        } else {
          state.isWorking = true;
          state.status = STATUS_WORKING;
          restartWorkElapsedTimeNotifyInterval();
        }
      }
    };
    const tryRevertEditWorkSituation = () => {
      state.isWorkSituationEditing = false;
    };
    const tryRevertCreateReport = () => {
      state.isCreatingReport = false;
    };
    const logout = async() => {
      await stopAll();
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };

    const { route } = useRoute();
    const isAvailableJohaisetsuType = (johaisetsuType: string | null): boolean => {
      return !!johaisetsuType && state.johaisetsuTypes.some(x => x.key === johaisetsuType);
    };
    onMounted(async() => {
      const promiseResults = await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
        johaisetsuCarApi.getMyJohaisetsuCar(),
      ]);
      if (!abilityMap.value[props.ability]) {
        location.href = '/';
        return;
      }

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      enableNoSleep();
      startGettingCurrentLocation();

      if (props.johaisetsuTypeGroup) {
        state.johaisetsuTypeMap = window.johaisetsuMaster.johaisetsuTypeMap;
        state.johaisetsuTypes = window.johaisetsuMaster.johaisetsuTypes.filter(e => {
          return e.key.endsWith(props.johaisetsuTypeGroup);
        });
        const johaisetsuTypeObj = state.johaisetsuTypes[0];
        if (johaisetsuTypeObj) {
          state.selectedJohaisetsuType = johaisetsuTypeObj.key;
        }
      }
      try {
        const localStorageKey = route.value.name;
        if (localStorageKey) {
          const obj = JSON.parse(localStorage.getItem(localStorageKey) || '');
          if (isAvailableJohaisetsuType(obj.selectedJohaisetsuType)) {
            state.selectedJohaisetsuType = obj.selectedJohaisetsuType;
          }
        }
      } catch (e) {}
      const myJohaisetsuCar = promiseResults[2];
      if (myJohaisetsuCar?.id) {
        await setMyJohaisetsuCarOnMounted(myJohaisetsuCar);
        if (isAvailableJohaisetsuType(myJohaisetsuCar.johaisetsu_type)) {
          state.selectedJohaisetsuType = myJohaisetsuCar.johaisetsu_type;
        }
      }

      state.isReady = true;
      state.displayJohaisetsuHan = johaisetsuHan.value;
    });
    onBeforeUnmount(() => {
      clearTimers();
      clearGeolocationWatch();
      disableNoSleep();
    });

    return {
      state,
      speechSynthesisVoices,
      // computed
      abilityMap,
      currentComponent,
      displayName,
      hasError,
      isGettingCurrentLocation,
      workElapsedTimeInt,
      showWorkTime,
      // methods
      baseArrival,
      clearGeolocationErrors,
      clearGeolocationWatch,
      editWorkSituation,
      createReport,
      onGeolocationError,
      saveWorkSituation,
      startMoving,
      startWorking,
      stopWorking,
      showErrorMsg,
      temporarilyStopWorking,
      tryRevertEditWorkSituation,
      tryRevertCreateReport,
      logout,
      dtFormat,
      timeInteger,
    };
  },
  components: {
    StartWork,
    SaveWorkSituation,
    CreateReport,
    Working,
    Pause,
  },
});
