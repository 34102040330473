











import {
  defineComponent,
} from '@vue/composition-api';

import { DispData } from '@/models/geoItem';
import JhWorkStatusHelper from '@/lib/geo_item/jh_work_statusHelper';
export default defineComponent({
  name: 'legend-jh-work-status',
  setup() {
    const jhWorkStatusHelper = new JhWorkStatusHelper();
    const dispData: DispData[] = [
      { val: 10, label: '通止' },
      { val: 20, label: '除中' },
      { val: 30, label: '排中' },
      { val: 40, label: '排完' },
      { val: 50, label: '査中' },
      { val: 60, label: '査完' },
    ];
    dispData.forEach(e => {
      e.color = jhWorkStatusHelper.objKeyToColor(jhWorkStatusHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
