











import {
  defineComponent,
} from '@vue/composition-api';
import SnowfallHelper from '@/lib/geo_item/snowfallHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-snowfall',
  setup() {
    const snowfallHelper = new SnowfallHelper();
    const dispData: DispData[] = [
      { val: 0, label: 'なし' },
      { val: 5, label: '5' },
      { val: 10, label: '10' },
      { val: 15, label: '15' },
      { val: 20, label: '20' },
      { val: 25, label: '25' },
      { val: 30, label: '30' },
      { val: 35, label: '35' },
    ];
    dispData.forEach(e => {
      e.color = snowfallHelper.objKeyToColor(snowfallHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
