import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  CleaningMTX,
  CleaningMtxsCreateCleaningMTXsParams,
  CleaningMtxGetParams,
} from '@/models/apis/cleaning/cleaningMtxsRequest';
import { EmptyResponse } from '@/models/index';

export default {
  createCleaningMTXs(params: CleaningMtxsCreateCleaningMTXsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/cleaning_mtxs'), params);
  },
  getCleaningMtxs(params: CleaningMtxGetParams): Promise<AxiosResponse<Record<number, CleaningMTX[]>>> {
    return axios.get(utils.getApiUrl(`/cleaning_mtxs`), {
      params: params || {},
    });
  },
};
