


import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  ref,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import boardEntryApi from '@/apis/board_entry';
import {
  HanMaster,
  BoardTab,
  BoardEntry,
} from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { dtFormat } from '@/lib/dateHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import {
  JOHAISETSU_ROLE_EAST,
  JOHAISETSU_ROLE_KANAGAWA,
  JOHAISETSU_ROLE_WEST,
} from '@/consts/johaisetsu';

interface Pagination {
  currentPage: number;
  itemsPerPage: number;
}
interface BoardEntryEdit {
  id?: number;
  dispNo?: number;
  timestamp?: string;
  date?: string | null;
  time?: string | null;
  companyName?: string | null;
  han?: number | null;
  hanDisp?: string;
  isHonshaReportNeeded: boolean;
  isReportedToHonsha: boolean;
  content: string | null;
  reply: string | null;
  bikou: string | null;
  formattedContent?: string;
  formattedReply?: string;
  formattedBikou?: string;
  createdBy?: number | null;
  createdByDispName?: string;
}
interface SettouMessageBoardState {
  boardTabs: BoardTab[];
  currentTabId: number;
  filteredTabMinIdx: number;
  maxFilteredTabCount: number;
  hanMsts: HanMaster[];
  hanChoices: HanMaster[];
  hanChoicesMap: Record<number, string>;
  boardEntries: BoardEntryEdit[];
  editingBoardEntry: BoardEntryEdit;
  editingBoardEntryOrig?: BoardEntryEdit;
  isCreate: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  deleteBody: string;
  showNewFormClearConfirmModal: boolean;
  showEditClearConfirmModal: boolean;
  showErrorModal: boolean;
  errorBody: string;
  destroyId: number | null;
  pager: Pagination;

  onResizeFunc: () => void;
}

export default defineComponent({
  name: 'settouMessageBoard',
  setup() {
    const state = reactive<SettouMessageBoardState>({
      boardTabs: [],
      currentTabId: 0,
      filteredTabMinIdx: 0,
      maxFilteredTabCount: 3,
      hanMsts: [],
      hanChoices: [],
      hanChoicesMap: {},
      boardEntries: [],
      editingBoardEntry: {
        isHonshaReportNeeded: false,
        isReportedToHonsha: false,
        content: null,
        reply: null,
        bikou: null,
      },
      isCreate: false,
      showEditModal: false,
      showDeleteModal: false,
      deleteBody: '',
      showNewFormClearConfirmModal: false,
      showEditClearConfirmModal: false,
      showErrorModal: false,
      errorBody: '',
      destroyId: null,
      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },

      onResizeFunc: (): void => {},
    });
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRoleMap: Record<string, number> = {
      [JOHAISETSU_ROLE_WEST]: 20,
      [JOHAISETSU_ROLE_EAST]: 30,
      [JOHAISETSU_ROLE_KANAGAWA]: 40,
    };
    const departmentDispMap: Record<string, string> = {
      'west': '西局本部',
      'east': '東局本部',
      'kanagawa': '神局本部',
    };
    const boardRoleCreate = 10;
    const boardRoleReply = 20;
    const LONG_TEXT_MAX_LENGTH = 3000;
    // const DEFAULT_TEXT_MAX_LENGTH = 255;
    const tabBar = ref<HTMLElement>();
    const modalDialog = ref<HTMLElement>();
    const modalHeader = ref<HTMLElement>();
    const modalFooter = ref<HTMLElement>();
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });
    const shouldShowEditModal = computed<boolean>(() => {
      if (state.showErrorModal || state.showEditClearConfirmModal) {
        return false;
      }
      return state.showEditModal;
    });
    const shouldShowDeleteModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      return state.showDeleteModal;
    });
    const currentBoardTab = computed<BoardTab>(() => {
      return state.boardTabs.find(e => e.id === state.currentTabId) ?? {
        id: 0,
        disp_name: '',
        board_kind: '',
        department: '',
        owner_company_name: '',
        disp_order: 999,
        johaisetsu_role: '',
        board_role: 99,
      };
    });
    const isHonbuBoard = computed<boolean>(() => {
      return currentBoardTab.value.board_kind === 'honbu';
    });
    const isMaintenanceBoard = computed<boolean>(() => {
      return currentBoardTab.value.board_kind === 'maintenance';
    });
    const isKyokuUser = computed<boolean>(() => {
      return userState.johaisetsu_role === JOHAISETSU_ROLE_WEST ||
        userState.johaisetsu_role === JOHAISETSU_ROLE_EAST ||
        userState.johaisetsu_role === JOHAISETSU_ROLE_KANAGAWA;
    });
    const boardTabDepartmentDisp = computed<string>(() => {
      return departmentDispMap[currentBoardTab.value.department];
    });
    const currentBoardRole = computed<number>(() => {
      return state.boardTabs.find(e => e.id === state.currentTabId)?.board_role ?? 99;
    });
    const isCreatableRole = computed<boolean>(() => {
      return !isSuperAdmin.value && currentBoardRole.value === boardRoleCreate;
    });
    const isReplyRole = computed<boolean>(() => {
      return !isSuperAdmin.value && currentBoardRole.value === boardRoleReply;
    });
    const filteredBoardTabs = computed<BoardTab[]>(() => {
      const maxIdx = state.filteredTabMinIdx + state.maxFilteredTabCount;
      return state.boardTabs.filter((e, i) => {
        return i >= state.filteredTabMinIdx && i < maxIdx;
      });
    });
    const beforeFilteredBoardTabs = computed<BoardTab[]>(() => {
      return state.boardTabs.filter((e, i) => {
        return i < state.filteredTabMinIdx;
      });
    });
    const afterFilteredBoardTabs = computed<BoardTab[]>(() => {
      const maxIdx = state.filteredTabMinIdx + state.maxFilteredTabCount;
      return state.boardTabs.filter((e, i) => {
        return i >= maxIdx;
      });
    });
    const paginatedBoardEntries = computed<BoardEntryEdit[]>(() => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      return state.boardEntries.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    });
    const modalBodyMaxHeight = computed<number>(() => {
      const windowH = window.innerHeight;
      const dialogOffsetTop = modalDialog.value?.offsetTop || 0;
      const modalHeaderH = modalHeader.value?.clientHeight || 0;
      const modalFooterH = modalFooter.value?.clientHeight || 0;
      return windowH - modalHeaderH - modalFooterH - dialogOffsetTop * 2;
    });

    onMounted(async() => {
      initResizeFunc();
      await waitForUserAndMasters();
      await prepareBoardTabs();
      await getBoardHanMasters();
      await reloadBoardEntries();
    });
    const initResizeFunc = (): void => {
      state.onResizeFunc = () => {
        // resizeされたときにタブの最大表示数を変更する
        const tabBarW = tabBar.value?.clientWidth ?? window.innerWidth;
        const btnsW = document.getElementById('tab-pager')?.clientWidth ?? 0;
        state.maxFilteredTabCount = parseInt(((tabBarW - btnsW) / 160).toString());
      };
      state.onResizeFunc();
      window.addEventListener('resize', state.onResizeFunc);
    };
    const prepareBoardTabs = async(): Promise<void> => {
      const res = await boardEntryApi.getBoardTabs();
      state.boardTabs = res.data;
      // 所属組織の掲示板をデフォルトで表示
      const ownCompanyBoardTabIdx = state.boardTabs.findIndex(e => e.owner_company_name === userState.johaisetsu_role);
      if (ownCompanyBoardTabIdx < 0) {
        state.currentTabId = state.boardTabs[0].id;
        return;
      }
      state.currentTabId = state.boardTabs[ownCompanyBoardTabIdx].id;
      if (ownCompanyBoardTabIdx < state.maxFilteredTabCount) {
        return;
      }
      // 所属組織のタブが表示範囲外の場合、一番右側に表示されるように範囲を移動する
      state.filteredTabMinIdx = ownCompanyBoardTabIdx - state.maxFilteredTabCount + 1;
    };
    const getBoardHanMasters = async(): Promise<void> => {
      const res = await boardEntryApi.getBoardHanMasters();
      state.hanMsts = res.data;
    };
    const refreshHanChoices = (): void => {
      const boardTabJohaisetsuRole = johaisetsuRoleMap[currentBoardTab.value.owner_company_name];
      state.hanChoices = state.hanMsts.filter(e => e.johaisetsu_role === boardTabJohaisetsuRole);
      state.hanChoicesMap = {};
      for (const han of state.hanMsts) {
        state.hanChoicesMap[han.id] = han.name.toString();
      }
    };
    const reloadBoardEntries = async(): Promise<void> => {
      state.pager.currentPage = 1;
      state.boardEntries = [];
      refreshHanChoices();
      const res = await boardEntryApi.index(state.currentTabId);
      // 素早く板を切り替えた時に別の板の投稿が表示されてしまうのを防ぐ
      if (res.data[0]?.board_master_id !== state.currentTabId) {
        return;
      }
      state.boardEntries = convSettouBoardEntriesResponse(res.data);
    };
    const onClickTabListItem = async(board_master_id: number): Promise<void> => {
      const boardTabIdx = state.boardTabs.findIndex(e => e.id === board_master_id);
      if (boardTabIdx < 0) { return; }
      state.currentTabId = board_master_id;
      if (boardTabIdx >= state.boardTabs.length - state.maxFilteredTabCount) {
        state.filteredTabMinIdx = state.boardTabs.length - state.maxFilteredTabCount;
      } else {
        state.filteredTabMinIdx = boardTabIdx;
      }
      await reloadBoardEntries();
    };
    const convSettouBoardEntriesResponse = (data: BoardEntry[]): BoardEntryEdit[] => {
      const converted = data.map((e, i) => {
        return {
          ...e,
          dispNo: data.length - i,
          isHonshaReportNeeded: e.is_honsha_report_needed,
          isReportedToHonsha: e.is_reported_to_honsha,
          hanDisp: (state.hanChoicesMap[e.han ?? 0] || ''),
          companyName: e.company_name,
          createdBy: e.created_by,
          createdByDispName: e.created_by_disp_name,
          formattedContent: convertLinkInText(e.content),
          formattedReply: convertLinkInText(e.reply),
          formattedBikou: convertLinkInText(e.bikou),
        };
      });
      // 本社ユーザーは本社本部報告が必要かつ掲示の場合、投稿を表示する
      if (userState.johaisetsu_role === 'honsha') {
        return converted.filter(e => e.isHonshaReportNeeded && e.isReportedToHonsha);
      }
      return converted;
    };
    const convertLinkInText = (text: string | null): string => {
      if (!text) { return ''; }

      const regExpUrl = /\b(https?:\/\/[\S]+)\b/g;
      const regExpDrivePath = /\b([A-Za-z]:\\[\S]+)\b/g;
      return text.replaceAll(regExpUrl, (url) => {
        return `<a href="${url}" target="_blank">${url}<i class="fa fa-external-link-alt ml3"></i></a>`;
      }).replaceAll(regExpDrivePath, (path) => {
        return `<a href="" onclick="
          if (!navigator.clipboard) { return false; }
          navigator.clipboard.writeText('${path.replaceAll('\\', '\\\\')}');
          return false;
        ">${path}<i class="far fa-copy ml3"></i></a>`;
      });
    };
    const onChangeBoardEntryDate = (): void => {
      const dateStr = dtFormat(state.editingBoardEntry.date, 'yyyy/mm/dd');
      if (!dateStr) { return; }
      state.editingBoardEntry.date = dtFormat(state.editingBoardEntry.date, 'yyyy/mm/dd');
    };
    const onChangeBoardEntryTime = (): void => {
      const timeStr = dtFormat(`${state.editingBoardEntry.date} ${state.editingBoardEntry.time}:00`, 'HH:MM');
      if (!timeStr) { return; }
      state.editingBoardEntry.time = timeStr;
    };
    const formatText = (text: string | null, maxLength: number): string => {
      if (!text) { return ''; }
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength);
    };
    const toTimestampStr = (dateStr?: string | null, timeStr?: string | null): string => {
      if (!dateStr || !timeStr) { return ''; }
      return `${dtFormat(dateStr, 'yyyy-mm-dd')} ${timeStr}:00`;
    };
    const editBoardEntry = (origFormObj: BoardEntry | null): void => {
      const now = new Date();
      let formObj: BoardEntryEdit = {
        date: dtFormat(now, 'yyyy/mm/dd'),
        time: dtFormat(now, 'HH:MM'),
        isHonshaReportNeeded: false,
        isReportedToHonsha: false,
        content: null,
        reply: null,
        bikou: null,
      };
      if (origFormObj) {
        formObj = {
          ...origFormObj,
          date: dtFormat(origFormObj.timestamp, 'yyyy/mm/dd'),
          time: dtFormat(origFormObj.timestamp, 'HH:MM'),
          companyName: origFormObj.company_name,
          isHonshaReportNeeded: origFormObj.is_honsha_report_needed,
          isReportedToHonsha: origFormObj.is_reported_to_honsha,
        };
      }
      prepareOrigValues(formObj);
      state.editingBoardEntry = formObj;
      state.isCreate = !origFormObj;
      state.showEditModal = true;
    };
    const tryCancelEditBoardEntry = (): void => {
      if (isEdited(state.editingBoardEntry)) {
        state.showEditClearConfirmModal = true;
      } else {
        state.showEditModal = false;
      }
    };
    const cancelEditBoardEntry = (): void => {
      state.showEditClearConfirmModal = false;
      state.showEditModal = false;
    };
    const saveBoardEntry = async(): Promise<void> => {
      if (!checkInputs(state.editingBoardEntry)) { return; }
      const params = {
        timestamp: toTimestampStr(state.editingBoardEntry.date, state.editingBoardEntry.time),
        board_master_id: currentBoardTab.value.id,
        company_name: state.editingBoardEntry.companyName ?? null,
        han: state.editingBoardEntry.han ?? null,
        is_honsha_report_needed: state.editingBoardEntry.isHonshaReportNeeded,
        is_reported_to_honsha: state.editingBoardEntry.isReportedToHonsha,
        content: formatText(state.editingBoardEntry.content, LONG_TEXT_MAX_LENGTH),
        reply: formatText(state.editingBoardEntry.reply, LONG_TEXT_MAX_LENGTH),
        bikou: formatText(state.editingBoardEntry.bikou, LONG_TEXT_MAX_LENGTH),
      };
      try {
        if (state.isCreate) {
          await boardEntryApi.create(params);
          state.isCreate = false;
        } else {
          if (!isSuperAdmin.value && currentBoardRole.value === 20) {
            await boardEntryApi.reply(
              state.editingBoardEntry.id || 0,
              params);
          } else {
            // 管理者は連絡事項を含む全ての項目が編集可
            await boardEntryApi.update(
              state.editingBoardEntry.id || 0,
              params);
          }
        }
        state.showEditModal = false;
        await reloadBoardEntries();
      } catch (e) {
        console.error('error', e);
        state.errorBody = '保存に失敗しました。再度保存を行ってください';
        state.showErrorModal = true;
      }
    };
    const tryDeleteBoardEntry = (formObj: BoardEntryEdit): void => {
      if (formObj.id) {
        state.destroyId = formObj.id;
      }
      state.deleteBody = `投稿No.${formObj.dispNo}を削除してもよろしいですか？`;
      state.showDeleteModal = true;
    };
    const deleteBoardEntry = async(): Promise<void> => {
      try {
        await boardEntryApi.destroy(state.destroyId || 0);
        reloadBoardEntries();
        state.showDeleteModal = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const isReadyToSubmit = (formObj: BoardEntryEdit): boolean => {
      return checkInputs(formObj);
    };
    const checkInputs = (formObj: BoardEntryEdit): boolean => {
      if (isHonbuBoard.value && !formObj.han) {
        return false;
      }
      if (isMaintenanceBoard.value && !formObj.companyName) {
        return false;
      }
      if (!formObj.date ||
          !formObj.time ||
          !formObj.content ||
          formObj.content.match(/^\s*$/)) {
        return false;
      }
      const timestampStr = toTimestampStr(formObj.date, formObj.time);
      const m = timestampStr.match(/^(\d{4})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})$/);
      if (!m) {
        return false;
      }
      return true;
    };
    const isEdited = (formObj: BoardEntryEdit): boolean => {
      // 値を編集していたら破棄してよいかの確認メッセージを出す
      if (formObj.date !== state.editingBoardEntryOrig?.date ||
          formObj.time !== state.editingBoardEntryOrig?.time ||
          formObj.han !== state.editingBoardEntryOrig?.han ||
          formObj.companyName !== state.editingBoardEntryOrig?.companyName ||
          formObj.isHonshaReportNeeded !== state.editingBoardEntryOrig?.isHonshaReportNeeded ||
          formObj.isReportedToHonsha !== state.editingBoardEntryOrig?.isReportedToHonsha ||
          formObj.content !== state.editingBoardEntryOrig?.content ||
          formObj.reply !== state.editingBoardEntryOrig?.reply ||
          formObj.bikou !== state.editingBoardEntryOrig?.bikou) {
        return true;
      }
      return false;
    };
    const prepareOrigValues = (formObj: BoardEntryEdit): void => {
      state.editingBoardEntryOrig = (Object.assign({}, formObj) as BoardEntryEdit);
      const now = new Date();
      state.editingBoardEntryOrig.date = dtFormat(formObj.timestamp ?? now, 'yyyy/mm/dd');
      state.editingBoardEntryOrig.time = dtFormat(formObj.timestamp ?? now, 'HH:MM');
    };
    const prevClick = (): void => {
      if (state.filteredTabMinIdx === 0) { return; }
      state.filteredTabMinIdx = state.filteredTabMinIdx - 1;
    };
    const nextClick = (): void => {
      if (state.filteredTabMinIdx >= state.boardTabs.length - state.maxFilteredTabCount) {
        return;
      }
      state.filteredTabMinIdx = state.filteredTabMinIdx + 1;
    };
    const isEditable = (item: BoardEntryEdit): boolean => {
      if (isSuperAdmin.value || isReplyRole.value) {
        return true;
      }
      return userState.id === item.createdBy;
    };
    const isDeletable = (item: BoardEntryEdit): boolean => {
      if (isSuperAdmin.value) {
        return true;
      }
      return userState.id === item.createdBy;
    };
    onUnmounted(() => {
      window.removeEventListener('resize', state.onResizeFunc);
    });
    return {
      ...toRefs(state),
      LONG_TEXT_MAX_LENGTH,
      tabBar,
      modalDialog,
      modalHeader,
      modalFooter,
      johaisetsuRoleMap,
      // computed
      isSuperAdmin,
      shouldShowEditModal,
      shouldShowDeleteModal,
      currentBoardTab,
      isHonbuBoard,
      isMaintenanceBoard,
      isKyokuUser,
      boardTabDepartmentDisp,
      isCreatableRole,
      isReplyRole,
      filteredBoardTabs,
      beforeFilteredBoardTabs,
      afterFilteredBoardTabs,
      paginatedBoardEntries,
      modalBodyMaxHeight,
      // methods
      reloadBoardEntries,
      onClickTabListItem,
      onChangeBoardEntryDate,
      onChangeBoardEntryTime,
      editBoardEntry,
      tryCancelEditBoardEntry,
      cancelEditBoardEntry,
      saveBoardEntry,
      tryDeleteBoardEntry,
      deleteBoardEntry,
      isReadyToSubmit,
      checkInputs,
      isEdited,
      prevClick,
      nextClick,
      isEditable,
      isDeletable,
      dtFormat,
    };
  },
});
