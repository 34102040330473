import { Ref, ref, onMounted } from '@vue/composition-api';
export interface SpeechResult {
  speechSynthesisVoices : Ref<SpeechSynthesisVoice[] | undefined>;
  doSpeech: (text: string) => void;
}
export default function useSpeech(): SpeechResult {
  const speechSynthesisVoices = ref<SpeechSynthesisVoice[]>();

  function loadSpeechSynthesisVoices(): void {
    speechSynthesisVoices.value = window.speechSynthesis.getVoices();
  }
  const doSpeech = (text: string, lang = 'ja-JP') => {
    const voiceGuidance = new SpeechSynthesisUtterance();
    const voice = _getSpeechSynthesisVoice(lang);
    if (voice) {
      voiceGuidance.voice = voice;
    } else {
      voiceGuidance.lang = lang;
    }
    voiceGuidance.text = text;
    window.speechSynthesis.speak(voiceGuidance);
  };
  function _getSpeechSynthesisVoice(lang: string) {
    return speechSynthesisVoices.value?.find(e => e.lang === lang);
  }

  onMounted(() => {
    loadSpeechSynthesisVoices();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      // Chromeの場合
      window.speechSynthesis.onvoiceschanged = () => {
        loadSpeechSynthesisVoices();
      };
    }
  });

  return { speechSynthesisVoices, doSpeech };
}
