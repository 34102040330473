




































































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  PropType,
} from '@vue/composition-api';
import Vue from 'vue';
import cyzenApi from '@/apis/cyzen';

import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { dtFormat } from '@/lib/dateHelper';
import { HistoryGroup, HistoryExt } from '@/lib/ExtremeMapDebugCyzenLayerManager';

interface SearchParams {
  dt: string;
}
interface TopDebugComponentTool2State {
  search: SearchParams;
  disp: Record<string, boolean>;
  checkAll: boolean;
  historyGroups: HistoryGroup[];
  errorMsg: string;
}
export default defineComponent({
  name: 'top-debug-component-tool2',
  props: {
    refExtremeMap: {
      type: Object as PropType<InstanceType<typeof ExtremeMap>>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<TopDebugComponentTool2State>({
      search: {
        dt: '',
      },
      disp: {},
      checkAll: false,
      historyGroups: [],
      errorMsg: '',
    });

    onMounted(() => {
      if (Vue.prototype.$isDevelopment()) {
        state.search.dt = '2018-01-22';
      } else {
        state.search.dt = dtFormat(new Date(), 'yyyy-mm-dd');
      }
    });

    const showCyzenData = async() => {
      const search = state.search;
      if (!search.dt) { return; }
      state.errorMsg = '';
      state.historyGroups = [];

      const dtReg = /^\d{4}-\d{2}-\d{2}$/;
      if (!search.dt.match(dtReg)) {
        state.errorMsg = '日付はyyyy-mm-ddで指定してください';
        return;
      }

      state.disp = {};

      const startDt = new Date(search.dt + 'T00:00:00');
      const startTs = dtFormat(startDt, 'yyyy-mm-dd HH:MM:SS');
      const endDt = new Date(startDt.valueOf() + 86400 * 1000);
      const endTs = dtFormat(endDt, 'yyyy-mm-dd HH:MM:SS');
      let result;
      try {
        result = await cyzenApi.adminGetRawHistories({
          start_ts: startTs,
          end_ts: endTs,
        });
        result = result.data;
      } catch (e: any) {
        let msg = `status:${e.response.status}, `;
        msg += `statusText:${e.response.statusText}, `;
        msg += `data:${JSON.stringify(e.response.data)}`;
        state.errorMsg = msg;
        return;
      }
      if (result.histories.length === 0) {
        state.errorMsg = 'データが見つかりませんでした';
        return;
      }

      const historyGroupMap: Record<string, HistoryExt[]> = {};
      result.histories.forEach(e => {
        const history = {
          ...e,
          kp: (e.kp !== null ? parseFloat(e.kp) : 0),
          lat: parseFloat(e.lat),
          lon: parseFloat(e.lon),
        };
        if (!historyGroupMap[e.history_group_id]) {
          historyGroupMap[e.history_group_id] = [];
        }
        historyGroupMap[e.history_group_id].push(history);
      });
      state.historyGroups =
        Object.entries(historyGroupMap).map((ent, i) => {
          const points = ent[1];
          const ret: HistoryGroup = {
            groupNumDisp: `#${i + 1}`,
            historyGroupId: points[0].history_group_id,
            statusType: points[0].status_type,
            startTs: new Date(points[0].ts),
            endTs: new Date(points[points.length - 1].ts),
            points: points,
          };
          ret.workTimeDisp = getWorkTimeDisp(ret.startTs, ret.endTs);
          ret.kpsDisp = getKpsDisp(ret.points);
          return ret;
        });
      state.historyGroups.forEach((e) => {
        state.disp[e.historyGroupId] = true;
      });
      state.checkAll = true;
      refreshCyzenLayer();
    };
    const getWorkTimeDisp = (startTs: Date, endTs: Date) => {
      const seconds = (endTs.getTime() - startTs.getTime()) / 1000;
      if (seconds < 60) {
        return `${seconds}秒`;
      } else if (seconds < 3600) {
        const m = parseInt((seconds / 60).toString());
        const s = seconds % 60;
        return `${m}分${s}秒`;
      } else {
        const h = parseInt((seconds / 3600).toString());
        const rest = seconds - h * 3600;
        const m = parseInt((rest / 60).toString());
        const s = rest % 60;
        return `${h}時間${m}分${s}秒`;
      }
    };
    const getKpsDisp = (points: HistoryExt[]) => {
      return '(0) → ' + points.map((pt, i) => {
        const num = i + 1;
        let kpDisp = '';
        if (!pt.is_calculated) {
          kpDisp = '(...)';
        } else if (!pt.bikou1) {
          kpDisp = '(KPなし)';
        } else if (pt.bikou1.indexOf('EMPTY') !== -1) {
          kpDisp = '(KPなし)';
        } else {
          // 高速4号新宿線#上 9.70-5.83 などと入っている
          kpDisp = pt.bikou1.replace(/高速/g, '');
        }
        return `${kpDisp} → (${num})`;
      }).join(' → ');
    };
    const refreshCyzenLayer = () => {
      const historyGroups: HistoryGroup[] = [];
      state.historyGroups.forEach(hg => {
        if (state.disp[hg.historyGroupId]) {
          historyGroups.push(hg);
        }
      });
      removeCyzenData();
      props.refExtremeMap.showDebugCyzenLayer({
        historyGroups: historyGroups,
      });
    };
    const removeCyzenData = () => {
      props.refExtremeMap.removeDebugCyzenLayer();
    };
    const changeDisp = () => {
      refreshCyzenLayer();
    };
    const changeCheckAll = () => {
      const flg = state.checkAll;
      for (const k in state.disp) {
        state.disp[k] = flg;
      }
      refreshCyzenLayer();
    };
    return {
      ...toRefs(state),
      // methods
      showCyzenData,
      getWorkTimeDisp,
      getKpsDisp,
      refreshCyzenLayer,
      removeCyzenData,
      changeDisp,
      changeCheckAll,
      dtFormat,
    };
  },
});
