import {
  Style,
  Fill,
  Stroke,
  Text,
  RegularShape,
} from 'ol/style';
import { Feature } from 'ol';

import { Point, Polygon } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { Movie } from '@/models/apis/movie/movieResponse';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';

interface Opts {
  showWeak?: boolean;
  color?: number[];
  showText?: boolean;
}
export interface QueryArea {
  lonMin: number;
  latMin: number;
  lonMax: number;
  latMax: number;
}
interface MoviePointFeatureParams {
  ts: Date;
  lon: string;
  lat: string;
}

export default class ExtremeMapMovieLayerManager extends ExtremeMapAbstractLayerManager {
  opts: Opts;
  queryAreaFeat: Feature | null;
  constructor(opts: Opts = {}) {
    super();
    this.layerInfo.onLayerClick = () => {};
    this.opts = opts;
    this.queryAreaFeat = null;
  }

  formatDt(dt: Date): string {
    // const dt = new Date(inDt)
    const y = dt.getFullYear();
    const mon = ('0' + (dt.getMonth() + 1)).slice(-2);
    const d = ('0' + dt.getDate()).slice(-2);
    const h = ('0' + dt.getHours()).slice(-2);
    const min = ('0' + dt.getMinutes()).slice(-2);
    const s = ('0' + dt.getSeconds()).slice(-2);
    const ts = `${y}-${mon}-${d} ${h}:${min}:${s}`;
    return ts;
  }

  getMoviePointFeature_({ ts, lon, lat }: MoviePointFeatureParams, opts: Opts): Feature {
    const shapeFillColor = opts.color ? [...opts.color.slice()] : [];
    const shapeStrokeColor = [0, 0, 0];
    const textFillColor = [238, 238, 238];
    const textStrokeColor = [50, 50, 50, 0.8];
    if (opts.showWeak) {
      shapeFillColor.push(0.5);
      shapeStrokeColor.push(0.5);
      textFillColor.push(0.5);
    }

    const coord = this.convCoord({
      lon: parseFloat(lon),
      lat: parseFloat(lat),
    });
    const feat = new Feature({
      geometry: new Point(coord),
    });
    const style = new Style({
      image: new RegularShape({
        fill: new Fill({
          color: shapeFillColor,
        }),
        stroke: new Stroke({
          color: shapeStrokeColor,
          width: 1,
        }),
        points: 4,
        radius: 4,
        angle: Math.PI / 4,
      }),
    });
    if (opts.showText) {
      const todayTsStr = this.formatDt(new Date());
      const tsStr = this.formatDt(ts);
      const isSameDate = tsStr.slice(0, 10) === todayTsStr.slice(0, 10);

      const text = new Text({
        text: isSameDate ? tsStr.slice(-8) : tsStr,
        font: '8px sans-serif',
        fill: new Fill({
          color: textFillColor,
        }),
        stroke: new Stroke({
          color: textStrokeColor,
          width: 3,
        }),
        offsetX: 0,
        offsetY: 10,
      });
      style.setText(text);
    }
    feat.setStyle(style);
    return feat;
  }

  getMoviePointFeatures_(movie: Movie): Feature[] {
    const feats: Feature[] = [];
    const startTs = new Date(movie.start_ts);
    const color = movie.color;
    const mgiLen = movie.movie_geo_indices.length;
    let prevShowWeak = true;
    movie.movie_geo_indices.forEach((mgi, i) => {
      const ts = new Date(startTs.valueOf() + mgi.ts_msec_diff);
      let showText = i === 0 || i === mgiLen - 1;
      const showWeak = mgi.ts_msec_diff < movie.playStartMsec;
      // ちょうど開始位置のmgiのとこに時刻打ちたい
      if (prevShowWeak && !showWeak) {
        showText = true;
      }
      feats.push(this.getMoviePointFeature_({
        ts: ts,
        lon: mgi.lon,
        lat: mgi.lat,
      }, {
        color,
        showText,
        showWeak,
      }));
      prevShowWeak = showWeak;
    });
    return feats;
  }

  createMoviePointLayer_(movies: Movie[]): void {
    const feats: Feature[] = [];
    movies.forEach(movie => {
      feats.push(...this.getMoviePointFeatures_(movie));
    });
    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: feats}),
    });
    layer.name = 'movies';
    this.layer = layer;
  }

  prepareLayer(movies: Movie[]): LayerWithInfo {
    this.createMoviePointLayer_(movies);
    return this.getLayer();
  }

  getQueryAreaFeature_(qArea: QueryArea): Feature {
    const pt1 = this.convCoord({ lon: qArea.lonMin, lat: qArea.latMin });
    const pt2 = this.convCoord({ lon: qArea.lonMin, lat: qArea.latMax });
    const pt3 = this.convCoord({ lon: qArea.lonMax, lat: qArea.latMax });
    const pt4 = this.convCoord({ lon: qArea.lonMax, lat: qArea.latMin });
    const ring = [pt1, pt2, pt3, pt4, pt1];
    const feat = new Feature({
      geometry: new Polygon([ring]),
    });
    feat.setId('queryArea');
    feat.setStyle(new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.4)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 97, 255, 0.8)',
        width: 2,
      }),
    }));
    return feat;
  }

  showMovieQueryArea(queryArea: QueryArea): void {
    this.hideMovieQueryArea();
    this.queryAreaFeat = this.getQueryAreaFeature_(queryArea);
    if (this.layer) {
      this.layer.getSource().addFeature(this.queryAreaFeat);
    }
  }

  hideMovieQueryArea(): void {
    if (this.queryAreaFeat && this.layer) {
      // vectorSourceが空になってるかもしれない
      // (まぁ横着してるからなんだけど...)
      const queryAreaFeatId = this.queryAreaFeat.getId();
      if (queryAreaFeatId) {
        const feat = this.layer.getSource().getFeatureById(queryAreaFeatId);
        if (feat) {
          this.layer.getSource().removeFeature(this.queryAreaFeat);
        }
      }
      this.queryAreaFeat = null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshLayerOnZoomChange({ zoom, oldZoom }: { zoom?: number; oldZoom?: number }): void {
    // nothing to do
  }
}
