var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('nav',{staticClass:"navbar navbar-expand-xl navbar-dark bg-primary"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":{name: _vm.logoRouteName}}},[_vm._v(" リアルタイム動画配信 "),(_vm.$isDevelopment())?_c('span',{staticClass:"badge badge-pill badge-secondary env dev"},[_vm._v("dev")]):_vm._e(),(_vm.$isStaging())?_c('span',{staticClass:"badge badge-pill badge-secondary env stg"},[_vm._v("stg")]):_vm._e()]),_vm._m(0),_c('div',{ref:"navbarCollapse",staticClass:"collapse navbar-collapse",attrs:{"id":"navbar-nav-items"}},[_c('ul',{staticClass:"navbar-nav mr-auto",on:{"click":_vm.onNavVarClick}},[(!_vm.isJohaisetsuOnly && !_vm.isCleaningOnly)?[_c('li',{staticClass:"nav-item",class:{active: _vm.routeName === 'Top'}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'Top'}}},[_vm._v(" 全体 ")])],1),_c('li',{staticClass:"nav-item",class:{active: _vm.routeName === 'Route'}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'Route'}}},[_vm._v(" 路線別 ")])],1)]:_vm._e(),(_vm.canJohaisetsuMessageBoard)?[_c('li',{staticClass:"nav-item",class:{active: _vm.routeName === 'SettouMessageBoard'}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'SettouMessageBoard'}}},[_vm._v(" 掲示板 ")])],1)]:_vm._e(),(_vm.canUseJohaisetsuMap)?[_c('li',{staticClass:"nav-item",class:{active: _vm.routeName === 'JohaisetsuMap'}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'JohaisetsuMap'}}},[_vm._v(" 雪凍対応地図 ")])],1)]:_vm._e(),(!_vm.canUseJohaisetsuKanri && _vm.canUseJohaisetsuMap)?[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
              name: 'JohaisetsuCompanyHanManagement',
              params: { taskForceId: 'current', headerId: 'current' },
            }}},[_vm._v(" 作業班管理 ")])]:_vm._e(),(_vm.canUseJohaisetsuKanri)?[_c('li',{staticClass:"nav-item dropdown",class:{active: _vm.isActiveJohaisetsuKanri}},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("雪凍対応機能")]),_c('ul',{ref:"navbarDropdownMenu1",staticClass:"dropdown-menu dropright"},[_c('li',{staticClass:"dropdown-submenu"},[_c('a',{staticClass:"dropdown-item dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#","aria-haspopup":"true","aria-expanded":"false"},on:{"click":_vm.subMenuOnclick}},[_vm._v("雪凍対応管理")]),_c('div',{ref:"navbarDropdownMenu1Sub",staticClass:"dropdown-menu"},[(_vm.isJohaisetsuKanriRoleHonsha)?[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                        name: 'JohaisetsuTaskForce'
                      }}},[_vm._v(" 対策本部管理 ")]),_c('div',{staticClass:"dropdown-divider"})]:_vm._e(),(_vm.canUseJohaisetsuMap)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                        name: 'JohaisetsuCompanyHanManagement',
                        params: { taskForceId: 'current', headerId: 'current' },
                      }}},[_vm._v(" 作業班管理 ")]):_vm._e(),(_vm.canUseJohaisetsuMap && _vm.canUseJohaisetsuWork)?_c('div',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.canUseJohaisetsuEnsuiJutenKaradanpuInput)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuEnsuiSanpuJoukyouInput',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 凍結防止剤散布状況入力（本線ルート散布） ")]):_vm._e(),(_vm.canUseJohaisetsuEnsuiJutenKaradanpuMap)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuEnsuiSanpuJoukyouMap',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 凍結防止剤散布状況図（本線ルート散布） ")]):_vm._e(),(_vm.canUseJohaisetsuEnsuiJutenKaradanpuInput)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuJutenSanpuJoukyouInput',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 凍結防止剤散布状況入力（出入口その他） ")]):_vm._e(),(_vm.canUseJohaisetsuEnsuiJutenKaradanpuMap)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuJutenSanpuJoukyouMap',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 凍結防止剤散布状況図（出入口その他） ")]):_vm._e(),_c('div',{staticClass:"dropdown-divider"}),(_vm.canUseJohaisetsuSekisetsuInput)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuSekisetsuInput',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 積雪量入力 ")]):_vm._e(),(_vm.canUseJohaisetsuHaisetsuKeisanInput)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuHaisetsuKeisanInput',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 排雪時間計算表 ")]):_vm._e(),(_vm.canUseJohaisetsuProgressTable)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuProgressTable',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 進捗管理表 ")]):_vm._e(),(_vm.canUseJohaisetsuSekisetsuInput || _vm.canUseJohaisetsuHaisetsuKeisanInput || _vm.canUseJohaisetsuProgressTable)?_c('div',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.canUseJohaisetsuSagyouJoukyouInput)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuSagyouJoukyouInput',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 除排雪作業状況入力 ")]):_vm._e(),(_vm.canUseJohaisetsuSagyouJoukyouMap)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                      name: 'JohaisetsuSagyouJoukyouMap',
                      params: { taskForceId: 'current', headerId: 'current' },
                    }}},[_vm._v(" 除排雪作業状況地図 ")]):_vm._e()],2)]),(_vm.canUseJohaisetsuSettouPatrolReportList || _vm.canUseJohaisetsuEnsuiPlantStockTable)?_c('div',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.canUseJohaisetsuSettouPatrolReportList)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{name: 'SettouPatrolList'}}},[_vm._v(" 雪凍現場報告 ")]):_vm._e(),(_vm.canUseJohaisetsuEnsuiPlantStockTable)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{name: 'EnsuiPlantStockTable'}}},[_vm._v(" 塩水プラント在庫状況 ")]):_vm._e(),(_vm.canUseWeatherObservatoryDataPointsTable)?[_c('div',{staticClass:"dropdown-divider"}),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{name: 'WeatherObservatoryDataPointsTable'}}},[_vm._v(" 気象観測履歴 ")])]:_vm._e()],2)])]:_vm._e(),(_vm.canUseCleaningMap)?[_c('li',{staticClass:"nav-item",class:{active: _vm.routeName === 'CleaningMap'}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name: 'CleaningMap'}}},[_vm._v(" 清掃管理 ")])],1)]:_vm._e(),(_vm.isAdmin)?[_c('li',{staticClass:"nav-item",class:{active: _vm.isAdminPageGroup}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name:'AdminTop'}}},[_vm._v(" 管理画面 ")])],1)]:_vm._e(),(_vm.isSuperAdmin)?[_c('li',{staticClass:"nav-item",class:{active: _vm.isDevDebugPageGroup}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{name:'DevDebugTop'}}},[_vm._v(" 開発者用画面 ")])],1)]:_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],staticClass:"form-inline my-2 my-lg-0 nav-right-side"},[_c('span',{staticClass:"user-display-name"},[_vm._v(" ユーザー名: "+_vm._s(_vm.displayName)+" ")]),_c('a',{staticClass:"logout ml12",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('span',[_vm._v("ログアウト")])])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbar-nav-items","aria-controls":"navbar-nav-items","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }