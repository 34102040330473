import { AxiosError } from 'axios';

interface ErrorResponse {
  errors: Record<string, string[]>;
}

export interface GetErrorResponseResult {
  msgs: string[];
  keys: {[key in string]: boolean};
}

function getErrObjects(err : AxiosError): GetErrorResponseResult {
  const response = err.response;
  if (!response || !response.data) {
    return {msgs: [], keys: {}};
  }
  if (response.status === 200) {
    return {msgs: [], keys: {}};
  }

  const retMsgs: string[] = [];
  const retKeys: {[key:string]: boolean} = {};

  const errMap = (response.data as ErrorResponse).errors || {};
  Object.keys(errMap).forEach(key => {
    retKeys[key] = true;
    errMap[key].forEach(msg => {
      retMsgs.push(msg);
    });
  });

  return {msgs: retMsgs, keys: retKeys};
}

export function getErrorMessages(err : AxiosError): string[] {
  const result = getErrObjects(err);
  if (result.msgs.length === 0 && err.response?.data) {
    if (Array.isArray(err.response.data)) {
      return err.response.data as string[];
    }
    if (err.response.data.error) {
      return [err.response.data.error] as string[];
    }
  }
  return result.msgs;
}
