import { reactive } from '@vue/composition-api';

import { PaneState } from '@/models/cleaningMap';

interface UsePaneControlParams {
  paneStyleLimitMap?: {
    listMinHeightRate: number;
    listMaxHeightRate: number;
  };
}
interface UsePaneControlResult {
  paneState: PaneState;
  onChangeShowCarIcons: () => void;
  onChangeShowCleaningPhotoIcons: () => void;
  onChangeShowDefectPhotoIcons: () => void;
}

export default function usePaneControl(params: UsePaneControlParams): UsePaneControlResult {
  const paneState = reactive<PaneState>({
    showCarIcons: true,
    showCleaningPhotoIcons: true,
    showDefectPhotoIcons: true,
    paneStyleLimitMap: {
      listMinHeightRate: params.paneStyleLimitMap?.listMinHeightRate || 0,
      listMaxHeightRate: params.paneStyleLimitMap?.listMaxHeightRate || 0,
    },
  });

  const onChangeShowCarIcons = () => {
    paneState.showCarIcons = !paneState.showCarIcons;
  };
  const onChangeShowCleaningPhotoIcons = () => {
    paneState.showCleaningPhotoIcons = !paneState.showCleaningPhotoIcons;
  };
  const onChangeShowDefectPhotoIcons = () => {
    paneState.showDefectPhotoIcons = !paneState.showDefectPhotoIcons;
  };

  return {
    paneState,
    onChangeShowCarIcons,
    onChangeShowCleaningPhotoIcons,
    onChangeShowDefectPhotoIcons,
  };
}
