import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { GetCarsParams } from '@/models/apis/cars/carRequest';
import { GetCarsResponse } from '@/models/apis/cars/carResponse';

export default {
  getCars(params?: GetCarsParams): Promise<AxiosResponse<GetCarsResponse>> {
    return axios.get(utils.getApiUrl('/cars'), {
      params: params || {},
    });
  },
};
