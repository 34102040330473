import { isRoleHonsha, isRoleKyoku, isRoleDriver } from '@/lib/roleHelper';

export function isJohaisetsuRoleHonsha(johaisetsuRole: string | null): boolean {
  return isRoleHonsha(johaisetsuRole);
}
export function isJohaisetsuRoleKyoku(johaisetsuRole: string | null): boolean {
  return isRoleKyoku(johaisetsuRole);
}
export function isJohaisetsuRoleDriver(johaisetsuRole: string | null): boolean {
  return isRoleDriver(johaisetsuRole);
}

const JOHAISETSU_ONLY_ROLE = [
  'shien_hozen1_west',
  'shien_hozen2_west',
  'shien_shisetukansei_west',
  'shien_saigaikyoutei_1_west',
  'shien_saigaikyoutei_2_west',
  'shien_saigaikyoutei_3_west',
  'shien_saigaikyoutei_4_west',
  'shien_saigaikyoutei_5_west',
  'shien_other_1_west',
  'shien_other_2_west',
  'shien_saigaikyoutei_1_east',
  'shien_saigaikyoutei_2_east',
  'shien_saigaikyoutei_3_east',
  'shien_saigaikyoutei_4_east',
  'shien_saigaikyoutei_5_east',
  'shien_saigaikyoutei_kanagawa',
  'shien_doboku_kanagawa',
  'shien_shisetu_kanagawa',
];
export function shouldForceJohaisetsuOnly(johaisetsuRole: string): boolean {
  return JOHAISETSU_ONLY_ROLE.indexOf(johaisetsuRole) >= 0;
}

export function getKyokuIdByJohaisetsuRole(johaisetsuRole: string): string {
  if (johaisetsuRole.endsWith('west')) {
    return 't-west';
  }
  if (johaisetsuRole.endsWith('east')) {
    return 't-east';
  }
  if (johaisetsuRole.endsWith('kanagawa')) {
    return 'kanagawa';
  }
  return johaisetsuRole;
}
