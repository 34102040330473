import cleaningMtxApi from '@/apis/cleaning_mtx';
import { CleaningCarsGetResponse } from '@/models/apis/cleaning/cleaningCarResponse';
import { CleaningCarExt } from '@/models';
import { CleaningReport, CleaningReportExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningCompany } from '@/models/apis/cleaning/cleaningCommon';
import { CarInfo, CleaningArea, SearchConds } from '@/models/cleaningMap';
import { Lov } from '@/models/apis/master/masterResponse';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';

const CAR_WORKING_STATUS_DISP = '清掃中';
const CAR_MOVING_STATUS_DISP = '移動中';
const maxDateRange = 31;
const oneDayMsec = 1000 * 60 * 60 * 24;

interface ConvDataParams {
  cars: CleaningCarsGetResponse;
  currentCarInfoMap: Record<string, CarInfo>;
  carKindMap: Record<string, Lov>;
  cleaningCompanies: CleaningCompany[];
}
export interface CurrentReportItemInfo {
  selectedPhotoId: number;
  selectedMtxTs: string | Date;
  savedImageMap: Record<number, string>;
  loadedMtxs: CleaningMtxExt[];
}

export function initSearchConds(areaChoices: CleaningArea[], cleaningCompanies: CleaningCompany[]): SearchConds {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return {
    cleaningArea: areaChoices.length === 1 ? areaChoices[0].name : null,
    cleaningCompanyId: cleaningCompanies.length === 1 ? cleaningCompanies[0].id : null,
    cleaningHanName: null,
    status: '全て',
    dateFrom: new Date(now.getTime() - oneDayMsec * maxDateRange),
    dateTo: now,
  };
}

export function validSearchDateRange(searchConds: SearchConds): boolean {
  return searchConds.dateFrom.getTime() <= searchConds.dateTo.getTime() &&
    (Math.abs(searchConds.dateTo.getTime() - searchConds.dateFrom.getTime()) < (maxDateRange + 1) * oneDayMsec);
}

export function createInitialCarsData(recentData: CleaningCarsGetResponse): CleaningCarExt[] {
  return recentData.map(car => {
    return {
      ...car,
      isMoving: false,
      isWorking: false,
      shouldShow: true,
      isSelected: false,
      pos: null,
      cleaningCompanyName: '',
      carKindDisp: '',
      reportExt: null,
    };
  });
}

export function convData(params: ConvDataParams): CleaningCarExt[] {
  const carsExt = params.cars.map(car => {
    const carKind = params.carKindMap[car.car_kind] || {};

    const carKindDisp = carKind.val || '';
    let reportExt: CleaningReportExt | null = null;
    const tmpCar = params.currentCarInfoMap[car.id];
    if (car.report) {
      reportExt = convReport(
        car.report,
        params.cleaningCompanies,
        {
          selectedPhotoId: tmpCar.selectedPhotoId,
          selectedMtxTs: tmpCar.selectedMtxTs,
          savedImageMap: tmpCar.savedImageMap,
          loadedMtxs: tmpCar.loadedMtxs,
        },
      );
    }
    const cleaningCompanyName = params.cleaningCompanies.find(e => e.id === car.cleaning_company_id)?.name ?? '';
    const shouldShow = true;
    const isWorking = car.status_disp === CAR_WORKING_STATUS_DISP;
    // ts_is_newがfalseの場合は、停止中とみなす
    const isMoving = car.ts_is_new && (isWorking || car.status_disp === CAR_MOVING_STATUS_DISP);
    const isSelected = tmpCar?.isSelected ?? false;

    // posはLayerManagerで変換して設定する
    return {
      ...car,
      isMoving,
      isWorking,
      shouldShow,
      isSelected,
      pos: null,
      cleaningCompanyName,
      carKindDisp,
      reportExt,
    };
  });
  return carsExt;
}

export function shouldShowCar(
  car: CleaningCarExt,
  decidedSearchConds: SearchConds,
  cleaningCompanyAreaMap: Record<string, number[]>,
): boolean {
  // 検索条件に照らして車を表示すべきか判定
  if (decidedSearchConds.cleaningArea) {
    const cleaningCompanyIds = cleaningCompanyAreaMap[decidedSearchConds.cleaningArea];
    if (!cleaningCompanyIds.includes(car.cleaning_company_id ?? 0)) {
      return false;
    }
  }
  if (decidedSearchConds.cleaningCompanyId &&
    car.cleaning_company_id !== decidedSearchConds.cleaningCompanyId) {
    return false;
  }
  if (decidedSearchConds.cleaningHanName &&
    car.cleaning_han_name !== decidedSearchConds.cleaningHanName) {
    return false;
  }
  if (decidedSearchConds.status !== '' && decidedSearchConds.status !== '全て') {
    if (car.status_disp !== decidedSearchConds.status) {
      return false;
    }
  }

  return true;
}

export const convReport = (
  report: CleaningReport,
  cleaningCompanies: CleaningCompany[],
  currentReportItemInfo?: CurrentReportItemInfo,
): CleaningReportExt => {
  const cleaningCompanyName = cleaningCompanies.find(e =>
    e.id === report.cleaning_company_id,
  )?.name ?? '';
  const contentsDisp = report.other_content
    ? report.contents.replace('その他', `その他(${report.other_content})`)
    : report.contents;
  const loadedMtxs = currentReportItemInfo?.loadedMtxs || [];
  const reportExt: CleaningReportExt = {
    ...report,
    cleaningCompanyName,
    contentsDisp,
    photosExt: report.photos.map(photo => ({
      ...photo,
      isSelected: currentReportItemInfo?.selectedPhotoId === photo.id,
      cleaningCompanyName,
      cleaningHanName: report.cleaning_han_name,
      reportContents: contentsDisp,
      reportRoadNames: report.road_names,
      savedImage: null,
      samePosPhotos: [],
    })),
    mtxsExt: loadedMtxs,
    isSelected: false,
    isMtxsLoaded: loadedMtxs.length > 0,
  };
  reportExt.photosExt.forEach(async(photo) => {
    // 取得済みの画像は再取得しない
    photo.savedImage = currentReportItemInfo?.savedImageMap[photo.id] || null;
    photo.samePosPhotos = reportExt.photosExt.filter(e => e.lat === photo.lat && e.lon === photo.lon);
  });
  return reportExt;
};

export const loadCleaningMtxs = async(reports: CleaningReportExt[], maxMtxs: number): Promise<void> => {
  const { data: mtxs } = await cleaningMtxApi.getCleaningMtxs({
    cleaningReportIds: reports.map(report => report.id),
    maxMtxs,
  });
  reports.forEach(report => {
    if (!mtxs[report.id]) return;
    report.mtxsExt = mtxs[report.id].map(mtx => {
      return {
        ...mtx,
        isSelected: false,
        cleaningCompanyName: report.cleaningCompanyName,
        cleaningHanName: report.cleaning_han_name,
        reportContents: report.contentsDisp,
        reportRoadNames: report.road_names,
      };
    });
    report.isMtxsLoaded = true;
  });
};

export const deselectReportItems = (report: CleaningReportExt): void => {
  report.photosExt.forEach(photo => {
    photo.isSelected = false;
  });
  report.mtxsExt.forEach(mtx => {
    mtx.isSelected = false;
  });
};

export const convSearchParamTsTo = (dateTo: Date): Date => {
  return new Date(dateTo.getTime() + oneDayMsec - 1);
};
