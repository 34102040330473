import { CleaningMasterData } from '@/models/cleaningCommon';
import cleaningMasterApi from '@/apis/cleaning_master';

function loadCleaningMasters(): void {
  if (window.cleaningMaster) { return; }
  const prms = cleaningMasterApi.getAll().then(({ data }) => {
    const masterData: CleaningMasterData = {
      cleaningKinds: data.cleaning_kinds,
      cleaningCompanies: data.cleaning_companies,
    };

    const prms = window.cleaningMaster.$promise;
    window.cleaningMaster = {
      $promise: prms,
      ...masterData,
    };
  });
  window.cleaningMaster = { $promise: prms } as any;
}

export async function waitForCleaningMasters(): Promise<void> {
  loadCleaningMasters();
  await window.cleaningMaster.$promise;
}
