










import {
  defineComponent,
} from '@vue/composition-api';

import { DispDataAtypical } from '@/models/geoItem';
import FrozenHelper from '@/lib/geo_item/frozenHelper';

export default defineComponent({
  name: 'legend-frozen',
  setup() {
    const frozenHelper = new FrozenHelper();
    const dispData: DispDataAtypical[] = [
      { val: false, label: 'なし' },
      { val: true, label: 'あり' },
    ];
    dispData.forEach(e => {
      e.color = frozenHelper.objKeyToColor(frozenHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
