






















import MapElemInfoComment from './MapElemInfoComment/index.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { GeoItemMeta } from '@/models/geoItem';
import { Comment } from '@/models/apis/comment/commentResponse';

interface MapElemInfoCommentElement extends MapElemInfo<Comment> {
  candidateImages: Blob[];
}

interface MapElemInfoNewState {
  selectedDataType: string;
  elemConv: MapElemInfoCommentElement | null;
}

export default defineComponent({
  name: 'map-elem-info-new',
  props: {
    elem: {
      type: Object as PropType<MapElemInfoCommentElement>,
      required: true,
    },
    visibleLayers: {
      type: Array as PropType<GeoItemMeta[]>,
      default: () => { return []; },
    },
    forceShowCancelButton: {
      type: Boolean,
      default: false,
    },
    createFailed: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoNewState>({
      selectedDataType: '',
      elemConv: null,
    });

    const initDisplay = () => {
      const creatableLayers = props.visibleLayers.filter(e => e.isCreatable);
      state.selectedDataType = creatableLayers.length > 0 ? creatableLayers[0].name : '';
      if (props.elem) {
        state.elemConv = {
          dataName: state.selectedDataType,
          data: props.elem.data,
          candidateImages: props.elem.candidateImages,
        };
      }
    };

    onMounted(initDisplay);

    watch(() => props.elem, initDisplay);
    watch(() => props.visibleLayers, () => {
      const arr = props.visibleLayers.map(e => e.name);
      if (!arr.includes(state.selectedDataType)) {
        initDisplay();
      }
    });

    const currentElemInfoComponent = computed(() => {
      if (!state.elemConv) { return ''; }
      return 'map-elem-info-' + state.elemConv.dataName;
    });
    const selectableDataTypes = computed(() => {
      return props.visibleLayers.filter(e => e.isCreatable).map(e => {
        return { name: e.name, dispName: e.dispName };
      });
    });

    const onChildComponentUpdate = (obj: MapElemInfoCommentElement) => {
      // イベント種別を変換して投げ直す
      emit('create', obj);
    };

    return {
      ...toRefs(state),
      // computeds
      currentElemInfoComponent,
      selectableDataTypes,
      // methods
      onChildComponentUpdate,
    };
  },
  components: {
    MapElemInfoComment,
  },
});
