import View, { AnimationOptions } from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { Feature, Map as OlMap } from 'ol';
import { Style, Icon } from 'ol/style';
import { fromLonLat, get, transformExtent } from 'ol/proj';
import { boundingExtent, Extent } from 'ol/extent';
import * as control from 'ol/control';
import * as interaction from 'ol/interaction';
import { Interaction } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { NamedLayer, NamedLayerGroup } from 'src/lib/OlMapWrapper';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FeatureLike } from 'ol/Feature';

export interface FeatureExt extends Feature {
  onClickFunc: () => void;
}
export type LayerEventHandler = ({ event, feature }: { event?: MapBrowserEvent; feature?: FeatureLike }) => void;

interface AddViewParams {
  center?: Coordinate;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
}

export interface AddLayerParams {
  insertAt?: number;
  interaction?: Record<string, LayerEventHandler>;
}

export interface InitMapParams extends AddViewParams {
  element: string;
  enableAttribution?: boolean;
  enableZoomButton?: boolean;
  enableScaleLine?: boolean;
  enablePan?: boolean;
  enableMouseWheelZoom?: boolean;
  enableDoubleClickZoom?: boolean;
}

interface MarkerSetting {
  id: string;
  layerName: string;
  coord: Coordinate;
  anchor?: number[];
  icon?: string;
}

interface ExtOlMap extends OlMap {
  layers?: Record<string, NamedLayer | NamedLayerGroup>;
  markers?: Record<string, VectorLayer>;
}

export default class OlMapManager {
  map: ExtOlMap | null;
  private mapCallbacks: Record<string, Record<string, LayerEventHandler>>;
  view: View | null;

  constructor() {
    this.map = null;
    this.mapCallbacks = {
      click: {},
    };
    this.view = null;
  }

  getMap(): ExtOlMap | null {
    return this.map;
  }

  addMapInteraction(interaction: Interaction): void {
    if (!this.map) {
      return;
    }
    this.map.addInteraction(interaction);
  }

  addView(params: AddViewParams): View {
    this.view = new View({
      projection: 'EPSG:3857', // default
      center: params.center || [0, 0],
      zoom: params.zoom || 4,
      minZoom: params.minZoom || 4,
      maxZoom: params.maxZoom || 18,
    });
    return this.view;
  }

  getView(): View | null {
    return this.view;
  }

  getLayers(): Record<string, NamedLayer | NamedLayerGroup> | undefined {
    if (!this.map) {
      return undefined;
    }
    return this.map.layers;
  }

  getLayer(name: string): NamedLayer | NamedLayerGroup | null {
    if (!this.map || !this.map.layers) {
      return null;
    }
    return this.map.layers[name];
  }

  addLayer(layer: NamedLayer | NamedLayerGroup, opts: AddLayerParams = {}): NamedLayer | NamedLayerGroup | null {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined.');
      return null;
    }
    if (!layer.name) {
      console.warn('OlMapManager: Need layer name.');
      return null;
    }
    if (!this.map.layers) {
      return null;
    }

    this.removeLayer(layer.name);

    this.map.layers[layer.name] = layer;
    if (!opts.insertAt || isNaN(opts.insertAt)) {
      this.map.addLayer(layer);
    } else {
      this.map.getLayers().insertAt(opts.insertAt, layer);
    }
    if (opts.interaction) {
      this.addLayerInteraction(layer.name, opts.interaction);
    }

    this.updateSize();
    return layer;
  }

  removeLayer(name: string): void {
    if (!this.map || !this.map.layers || !this.map.layers[name]) {
      return;
    }
    this.map.removeLayer(this.map.layers[name]);
    delete this.map.layers[name];
    this.removeLayerInteraction(name);
  }

  isLayerVisible(name: string): boolean {
    if (!this.map || !this.map.layers) {
      return false;
    }
    return this.map.layers[name].getVisible();
  }

  setLayerVisible(name: string, isVisible: boolean): void {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return;
    }

    if (!this.map.layers || !this.map.layers[name]) {
      console.warn('OlMapManager: Layer undefined');
      return;
    }

    this.map.layers[name].setVisible(isVisible);
  }

  addLayerInteraction(layerName: string, params: Record<string, LayerEventHandler>): void {
    Object.keys(params).forEach(eventName => {
      if (!this.mapCallbacks[eventName]) {
        throw new Error(`Unsupported event name "${eventName}"` +
          ' given at addLayerInteraction');
      }
      this.mapCallbacks[eventName][layerName] = params[eventName];
    });
  }

  removeLayerInteraction(layerName: string): void {
    Object.keys(this.mapCallbacks).forEach(eventName => {
      delete this.mapCallbacks[eventName][layerName];
    });
  }

  getMarkerLayers(): Record<string, VectorLayer> | null {
    if (!this.map || !this.map.markers) {
      return null;
    }
    return this.map.markers;
  }

  getMarkerLayer(name: string): VectorLayer | null {
    if (!this.map || !this.map.markers) {
      return null;
    }
    return this.map.markers[name];
  }

  /* Add Marker Layer
  **
  ** Param
  **  - element (String)
  **  - name (String)
  */
  addMarkerLayer(name: string): VectorLayer | null {
    if (!this.map || !this.map.markers) {
      console.warn('OlMapManager: Map undefined');
      return null;
    }

    if (!this.map.markers[name]) {
      const layer = new VectorLayer({
        source: new VectorSource({features: []}),
      });

      this.map.markers[name] = layer;
      this.map.addLayer(layer);
    }

    this.updateSize();
    return this.map.markers[name];
  }

  setMarkerLayer(name: string): void {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return;
    }

    this.removeMarkerLayer(name);
    this.addMarkerLayer(name);
  }

  removeMarkerLayer(name: string): void {
    if (!this.map || !this.map.markers || !this.map.markers[name]) {
      return;
    }
    this.map.removeLayer(this.map.markers[name]);
    delete this.map.markers[name];
  }

  isMarkerLayerVisible(name: string): boolean {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return false;
    }
    if (!this.map.markers || !this.map.markers[name]) {
      console.warn('OlMapManager: Layer undefined');
      return false;
    }
    return this.map.markers[name].getVisible();
  }

  setMarkerLayerVisible(name: string, isVisible: boolean): void {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return;
    }
    if (!this.map.markers || !this.map.markers[name]) {
      console.warn('OlMapManager: Layer undefined');
      return;
    }

    this.map.markers[name].setVisible(isVisible);
  }

  getExtent(): Extent | undefined {
    return this.view?.calculateExtent(this.map?.getSize());
  }

  getZoom(): number | undefined {
    return this.view?.getZoom();
  }

  setZoom(to: number): void {
    if (!this.view) {
      console.warn('OlMapManager: View undefined');
      return;
    }

    this.view.setZoom(to);
  }

  getCenter(): Coordinate | undefined {
    if (!this.view) {
      return undefined;
    }
    return this.view.getCenter();
  }

  setCenter(to: Coordinate): void {
    if (!this.view) {
      console.warn('OlMapManager: View undefined');
      return;
    }

    this.view.setCenter(to);
  }

  updateSize(): void {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return;
    }
    this.map.updateSize();
  }

  /* Add Marker
  **
  ** Param
  ** - setting
  **   type = Object
  **   data =
  **   - id (String)
  **   - element (String)
  **   - layerName (String)
  **   - coord (Array)
  **   - anchor (Array)
  **   - icon (String)
  */
  addMarker(setting: MarkerSetting): void {
    if (!this.map) {
      console.warn('OlMapManager: Map undefined');
      return;
    }

    if (!this.map.markers || !this.map.markers[setting.layerName]) {
      console.warn('OlMapManager: Marker layer undefined');
      return;
    }

    const geom = new Point(fromLonLat(setting.coord));
    const feature = new Feature(geom);
    feature.setStyle([
      new Style({
        image: new Icon(({
          anchor: setting.anchor || [0.5, 0.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          opacity: 1,
          src: setting.icon || '',
        })),
      }),
    ]);
    feature.setId(setting.id);
    this.map.markers[setting.layerName].getSource().addFeature(feature);
  }

  animate(setting: AnimationOptions): void {
    if (!this.view) {
      return;
    }
    this.view.animate(setting);
  }

  fitPoints(point: Coordinate[]): void {
    if (!this.view) {
      return;
    }
    let ext = boundingExtent(point);
    ext = transformExtent(
      ext,
      get('EPSG:4326'),
      get('EPSG:3857'),
    );
    this.view.fit(ext, {duration: 2000});
  }

  /* Initialize Openlayers Maps
  **
  ** Param
  ** - setting
  **   type = Object
  **   data =
  **   - element (String)
  **   - center (Array)
  **   - zoom (Number)
  **   - enableAttribution (Boolean)
  **   - enablePan (Boolean)
  **   - enableZoomButton (Boolean)
  **   - enableMouseWheelZoom (Boolean)
  **   - enableDoubleClickZoom (Boolean)
  **   - enableScaleLine (Boolean)
  */
  initMap(params: InitMapParams): ExtOlMap | null {
    if (this.map) {
      console.warn('OlMapManager: Map already exists');
      return null;
    }

    let controls = control.defaults({
      attribution: !!params.enableAttribution,
      zoom: !!params.enableZoomButton,
    });

    if (params.enableScaleLine) {
      controls = controls.extend([
        new control.ScaleLine(),
      ]);
    }

    this.addView(params);

    this.map = new OlMap({
      layers: [],
      controls: controls,
      target: params.element,
      view: this.getView() ?? undefined,
      interactions: interaction.defaults({
        dragPan: !!params.enablePan,
        mouseWheelZoom: !!params.enableMouseWheelZoom,
        doubleClickZoom: !!params.enableDoubleClickZoom,
      }),
    });

    this.map.layers = {};
    this.map.markers = {};

    // disguise event handler, since
    // openlayer feature does not handle click events by itself.
    const evts = ['click'];
    evts.forEach(evtName => {
      if (!this.map) {
        return;
      }
      this.map.on(evtName, event => {
        if (!this.map) {
          return;
        }
        this.map.forEachFeatureAtPixel(
          event.pixel,
          (feature, layer: NamedLayer) => {
            const func = (this.mapCallbacks[evtName] || {})[layer.name || ''];
            if (func) {
              func({ event, feature });
            }
          });
      });
    });

    return this.map;
  }
}
