// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VTooltip from 'v-tooltip';
import router from '@/router';
import store from '@/store';
import rafFuncs from '@/lib/requestAnimationFrame';
import App from '@/App.vue';

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import Notifications from 'vue-notification';
import Multiselect from 'vue-multiselect';

import { Multipane, MultipaneResizer } from '@/components/lib/multipane';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import Modal from '@/components/lib/Modal.vue';
import ModalImageView from '@/components/lib/ModalImageView.vue';
import LoadWaitSpinner from '@/components/lib/LoadWaitSpinner.vue';
import Pagination from '@/components/lib/Pagination.vue';
import MyCalendar from '@/components/lib/MyCalendar.vue';
import AdminListTable from '@/components/lib/AdminListTable.vue';
import VueCompositionApi from '@vue/composition-api';

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(Notifications);
Vue.use(VueCompositionApi);

// requestAnimationFrame funcs
window['requestAnimFrame'] = rafFuncs.requestAnimFrame;
window['requestInterval'] = rafFuncs.requestInterval;
window['clearRequestInterval'] = rafFuncs.clearRequestInterval;
window['requestTimeout'] = rafFuncs.requestTimeout;
window['clearRequestTimeout'] = rafFuncs.clearRequestTimeout;

Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('multipane', Multipane);
Vue.component('multipane-resizer', MultipaneResizer);

Vue.component('extreme-map', ExtremeMap);
Vue.component('modal', Modal);
Vue.component('load-wait-spinner', LoadWaitSpinner);
Vue.component('modal-image-view', ModalImageView);
Vue.component('pagination', Pagination);
Vue.component('my-calendar', MyCalendar);
Vue.component('multiselect', Multiselect);
Vue.component('admin-list-table', AdminListTable);

new Vue({
  router,
  store,
  components: { App },
  render: h => h(App),
}).$mount('#app');
