export const JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU = 'sanpu';
export const JOHAISETSU_TYPE_GROUP_YUSETSU_SAGYOU = 'soukou';
export const JOHAISETSU_TYPE_GROUP_KISEI_SAGYOU = 'kisei';
export const JOHAISETSU_TYPE_GROUP_JOSETSU_SAGYOU = 'josetsu';
export const JOHAISETSU_TYPE_GROUP_HAISETSU_SAGYOU = 'haisetsu';

export const JOHAISETSU_CAR_STATUS_RUNNING = 'running';
export const JOHAISETSU_CAR_STATUS_WORKING = 'working';
export const JOHAISETSU_CAR_STATUS_STOPPED = 'stopped';

export const JOHAISETSU_TYPE_GROUP_SETTOU_PATROL = 'settou_patrol';
export const JOHAISETSU_TYPE_GROUP_SETTOU_ONSITE_MANAGEMENT = 'onsite_management';
