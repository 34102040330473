import axios from 'axios';
import FileSaver from 'file-saver';

const downloadUrl = async(url: string, filename: string): Promise<void> => {
  const { data: blob } = await axios.get(url, { responseType: 'blob' });
  downloadBlob(blob, filename);
};
const downloadBlob = async(blob: string | Blob, filename: string): Promise<void> => {
  FileSaver.saveAs(blob, filename);
};
const downloadObjectUrl = async(objectUrl: string, filename: string): Promise<void> => {
  // downloadBlobと同じであるが、混乱させないため、新たな関数を追加
  downloadBlob(objectUrl, filename);
};

export {
  downloadUrl,
  downloadBlob,
  downloadObjectUrl,
};
