export const ABILITY_ENSUI_JUTEN_KARADANPU_INPUT = 11;
export const ABILITY_ENSUI_JUTEN_KARADANPU_MAP = 12;
export const ABILITY_SEKISETSU_INPUT = 21;
export const ABILITY_HAISETSU_KEISAN_INPUT = 22;
export const ABILITY_PROGRESS_TABLE = 23;
export const ABILITY_SAGYOU_JOUKYOU_INPUT = 31;
export const ABILITY_SAGYOU_JOUKYOU_MAP = 32;

export const ABILITY_SETTOU_REPORT_LIST = 41;
export const ABILITY_ENSUI_PLANT_STOCK_TABLE = 42;

export const ABILITY_TOUKETSU_BOUSHI_INPUT = 51;
export const ABILITY_YUSETSU_INPUT = 52;
export const ABILITY_KISEI_INPUT = 53;
export const ABILITY_JOSETSU_INPUT = 54;
export const ABILITY_HAISETSU_INPUT = 55;
export const ABILITY_SETTOU_PATROL_REPORT_INPUT = 56;
export const ABILITY_ENSUI_PLANT_STOCK_INPUT = 57;
export const ABILITY_SETTOU_ONSITE_MANAGEMENT_REPORT_INPUT = 58;

export const ABILITY_JOHAISETSU_MAP = 61;

export const ABILITY_MESSAGE_BOARD = 90;

export const ABILITY_CLEANING_MAP = 201;
export const ABILITY_CLEANING_REPORT = 202;

export const JOHAISETSU_ABILITY_MIN_ID = 11;
export const JOHAISETSU_ABILITY_MAX_ID = 90;
export const CLEANING_ABILITY_MIN_ID = 201;
export const CLEANING_ABILITY_MAX_ID = 202;
