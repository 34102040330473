


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import ConfirmModal from '@/components/lib/ConfirmModal.vue';
import PhotoTaker from '@/components/lib/PhotoTaker.vue';
import { UserActionTypes } from '@/store/modules/user';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForCleaningMasters } from '@/lib/cleaningHelper';
import {
  timeDifferenceInSeconds,
  secondsToTimeInteger,
  timeInteger,
} from '@/lib/dateTimeHelper';
import { ABILITY_CLEANING_REPORT } from '@/consts/ability';
import {
  CLEANING_CAR_STATUS_RUNNING,
  CLEANING_CAR_STATUS_WORKING,
  CLEANING_CAR_STATUS_STOPPED,
} from '@/components/CleaningMap/consts/cleaning_car';
import { CONNECTION_CHAR } from '@/components/CleaningMap/consts/cleaning_map';
import useSpeech from '@/composables/useSpeech';
import cleaningCarApi from '@/apis/cleaning_car';
import cleaningReportApi from '@/apis/cleaning_report';
import cleaningMtxApi from '@/apis/cleaning_mtx';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { Ability } from '@/models/apis/user/userResponse';
import { ImageCandidateBase as ImageCandidate } from '@/models/apis/image/imageRequest';
import { dtFormat, ensureDate } from '@/lib/dateHelper';
import { CleaningCar, CleaningCarShowResponse } from '@/models/apis/cleaning/cleaningCarResponse';
import { CleaningMTX } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { CleaningReportState, EditCleaningReportState } from '@/models/spCleaningReport';
import {
  initCleaningReportState,
  initEditCleaningReportState,
  initCleaningReportExt,
  notifyWorkElapsedTimeSpeech,
  updateCleaningStart,
  updateCleaningEnd,
  updateBaseArrival,
  updateCleaningContentAndRoad,
  setViewport,
  getMyCleaningCar,
  convCleaningReport,
} from './utils/index';
import useGeolocation from './composables/geolocation';
import { CleaningReport } from '@/models/apis/cleaning/cleaningReportResponse';
import { getImageCandidate } from '@/lib/imageHelper';

const MAX_PENDING_PHOTOS_COUNT = 8;
const MAX_SAVABLE_PHOTOS_COUNT = 50;

const CLEANING_CAR_UPDATE_TIMER_SEC = 10;
const WORK_ELAPSED_TIME_NOTIFY_TIMER_SEC = 1;
const CLEANING_MTX_STORE_TIMER_SEC = 10; // 1秒だとさすがに多すぎた
const CLEANING_MTX_CREATE_TIMER_SEC = 60;

export default defineComponent({
  name: 'sp-cleaning-report',
  setup() {
    const cleaningReportTypeGroupName = '清掃作業';
    const { speechSynthesisVoices, doSpeech } = useSpeech();
    const state = reactive<CleaningReportState>(initCleaningReportState());
    const editState = reactive<EditCleaningReportState>(initEditCleaningReportState());

    const store = useStore();
    const userState = store.state.user;

    const stopAll = async() => {
      clearTimers();
      await Promise.all([
        doStopMoving(),
      ]);
    };
    const geolocationState = useGeolocation(stopAll);

    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });

    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const cleaningHan = computed<string>(() => {
      if (userState.cleaning_han && userState.cleaning_han.length > 0) {
        return userState.cleaning_han[0].name;
      } else {
        return '';
      }
    });
    const cleaningCompanyId = computed<number>(() => {
      if (userState.cleaning_company && userState.cleaning_company.length > 0) {
        return userState.cleaning_company[0].id;
      } else {
        return -1;
      }
    });
    const workElapsedTimeInt = computed<number>(() => {
      if (!state.workStartTime || !state.currentTime) { return 0; }

      const workElapsedTimeSec =
        timeDifferenceInSeconds(state.workStartTime, state.workEndTime ?? state.currentTime);
      return secondsToTimeInteger(workElapsedTimeSec);
    });
    const showOtherCleaningTypeArea = computed<boolean>(() => {
      return editState.selectedCleaningKind.some(e => e.name === 'その他');
    });
    const canSave = computed<boolean>(() => {
      return !state.isRequesting && checkInputs() && editState.isChanged;
    });
    const canAddPendingPhoto = computed<boolean>(() => {
      return editState.imageCandidates.length < MAX_PENDING_PHOTOS_COUNT && state.photoCount < MAX_SAVABLE_PHOTOS_COUNT;
    });
    const canSaveAdditionalPhoto = computed<boolean>(() => {
      return state.photoCount <= MAX_SAVABLE_PHOTOS_COUNT;
    });
    const canSavePhotos = computed<boolean>(() => {
      return !state.isRequesting && canSaveAdditionalPhoto.value && editState.imageCandidates.length > 0;
    });
    const pendingPhotosCount = computed<number>(() => {
      return editState.imageCandidates.length;
    });
    const contents = computed<string>(() => {
      return editState.selectedCleaningKind.sort(e => e.disp_order).map(e => e.name).join(CONNECTION_CHAR);
    });
    const roadNames = computed<string>(() => {
      return editState.selectedRoadNames.map(e => e.roadNameDisp).join(CONNECTION_CHAR);
    });
    const fileNamePrefixForDownload = computed(() => {
      return `清掃作業_${editState.photoTypeDisp}`;
    });
    const clearTimers = () => {
      if (state.cleaningCarUpdateTimer) {
        clearInterval(state.cleaningCarUpdateTimer);
      }
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      if (state.cleaningMtxStoreTimer) {
        clearInterval(state.cleaningMtxStoreTimer);
      }
      if (state.cleaningMtxCreateTimer) {
        clearInterval(state.cleaningMtxCreateTimer);
      }
      state.cleaningCarUpdateTimer = null;
      state.workElapsedTimeNotifyTimer = null;
      state.cleaningMtxStoreTimer = null;
      state.cleaningMtxCreateTimer = null;
    };
    const initCleaningReport = () => {
      state.cleaningReportId = -1;
      state.baseDepartureTime = null;
      state.workStartTime = null;
      state.workEndTime = null;
      state.cleaningCar.report = initCleaningReportExt();
      editState.selectedCleaningKind = [];
      editState.selectedRoadNames = [];
      editState.otherCleaningType = '';
    };

    const tryShowConfirmStartWorkingModal = () => {
      state.showConfirmStartWorkingModal = true;
    };

    const updateCleaningCar = async(status: string): Promise<CleaningCar | null> => {
      if (!geolocationState.currentLocation) { return null; }

      const { lat, lon } = geolocationState.currentLocation;
      state.status = status ?? state.status;
      const reqObj = {
        lat: lat,
        lon: lon,
        status: state.status,
        cleaning_type: editState.selectedCleaningType,
      };
      const { data } = await cleaningCarApi.createOrUpdate(reqObj);
      // 初回作成時はidをもらう
      if (state.cleaningCar.id === -1) {
        state.cleaningCar.id = data.id;
      }
      return data;
    };
    const createCleaningReport = async(cleaningCarId: number | null) => {
      if (!cleaningCarId) return;
      state.baseDepartureTime = new Date();
      const reqObj = {
        cleaning_car_id: cleaningCarId,
        base_departure_ts: state.baseDepartureTime,
        cleaning_company_id: cleaningCompanyId.value,
        cleaning_han_name: state.displayCleaningHan,
        contents: contents.value,
        other_content: editState.otherCleaningType,
        road_names: roadNames.value,
      };
      const { data } = await cleaningReportApi.createCleaningReport(reqObj);

      setCleaningReport(data);

      const reqObjWithReportId = {
        current_cleaning_report_id: state.cleaningReportId,
      };
      await cleaningCarApi.updateReportId(cleaningCarId, reqObjWithReportId);
    };
    const storeCleaningMtx = () => {
      if (!geolocationState.currentLocation) { return; }
      if (!state.cleaningCar.id || !state.cleaningReportId) { return; }

      const now = new Date();
      if (state.lastCleaningMtxTs) {
        const thres = 1000; // milliseconds
        const diffMSec = now.getTime() - state.lastCleaningMtxTs.getTime();
        if (diffMSec < thres) { return; }
      }

      const { lat, lon } = geolocationState.currentLocation;
      const obj: CleaningMTX = {
        cleaning_car_id: state.cleaningCar.id,
        ts: now,
        lat: lat,
        lon: lon,
        status: state.status,
        cleaning_report_id: state.cleaningReportId,
      };
      state.cleaningMtxs.push(obj);
      state.lastCleaningMtxTs = now;
    };
    const saveCleaningMtxs = async() => {
      // 値として取れているものが何個か以上あったら送る
      const thres = 2;
      if (state.cleaningMtxs.length < thres) { return; }

      const cleaningMtxs = state.cleaningMtxs.splice(0);
      const reqObj = { cleaning_mtxs: cleaningMtxs };
      await cleaningMtxApi.createCleaningMTXs(reqObj);
    };
    const notifyWorkElapsedTime = () => {
      // 現在時刻の更新
      state.currentTime = new Date();
      notifyWorkElapsedTimeSpeech(workElapsedTimeInt.value, doSpeech);
    };
    const restartCleaningCarUpdateInterval = async() => {
      if (state.cleaningCarUpdateTimer) {
        clearInterval(state.cleaningCarUpdateTimer);
      }
      await updateCleaningCar(state.status);
      state.cleaningCarUpdateTimer = setInterval(updateCleaningCar, CLEANING_CAR_UPDATE_TIMER_SEC * 1000);
    };
    const restartWorkElapsedTimeNotifyInterval = () => {
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer = setInterval(notifyWorkElapsedTime, WORK_ELAPSED_TIME_NOTIFY_TIMER_SEC * 1000);
    };
    const restartCleaningMtxUpdateInterval = () => {
      state.cleaningMtxs = [];
      if (state.cleaningMtxStoreTimer) {
        clearInterval(state.cleaningMtxStoreTimer);
      }
      if (state.cleaningMtxCreateTimer) {
        clearInterval(state.cleaningMtxCreateTimer);
      }
      state.cleaningMtxStoreTimer = setInterval(storeCleaningMtx, CLEANING_MTX_STORE_TIMER_SEC * 1000);
      state.cleaningMtxCreateTimer = setInterval(saveCleaningMtxs, CLEANING_MTX_CREATE_TIMER_SEC * 1000);
    };
    const startMoving = async() => {
      state.isRequesting = true;
      try {
        clearTimers();
        await updateCleaningCar(CLEANING_CAR_STATUS_RUNNING);
        await createCleaningReport(state.cleaningCar.id);
        await restartCleaningCarUpdateInterval();
        restartCleaningMtxUpdateInterval();
        doSpeech('走行を開始します');
        saveLocalStorage();
        state.showConfirmBaseDepartureModal = false;
        state.isMoving = true;
      } catch (e) {
        state.errorModalMsg = '走行開始に失敗しました。再度操作を行ってください';
        state.showCommonErrorModal = true;
      } finally {
        state.isRequesting = false;
      }
    };
    const startWorking = async() => {
      state.isRequesting = true;
      const isResumingWork = state.isSuspending;
      const opType = isResumingWork ? '再開' : '開始';
      try {
        state.workStartTime = new Date();
        await updateCleaningCar(CLEANING_CAR_STATUS_WORKING);
        if (!isResumingWork) {
          await updateCleaningStart(state.cleaningReportId, state.workStartTime);
        }
        restartWorkElapsedTimeNotifyInterval();
        doSpeech(`作業を${opType}します`);
        state.isSuspending = false;
        state.isWorking = true;
        state.showConfirmStartWorkingModal = false;
      } catch (e) {
        state.errorModalMsg = `作業${opType}に失敗しました。再度操作を行ってください。`;
        state.showCommonErrorModal = true;
        state.showConfirmStartWorkingModal = false;
      } finally {
        state.isRequesting = false;
      }
    };
    const stopMoving = async() => {
      state.isRequesting = true;
      try {
        clearTimers();
        await doStopMoving();
        doSpeech('走行を終了します');
      } catch (e) {
        state.errorModalMsg = '走行終了に失敗しました。再度操作を行ってください。';
        state.showCommonErrorModal = true;
      } finally {
        state.isRequesting = false;
      }
    };
    const stopWorking = async() => {
      state.isRequesting = true;
      try {
        await doStopWorking();
        state.workEndTime = new Date();
        await updateCleaningEnd(state.cleaningReportId, state.workEndTime);
        state.isBacking = true;
        doSpeech('作業を終了します');
        if (state.workElapsedTimeNotifyTimer) {
          clearInterval(state.workElapsedTimeNotifyTimer);
        }
        state.showConfirmStopWorkingModal = false;
      } catch (e) {
        state.errorModalMsg = '作業終了に失敗しました。再度操作を行ってください。';
        state.showCommonErrorModal = true;
        state.showConfirmStopWorkingModal = false;
      } finally {
        state.isRequesting = false;
      }
    };
    const baseArrival = async() => {
      if (!state.cleaningCar.id) return;
      state.isRequesting = true;
      try {
        await stopAll();
        await updateBaseArrival(state.cleaningReportId);

        const reqObjWithReportId = {
          current_cleaning_report_id: null,
        };
        await cleaningCarApi.updateReportId(state.cleaningCar.id, reqObjWithReportId);
        doSpeech('基地に到着します');
        state.showConfirmBaseArrivalModal = false;
        state.isBacking = false;
        initCleaningReport();
        clearLocalStorage();
      } catch (e) {
        state.errorModalMsg = '基地到着に失敗しました。再度操作を行ってください。';
        state.showCommonErrorModal = true;
        state.showConfirmBaseArrivalModal = false;
      } finally {
        state.isRequesting = false;
      }
    };
    const editContentAndRoad = async() => {
      state.isContentAndRoadEditing = true;
      editState.isChanged = false;
    };
    const saveContentAndRoad = async() => {
      state.isRequesting = true;
      try {
        const cleaningReportContentAndRoadParams = {
          contents: contents.value,
          other_content: editState.otherCleaningType,
          road_names: roadNames.value,
        };
        await updateCleaningContentAndRoad(state.cleaningReportId, cleaningReportContentAndRoadParams);
        saveLocalStorage();
        state.showConfirmContentAndRoadModal = false;
        state.isContentAndRoadEditing = false;
      } catch (e) {
        state.errorModalMsg = '保存に失敗しました。再度操作を行ってください';
        state.showCommonErrorModal = true;
      } finally {
        state.isRequesting = false;
      }
    };
    const tryRevertEditContentAndRoad = async() => {
      if (editState.isChanged) {
        state.showRevertEditContentAndRoadConfirmModal = true;
        return;
      }
      state.isContentAndRoadEditing = false;
    };
    const revertEditContentAndRoad = async() => {
      loadContentAndRoadDataFromLocalStorage();
      state.isContentAndRoadEditing = false;
      state.showRevertEditContentAndRoadConfirmModal = false;
    };

    const temporarilyStopWorking = async() => {
      state.isRequesting = true;
      try {
        await suspendWork();
        doSpeech('作業を一時停止します');
        state.showConfirmTemporarilyStopWorkingModal = false;
      } catch (e) {
        state.errorModalMsg = '作業一時停止に失敗しました。再度操作を行ってください。';
        state.showCommonErrorModal = true;
        state.showConfirmTemporarilyStopWorkingModal = false;
      } finally {
        state.isRequesting = false;
      }
    };
    const doStopWorking = async() => {
      await Promise.all([
        saveCleaningMtxs(),
        updateCleaningCar(CLEANING_CAR_STATUS_RUNNING),
      ]);
      state.isSuspending = false;
      state.isWorking = false;
    };
    const doStopMoving = async() => {
      await updateCleaningCar(CLEANING_CAR_STATUS_STOPPED);
      state.isMoving = false;
    };
    const suspendWork = async() => {
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer = null;

      await doStopWorking();
      state.isSuspending = true;
    };
    const saveLocalStorage = (): void => {
      const obj = {
        selectedCleaningKind: editState.selectedCleaningKind,
        selectedRoadNames: editState.selectedRoadNames,
        otherCleaningType: editState.otherCleaningType,
      };
      const localStorageKey = route.value.name;
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, JSON.stringify(obj));
      }
    };

    const clearLocalStorage = (): void => {
      const localStorageKey = route.value.name;
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, JSON.stringify({}));
      }
    };

    const loadContentAndRoadDataFromLocalStorage = (): void => {
      try {
        const localStorageKey = route.value.name;
        if (localStorageKey) {
          const obj = JSON.parse(localStorage.getItem(localStorageKey) || '');
          if (obj.selectedCleaningKind) {
            editState.selectedCleaningKind = obj.selectedCleaningKind;
          }
          if (obj.otherCleaningType) {
            editState.otherCleaningType = obj.otherCleaningType;
          }
          if (obj.selectedRoadNames) {
            editState.selectedRoadNames = obj.selectedRoadNames;
          }
          editState.isChanged = obj.selectedCleaningKind && obj.selectedRoadNames;
        }
      } catch (e) {}
    };
    const cleaningKindInput = () => {
      if (!checkValueChanged()) return;
      if (!checkInputs()) return;
      editState.isChanged = true;
    };
    const otherCleaningTypeAreaInput = () => {
      if (!checkValueChanged()) return;
      if (!checkInputs()) return;
      editState.isChanged = true;
    };
    const roadNamesInput = () => {
      if (!checkValueChanged()) return;
      if (!checkInputs()) return;
      editState.isChanged = true;
    };
    const sendImagesInner = async() => {
      if (state.cleaningReportId === -1 || !editState.imageCandidates.length) return;

      const mtxSlice = state.cleaningMtxs.slice(0);
      const mtxLength = mtxSlice.length;
      const lastMtx = mtxSlice.slice(mtxLength - 1, mtxLength)[0];

      let remainingImageCandidates: ImageCandidate[] = [];
      state.pendingPhotosCountBeforeSave = editState.imageCandidates.length;
      for (const [index, imageCandidate] of editState.imageCandidates.entries()) {
        try {
          const reqObj = {
            cleaning_report_id: state.cleaningReportId,
            ts: dtFormat(lastMtx.ts),
            lat: lastMtx.lat,
            lon: lastMtx.lon,
            photo_type: editState.photoType,
            image: imageCandidate.file,
          };
          await cleaningReportApi.addPhoto(state.cleaningReportId, reqObj);
          URL.revokeObjectURL(imageCandidate.src);
        } catch (e) {
          remainingImageCandidates = editState.imageCandidates.slice(index);
          // 一つでも失敗したらユーザーに再送信を促す
          break;
        }
      }
      editState.imageCandidates = remainingImageCandidates;
    };
    const sendImages = async() => {
      state.isRequesting = true;
      await sendImagesInner();
      state.isRequesting = false;

      // 写真の送信失敗処理
      if (editState.imageCandidates.length > 0) {
        state.showSavePhotosConfirmModal = false;
        state.showPhotoSaveErrorModal = true;
        return;
      }
      state.isImagesEditing = false;
      state.showSavePhotosConfirmModal = false;
    };
    const tryRevertEditImages = () => {
      if (editState.imageCandidates.length > 0) {
        state.showRevertSavePhotosConfirmModal = true;
        return;
      }
      state.isImagesEditing = false;
    };
    const revertEditImages = () => {
      editState.imageCandidates = [];
      state.isImagesEditing = false;
      state.showRevertSavePhotosConfirmModal = false;
    };
    const onImageFileAttach = async(evt: Event) => {
      const fileList = (evt.target as HTMLInputElement).files;
      if (!fileList || fileList.length === 0) { return; }
      const file = fileList[0];
      attachImageFile(file);
    };
    const attachImageFile = async(file: File) => {
      calcPhotoCount();
      if (!canSaveAdditionalPhoto.value) {
        state.errorModalMsg = '添付画像の最大数は' + MAX_SAVABLE_PHOTOS_COUNT + '枚です。';
        state.showCommonErrorModal = true;
        return;
      }

      const imageCandidate = await getImageCandidate(file);
      if (!imageCandidate) return;
      editState.imageCandidates.push(imageCandidate);
    };
    const removeImageCandidate = (idx: number) => {
      URL.revokeObjectURL(editState.imageCandidates[idx].src);
      editState.imageCandidates.splice(idx, 1);
    };
    const logout = async() => {
      await stopAll();
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };

    const calcPhotoCount = () => {
      const result = cleaningReportApi.getReportPhotoCount(state.cleaningReportId, editState.photoType);
      result.then(({data: count}) => {
        state.photoCount = count + editState.imageCandidates.length;
      });
    };
    const checkInputs = (): boolean => {
      if (editState.selectedCleaningKind.length === 0 ||
        editState.selectedRoadNames.length === 0) {
        return false;
      }
      return true;
    };
    const checkValueChanged = (): boolean => {
      const report = state.cleaningCar.report;
      if (contents.value !== report.contents) {
        return true;
      }
      if (roadNames.value !== report.roadNames) {
        return true;
      }
      if (editState.otherCleaningType !== report.otherContent) {
        return true;
      }
      return false;
    };
    const tryShowSavePhotosConfirmModal = () => {
      if (!canSavePhotos.value) return;
      state.showSavePhotosConfirmModal = true;
    };
    const tryShowConfirmBaseDepartureModal = () => {
      if (!canSave.value) return;
      state.showConfirmBaseDepartureModal = true;
    };
    const tryShowConfirmContentAndRoadModal = () => {
      if (!canSave.value) return;
      state.showConfirmContentAndRoadModal = true;
    };
    watch(() => showOtherCleaningTypeArea.value, () => {
      if (!showOtherCleaningTypeArea.value) {
        editState.otherCleaningType = null;
        otherCleaningTypeAreaInput();
      }
    });
    watch(() => state.isImagesEditing, () => {
      if (state.isImagesEditing) {
        calcPhotoCount();
      }
    });

    const setMyCleaningCarOnMounted = (cleaningCar: CleaningCarShowResponse) => {
      state.cleaningCar.id = cleaningCar.id;
      state.status = cleaningCar.status;
      if (!cleaningCar.report) return;
      const currentReport = cleaningCar.report;
      setCleaningReport(currentReport);
      restartCleaningCarUpdateInterval();
      restartCleaningMtxUpdateInterval();
      // 現在進行中の報告書がある場合はその情報をもとに画面を復旧させる
      if (currentReport.base_arrival_ts) {
        state.isMoving = false; // 最初からfalseだが一応
        return;
      }
      state.isMoving = true; // フラグが排他じゃないのが困るが、trueにしないといけないらしい
      state.status = CLEANING_CAR_STATUS_RUNNING;
      if (currentReport.cleaning_end_ts) {
        state.isBacking = true;
        return;
      }
      if (currentReport.cleaning_start_ts) {
        if (cleaningCar.status === CLEANING_CAR_STATUS_RUNNING) { //　一時停止中
          state.isSuspending = true;
        } else {
          state.isWorking = true;
          state.status = CLEANING_CAR_STATUS_WORKING;
          restartWorkElapsedTimeNotifyInterval();
        }
      }
    };
    const setCleaningReport = (cleaningReport: CleaningReport) => {
      state.cleaningReportId = cleaningReport.id;
      state.cleaningCar.report = convCleaningReport(cleaningReport);
      state.baseDepartureTime = ensureDate(cleaningReport.base_departure_ts);
      state.workStartTime = ensureDate(cleaningReport.cleaning_start_ts);
      state.workEndTime = ensureDate(cleaningReport.cleaning_end_ts);
    };

    const { route } = useRoute();
    onMounted(async() => {
      const promiseResults = await Promise.all([waitForUserAndMasters(), waitForCleaningMasters(), getMyCleaningCar()]);
      if (!abilityMap.value[ABILITY_CLEANING_REPORT]) {
        location.href = '/';
        return;
      }

      setViewport(document);
      enableNoSleep();

      state.cleaningKinds = window.cleaningMaster.cleaningKinds.filter(e => {
        return e.cleaning_company_role.find(e2 => {
          return e2.cleaning_role === userState.cleaning_role;
        });
      });

      state.displayCleaningHan = cleaningHan.value;
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));

      initCleaningReport();
      loadContentAndRoadDataFromLocalStorage();

      const myCleaningCar = promiseResults[2];
      if (myCleaningCar) {
        setMyCleaningCarOnMounted(myCleaningCar);
      }

      state.isReady = true;
    });

    onBeforeUnmount(() => {
      clearTimers();
      disableNoSleep();
    });
    return {
      ...toRefs(state),
      ...toRefs(editState),
      ...toRefs(geolocationState),
      speechSynthesisVoices,
      cleaningReportTypeGroupName,
      // computed
      displayName,
      workElapsedTimeInt,
      showOtherCleaningTypeArea,
      canSave,
      canAddPendingPhoto,
      canSavePhotos,
      pendingPhotosCount,
      fileNamePrefixForDownload,
      // methods
      tryShowConfirmStartWorkingModal,
      startMoving,
      startWorking,
      stopMoving,
      stopWorking,
      baseArrival,
      editContentAndRoad,
      saveContentAndRoad,
      tryRevertEditContentAndRoad,
      revertEditContentAndRoad,
      temporarilyStopWorking,
      cleaningKindInput,
      otherCleaningTypeAreaInput,
      roadNamesInput,
      sendImages,
      revertEditImages,
      tryRevertEditImages,
      onImageFileAttach,
      attachImageFile,
      removeImageCandidate,
      logout,
      dtFormat,
      timeInteger,
      calcPhotoCount,
      tryShowSavePhotosConfirmModal,
      tryShowConfirmBaseDepartureModal,
      tryShowConfirmContentAndRoadModal,
    };
  },
  components: {
    ConfirmModal,
    PhotoTaker,
  },
});
