import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
import { isJohaisetsuMap } from '@/lib/johaisetsu/johaisetsuCommonUtil';

export default class OtherSoukouHelper extends AbstractGeoItem<number, number, number, string> {
  dataToObjKey(inputData: number): number {
    let data: DataContent = { v: Number.MAX_VALUE };
    if (typeof inputData !== 'object') { data = { v: inputData }; }

    return parseInt(data.v.toString());
  }
  objKeyToColor(intputObjKey: number): string {
    const objKey = parseInt(intputObjKey.toString());
    let ret = '#b1b1b1';
    const x = objKey;
    if (isJohaisetsuMap(location.pathname)) {
      ret = '#75fc4c';
    } else if (x < 2) {
      // 1 <= x < 2
      ret = '#18FFFF';
    } else if (x < 3) {
      // 2 <= x < 3
      ret = '#42A5F5';
    } else if (x < 5) {
      // 3 <= x < 5
      ret = '#0000FF';
    } else if (x < 10) {
      // 5 <= x < 10
      ret = '#A001C3';
    } else if (x < 20) {
      // 10 <= x < 20
      ret = '#FFFF00';
    } else {
      // 20 <= x
      ret = '#FF0000';
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
