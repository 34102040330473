


import deviceApi from '@/apis/device';
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { Lov } from '@/models/apis/master/masterResponse';
import { AdminDevice } from '@/models/apis/device/adminDeviceResponse';

import { waitForMasters } from '@/lib/masterHelper';

interface SearchParams {
  device_id: string;
  car_name: string;
  car_kind: string;
}

interface AdminDeviceState {
  lovs: {
    carKinds: Lov[];
  };
  search: SearchParams;
  isSearched: boolean;
  devicesOrig: AdminDevice[];
  devices: AdminDevice[];

  isRequesting: boolean;
  requestType: string;
  requestedDevice?: AdminDevice;

  showModal: boolean;
  showModal2: boolean;
  modalTitle: string;
  modalMsg: string;
}

type AdminDeviceEx = AdminDevice & {
  carKindDisp: string;
  isMoving: boolean;
  streamStopButtonClickCnt: number;
  forceShowStreamStopButton: boolean;
};

export default defineComponent({
  name: 'admin-devices',
  setup() {
    const initSearchState = (): SearchParams => {
      return {
        device_id: '',
        car_name: '',
        car_kind: '',
      };
    };

    const initLovsState = () => {
      return {
        carKinds: [],
      };
    };

    const initRequestedDevice = (): AdminDevice => {
      return {
        id: -1,
      };
    };

    const state = reactive<AdminDeviceState>({
      lovs: initLovsState(),
      search: initSearchState(),
      isSearched: false,
      devicesOrig: [],
      devices: [],

      isRequesting: false,
      requestType: '',
      requestedDevice: initRequestedDevice(),

      showModal: false,
      showModal2: false,
      modalTitle: '',
      modalMsg: '',
    });

    const store = useStore();
    const userState = store.state.user;
    const isSuperAdmin = computed<boolean>(() => {
      return userState.has_role_super_admin;
    });

    const convData = (arr: AdminDevice[]): AdminDeviceEx[] => {
      const carKindMap = window.master.lovs.car_kind.map;
      return arr.map(e => {
        return {
          ...e,
          carKindDisp: (carKindMap[e.car_kind || ''] || {}).val,
          status_disp: e.status_disp || '停止中',
          isMoving: e.status_disp === '移動中',
          forceShowStreamStopButton: false,
          streamStopButtonClickCnt: 0,
        };
      });
    };

    const filterDevices = (origArr: AdminDevice[]) => {
      const qDeviceId = state.search.device_id;
      const qCarName = state.search.car_name;
      const qCarKind = state.search.car_kind;
      return origArr.filter(e => {
        if (qDeviceId) {
          if (e.device_id?.indexOf(qDeviceId) === -1) { return false; }
        }
        if (qCarName) {
          if (e.car_name?.indexOf(qCarName) === -1) { return false; }
        }
        if (qCarKind) {
          if (e.car_kind !== qCarKind) { return false; }
        }

        return true;
      });
    };

    const getDevices = () => {
      state.isSearched = false;
      state.devicesOrig = [];
      state.devices = [];
      deviceApi.adminGetDevices()
        .then(waitForMasters)
        .then(({ data }) => {
          state.isSearched = true;
          if (!data || data.length === 0) { return; }
          state.devicesOrig = convData(data);
          state.devices = filterDevices(state.devicesOrig);
        });
    };

    const doSearch = () => {
      state.devices = filterDevices(state.devicesOrig);
    };
    const prepareRequest = (device: AdminDevice, type: string) => {
      state.isRequesting = true;
      state.requestType = type;
      state.requestedDevice = device;
    };
    const endRequest = () => {
      state.isRequesting = false;
      state.requestType = '';
      state.requestedDevice = initRequestedDevice();
    };
    const showStreamStartStopNgMsg = () => {
      state.showModal = true;
      state.modalTitle = 'エラー';
      state.modalMsg =
        (state.requestType === 'start' ? '配信開始' : '配信停止') +
        '要求に失敗しました。';
    };
    const requestStreamingStart = (device: AdminDevice) => {
      prepareRequest(device, 'start');
      deviceApi.startStream(device.id)
        .then(() => {
          state.showModal = true;
          state.modalTitle = '配信開始要求完了';
          state.modalMsg = `配信開始を<b>車載器ID: ${device.device_id}</b>に通知しました。<br>` +
            '配信開始まで1分ほどお待ちください。';
        })
        .catch(() => {
          showStreamStartStopNgMsg();
        })
        .finally(() => {
          endRequest();
        });
    };
    const requestStreamingStop = (device: AdminDeviceEx) => {
      device.forceShowStreamStopButton = false;
      device.streamStopButtonClickCnt = 0;

      prepareRequest(device, 'stop');
      deviceApi.stopStream(device.id)
        .then(() => {
          state.showModal = true;
          state.modalTitle = '配信停止要求完了';
          state.modalMsg = `配信停止を<b>車載器ID: ${device.device_id}</b>に通知しました。`;
        })
        .catch(() => {
          showStreamStartStopNgMsg();
        })
        .finally(() => {
          endRequest();
        });
    };
    const getMTX = (device: AdminDevice) => {
      prepareRequest(device, 'mtx');
      deviceApi.adminGetLatestMTXs(device.id)
        .then(({ data }) => {
          const { mtxs, description } = data;
          state.showModal2 = true;
          state.modalTitle = `${device.id}: MTX取得完了`;

          const cnt = mtxs.length;
          const dataConv = mtxs.map(e => {
            const arr = [
              `ts:${e.ts}`,
              `緯度:${e.lat}`,
              `経度:${e.lon}`,
              `速度:${e.velocity}`,
              `路温:${e.temperature}`,
              `塩分濃度:${e.salinity}`,
            ];
            if (!e.is_place_filled) {
              arr.push('KP:計算中');
            } else {
              if (!e.road_name) {
                arr.push('KP:なし');
              } else {
                const tmp = [e.road_name, e.direction];
                if (e.place_name) { tmp.push(e.place_name); }
                tmp.push(e.kp);
                arr.push(`KP:${tmp.join(' ')}`);
              }
            }
            return arr.join(', ');
          });

          const msgArr = [
            `${description.time_range}のデータ数(最大${description.limit}件): ${cnt}件`,
            '',
            ...dataConv,
          ];
          state.modalMsg = msgArr.join('<br>');
        })
        .catch(() => {
          state.showModal2 = true;
          state.modalTitle = 'エラー';
          state.modalMsg = 'MTXの取得に失敗しました。';
        })
        .finally(() => {
          endRequest();
        });
    };
    const countUpStreamingStopButton = (device: AdminDeviceEx) => {
      // 画面側から停止しても車載器側でうまく停止してくれないことがある.
      // そのようなときは連打してればもっかい停止ボタンを押せるようにする.
      if (++device.streamStopButtonClickCnt > 3) {
        device.forceShowStreamStopButton = true;
      }
    };
    onMounted(() => {
      window.master.$promise.then(() => {
        state.lovs.carKinds = window.master.lovs.car_kind.vals;
      });
      getDevices();
    });
    return {
      ...toRefs(state),
      // computed
      isSuperAdmin,
      // methods
      getDevices,
      filterDevices,
      doSearch,
      prepareRequest,
      endRequest,
      showStreamStartStopNgMsg,
      requestStreamingStart,
      requestStreamingStop,
      getMTX,
      countUpStreamingStopButton,
    };
  },
});
