import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/components/Login.vue';
import Top from '@/components/Top/index.vue';
import CleaningMap from '@/components/CleaningMap/index.vue';
import JohaisetsuMap from '@/components/Johaisetsu/JohaisetsuMap/index.vue';
import PrintCleaningReport from '@/components/CleaningMap/ReportPrint/index.vue';
import PrintJohaisetsuReport from '@/components/Johaisetsu/JohaisetsuMap/ReportPrint/index.vue';
import SettouMessageBoard from '@/components/SettouMessageBoard/index.vue';
import Route from '@/components/Route/index.vue';

import Sp from '@/components/Sp/index.vue';
import SpTop from '@/components/Sp/Top/index.vue';
import SpGeoItemGroups from '@/components/Sp/GeoItemGroups/index.vue';
import SpTouketsuBoushiReport from '@/components/Sp/Johaisetsu/SettouSagyou/TouketsuBoushiSagyou/index.vue';
import SpYusetsuReport from '@/components/Sp/Johaisetsu/SettouSagyou/YusetsuSagyou/index.vue';
import SpKiseiReport from '@/components/Sp/Johaisetsu/SettouSagyou/KiseiSagyou/index.vue';
import SpJosetsuReport from '@/components/Sp/Johaisetsu/SettouSagyou/JosetsuSagyou/index.vue';
import SpHaisetsuReport from '@/components/Sp/Johaisetsu/SettouSagyou/HaisetsuSagyou/index.vue';

import SpSettouPatrolReport from '@/components/Sp/Johaisetsu/SettouPatrol/SettouPatrol/index.vue';
import SpEnsuiPlantStockReport from '@/components/Sp/EnsuiPlantStockReport/index.vue';
import SpSettouOnsiteManagementReport from '@/components/Sp/Johaisetsu/SettouPatrol/SettouOnsiteManagement/index.vue';

import SpCleaningReport from '@/components/Sp/CleaningReport/index.vue';

import JohaisetsuTaskForce from '@/components/Johaisetsu/TaskForce/index.vue';
import JohaisetsuSekisetsuInput from '@/components/Johaisetsu/SekisetsuInput/index.vue';
import JohaisetsuHaisetsuKeisanInput from '@/components/Johaisetsu/HaisetsuKeisanInput/index.vue';
import JohaisetsuProgressTable from '@/components/Johaisetsu/ProgressTable/index.vue';
import JohaisetsuSagyouJoukyouInput from '@/components/Johaisetsu/SagyouJoukyouInput/index.vue';
import JohaisetsuSagyouJoukyouMap from '@/components/Johaisetsu/SagyouJoukyouMap/index.vue';
import JohaisetsuEnsuiSanpuJoukyouInput from '@/components/Johaisetsu/EnsuiSanpuJoukyouInput/index.vue';
import JohaisetsuJutenSanpuJoukyouInput from '@/components/Johaisetsu/JutenSanpuJoukyouInput/index.vue';
import JohaisetsuEnsuiSanpuJoukyouMap from '@/components/Johaisetsu/EnsuiSanpuJoukyouMap/index.vue';
import JohaisetsuJutenSanpuJoukyouMap from '@/components/Johaisetsu/JutenSanpuJoukyouMap/index.vue';
import JohaisetsuCompanyHanManagement from '@/components/Johaisetsu/CompanyHanManagement/index.vue';

import SettouPatrolList from '@/components/Johaisetsu/SettouReport/SettouReportList/index.vue';
import SettouPatrolReportDetail from '@/components/Johaisetsu/SettouReport/SettouPatrolReportDetail/index.vue';
import SettouOnsiteManagementReportDetail from '@/components/Johaisetsu/SettouReport/SettouOnsiteManagementReportDetail/index.vue';

import EnsuiPlantStockTable from '@/components/EnsuiPlantStockTable/index.vue';

import WeatherObservatoryDataPointsTable from '@/components/WeatherObservatoryDataPointsTable/index.vue';

import AdminTop from '@/components/Admin/Top/index.vue';
import AdminUsers from '@/components/Admin/Users/index.vue';
import AdminDevices from '@/components/Admin/Devices/index.vue';
import AdminTairyuIgnition from '@/components/Admin/TairyuIgnition/index.vue';
import AdminComments from '@/components/Admin/Comments/index.vue';
import AdminCommentTypes from '@/components/Admin/CommentTypes/index.vue';

import DevDebugTop from '@/components/DevDebug/Top/index.vue';
import DevDebugLiveStreamList from '@/components/DevDebug/LiveStreamList/index.vue';
import DevDebugMovieViewStatusList from '@/components/DevDebug/MovieViewStatusList/index.vue';
import DevDebugSP from '@/components/DevDebug/SP/index.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior(/* to, from, savedPosition */) {
    return {x: 0, y: 0};
  },
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Top',
      component: Top,
    },
    {
      path: '/settou-message-board',
      name: 'SettouMessageBoard',
      component: SettouMessageBoard,
    },
    {
      path: '/route',
      name: 'Route',
      component: Route,
    },
    {
      path: '/johaisetsu-map',
      name: 'JohaisetsuMap',
      component: JohaisetsuMap,
    },
    {
      path: '/cleaning-map',
      name: 'CleaningMap',
      component: CleaningMap,
    },
    {
      path: '/print-cleaning-report/:cleaningReportId',
      name: 'PrintCleaningReport',
      component: PrintCleaningReport,
    },
    {
      path: '/print-cleaning-report/:dateFrom/:dateTo/' +
        'cleaningCompanyIds=:cleaningCompanyIds&cleaningHanName=:cleaningHanName' +
        '&currentPage=:currentPage&itemsPerPage=:itemsPerPage',
      name: 'PrintCleaningReportsAll',
      component: PrintCleaningReport,
    },
    {
      path: '/print-johaisetsu-report/:johaisetsuReportId',
      name: 'PrintJohaisetsuReport',
      component: PrintJohaisetsuReport,
    },
    {
      path: '/print-johaisetsu-report/:dateFrom/:dateTo/' +
        'johaisetsuCompanyIds=:johaisetsuCompanyIds' +
        '&johaisetsuHanName=:johaisetsuHanName' +
        '&johaisetsuType=:johaisetsuType' +
        '&workType=:workType' +
        '&currentPage=:currentPage' +
        '&itemsPerPage=:itemsPerPage',
      name: 'PrintJohaisetsuReportsAll',
      component: PrintJohaisetsuReport,
    },
    {
      path: '/sp',
      component: Sp,
      children: [
        {
          path: '/sp',
          name: 'SpTop',
          component: SpTop,
        },
        {
          path: '/sp/geo_item_groups/:id',
          name: 'SpTopWithParam',
          component: SpTop,
        },
        {
          path: '/sp/geo_item_groups',
          name: 'SpHistory',
          component: SpGeoItemGroups,
        },
        {
          path: '/sp/touketsu-boushi',
          name: 'SpTouketsuBoushi',
          component: SpTouketsuBoushiReport,
        },
        {
          path: '/sp/yusetsu',
          name: 'SpYusetsu',
          component: SpYusetsuReport,
        },
        {
          path: '/sp/kisei',
          name: 'SpKisei',
          component: SpKiseiReport,
        },
        {
          path: '/sp/josetsu',
          name: 'SpJosetsu',
          component: SpJosetsuReport,
        },
        {
          path: '/sp/haisetsu',
          name: 'SpHaisetsu',
          component: SpHaisetsuReport,
        },
        {
          path: '/sp/settou-patrol-report',
          name: 'SpSettouPatrolReport',
          component: SpSettouPatrolReport,
        },
        {
          path: '/sp/ensui-plant-stock-report',
          name: 'SpEnsuiPlantStockReport',
          component: SpEnsuiPlantStockReport,
        },
        {
          path: '/sp/settou-onsite-management-report',
          name: 'SpSettouOnsiteManagementReport',
          component: SpSettouOnsiteManagementReport,
        },
        {
          path: '/sp/cleaning-report',
          name: 'SpCleaningReport',
          component: SpCleaningReport,
        },
      ],
    },
    {
      path: '/johaisetsu/task-force',
      name: 'JohaisetsuTaskForce',
      component: JohaisetsuTaskForce,
    },
    {
      path: '/johaisetsu/:taskForceId/sekisetsu-input/:headerId',
      name: 'JohaisetsuSekisetsuInput',
      component: JohaisetsuSekisetsuInput,
    },
    {
      path: '/johaisetsu/:taskForceId/haisetsu-keisan-input/:headerId',
      name: 'JohaisetsuHaisetsuKeisanInput',
      component: JohaisetsuHaisetsuKeisanInput,
    },
    {
      path: '/johaisetsu/:taskForceId/progress-table/:headerId',
      name: 'JohaisetsuProgressTable',
      component: JohaisetsuProgressTable,
    },
    {
      path: '/johaisetsu/:taskForceId/sagyou-joukyou-input/:headerId',
      name: 'JohaisetsuSagyouJoukyouInput',
      component: JohaisetsuSagyouJoukyouInput,
    },
    {
      path: '/johaisetsu/:taskForceId/sagyou-joukyou-map/:headerId',
      name: 'JohaisetsuSagyouJoukyouMap',
      component: JohaisetsuSagyouJoukyouMap,
    },
    {
      path: '/johaisetsu/:taskForceId/ensui-sanpu-joukyou-input/:headerId',
      name: 'JohaisetsuEnsuiSanpuJoukyouInput',
      component: JohaisetsuEnsuiSanpuJoukyouInput,
    },
    {
      path: '/johaisetsu/:taskForceId/juten-sanpu-joukyou-input/:headerId',
      name: 'JohaisetsuJutenSanpuJoukyouInput',
      component: JohaisetsuJutenSanpuJoukyouInput,
    },
    {
      path: '/johaisetsu/:taskForceId/ensui-sanpu-joukyou-map/:headerId',
      name: 'JohaisetsuEnsuiSanpuJoukyouMap',
      component: JohaisetsuEnsuiSanpuJoukyouMap,
    },
    {
      path: '/johaisetsu/:taskForceId/juten-sanpu-joukyou-map/:headerId',
      name: 'JohaisetsuJutenSanpuJoukyouMap',
      component: JohaisetsuJutenSanpuJoukyouMap,
    },
    {
      path: '/johaisetsu/:taskForceId/company-han-management/:headerId',
      name: 'JohaisetsuCompanyHanManagement',
      component: JohaisetsuCompanyHanManagement,
    },
    {
      path: '/settou-patrol-reports',
      name: 'SettouPatrolList',
      component: SettouPatrolList,
    },
    {
      path: '/settou-patrol-reports/:id',
      name: 'SettouPatrolDetail',
      component: SettouPatrolReportDetail,
    },
    {
      path: '/settou-onsite-management-reports/:id',
      name: 'SettouOnsiteManagementDetail',
      component: SettouOnsiteManagementReportDetail,
    },
    {
      path: '/ensui-plant-stock-table',
      name: 'EnsuiPlantStockTable',
      component: EnsuiPlantStockTable,
    },
    {
      path: '/weather-observatory-data-points-table',
      name: 'WeatherObservatoryDataPointsTable',
      component: WeatherObservatoryDataPointsTable,
    },
    {
      path: '/admin',
      name: 'AdminTop',
      component: AdminTop,
    },
    {
      path: '/admin/users',
      name: 'AdminUsers',
      component: AdminUsers,
    },
    {
      path: '/admin/devices',
      name: 'AdminDevices',
      component: AdminDevices,
    },
    {
      path: '/admin/tairyu-ignition',
      name: 'AdminTairyuIgnition',
      component: AdminTairyuIgnition,
    },
    {
      path: '/admin/comments',
      name: 'AdminComments',
      component: AdminComments,
    },
    {
      path: '/admin/comment_types',
      name: 'AdminCommentTypes',
      component: AdminCommentTypes,
    },
    {
      path: '/dev-debug',
      name: 'DevDebugTop',
      component: DevDebugTop,
    },
    {
      path: '/dev-debug/live-streams',
      name: 'DevDebugLiveStreamList',
      component: DevDebugLiveStreamList,
    },
    {
      path: '/dev-debug/movie-view-statuses',
      name: 'DevDebugMovieViewStatusList',
      component: DevDebugMovieViewStatusList,
    },
    {
      path: '/dev-debug/sp',
      name: 'DevDebugSP',
      component: DevDebugSP,
    },
    // catch all
    {
      path: '*',
      redirect: 'login',
    },
  ],
});
