




































import { defineComponent } from '@vue/composition-api';
import { dtFormat } from '@/lib/dateHelper';

export default defineComponent({
  props: {
    isRealtimeMode: {
      type: Boolean,
      default: false,
    },
    carsUpdatedAt: {
      type: Date,
    },
    movingCarsCount: {
      type: Number,
      default: 0,
    },
    stoppedCarsCount: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      dtFormat,
    };
  },
});
