



























































































































import {
  toRefs,
  defineComponent,
  reactive,
  computed,
  watch,
  PropType,
  onMounted,
  onBeforeUnmount,
  ref,
} from '@vue/composition-api';

import Vue from 'vue';

import deviceApi from '@/apis/device';
import {
  RoadNameDirection,
  DialogInfo,
  DeviceExt,
  MovieCandidate } from '@/models/index';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { IntervalID } from '@/lib/requestAnimationFrame';

interface VideoJsPlayerOptionsExt extends VideoJsPlayerOptions {
  errorDisplay?: boolean;
}
interface MovieCandidaciesDimension {
  x: number;
  y: number;
  h: number;
}
interface LiveModeInfo {
  wasPaused: boolean;
  lastPlaybackPosition: number;
  zeroProgressCount: number;
  playRetryCount: number;
  playResetFlag: boolean;
}
interface StoredModeInfo {
  hasStarted: boolean;
}

interface MoviePlayerDialog {
  roadNameDirectionMap: Record<string, RoadNameDirection>;
  selectedCandidacy: MovieCandidate | null;
  otherCandidacies: MovieCandidate[];
  shouldShowMovieCandidacies: boolean;
  movieCandidaciesDimension: MovieCandidaciesDimension;

  isDragged: boolean;
  wasMovedOnLastDrag: boolean;
  // vjs: {} // vue管理しない
  movieEnded: boolean;

  liveModeInfo: LiveModeInfo;
  storedModeInfo:StoredModeInfo;

  timeupdateDetectTimer: IntervalID | null;
  shouldShowLiveErrorView: boolean;

  isMobile: boolean;
  isIOS: boolean;
  isAndroid: boolean;

  showVideoToolbox: boolean;
}

const Z_INDEX = 999;

export default defineComponent({
  name: 'movie-player-dialog',
  props: {
    roadNameDirections: {
      type: Array as PropType<RoadNameDirection[]>,
      required: true,
    },
    dialogInfo: {
      type: Object as PropType<DialogInfo>,
      required: true,
    },
    isDownloadingMovieFile: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const state = reactive<MoviePlayerDialog>({
      roadNameDirectionMap: {},

      selectedCandidacy: null,
      otherCandidacies: [],
      shouldShowMovieCandidacies: false,
      movieCandidaciesDimension: { x: 0, y: 0, h: 0 },

      isDragged: false,
      wasMovedOnLastDrag: false,
      // vjs: {} // vue管理しない
      movieEnded: false,

      liveModeInfo: {
        wasPaused: false,
        lastPlaybackPosition: 0,
        zeroProgressCount: 0,
        playRetryCount: 0,
        playResetFlag: false,
      },
      storedModeInfo: {
        hasStarted: false,
      },

      timeupdateDetectTimer: null,
      shouldShowLiveErrorView: false,

      isMobile: false,
      isIOS: false,
      isAndroid: false,

      showVideoToolbox: false,
    });
    let vjs: VideoJsPlayer;
    watch(() => props.isDownloadingMovieFile, () => {
      if (!vjs) { return; }

      const controlBar = vjs.getChild('controlBar');
      if (!controlBar) { return; }

      const downloadButton = controlBar.getChild('downloadButton') as videojs.Button;
      if (!downloadButton) { return; }

      if (props.isDownloadingMovieFile) {
        downloadButton.disable();
      } else {
        downloadButton.enable();
      }
    });

    const x = computed({
      get: () => { return props.dialogInfo.options.left; },
      set: (val: number) => { props.dialogInfo.options.left = val; },
    });
    const y = computed({
      get: () => { return props.dialogInfo.options.top; },
      set: (val: number) => { props.dialogInfo.options.top = val; },
    });
    const w = computed({
      get: () => { return props.dialogInfo.options.width; },
      set: (val: number) => { props.dialogInfo.options.width = val; },
    });
    const h = computed({
      get: () => { return props.dialogInfo.options.height; },
      set: (val: number) => { props.dialogInfo.options.height = val; },
    });
    const isLiveMovie = computed(() => {
      return props.dialogInfo.kind === 'realtime';
    });
    const isStoredMovie = computed(() => {
      return !isLiveMovie.value;
    });
    const shouldShowPostPlayView = computed(() => {
      return isStoredMovie.value && state.movieEnded;
    });
    const hasNextMovie = computed(() => {
      return isStoredMovie.value && props.dialogInfo.movie.next_mgi;
    });
    const vid = computed(() => {
      const uid = Math.floor(Math.random() * 10000);
      return `vid-${uid}`;
    });
    const liveMovieBadgeColorStr = computed(() => {
      const r = props.dialogInfo.car.color ? props.dialogInfo.car.color[0] : 0;
      const g = props.dialogInfo.car.color ? props.dialogInfo.car.color[1] : 0;
      const b = props.dialogInfo.car.color ? props.dialogInfo.car.color[2] : 0;
      return `rgb(${r},${g},${b})`;
    });
    const storedMovieBadgeColorStr = computed(() => {
      const r = props.dialogInfo.movie.color ? props.dialogInfo.movie.color[0] : 0;
      const g = props.dialogInfo.movie.color ? props.dialogInfo.movie.color[1] : 0;
      const b = props.dialogInfo.movie.color ? props.dialogInfo.movie.color[2] : 0;
      return `rgb(${r},${g},${b})`;
    });
    const liveMovieTitle = computed(() => {
      return props.dialogInfo.car.device?.car_name;
    });
    const isSafieDevice = computed(() => {
      return props.dialogInfo.car?.external_type === 'safie';
    });
    const movieMimeType = computed(() => {
      return state.isIOS || isSafieDevice.value ? 'application/x-mpegURL' : 'application/dash+xml';
    });
    const liveMovieMimeType = computed(() => {
      return movieMimeType.value;
    });
    const isDevelopment = Vue.prototype.$isDevelopment;
    const safieLiveMovieUrl = computed(() => {
      const movieObj = props.dialogInfo.car.device?.live_stream;
      const proto = isDevelopment() ? 'http' : 'https';
      const hostport = isDevelopment() ? movieObj?.streaming_server_hostport : movieObj?.https_streaming_server_hostport;
      const path = `${movieObj?.stream_name}/${movieObj?.file_hls}`;
      return `${proto}://${hostport}/${path}`;
    });
    const liveMovieUrl = computed(() => {
      if (!props.dialogInfo.car.device?.live_stream) { return ''; }
      if (isSafieDevice.value) {
        return safieLiveMovieUrl.value;
      }
      const movieObj = props.dialogInfo.car.device.live_stream;
      const proto = 'https'; // httpsにしないとmpegdash見れない
      const hostport = movieObj.https_streaming_server_hostport;
      const appName = 'live';
      const filename = state.isIOS ? movieObj.file_hls : movieObj.file_mpegdash;
      const path = `${movieObj.stream_name}/${filename}`;
      const authToken = movieObj.auth_token;
      return `${proto}://${hostport}/${appName}/${path}?t=${authToken}`;
    });
    const storedMovieMimeType = computed(() => {
      return movieMimeType.value;
    });
    const storedMovieUrl = computed(() => {
      if (!props.dialogInfo.movie) { return ''; }
      // return 'http://192.168.25.25:23468/test_movies/sample1.mp4'
      const movieObj = props.dialogInfo.movie;
      const proto = 'https'; // httpsにしないとmpegdash見れない
      const hostport = movieObj.https_streaming_server_hostport;
      const appName = movieObj.vod_app_name;
      const filename = state.isIOS ? movieObj.file_hls : movieObj.file_mpegdash;
      const path = `${movieObj.movie_path}/${filename}`;
      const authToken = movieObj.auth_token;
      return `${proto}://${hostport}/${appName}${path}?t=${authToken}`;
    });
    onMounted(() => {
      detectUserAgent();
      state.roadNameDirectionMap = props.roadNameDirections.reduce((acc: Record<string, RoadNameDirection>, e) => {
        acc[e.roadNameReal] = e; return acc;
      }, {});

      prepareVideoPlayer();
    });

    const getMobileDetector = Vue.prototype.$getMobileDetector;
    const detectUserAgent = () => {
      const md = getMobileDetector(window.navigator.userAgent);
      state.isMobile = md.isMobile();
      state.isIOS = md.isIOS();
      state.isAndroid = md.isAndroid();
    };
    const formatDt = (inputDt: string) => {
      const dt = new Date(inputDt);
      var y = dt.getFullYear();
      var mon = ('0' + (dt.getMonth() + 1)).slice(-2);
      var d = ('0' + dt.getDate()).slice(-2);
      var h = ('0' + dt.getHours()).slice(-2);
      var min = ('0' + dt.getMinutes()).slice(-2);
      var s = ('0' + dt.getSeconds()).slice(-2);
      var ts = `${y}/${mon}/${d} ${h}:${min}:${s}`;
      return ts;
    };
    const prepareVideoPlayer = () => {
      if (isLiveMovie.value) {
        prepareLiveMovieVideojs();
      } else {
        prepareStoredMovieVideojs();
        prepareCandidacies();
      }
    };
    const getVideoJsDefaultOptions = (): VideoJsPlayerOptionsExt => {
      // https://docs.videojs.com/tutorial-components.html
      return {
        autoplay: true,
        controlBar: {
          // 音は出さないでと言われたので
          volumePanel: false,
          pictureInPictureToggle: false,
        },
      };
    };
    const setDownloadButton = (vjs: VideoJsPlayer) => {
      const controlBar = vjs.getChild('controlBar');
      const fullscreenToggle = controlBar?.getChild('FullscreenToggle');
      const fullscreenToggleIndex = controlBar?.children().findIndex(e => {
        return e.id() === fullscreenToggle?.id();
      });

      if (!controlBar) {
        return;
      }

      controlBar.addChild(
        'downloadButton',
        {
          clickHandler: () => { tryDownloadStoredMovieFile(); },
        },
        fullscreenToggleIndex,
      );
    };
    const setVideojsHeaders = (vhs: {xhr: { beforeRequest: (options: videojs.XhrOptions) => void }}) => {
      const upstreamIp = props.dialogInfo.car.device?.live_stream?.upstream_ip;
      vhs.xhr.beforeRequest = (options: videojs.XhrOptions) => {
        options.headers = options.headers || {};
        options.headers['Authorization'] = `Bearer ${props.dialogInfo.car.device?.live_stream?.auth_token}`;
        options.headers['upstream_ip'] = `${upstreamIp}`;
        return options;
      };
    };
    const fetchDeviceLiveStreamInfo = async(device: DeviceExt) => {
      // safie_devicesにはidがないので、device_idで取得する
      const res = await (isSafieDevice.value ? deviceApi.getSafieDeviceLiveStreamInfo(device.device_id)
        : deviceApi.getDeviceLiveStreamInfo(device.device_id));
      if (!res.data) {
        return null;
      }
      return res.data;
    };
    const beforeRequestUndefined = () => {};
    const prepareLiveMovieVideojs = () => {
      const vjsOpts = getVideoJsDefaultOptions();
      vjsOpts.errorDisplay = false;

      const vjsTmp = videojs(vid.value, vjsOpts);
      const onReady = async() => {
        // live_stream情報を取得する
        if (!props.dialogInfo.car.device) { return; }
        props.dialogInfo.car.device.live_stream = await fetchDeviceLiveStreamInfo(props.dialogInfo.car.device);
        // safie以外の場合は、下記の設定があるとCORSエラーになるため、クリアする
        videojs.Vhs.xhr.beforeRequest = beforeRequestUndefined;
        if (isSafieDevice.value) {
          // playlistを取得するため、一時的にglobalのVhsに認証情報を設定するが、play開始時に消す
          // onPause->onPlayの場合も同様に再設定が必要
          setVideojsHeaders(videojs.Vhs);
        }
        vjsTmp.src({
          src: liveMovieUrl.value,
          type: liveMovieMimeType.value,
        });
        vjsTmp.muted(true);
        vjsTmp.volume(0.0);
        vjsTmp.controlBar.options_.progressControl = false;
        vjsTmp.controlBar.options_.remainingTimeDisplay = false;
        vjsTmp.play();
        state.liveModeInfo.playResetFlag = true;
      };
      vjsTmp.ready(onReady);
      vjsTmp.on('loadedmetadata', () => {
        // durationがinfinityだと表示されるらしいが、
        // サーバから返されるdurationがそうでもないっぽいので強引に
        vjsTmp.controlBar.options_.liveDisplay = true;
      });

      // 一度停止してしばらく経つと、その後再生しても
      // もうその部分の動画はサーバ上に無いケースが多いので
      // 再度読み込んでしまうことにする.
      // その場合、onPause->onPlay->onPlayと呼び出される.
      vjsTmp.on('play', () => {
        if (state.liveModeInfo.wasPaused) {
          state.liveModeInfo.wasPaused = false;
          // console.log(`* vjs ${vid.value} on play after pause`)
          onReady();
        }
        // onPause->onPlayの場合、設定されたvhsがクリアされてしまうため、
        // onReady()の後に設定する.
        const vjsTech = vjsTmp.tech() as any;
        if (isSafieDevice.value && vjsTech.vhs) {
          // globalの設定をクリアし、個別playerにヘッダーを設定する
          videojs.Vhs.xhr.beforeRequest = beforeRequestUndefined;
          setVideojsHeaders(vjsTech.vhs);
        }
        console.log('vhs.xhr.beforeRequest inside on(play):', vjsTech.vhs?.xhr?.beforeRequest);
      });
      vjsTmp.on('pause', () => {
        state.liveModeInfo.wasPaused = true;
      });

      /*
       * 一定時間ごとに、再生位置が進んでいるかどうかを監視し、
       * 閾値回数a以上連続で進んでいなかった場合リトライを試みる.
       * リトライ回数を監視し、閾値回数b以上連続で失敗した場合
       * 諦めてエラー画面を表示する.
       * 再生位置が進んでいたらリトライ回数はリセットする.
       * ライブ配信の場合、リトライ(pause->play)を行うと見た目上の再生位置が
       * 変化していないにも関わらずvjs.currentTime()は進んでしまうっぽいので、
       * play直後は適当に細工してやる.
       */
      vjsTmp.one('play', () => {
        state.liveModeInfo.lastPlaybackPosition = vjsTmp.currentTime();
        const resetCounts = () => {
          state.liveModeInfo.zeroProgressCount = 0;
          state.liveModeInfo.playRetryCount = 0;
        };
        resetCounts();
        const maxRetryCount = 10;
        const vjsTech = vjsTmp.tech() as any;
        state.timeupdateDetectTimer = window.requestInterval(() => {
          console.log('vhs.xhr.beforeRequest inside one(play):', vjsTech.vhs?.xhr?.beforeRequest);
          // 一旦エラー表示になったらもうそのまま.
          if (state.liveModeInfo.playRetryCount >= maxRetryCount) {
            state.shouldShowLiveErrorView = true;
            return;
          }
          // pause->play後は映像の見た目の状態とは関係なくcurrentTimeは
          // 進んでしまってるようなので、一回だけ無視できるようにする.
          if (state.liveModeInfo.playResetFlag) {
            state.liveModeInfo.playResetFlag = false;
            state.liveModeInfo.lastPlaybackPosition = vjsTmp.currentTime();
            return;
          }
          // pause中はカウントアップしなくていい.
          if (vjsTmp.paused()) {
            resetCounts();
            return;
          }
          const currentTime = vjsTmp.currentTime();
          const playbackPositionDiff =
            currentTime - state.liveModeInfo.lastPlaybackPosition;
          state.liveModeInfo.lastPlaybackPosition = currentTime;
          console.log(`* vjs ${vid.value} ct=${currentTime}, diff=${playbackPositionDiff}`);
          if (playbackPositionDiff < Number.EPSILON) {
            console.log(`* vjs ${vid.value} increment zp count since diff == 0`);
            state.liveModeInfo.zeroProgressCount++;
          } else {
            console.log(`* vjs ${vid.value} reset zp count since diff > 0`);
            resetCounts();
          }

          const thres = 2;
          if (state.liveModeInfo.zeroProgressCount >= thres) {
            state.liveModeInfo.playRetryCount++;
            console.log(`* vjs ${vid.value} ${thres} or more zero progress detected`);
            console.log(`* vjs ${vid.value} zp=${state.liveModeInfo.zeroProgressCount}, pr=${state.liveModeInfo.playRetryCount}`);
            vjsTmp.pause();
            window.requestTimeout(() => {
              if (isSafieDevice.value) {
                // セーフィー再生中に配信サーバーが切り替わる場合があるため、srcも含めて再設定が必要
                onReady();
              } else {
                vjsTmp.play();
              }
            }, 100);
          }
        }, 3 * 1000);
      });

      vjs = vjsTmp;
    };
    const prepareStoredMovieVideojs = () => {
      const vjsTmp = videojs(vid.value, getVideoJsDefaultOptions());
      vjsTmp.ready(() => {
        // 過去動画の場合はダウンロードボタンを表示する
        setDownloadButton(vjsTmp);
        vjsTmp.src({
          src: storedMovieUrl.value,
          type: storedMovieMimeType.value,
        });
        vjsTmp.muted(true);
        vjsTmp.volume(0.0);
        vjsTmp.one('play', () => {
          const startSec = parseInt((props.dialogInfo.movie.playStartMsec / 1000).toString());
          vjsTmp.currentTime(startSec);
          state.storedModeInfo.hasStarted = true;
        });
        vjsTmp.on('play', () => {
          state.movieEnded = false;
        });
        vjsTmp.on('ended', () => {
          state.movieEnded = true;
        });
        vjsTmp.play();
      });

      vjs = vjsTmp;
    };
    const prepareCandidacies = () => {
      const candidacies = props.dialogInfo.candidacies || [];
      const candidacyIdx = props.dialogInfo.candidacyIdx;
      const newCandidacies = candidacies.map(c => {
        let roadName = c.road_name_disp;
        if (state.roadNameDirectionMap[roadName]) {
          roadName = state.roadNameDirectionMap[roadName].roadNameDisp;
        }
        return {
          ...c,
          roadNameShort: roadName,
          tsDisp: c.ts ? formatDt(c.ts) : '',
          latDisp: convertDecimalLatLonToDms(Number(c.lat)),
          lonDisp: convertDecimalLatLonToDms(Number(c.lon)),
        };
      });

      state.selectedCandidacy = newCandidacies[candidacyIdx];
      state.otherCandidacies = newCandidacies.filter(c => {
        return c.id !== state.selectedCandidacy?.id;
      });
    };
    const convertDecimalLatLonToDms = (latLon: number) => {
      const deg = Math.floor(latLon);
      const min = Math.floor((latLon - deg) * 60);
      const sec = ((latLon - deg) * 60 - min) * 60;
      return `${deg}°${min}′${sec.toFixed(4)}″`;
    };
    const close = () => {
      // これを呼び出さないと通信が継続されてしまう
      vjs.dispose();
      emit('close', props.dialogInfo.dialogId);
    };
    const onDrag = (drayX: number, dragY: number) => {
      if (!state.isDragged) {
        emit('drag-start', props.dialogInfo.dialogId);
      }
      state.isDragged = true;
      x.value = drayX;
      y.value = dragY;
    };
    const onDragStop = () => {
      // handleをつかんだが動かさずに話した場合は、
      // isDraggedが更新されてないのでfalseになる
      state.wasMovedOnLastDrag = state.isDragged;
      state.isDragged = false;
      emit('drag-end', props.dialogInfo.dialogId);
    };
    const onResizeStop = (resizeX: number, resizeY: number, resizeW: number, resizeH: number) => {
      x.value = resizeX;
      y.value = resizeY;
      w.value = resizeW;
      h.value = resizeH;
      emit('resize-end', props.dialogInfo.dialogId);
    };
    const tryToggleMovieCandidacies = () => {
      // control-barがdrag領域になってるので、この動作がドラッグなのか
      // クリックなのかを判定する必要あり
      if (state.wasMovedOnLastDrag) { return; }

      const show = !state.shouldShowMovieCandidacies;
      if (show) {
        updateMovieCandidaciesDimensions();
        state.shouldShowMovieCandidacies = true;
      } else {
        state.shouldShowMovieCandidacies = false;
      }
    };
    const selectionAreaWrapDom = ref<HTMLDivElement>();
    const el = root.$el as HTMLElement;
    const updateMovieCandidaciesDimensions = () => {
      const topNavBarHeight = 57;
      const usableWindowHeight = window.innerHeight - topNavBarHeight;
      const controlBarHeight = 22;
      const candidacyRowHeight = 22;
      const listMaxheight = 300;
      const listOffsetAdjustAbove = 2;
      const listOffsetAdjustBelow = -2;
      let listHeight = candidacyRowHeight * state.otherCandidacies.length;
      listHeight = Math.min(listHeight, listMaxheight);

      // 路線名によって可変なので...
      state.movieCandidaciesDimension.x = selectionAreaWrapDom.value?.offsetLeft || 0;

      state.movieCandidaciesDimension.h = listHeight;

      const hasSpaceBelow = usableWindowHeight - ((el.offsetTop || 0) + controlBarHeight + listHeight) > 0;
      const hasSpaceAbove = el.offsetTop - listHeight > 0;
      const showBelow = hasSpaceBelow || !hasSpaceAbove;
      if (showBelow) {
        state.movieCandidaciesDimension.y = controlBarHeight + listOffsetAdjustBelow;
      } else {
        state.movieCandidaciesDimension.y = -listHeight + listOffsetAdjustAbove;
      }
    };
    const onClickMovieCandidate = (clickedCandidate: MovieCandidate) => {
      const candidacies = props.dialogInfo.candidacies.slice();
      // extremeMapでソートしてから渡しているのだが、
      // ユーザーが候補リストから選択した際は、選択した
      // 場所(本線、それ以外)が同じものが上にきててほしい
      candidacies.sort((a, b) => {
        const pn1 = a.place_name;
        const pn2 = b.place_name;
        const pnSortKey1 = clickedCandidate.place_name === pn1 ? 0 : (pn1 === 'main_line' ? 1 : 2);
        const pnSortKey2 = clickedCandidate.place_name === pn2 ? 0 : (pn2 === 'main_line' ? 1 : 2);
        const ts1 = a.ts ? new Date(a.ts) : new Date();
        const ts2 = b.ts ? new Date(b.ts) : new Date();
        const kp1 = parseFloat(a.kp.toString());
        const kp2 = parseFloat(b.kp.toString());
        if (pnSortKey1 !== pnSortKey2) {
          return pnSortKey1 < pnSortKey2 ? -1 : 1;
        } else if (ts1 !== ts2) {
          // 時間は降順
          return ts2 < ts1 ? -1 : 1;
        }
        // KPは昇順にしておこう
        return kp1 < kp2 ? -1 : (kp1 > kp2 ? 1 : 0);
      });

      const candidacyIdx = candidacies.findIndex(c => {
        return c.id === clickedCandidate.id;
      });
      emit('select-movie-candidate', {
        candidacies,
        candidacyIdx,
      });
    };
    const replay = () => {
      vjs.currentTime(0);
      vjs.play();
    };
    const playNextMovie = () => {
      // 次のmovieを再生する.
      // candidaciesは自分のみにしてしまう.
      const candidacies = [props.dialogInfo.movie.next_mgi].map(c => {
        let roadName = c.road_name || '';
        if (state.roadNameDirectionMap[roadName]) {
          roadName = state.roadNameDirectionMap[roadName].roadNameDisp;
        }
        return {
          ...c,
          placeNameDisp: c.place_name === 'main_line' ? '本線' : c.place_name,
          roadNameShort: roadName,
          tsDisp: c.ts ? formatDt(c.ts) : '',
        };
      });
      emit('select-movie-candidate', { candidacies, candidacyIdx: 0 });
    };
    const tryDownloadStoredMovieFile = async() => {
      // 連打防止
      if (props.isDownloadingMovieFile) { return; }
      emit('download-stored-movie-file', props.dialogInfo.dialogId);
    };

    onBeforeUnmount(() => {
      if (state.timeupdateDetectTimer) {
        window.clearRequestInterval(state.timeupdateDetectTimer);
      }
    });
    return {
      // const
      Z_INDEX,
      ...toRefs(state),
      // ref
      selectionAreaWrapDom,
      // computeds
      x,
      y,
      w,
      h,
      isLiveMovie,
      isStoredMovie,
      shouldShowPostPlayView,
      hasNextMovie,
      vid,
      liveMovieBadgeColorStr,
      storedMovieBadgeColorStr,
      liveMovieTitle,
      isSafieDevice,
      movieMimeType,
      liveMovieMimeType,
      safieLiveMovieUrl,
      liveMovieUrl,
      storedMovieMimeType,
      storedMovieUrl,
      // methods
      detectUserAgent,
      formatDt,
      prepareVideoPlayer,
      getVideoJsDefaultOptions,
      setDownloadButton,
      setVideojsHeaders,
      fetchDeviceLiveStreamInfo,
      prepareLiveMovieVideojs,
      prepareCandidacies,
      close,
      onDrag,
      onDragStop,
      onResizeStop,
      tryToggleMovieCandidacies,
      updateMovieCandidaciesDimensions,
      onClickMovieCandidate,
      replay,
      playNextMovie,
      tryDownloadStoredMovieFile,
    };
  },
});
