import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Icon, Style, Stroke, Fill, Circle, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Point, MultiPoint, LineString } from 'ol/geom';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import { Mtx, MtxMovie, MovieGeoIndex } from '@/models/apis/device/adminDeviceResponse';

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}

interface MtxPt {
  mtx: Mtx;
  pt: Coordinate;
}

interface PlaceInfo {
  placeNameDisp: string | null;
  kpDisp: string;
}
export type MtxExt = Mtx & PlaceInfo;
export type MovieGeoIndexExt = MovieGeoIndex & PlaceInfo;
export interface MtxMovieExt extends Omit<MtxMovie, 'movie_geo_indices'> {
  movie_geo_indices: MovieGeoIndexExt[];
}

export interface LayerParams {
  mtxs: MtxExt[];
  movies: MtxMovieExt[] ;
}

export default class ExtremeMapDebugMTXMovieLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  opts: Options;

  constructor(opts = {}) {
    super();
    this.opts = opts;
    this.layerName = 'debugMtxMovie';
  }

  formatDt(inputDt: string): string {
    const dt = new Date(inputDt);
    const y = dt.getFullYear();
    const mon = ('0' + (dt.getMonth() + 1)).slice(-2);
    const d = ('0' + dt.getDate()).slice(-2);
    const h = ('0' + dt.getHours()).slice(-2);
    const min = ('0' + dt.getMinutes()).slice(-2);
    const s = ('0' + dt.getSeconds()).slice(-2);
    const ts = `${y}-${mon}-${d} ${h}:${min}:${s}`;
    return ts;
  }

  getMtxsFeatures(mtxs: MtxExt[]): Feature[] {
    const line: Coordinate[] = [];
    const pointsUnderCalc: Coordinate[] = [];
    const pointsNoKp: Coordinate[] = [];
    const pointsYesKp: Coordinate[] = [];
    const texts: MtxPt[] = [];
    mtxs.forEach((mtx, i) => {
      const lon = parseFloat(mtx.lon);
      const lat = parseFloat(mtx.lat);
      if (lon < 0.000001 || lat < 0.000001) { return; }
      const pt = this.coordFromLonLat(lon, lat);
      line.push(pt);
      if (!mtx.is_place_filled) {
        pointsUnderCalc.push(pt);
      } else if (mtx.road_name === null) {
        pointsNoKp.push(pt);
      } else {
        pointsYesKp.push(pt);
      }
      if (mtx.showTimestamp && i % 15 === 0) {
        texts.push({ mtx, pt });
      }
    });

    const ret = [];
    let feat: Feature;
    let feats: Feature[] = [];

    feat = new Feature(new LineString(line));
    feat.setStyle(new Style({
      stroke: new Stroke({ color: '#bc42f4', width: 0.5 }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsUnderCalc));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 1,
        fill: new Fill({ color: '#bc42f4' }),
        stroke: new Stroke({ color: '#555555', width: 1 }),
      }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsNoKp));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 2,
        fill: new Fill({ color: '#222222' }),
        stroke: new Stroke({ color: '#000000', width: 1 }),
      }),
    }));
    ret.push(feat);

    feat = new Feature(new MultiPoint(pointsYesKp));
    feat.setStyle(new Style({
      image: new Circle({
        radius: 3,
        fill: new Fill({ color: '#9de532' }),
        stroke: new Stroke({ color: '#3c5d0c', width: 1 }),
      }),
    }));
    ret.push(feat);

    feats = texts.map(({ mtx, pt }) => {
      const ts = this.formatDt(mtx.ts).slice(-8);
      const text = ts;
      const feat = new Feature(new Point(pt));
      feat.setStyle(new Style({
        text: new Text({
          text: text,
          font: '8px sans-serif',
          fill: new Fill({ color: '#eeeeee' }),
          stroke: new Stroke({ color: [50, 50, 50, 0.8], width: 3 }),
          offsetX: 0,
          offsetY: 6,
        }),
      }));
      return feat;
    });
    ret.push(...feats);

    return ret;
  }

  getMoviesFeatures(movies: MtxMovieExt[]): Feature[] {
    const moviePts: Coordinate[] = [];
    const giPts: Coordinate[] = [];
    movies.forEach(movie => {
      const lon = parseFloat(movie.lon);
      const lat = parseFloat(movie.lat);
      if (isNaN(lon) || isNaN(lat)) { return; }
      if (lon < 0.000001 || lat < 0.000001) { return; }
      const pt = this.coordFromLonLat(lon, lat);
      moviePts.push(pt);
      movie.movie_geo_indices.forEach(gi => {
        const lon = parseFloat(gi.lon);
        const lat = parseFloat(gi.lat);
        if (isNaN(lon) || isNaN(lat)) { return; }
        if (lon < 0.000001 || lat < 0.000001) { return; }
        const pt = this.coordFromLonLat(lon, lat);
        giPts.push(pt);
      });
    });

    const movieMp = new MultiPoint(moviePts);
    const movieMpFeat = new Feature(movieMp);
    movieMpFeat.setStyle(new Style({
      image: new Icon({
        src: '/static/img/dbg-component-video-file.png',
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.04,
      }),
    }));

    const giMp = new MultiPoint(giPts);
    const giMpFeat = new Feature(giMp);
    giMpFeat.setStyle(new Style({
      image: new Icon({
        src: '/static/img/dbg-component-video-camera.png',
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.04,
      }),
    }));

    return [movieMpFeat, giMpFeat];
  }

  createLayer_({ mtxs, movies }: { mtxs: MtxExt[]; movies: MtxMovieExt[] }): void {
    const features = [];
    const mtxFeats = this.getMtxsFeatures(mtxs);
    const movieFeats = this.getMoviesFeatures(movies);
    features.push(...mtxFeats);
    features.push(...movieFeats);
    const layer: NamedVectorLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({features: features}),
    });
    layer.name = this.layerName;
    this.layer = layer;
  }

  prepareLayer(data: LayerParams): LayerWithInfo {
    this.createLayer_(data);
    return this.getLayer();
  }

  getDebugLayerName(): string {
    return this.layerName;
  }
}
