
















import { defineComponent, reactive, watch } from '@vue/composition-api';

interface MyCalenderState {
  dateValue?: Date | string;
  timeValue: string;
}

export default defineComponent({
  props: {
    dateValue: {
      type: [String, Date],
    },
  },
  setup(props, { emit }) {
    const getTimeValue = (): string => {
      if (props.dateValue) {
        const date = new Date(props.dateValue);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      } else {
        return '00:00';
      }
    };

    const initState = (): MyCalenderState => {
      return {
        timeValue: getTimeValue(),
        dateValue: props.dateValue,
      };
    };
    const state = reactive<MyCalenderState>(initState());

    const onTimeInput = () => {
      const dt = mergeDateAndTime();
      emit('date-input', dt);
    };

    const onDateInput = (date: Date) => {
      state.dateValue = date;
      const dt = mergeDateAndTime();
      emit('date-input', dt);
    };

    const trySetDateValue = () => {
      if (!props.dateValue) return;

      state.timeValue = getTimeValue();
      state.dateValue = props.dateValue;
    };

    const mergeDateAndTime = (): Date | string => {
      if (!state.dateValue) return '';

      const [hours, minutes] = state.timeValue.split(':').map(Number);
      const date = new Date(state.dateValue);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
    };
    watch(() => props.dateValue, trySetDateValue);
    return {
      state,
      onTimeInput,
      onDateInput,
    };
  },
});
