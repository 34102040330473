import userApi from '@/apis/user';
import { AxiosResponse } from 'axios';
import { RootState } from '@/hooks/useStore';
import { UserLoginParams } from '@/models/apis/user/userRequest';
import { CleaningCompany } from '@/models/apis/cleaning/cleaningCommon';
import { CleaningHan } from '@/models/apis/cleaningHanMasters/cleaningHanMastersResponse';
import { JohaisetsuHan } from '@/models/apis/johaisetsu/johaisetsuHanMastersResponse';
import {
  Ability,
  Settings,
  UserGetMeResponse,
  UserLoginResponse,
  UserLogoutResponse,
} from '@/models/apis/user/userResponse';
import {
  Store,
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

export interface LocalGroupInfo {
  g1_id: number;
  g1_name: string;
  g2_id: number;
  g2_name: string;
  g3_id: number;
  g3_name: string;
}

// the definition of initial state is required.
export interface UserState {
  id: number;
  username: string;
  display_name: string;
  role: number;
  has_role_super_admin: boolean;
  has_role_admin: boolean;
  has_role_manager: boolean;
  has_role_default: boolean;
  g_info: LocalGroupInfo;
  settings: Settings;
  johaisetsu_role: string;
  is_johaisetsu_only: boolean;
  cleaning_role: string;
  cleaning_han: CleaningHan[];
  johaisetsu_han: JohaisetsuHan[];
  cleaning_company: CleaningCompany[];
  is_cleaning_only: boolean;
  abilities: Ability[];
  abilityMap: Record<number, Ability>;
  group_id: number;
  active: number;
  timezone: string;
  default_page: string;
}

const state: UserState = {
  id: -1,
  username: '',
  display_name: '',
  role: -1,
  has_role_super_admin: false,
  has_role_admin: false,
  has_role_manager: false,
  has_role_default: false,
  g_info: {} as LocalGroupInfo,
  settings: {} as Settings,
  johaisetsu_role: '',
  is_johaisetsu_only: false,
  cleaning_role: '',
  cleaning_han: [],
  johaisetsu_han: [],
  cleaning_company: [],
  is_cleaning_only: false,
  abilities: [] as Ability[],
  abilityMap: {} as Record<number, Ability>,
  group_id: -1,
  active: -1,
  timezone: '',
  default_page: '',
};

export enum UserGetterTypes {
  IS_LOGGED_IN = 'isLoggedIn',
  GET_LOGIN_USER = 'getLoginUser',
}

export interface UserGetters<S = UserState> {
  [UserGetterTypes.IS_LOGGED_IN](state: S): boolean;
}

const getters: GetterTree<UserState, RootState> & UserGetters = {
  [UserGetterTypes.IS_LOGGED_IN]: (state) => state.id !== -1,
};

export enum UserMutationTypes {
  SET_LOGIN_USER = 'SET_LOGIN_USER',
  CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER',
}

export enum UserActionTypes {
  GET_ME = 'getMe',
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export type UserMutations<S = UserState> = {
  [UserMutationTypes.SET_LOGIN_USER](state: S, data: UserGetMeResponse): void;
  [UserMutationTypes.CLEAR_LOGIN_USER](state: S): void;
};

type AugmentedActionContext = {
  commit<K extends keyof UserMutations>(
    key: K,
    payload?: Parameters<UserMutations[K]>[1]
  ): ReturnType<UserMutations[K]>;
  getters: {
    [K in keyof UserGetters]: ReturnType<UserGetters[K]>
  };
} & Omit<ActionContext<UserState, RootState>, 'commit' | 'getters'>;

export interface UserActions {
  [UserActionTypes.GET_ME]({ commit, getters }: AugmentedActionContext): Promise<UserState>;
  [UserActionTypes.LOGIN]({ commit }: AugmentedActionContext, data: UserLoginParams): Promise<UserLoginResponse>;
  [UserActionTypes.LOGOUT]({ commit }: AugmentedActionContext): Promise<UserLogoutResponse>;
}

let promiseOfGetMe: Promise<AxiosResponse<UserGetMeResponse>> | null = null;
const actions: ActionTree<UserState, RootState> & UserActions = {
  async [UserActionTypes.GET_ME]({ commit }): Promise<UserState> {
    if (state.id !== -1) {
      return Promise.resolve({ ...state });
    }
    if (!promiseOfGetMe) {
      promiseOfGetMe = userApi.getMe();
    }
    const { data } = await promiseOfGetMe;
    commit(UserMutationTypes.SET_LOGIN_USER, data);
    return state;
  },
  async [UserActionTypes.LOGIN](_, loginParams: UserLoginParams): Promise<UserLoginResponse> {
    promiseOfGetMe = null;
    const { data } = await userApi.login(loginParams);
    return data;
  },
  async [UserActionTypes.LOGOUT]({ commit }): Promise<UserLogoutResponse> {
    promiseOfGetMe = null;
    const { data } = await userApi.logout();
    commit(UserMutationTypes.CLEAR_LOGIN_USER);
    return data;
  },
};

const mutations: MutationTree<UserState> & UserMutations = {
  [UserMutationTypes.SET_LOGIN_USER](state, data): void {
    state.id = data.id;
    state.username = data.username;
    state.display_name = data.display_name;
    state.role = data.role;
    state.has_role_super_admin = data.has_role_super_admin;
    state.has_role_admin = data.has_role_admin;
    state.has_role_manager = data.has_role_manager;
    state.settings = data.settings;
    state.johaisetsu_role = data.johaisetsu_role;
    state.is_johaisetsu_only = data.is_johaisetsu_only;
    state.cleaning_role = data.cleaning_role;
    state.cleaning_company = data.cleaning_company;
    state.cleaning_han = data.cleaning_han;
    state.johaisetsu_han = data.johaisetsu_han;
    state.is_cleaning_only = data.is_cleaning_only;
    state.abilities = data.abilities;
    state.default_page = data.default_page;

    state.abilityMap = state.abilities.reduce((acc: Record<number, Ability>, e: Ability) => {
      acc[e.id] = e;
      return acc;
    }, {});

    state.g_info.g1_id = data.g1id;
    state.g_info.g1_name = data.g1name;
    state.g_info.g2_id = data.g2id;
    state.g_info.g3_name = data.g2name;
    state.g_info.g3_id = data.g3id;
    state.g_info.g3_name = data.g3name;
    state.settings = data.settings;
    state.abilities = data.abilities;
  },
  [UserMutationTypes.CLEAR_LOGIN_USER](state): void {
    state.id = -1;
    state.username = '';
    state.display_name = '';
    state.role = -1;
    state.has_role_super_admin = false;
    state.has_role_admin = false;
    state.has_role_manager = false;
    state.has_role_default = false;
    state.g_info = {} as LocalGroupInfo;
    state.settings = {} as Settings;
    state.johaisetsu_role = '';
    state.is_johaisetsu_only = false;
    state.cleaning_role = '';
    state.cleaning_company = [];
    state.cleaning_han = [];
    state.is_cleaning_only = false;
    state.abilities = [];
    state.abilityMap = {} as Record<number, Ability>;
    state.default_page = '';
  },
};

export type UserStore<S = UserState> = Omit<Store<S>, 'getters' | 'commit' | 'dispatch'>
& {
  getters: {
    [K in keyof UserGetters]: ReturnType<UserGetters[K]>
  };
} & {
  commit<K extends keyof UserMutations, P extends Parameters<UserMutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<UserMutations[K]>;
} & {
  dispatch<K extends keyof UserActions>(
    key: K,
    payload?: Parameters<UserActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<UserActions[K]>;
};

export const store: Module<UserState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
