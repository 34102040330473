import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  WeatherObservatoryIndexResponse,
  GetWeatherObservatoryResponse,
  WeatherObservatoryDataPointResponse,
} from '@/models/apis/weatherObservatory/weatherObservatoryResponse';
import {
  WeatherObservatoryIndexRequest,
  GetWeatherObservatoryRequest,
} from '@/models/apis/weatherObservatory/weatherObservatoryRequest';

export default {
  index(params: WeatherObservatoryIndexRequest): Promise<AxiosResponse<WeatherObservatoryIndexResponse>> {
    return axios.get(utils.getApiUrl('/weather_observatories'), {
      params: params || {},
    });
  },
  getWeatherObservatory(params: GetWeatherObservatoryRequest): Promise<AxiosResponse<GetWeatherObservatoryResponse>> {
    return axios.get(utils.getApiUrl('/get_weather_observatories'), {
      params: params || {},
    });
  },
  getWeatherObservatoryDataPoints(
    observatory_id: number,
    params: WeatherObservatoryIndexRequest,
  ): Promise<AxiosResponse<WeatherObservatoryDataPointResponse>> {
    return axios.get(utils.getApiUrl(`/weather_observatories/data_points/${observatory_id}`), {
      params: params || {},
    });
  },
};
