import { computed, reactive } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import weatherObservatoryApi from '@/apis/weather_observatory';
import { WeatherObservatoryExt } from '@/models';
import {
  UseWeatherObservatoryParams,
  UseWeatherObservatoryResult,
  UseWeatherObservatoryState,
} from '@/models/useWeatherObservatory';
import { getInitUseWeatherObservatoryState } from '@/composables/utils';
import { WeatherObservatoryDataPoint } from '@/models/apis/weatherObservatory/weatherObservatoryResponse';
import { dtFormat } from '@/lib/dateHelper';
import { getInitDataPoint } from '@/lib/weatherObservatoryHelper';

export function useWeatherObservatory(params: UseWeatherObservatoryParams): UseWeatherObservatoryResult {
  const state = reactive<UseWeatherObservatoryState>(getInitUseWeatherObservatoryState());
  const store = useStore();
  const userState = store.state.user;
  const canUseWeatherObservatory = computed<boolean>(() => {
    return userState.settings.can_use_weather_observatory;
  });
  const onClickWeatherObservatory = async(observatory: WeatherObservatoryExt): Promise<void> => {
    params.deselectLayers({ excludes: ['weatherObservatories'] });
    if (!params.refExtremeMap.value) return;
    if (observatory.isSelected) {
      const now = new Date();
      const roundedMinutes = Math.floor(now.getMinutes() / 10) * 10;
      const tsTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), roundedMinutes, 0, 0);
      const tsFrom = new Date(tsTo.getTime() - 1000 * 60 * 60);
      const { data: dataPoints } = await weatherObservatoryApi.getWeatherObservatoryDataPoints(observatory.id, {
        ts_from: tsFrom,
        ts_to: tsTo,
      });
      const filledDataPoints = fillEmptyDataToDataPoints(tsFrom, tsTo, observatory.id, dataPoints);
      params.refExtremeMap.value.showWeatherObservatoryPopup(observatory, filledDataPoints);
    } else {
      params.refExtremeMap.value.hidePopup();
    }
  };
  const fillEmptyDataToDataPoints = (
    tsFrom: Date,
    tsTo: Date,
    observatory_id: number,
    dataPoints:WeatherObservatoryDataPoint[],
  ): WeatherObservatoryDataPoint[] => {
    const filledDataPoints: WeatherObservatoryDataPoint[] = [];
    const interval = 10 * 60 * 1000;
    for (let timestamp = tsFrom.getTime(); timestamp <= tsTo.getTime(); timestamp += interval) {
      const matchedDataPoint = dataPoints.find(dp => new Date(dp.timestamp).getTime() === timestamp);
      matchedDataPoint
        ? filledDataPoints.push(matchedDataPoint)
        : filledDataPoints.push(getInitDataPoint(observatory_id, dtFormat(new Date(timestamp))));
    }
    return filledDataPoints;
  };
  const deselectAllWeatherObservatory = (): void => {
    if (!state.showWeatherObservatories || !params.refExtremeMap.value) return;
    params.refExtremeMap.value.deselectAllWeatherObservatory();
  };
  const toggleWeatherObservatoryLayer = async(): Promise<void> => {
    if (!params.refExtremeMap.value) return;
    const currentDataMap = params.refExtremeMap.value.getWeatherObservatoryMap();
    if (state.showWeatherObservatories) {
      params.state.showWaitSpinner = true;
      if (!state.weatherObservatoryAreaFilter.length) {
        params.refExtremeMap.value.showWeatherObservatoryLayer([]);
        params.state.showWaitSpinner = false;
        return;
      }
      const reqParams = { areas: state.weatherObservatoryAreaFilter };
      const { data } = await weatherObservatoryApi.getWeatherObservatory(reqParams);
      const observatoriesExt = data.map(e => {
        return {
          ...e,
          isSelected: false,
        };
      });
      params.state.showWaitSpinner = false;
      observatoriesExt.forEach(e => {
        const current = currentDataMap[e.id];
        e.isSelected = current ? current.isSelected : false;
      });
      params.refExtremeMap.value.showWeatherObservatoryLayer(observatoriesExt);
    } else {
      const isSelected = Object.values(currentDataMap).find(e => e.isSelected);
      if (isSelected) {
        params.refExtremeMap.value.hidePopup();
      }
      params.refExtremeMap.value.removeWeatherObservatoryLayer();
    }
  };

  return {
    state,
    canUseWeatherObservatory,
    onClickWeatherObservatory,
    deselectAllWeatherObservatory,
    toggleWeatherObservatoryLayer,
  };
}
