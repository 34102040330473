











import {
  defineComponent,
} from '@vue/composition-api';
import { DispData } from '@/models/geoItem';
import { getGeoItemColor } from '@/lib/johaisetsu/johaisetsuCommonUtil';

export default defineComponent({
  setup() {
    const dispData: DispData[] = [
      { val: 1, label: '1' },
      { val: 2, label: '2' },
      { val: 3, label: '3' },
      { val: 4, label: '4' },
      { val: 5, label: '5' },
      { val: 10, label: '10' },
      { val: 15, label: '15' },
      { val: 20, label: '20' },
    ];
    dispData.forEach(e => {
      e.color = getGeoItemColor(e.val);
    });
    return { dispData };
  },
});
