
import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import { dtFormat } from '@/lib/dateHelper';
import johaisetsuSagyouJoukyouApi from '@/apis/johaisetsu/johaisetsu_sagyou_joukyou';
import {
  getTaskForceInfos,
  getJohaisetsuRoadNameInfos,
  isDataReadOnly,
  onChangeUrlPathParam,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  filterJohaisetsuSagyouJoukyouRoadNameInfoByRole,
  getStructurizedKanriBlockData,
  updateStatusDependentParams,
  getClearedErrorMap,
  checkItems,
  organizeDateTimeProps,
  deconvProps,
} from '@/lib/johaisetsu/johaisetsuSagyouJoukyouUtil';
import { notifySuccess } from '@/lib/notificationUtil';
import { johaisetsuKpDisp1 } from '@/lib/utils';
import SagyouJoukyouMap from '@/components/Johaisetsu/SagyouJoukyouMap/SagyouJoukyouMap.vue';
import DateTimeInput from '@/components/lib/DateTimeInput.vue';
import { ABILITY_SAGYOU_JOUKYOU_MAP } from '@/consts/ability';
import {
  JOHAISETSU_STATUS_NONE,
  JOHAISETSU_STATUS_TSUKOU_DOME,
  JOHAISETSU_STATUS_JOSETSU_CHU,
  JOHAISETSU_STATUS_HAISETSU_CHU,
  JOHAISETSU_STATUS_HAISETSU_KANRYOU,
  JOHAISETSU_STATUS_JISSA_CHU,
  JOHAISETSU_STATUS_JISSA_KANRYOU,
} from '@/consts/johaisetsu';
import { CommonHeader,
  JoukyouInfoRaw,
  StatusMap,
  KanriBlock,
  SagyouJoukyouDetail,
  SagyouJoukyouDetailRaw } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { JohaisetsuSagyouJoukyouRoadName } from '@/models/apis/master/masterResponse';
import { TaskForce, SagyouJoukyouInputError, MapBlockInfo } from '@/models/johaisetsu/johaisetsuCommon';
import { Ability } from '@/models/apis/user/userResponse';
import { IntervalID } from '@/lib/requestAnimationFrame';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsu/johaisetsuRoleHelper';

interface RoadName {
  roadNameDispKey: number;
}
interface StatusPopoverParams {
  top: number;
  left: number;
  arrow: string;
  blockInfo: KanriBlock;
}
interface StatusPopoverBboxParams {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface SagyouJoukyouInputState {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;
  roadNameInfos: JohaisetsuSagyouJoukyouRoadName[];
  taskForces: TaskForce[];
  sagyouJoukyouHeaders: CommonHeader[];
  johaisetsuStatuses: StatusMap[];
  selectedTaskForce: TaskForce;
  selectedRoadNameDispKey: number;
  showTaskForceSelect: boolean;
  selectedSagyouJoukyouInfo: JoukyouInfoRaw<SagyouJoukyouDetailRaw> | null;

  diagramUpper: KanriBlock | null;
  diagramLower: KanriBlock | null;
  tableMainLineBlocks: KanriBlock[];
  tableGateways: KanriBlock[];

  // 図の1セルあたりの幅(px)
  unitWidth: number;

  datePickerXOffsetCalcParent?: HTMLDivElement | null;
  datePickerYOffsetCalcParent?: HTMLDivElement | null;
  errorMap: Record<string, SagyouJoukyouInputError>;
  hasError: boolean;

  statusMap: Record<string, number>;

  // ポップアップ
  statusPopoverParams: StatusPopoverParams;
  statusPopoverBboxParams: StatusPopoverBboxParams;

  // 地図のデバッグ用.
  // sagyouJoukyouMapDemoMixinなど参照.
  showDebugMapView: boolean;
  debugMapDemoSpeed: number; // ms
  debugMapDemoJohaisetsuStatus: number;
  debugMapDemoSteps: MapBlockInfo[];
  debugMapDemoCurrentStepIdx: number;
  debugMapDemoTimerId: IntervalID | null;
}
export default defineComponent({
  name: 'johaisetsu-sagyou-joukyou-input',
  components: { SagyouJoukyouMap, DateTimeInput },
  setup() {
    const state = reactive<SagyouJoukyouInputState>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,
      roadNameInfos: [],
      taskForces: [],
      sagyouJoukyouHeaders: [],
      johaisetsuStatuses: [],
      selectedTaskForce: { id: -1, name: '' },
      selectedRoadNameDispKey: 0,
      showTaskForceSelect: false,
      selectedSagyouJoukyouInfo: null,

      diagramUpper: null,
      diagramLower: null,
      tableMainLineBlocks: [],
      tableGateways: [],

      // 図の1セルあたりの幅(px)
      unitWidth: 30,

      datePickerXOffsetCalcParent: null,
      datePickerYOffsetCalcParent: null,
      errorMap: {} as Record<string, SagyouJoukyouInputError>,
      hasError: false,

      statusMap: {
        none: JOHAISETSU_STATUS_NONE,
        tsukouDome: JOHAISETSU_STATUS_TSUKOU_DOME,
        josetsuChu: JOHAISETSU_STATUS_JOSETSU_CHU,
        haisetsuChu: JOHAISETSU_STATUS_HAISETSU_CHU,
        haisetsuKanryou: JOHAISETSU_STATUS_HAISETSU_KANRYOU,
        jissaChu: JOHAISETSU_STATUS_JISSA_CHU,
        jissaKanryou: JOHAISETSU_STATUS_JISSA_KANRYOU,
      },

      // ポップアップ
      statusPopoverParams: {
        top: 0,
        left: -9999,
        arrow: 'top',
        blockInfo: { inputMap: { status: 0 } },
      },
      statusPopoverBboxParams: {
        top: 0,
        left: -9999,
        width: 0,
        height: 0,
      },

      // 地図のデバッグ用.
      // sagyouJoukyouMapDemoMixinなど参照.
      showDebugMapView: false,
      debugMapDemoSpeed: 500, // ms
      debugMapDemoJohaisetsuStatus: 10,
      debugMapDemoSteps: [],
      debugMapDemoCurrentStepIdx: 0,
      debugMapDemoTimerId: null,
    });
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });
    const isStatusPopoverVisible = computed<boolean>(() => {
      return state.statusPopoverParams.left >= 0;
    });
    const canUseSagyouJoukyouMap = computed<boolean>(() => {
      return !!abilityMap.value[ABILITY_SAGYOU_JOUKYOU_MAP];
    });
    const parseUrlParams = (): RoadName | null => {
      let locSearch = window.location.search;
      if (!locSearch) { return null; }
      locSearch = decodeURIComponent(locSearch);
      locSearch = locSearch.replace(/^\?/, '');
      const ret = {} as RoadName;
      locSearch.split('&').forEach(e => {
        const [k, v] = e.split('=');
        if (k === 'rndk') {
          ret.roadNameDispKey = parseInt(v);
        }
      });
      return ret;
    };
    const restoreUIStateFromUrlParams = () => {
      const urlParams = parseUrlParams();
      if (urlParams?.roadNameDispKey) {
        state.selectedRoadNameDispKey = urlParams.roadNameDispKey;
        onRoadNameDispChange();
      }
    };
    const syncUIStateToUrlParams = () => {
      const arr = [];
      if (state.selectedRoadNameDispKey) {
        arr.push(`rndk=${state.selectedRoadNameDispKey}`);
      }
      let search = '';
      if (arr.length > 0) {
        search = '?' + arr.join('&');
      }
      history.replaceState({}, '', route.value.path + search);
    };
    const mainLinesTable = ref<HTMLDivElement>();
    const main2table = ref<HTMLDivElement>();
    const updateRefs = () => {
      nextTick(() => {
        state.datePickerXOffsetCalcParent = mainLinesTable.value;
        state.datePickerYOffsetCalcParent = main2table.value;
      });
    };
    const directionLongDisp1 = (direction: string) => {
      let ret = direction;
      switch (direction) {
        case '上': ret = '上り線'; break;
        case '下': ret = '下り線'; break;
        case '南': ret = '南行き'; break;
        case '北': ret = '北行き'; break;
        case '西': ret = '西行き'; break;
        case '東': ret = '東行き'; break;
        case '内': ret = '内回り'; break;
        case '外': ret = '外回り'; break;
      }
      return ret;
    };
    const onStatusChange = (inputMap: SagyouJoukyouDetail) => {
      updateStatusDependentParams(inputMap, window.johaisetsuMaster.statusMap);
    };
    const onRoadNameDispChange = () => {
      if (!state.selectedRoadNameDispKey) { return; }
      const roadNameInfo =
        state.roadNameInfos.filter(e => e.key === state.selectedRoadNameDispKey)[0];
      if (!roadNameInfo) { return; }

      const {
        diagramUpper,
        diagramLower,
        tableMainLineBlocks,
        tableGateways,
      } = getStructurizedKanriBlockData(
        roadNameInfo,
        state.selectedSagyouJoukyouInfo,
        window.johaisetsuMaster.statusMap,
      );

      state.diagramUpper = diagramUpper;
      state.diagramLower = diagramLower;
      state.tableMainLineBlocks = tableMainLineBlocks;
      state.tableGateways = tableGateways;
      resetErrorMap();

      syncUIStateToUrlParams();
      updateRefs();
    };
    const statusPopoverCalcBase = ref<HTMLDivElement>();
    const statusPopover = ref<HTMLDivElement>();
    const calcPopoverPosition = (targetElem: HTMLDivElement, blockInfo: KanriBlock, blockPosition: string, placeType: string) => {
      const calcBaseElem = statusPopoverCalcBase.value;
      const popoverElem = statusPopover.value;
      let currentElem = targetElem;

      let top = blockPosition === 'upper'
        ? -(popoverElem?.offsetHeight || 0) - 8 : targetElem.offsetHeight + 8;
      let left = targetElem.offsetWidth / 2 - (popoverElem?.offsetWidth || 0) / 2;
      let bboxTop = 0;
      let bboxLeft = 0;
      const bboxHeight = targetElem.offsetHeight;
      const bboxWidth = targetElem.offsetWidth;
      if (['mainline', 'start', 'end'].includes(placeType)) {
        // 本線部分の場合、ポップアップ部分にもうちょいスペースをプラス
        top += blockPosition === 'upper' ? -4 : 4;
      } else if (placeType === 'gateway') {
        // translateで位置を調整しているのでoffsetLeftだけだと計算しきれない.
        // cssの以下部分を参照.
        // &.box-align-center { transform: translate(-50%); }
        // &.box-align-left { transform: translate(-1px); }
        // &.box-align-right { transform: translate(-100%); }
        const boxWidth = targetElem.offsetWidth;
        if (blockInfo.alignment === 'center') {
          left -= boxWidth / 2;
          bboxLeft -= boxWidth / 2;
        }
        if (blockInfo.alignment === 'left') {
          left -= 1;
          bboxLeft -= 1;
        }
        if (blockInfo.alignment === 'right') {
          left -= boxWidth;
          bboxLeft -= boxWidth;
        }
      }

      while (calcBaseElem !== currentElem) {
        top += currentElem.offsetTop;
        left += currentElem.offsetLeft;
        bboxTop += currentElem.offsetTop;
        bboxLeft += currentElem.offsetLeft;
        const nextElem = currentElem.offsetParent as HTMLDivElement;
        if (!nextElem || nextElem === currentElem) { break; } // 念のため
        currentElem = nextElem;
      }
      return {
        popoverPosition: { top, left },
        popoverBboxPosition: { top: bboxTop, left: bboxLeft, width: bboxWidth, height: bboxHeight },
      };
    };
    const showStatusPopover = (evt: Event, blockInfo: KanriBlock, blockPosition: string, placeType: string) => {
      if (state.isReadOnly) { return; }
      if (!blockInfo.is_editable) { return; }
      const { popoverPosition, popoverBboxPosition } =
        calcPopoverPosition(evt.currentTarget as HTMLDivElement, blockInfo, blockPosition, placeType);
      state.statusPopoverParams.top = popoverPosition.top;
      state.statusPopoverParams.left = popoverPosition.left;
      state.statusPopoverParams.arrow = blockPosition === 'upper' ? 'bottom' : 'top';
      if (blockInfo.inputMap) {
        blockInfo.inputMap.isSelected = true;
      }
      state.statusPopoverParams.blockInfo = blockInfo;
      state.statusPopoverBboxParams = popoverBboxPosition;
    };

    const hideStatusPopover = (evt: Event) => {
      // clickイベントトリガの場合、capture phaseで呼び出される想定.
      // mainlineやgatewayをclickしてた場合は一旦ここで消されたあとで
      // showStatusPopoverが実行されるはず.
      if (evt) {
        // また、popover自体をclickされてるケース
        // があるのでこの場合はhideしないようにしたい
        const evtBaseElem = evt.currentTarget;
        let currentElem = evt.target as HTMLDivElement;
        while (evtBaseElem !== currentElem) {
          if (currentElem?.classList.contains('johaisetsu-status-popover')) {
            return;
          }
          const nextElem = currentElem.parentNode;
          if (!nextElem || nextElem === currentElem) { break; } // 念のため
          currentElem = nextElem as HTMLDivElement;
        }
      }
      if (state.statusPopoverParams.blockInfo.inputMap) {
        state.statusPopoverParams.blockInfo.inputMap.isSelected = false;
      }
      state.statusPopoverParams.left = -9999;
      state.statusPopoverBboxParams.left = -9999;
    };
    const onPopoverStatusChange = (inputMap: SagyouJoukyouDetail, evt: Event) => {
      onStatusChange(inputMap);
      hideStatusPopover(evt);
    };
    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const resetErrorMap = () => {
      const items = [...state.tableMainLineBlocks, ...state.tableGateways];
      state.errorMap = getClearedErrorMap((items));
    };

    const strEquals = (str1?: string | null, str2?: string | null) => {
      const val1 = str1?.toString() || '';
      const val2 = str2?.toString() || '';
      if (val1 !== val2) {
        return true;
      }
      return false;
    };
    const dateEquals = (date1?: Date | null, date2?: Date | null) => {
      const val1 = date1 || new Date(1970, 0, 1);
      const val2 = date2 || new Date(1970, 0, 1);
      if (val1.valueOf() !== val2.valueOf()) {
        return true;
      }
      return false;
    };
    const isItemEdited = (inputMap?: SagyouJoukyouDetail, origInputMap?: SagyouJoukyouDetail) => {
      // 非活性になってる日付は送信時に消されるので、その状態を再現した上で比較する.
      const compInputMap = Object.assign({}, inputMap);
      organizeDateTimeProps(compInputMap);

      if (inputMap && origInputMap && ((inputMap.status === origInputMap.status) ||
        strEquals(inputMap.genba_sekininsha, origInputMap.genba_sekininsha) ||
        strEquals(inputMap.genba_kantoku_han, origInputMap.genba_kantoku_han) ||
        strEquals(inputMap.sagyou_gaisha, origInputMap.sagyou_gaisha) ||
        (inputMap.hansuu === origInputMap.hansuu) ||
        strEquals(inputMap.josetsu_start_time_h, origInputMap.josetsu_start_time_h) ||
        strEquals(inputMap.josetsu_start_time_m, origInputMap.josetsu_start_time_m) ||
        strEquals(inputMap.josetsu_estimated_time_h, origInputMap.josetsu_estimated_time_h) ||
        strEquals(inputMap.josetsu_estimated_time_m, origInputMap.josetsu_estimated_time_m) ||
        strEquals(inputMap.josetsu_end_time_h, origInputMap.josetsu_end_time_h) ||
        strEquals(inputMap.josetsu_end_time_m, origInputMap.josetsu_end_time_m) ||
        strEquals(inputMap.haisetsu_start_time_h, origInputMap.haisetsu_start_time_h) ||
        strEquals(inputMap.haisetsu_start_time_m, origInputMap.haisetsu_start_time_m) ||
        strEquals(inputMap.haisetsu_estimated_time_h, origInputMap.haisetsu_estimated_time_h) ||
        strEquals(inputMap.haisetsu_estimated_time_m, origInputMap.haisetsu_estimated_time_m) ||
        strEquals(inputMap.haisetsu_end_time_h, origInputMap.haisetsu_end_time_h) ||
        strEquals(inputMap.haisetsu_end_time_m, origInputMap.haisetsu_end_time_m) ||
        strEquals(inputMap.bikou1, origInputMap.bikou1))) {
        return true;
      }

      if (inputMap && origInputMap && (dateEquals(inputMap.josetsu_start_date, origInputMap.josetsu_start_date) ||
        dateEquals(inputMap.josetsu_estimated_date, origInputMap.josetsu_estimated_date) ||
        dateEquals(inputMap.josetsu_end_date, origInputMap.josetsu_end_date) ||
        dateEquals(inputMap.haisetsu_start_date, origInputMap.haisetsu_start_date) ||
        dateEquals(inputMap.haisetsu_estimated_date, origInputMap.haisetsu_estimated_date) ||
        dateEquals(inputMap.haisetsu_end_date, origInputMap.haisetsu_end_date))) {
        return true;
      }
      return false;
    };
    const saveItems = async() => {
      if (state.isRequesting) { return; }

      state.hasError = false;
      let items = [...state.tableMainLineBlocks, ...state.tableGateways];
      if (!checkItems(items, state.errorMap)) {
        state.hasError = true;
        return;
      }

      // スマホで編集する場合に一人が更新するのはほんの一部だけだろうから、積雪と同様
      // 変更したものだけ送る
      items = items.filter(item => {
        return isItemEdited(item.inputMap, item.origInputMap);
      });
      if (items.length === 0) { return; }

      state.isRequesting = true;
      const reqItems : SagyouJoukyouDetailRaw[] = [];
      items.forEach(e => {
        if (e.inputMap) {
          reqItems.push(deconvProps(e.inputMap));
        }
      });
      await johaisetsuSagyouJoukyouApi.updateDetails(
        state.selectedTaskForce.id,
        { details: reqItems },
      );
      notifySuccess('', 'データを保存しました');
      // 再取得
      await getSelectedSagyouJoukyouInfo();
      state.isRequesting = false;
      onRoadNameDispChange();
    };
    const getSelectedSagyouJoukyouInfo = async() => {
      const { data: selectedSagyouJoukyouInfo } = await johaisetsuSagyouJoukyouApi.show({
        taskForceId: state.selectedTaskForce.id,
        headerId: headerId.value || '',
      });
      state.selectedSagyouJoukyouInfo = selectedSagyouJoukyouInfo;
    };
    const showMapPage = () => {
      const routeObj = {
        name: 'JohaisetsuSagyouJoukyouMap',
        params: {
          taskForceId: taskForceId.value.toString() || '',
          headerId: headerId.value || '',
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };

    onMounted(async() => {
      state.isRequesting = true;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const roadNameInfos = getJohaisetsuRoadNameInfos();
      state.roadNameInfos = filterJohaisetsuSagyouJoukyouRoadNameInfoByRole(
        roadNameInfos, johaisetsuRole.value);
      state.johaisetsuStatuses = window.johaisetsuMaster.statuses;

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;

      // 待たなくていい
      johaisetsuSagyouJoukyouApi.index({
        taskForceId: state.selectedTaskForce.id,
      }).then(({ data: sagyouJoukyouHeaders }) => {
        state.sagyouJoukyouHeaders = sagyouJoukyouHeaders;
      });

      await getSelectedSagyouJoukyouInfo();
      state.isRequesting = false;

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedSagyouJoukyouInfo?.header,
        johaisetsuRole.value,
      );

      restoreUIStateFromUrlParams();
      if (!state.selectedRoadNameDispKey) {
        state.selectedRoadNameDispKey = state.roadNameInfos[0].key;
        onRoadNameDispChange();
      }

      state.isReady = true;
    });

    const startDebugMapDemo = () => {
      if (state.debugMapDemoTimerId) { return; }
      state.debugMapDemoSteps = [
        ...state.tableMainLineBlocks.map(e => {
          return {
            placeType: e.place_type,
            blockCode: e.block_code,
            name: '', // 使わない
            status: state.debugMapDemoJohaisetsuStatus.toString(),
          };
        }),
        ...state.tableGateways.map(e => {
          return {
            placeType: e.place_type,
            blockCode: '', // 使わない
            name: e.start_place_name?.replace(/入口|出口/, '') || '',
            status: state.debugMapDemoJohaisetsuStatus.toString(),
          };
        }),
      ];
      progressDemoStep();
      pstartDemoTimer();
    };
    const sagyouJoukyouMap = ref<InstanceType<typeof SagyouJoukyouMap>>();
    const progressDemoStep = () => {
      if (sagyouJoukyouMap.value && state.debugMapDemoCurrentStepIdx === 0) {
        sagyouJoukyouMap.value.clearMap();
      }
      const step = state.debugMapDemoSteps[state.debugMapDemoCurrentStepIdx];
      console.log(state.debugMapDemoCurrentStepIdx, step.blockCode || step.name);
      if (sagyouJoukyouMap.value) {
        sagyouJoukyouMap.value.updateMapElement(step);
      }
      state.debugMapDemoCurrentStepIdx =
        (state.debugMapDemoCurrentStepIdx + 1) % state.debugMapDemoSteps.length;
    };
    const pauseDebugMapDemo = () => {
      stopDemoTimer();
    };
    const stopDebugMapDemo = () => {
      stopDemoTimer();
      state.debugMapDemoCurrentStepIdx = 0;
    };
    const pstartDemoTimer = () => {
      state.debugMapDemoTimerId = window.requestInterval(() => {
        progressDemoStep();
      }, state.debugMapDemoSpeed);
    };
    const stopDemoTimer = () => {
      if (state.debugMapDemoTimerId) {
        window.clearRequestInterval(state.debugMapDemoTimerId);
        state.debugMapDemoTimerId = null;
      }
    };

    return {
      ...toRefs(state),
      // refes
      mainLinesTable,
      main2table,
      statusPopoverCalcBase,
      statusPopover,
      sagyouJoukyouMap,
      // // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      isStatusPopoverVisible,
      canUseSagyouJoukyouMap,
      // methods
      parseUrlParams,
      restoreUIStateFromUrlParams,
      syncUIStateToUrlParams,
      directionLongDisp1,
      onStatusChange,
      onRoadNameDispChange,
      calcPopoverPosition,
      showStatusPopover,
      hideStatusPopover,
      onPopoverStatusChange,
      onSelectedTaskForceChange,
      resetErrorMap,
      isItemEdited,
      saveItems,
      getSelectedSagyouJoukyouInfo,
      showMapPage,
      dtFormat,
      johaisetsuKpDisp1,
      startDebugMapDemo,
      pauseDebugMapDemo,
      stopDebugMapDemo,
    };
  },
});
