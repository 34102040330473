import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { LocationRaw } from '@/models/index';
import { GetMovieGeoIndicesResponse } from '@/models/apis/movie/movieResponse';

export default {
  getMovieGeoIndices(params: LocationRaw): Promise<AxiosResponse<GetMovieGeoIndicesResponse>> {
    return axios.get(utils.getApiUrl('/movie_geo_indices'), {
      params: params || {},
    });
  },
};
