import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  CleaningHanMastersIndexResponse,
  CleaningHanMastersGetByUserResponse,
} from '@/models/apis/cleaningHanMasters/cleaningHanMastersResponse';

export default {
  index(): Promise<AxiosResponse<CleaningHanMastersIndexResponse>> {
    return axios.get(utils.getApiUrl('/cleaning_han_masters'));
  },
  getByUser(): Promise<AxiosResponse<CleaningHanMastersGetByUserResponse>> {
    return axios.get(utils.getApiUrl('/cleaning_han_masters/get_by_user'));
  },
};
