
import { FeatureColor } from '@/models/geoItem';
export function getFeatureColor(type: string): FeatureColor {
  const fillColor = [250, 250, 250];
  let strokeColor = [0, 0, 0];
  // 和カラーにしてみた
  // https://www.colordic.org/w
  if (type === 'main_line') {
    strokeColor = [56, 180, 139]; // 翡翠色
  } else if (type === 'jct') {
    strokeColor = [230, 0, 51]; // 赤
  } else if (type === 'gate') {
    strokeColor = [248, 181, 0]; // 山吹色
  }
  return { fillColor, strokeColor };
}
