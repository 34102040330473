import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { Style, Fill, Stroke, Text, Icon } from 'ol/style';
import { Feature } from 'ol';
import OlPoint from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Location } from '@/models';
import { GIPoint } from 'src/models/geoItem';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';

export default class ExtremeMapPinLayerManager extends ExtremeMapAbstractLayerManager {
  getIconPath_(): string {
    // typeによってアイコンを出し分けるかもしれない
    return '/static/img/select_pin.png';
  }

  getResourceStyles_(point: GIPoint): Style[] {
    const ret = [];
    ret.push(new Style({
      image: new Icon({
        src: this.getIconPath_(),
        anchor: [0.5, 0.85],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
        opacity: point.showWeak ? 0.75 : 1.0,
      }),
    }));
    if (point.text) {
      ret.push(new Style({
        text: new Text({
          text: point.text,
          font: '10px sans-serif',
          fill: new Fill({ color: '#eeeeee' }),
          stroke: new Stroke({ color: [50, 50, 50, 0.8], width: 3 }),
          offsetX: 0,
          offsetY: -32,
        }),
      }));
    }
    return ret;
  }

  getResourceFeatures_(point: GIPoint): Feature[] {
    const featId = point.id;
    const center = this.convCoord(point);
    const feat = new Feature(new OlPoint(center));
    feat.setId(featId);
    feat.setStyle(this.getResourceStyles_(point));
    return [feat];
  }

  createLayer_(points: GIPoint[]): void {
    const feats: Feature[] = [];
    points.forEach(p => {
      feats.push(...this.getResourceFeatures_(p));
    });
    const layer = new NamedVectorLayer('pins', {
      source: new VectorSource({features: feats}),
    });
    this.layer = layer;
  }

  prepareLayer(points: GIPoint[]): LayerWithInfo {
    this.createLayer_(points);
    return this.getLayer();
  }

  showPin(points: GIPoint[]): void {
    this.hidePins();
    const feats: Feature[] = [];
    points.forEach(p => {
      feats.push(...this.getResourceFeatures_(p));
    });
    if (!this.layer) { return; }
    this.layer.getSource().addFeatures(feats);
  }

  hidePins(): void {
    if (!this.layer) { return; }
    this.layer.getSource().clear();
  }

  updatePinPosition(featId: string | number, point: Location): void {
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    const feat = layerSource.getFeatureById(featId);
    if (!feat) {
      return;
    }
    const center = this.convCoord(point);
    if (!feat.getGeometry()) {
      return;
    }
    (feat.getGeometry() as SimpleGeometry).setCoordinates(center);
  }
}
