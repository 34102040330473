


import {
  defineComponent,
  ref,
} from '@vue/composition-api';

import { ITEMS_PER_PAGE_DEFAULT } from '@/consts/report_print';

export default defineComponent({
  name: 'PrintPagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: ITEMS_PER_PAGE_DEFAULT,
    },
  },
  setup(props, { emit }) {
    const inputItemsPerPage = ref<string>(props.itemsPerPage.toString());
    const onClickPageChange = (pageNum: number) => {
      emit('page-change', pageNum);
    };
    const onClickItemsPerPageChange = () => {
      const itemsPerPage = Number(inputItemsPerPage.value);
      if (itemsPerPage >= 1) emit('items-per-page-change', itemsPerPage);
    };

    return {
      inputItemsPerPage,
      onClickPageChange,
      onClickItemsPerPageChange,
    };
  },
});
