



































































import {
  defineComponent,
  reactive,
  PropType,
  computed,
  watch,
  nextTick,
  ref,
  onMounted,
  toRefs,
} from '@vue/composition-api';
import { MapElemInfo } from '@/models';
import { FetchGeoItemsParams, GIComment } from '@/models/geoItem';
import MapElemInfoComment from './MapElemInfoComment/index.vue';
import { Comment } from '@/models/apis/comment/commentResponse';

interface ContainerElement extends MapElemInfo<GIComment> {
  relativeComments: GIComment[];
}

interface MapElemInfoCommentContainerState {
  isStartReply: boolean;
  collapseNumber: number;
  isFetchingData: boolean;
  showFetchDataError: boolean;
}

export default defineComponent({
  name: 'map-elem-info-refContainer',
  props: {
    elem: {
      type: Object as PropType<ContainerElement>,
      required: true,
    },
    createFailed: {
      type: Number,
      default: 0,
    },
    updateFailed: {
      type: Number,
      default: 0,
    },
    fetchGeoItemFailed: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MapElemInfoComment,
  },
  setup(props, { emit }) {
    const state = reactive<MapElemInfoCommentContainerState>({
      isStartReply: false,
      collapseNumber: 5,
      isFetchingData: false,
      showFetchDataError: false,
    });
    const refContainer = ref<HTMLElement>();

    const scrollToTargetComment = (target: HTMLElement) => {
      if (!refContainer.value) { return; }
      const containerRect = refContainer.value.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();
      const isViewable = targetRect.top >= containerRect.top &&
        targetRect.bottom <= containerRect.top + refContainer.value.clientHeight;
      if (!isViewable) {
        const scrollTop = targetRect.top - containerRect.top;
        const scrollBot = targetRect.bottom - containerRect.bottom;
        Math.abs(scrollTop) < Math.abs(scrollBot) ? refContainer.value.scrollTop += scrollTop
          : refContainer.value.scrollTop += scrollBot;
      }
    };

    const refCommentElems = ref<HTMLElement[]>();
    const displayTargetComment = () => {
      nextTick(() => {
        const targetIdx = displayedComments.value.findIndex(comment => comment.data.id === props.elem.data.id);
        if (targetIdx >= 0 && refCommentElems.value) {
          const targetElem = refCommentElems.value[targetIdx];
          scrollToTargetComment(targetElem);
        }
      });
    };

    const initDisplay = () => {
      state.isStartReply = false;
      state.collapseNumber = 5;
      state.isFetchingData = false;
      state.showFetchDataError = false;
      displayTargetComment();
    };
    onMounted(initDisplay);

    watch(() => props.elem, initDisplay);
    watch(() => props.fetchGeoItemFailed, () => {
      if (props.fetchGeoItemFailed > 0) {
        state.showFetchDataError = true;
      }
    });

    const relativeComments = computed(() => {
      return props.elem.relativeComments || [];
    });
    const displayedComments = computed(() => {
      const allComments = [
        props.elem,
        ...relativeComments.value.map(comment => ({
          dataName: props.elem.dataName,
          data: comment,
        })),
      ].sort((a, b) => {
        if (a.data.isParentComment) { return -1; }
        if (b.data.isParentComment) { return 1; }
        return a.data.ts && b.data.ts && a.data.ts < b.data.ts ? -1
          : a.data.ts && b.data.ts && a.data.ts > b.data.ts ? 1 : 0;
      });
      if (state.collapseNumber && allComments.length >= state.collapseNumber) {
        return [allComments[0], allComments[allComments.length - 1]];
      }
      return allComments;
    });
    const parentComment = computed(() => {
      return relativeComments.value.find(e => e.id === props.elem.data.parent_id);
    });
    const initialCommentForReply = computed(() => {
      return {
        dataName: props.elem.dataName,
        data: {
          lat: props.elem.data.lat,
          lon: props.elem.data.lon,
          parent_id: props.elem.data.parent_id || props.elem.data.id,
          kp_uid: parentComment.value?.kp_uid || props.elem.data.kp_uid,
        },
      };
    });

    // methods
    const refNewCommentElem = ref<HTMLElement>();
    const startReply = () => {
      state.isStartReply = true;
      nextTick(() => {
        if (!refNewCommentElem.value) { return; }
        scrollToTargetComment(refNewCommentElem.value);
      });
    };
    const onChildComponentUpdate = (obj: ContainerElement) => {
      if (obj.data.id) {
        emit('update', obj);
        return;
      }

      emit('create', obj);
    };
    const appendDataName = (comments: Comment[]) => {
      return comments.map(comment => {
        return {
          dataName: props.elem.dataName,
          data: comment,
        };
      });
    };
    const confirmDelete = (obj: Comment) => {
      emit('confirm-delete', obj);
    };
    const cancelReply = () => {
      state.isStartReply = false;
      emit('cancel-edit');
    };
    const disableCollapse = () => {
      state.collapseNumber = 0;
    };
    const showImage = (obj: Comment) => {
      emit('show-image', obj);
    };
    const showPrevious = () => {
      emit('show-previous-geo-item', props.elem);
    };
    const showNext = () => {
      emit('show-next-geo-item', props.elem);
    };
    const searchParent = () => {
      // 連打防止
      if (state.isFetchingData) { return; }

      // 親側で再表示する際に、isFetchingDataがfalseに戻る
      state.isFetchingData = true;
      const evtObj: FetchGeoItemsParams = {
        dataName: props.elem.dataName,
        id: props.elem.data.parent_id,
      };
      emit('fetch-geo-item', evtObj);
    };

    const fetchDataErrorMessage = '付箋の閲覧権限がありません。';
    return {
      ...toRefs(state),
      // refs
      refCommentElems,
      // computed
      displayedComments,
      relativeComments,
      parentComment,
      initialCommentForReply,
      // methods
      startReply,
      onChildComponentUpdate,
      appendDataName,
      confirmDelete,
      cancelReply,
      disableCollapse,
      showImage,
      showPrevious,
      showNext,
      searchParent,
      // others
      fetchDataErrorMessage,
    };
  },
});
