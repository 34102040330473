import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

export function enableNoSleep(): void {
  noSleep.enable();
}

export function disableNoSleep(): void {
  noSleep.disable();
}
