import { TaskForceRaw,
  PlanningBlockRaw,
  SanpuBlockRaw,
  EnsuiPlantRaw,
  SanpuBlock,
  HoursPerKMGroupCmRaw } from '@/models/apis/johaisetsu/johaisetsuCommon';
import {
  TaskForce,
  JohaisetsuMasterData,
  PlanningBlock,
  EnsuiPlant,
  HoursPerKMGroupCm } from '@/models/johaisetsu/johaisetsuCommon';
import johaisetsuMasterApi from '@/apis/johaisetsu/johaisetsu_master';
import { dateStrToDateWithoutTime, dateStrToDate } from '@/lib/dateHelper';
import {
  getJohaisetsuSagyouJoukyouRoadNameDirectionsShutokou,
} from '@/lib/johaisetsu/johaisetsuSagyouJoukyouUtil';
import {
  JohaisetsuReport,
  JohaisetsuReportIndexResponse,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { validWorkTypesMap } from '@/lib/johaisetsu/johaisetsuCommonUtil';

function loadJohaisetsuMasters(): void {
  if (window.johaisetsuMaster) { return; }
  const prms = johaisetsuMasterApi.getAll().then(({ data }) => {
    const taskForces = convTaskForces_(data.task_forces);
    const statusMap = data.status_map;
    const planningBlocks = convJohaisetsuPlanningBlocks_(data.planning_blocks);
    const hoursPerKmGroupCm = convJohaisetsuHoursPerKmGroupCm_(data.hours_per_km_group_cm);
    const masterData: JohaisetsuMasterData = {
      taskForces,
      taskForceMap: taskForces.reduce((acc: Record<number, TaskForce>, e: TaskForce) => {
        acc[e.id] = e; return acc;
      }, {}),
      kanriBlockMap: data.kanri_block_map,
      statusMap,
      statuses: Object.keys(statusMap).sort().map(e => {
        return statusMap[e];
      }),
      planningBlocks,
      hoursPerKmGroupCm,
      ensuiSanpuBlocks: convJohaisetsuEnsuiSanpuBlocks_(data.ensui_sanpu_blocks),
      ensuiSanpuStatusMap: data.ensui_sanpu_status_map,
      ensuiSanpuStatuses: Object.keys(data.ensui_sanpu_status_map).sort().map(e => {
        return data.ensui_sanpu_status_map[e];
      }),
      jutenSanpuBlocks: convJohaisetsuJutenSanpuBlocks_(data.juten_sanpu_blocks),
      jutenSanpuStatusMap: data.juten_sanpu_status_map,
      jutenSanpuStatuses: Object.keys(data.juten_sanpu_status_map).sort().map(e => {
        return data.juten_sanpu_status_map[e];
      }),
      johaisetsuTypeMap: data.johaisetsu_type_map,
      johaisetsuTypes: Object.keys(data.johaisetsu_type_map)
        .map(e => {
          return data.johaisetsu_type_map[e];
        }).sort((a, b) => a.disp_order < b.disp_order ? -1 : 1),
      settouPatrolReportRoadConditionMap: data.settou_patrol_report_road_condition_map,
      settouPatrolReportRoadConditions: Object.keys(data.settou_patrol_report_road_condition_map).sort().map(e => {
        return data.settou_patrol_report_road_condition_map[e];
      }),
      ensuiPlants: convJohaisetsuEnsuiPlants_(data.ensui_plants),
      sagyouJoukyouRoadNameDirections: [],
      companyList: data.company_master_list,
    };

    const prms = window.johaisetsuMaster.$promise;
    window.johaisetsuMaster = {
      $promise: prms,
      ...masterData,
    };
  });
  window.johaisetsuMaster = { $promise: prms } as any;
}

function convTaskForces_(taskForces: TaskForceRaw[]): TaskForce[] {
  return taskForces.map(e => {
    return {
      ...e,
      start_date: dateStrToDateWithoutTime(e.start_date),
      end_date: dateStrToDateWithoutTime(e.end_date),
    };
  });
}

function convJohaisetsuPlanningBlocks_(planningBlocks: PlanningBlockRaw[]): PlanningBlock[] {
  return planningBlocks.map(e => {
    return {
      ...e,
      lane_width: parseFloat(e.lane_width),
      road_length: parseFloat(e.road_length),
      tunnel_length: parseFloat(e.tunnel_length),
      openair_length: parseFloat(e.openair_length),
      double_deck_ratio: parseFloat(e.double_deck_ratio),
      converted_openair_length: parseFloat(e.converted_openair_length),
      use_start_date: dateStrToDate(e.use_start_date),
      use_end_date: dateStrToDate(e.use_end_date),
    };
  });
}
function convJohaisetsuHoursPerKmGroupCm_(hoursPerKmGroupCm: HoursPerKMGroupCmRaw[]): HoursPerKMGroupCm[] {
  return hoursPerKmGroupCm.map(e => {
    return {
      ...e,
      hours: parseFloat(e.hours),
      use_start_date: dateStrToDate(e.use_start_date),
      use_end_date: dateStrToDate(e.use_end_date),
    };
  });
}
function convJohaisetsuEnsuiSanpuBlocks_(ensuiSanpuBlocks: SanpuBlockRaw[]): SanpuBlock[] {
  return ensuiSanpuBlocks.map(e => {
    return {
      ...e,
      use_start_date: dateStrToDate(e.use_start_date),
      use_end_date: dateStrToDate(e.use_end_date),
    };
  });
}
function convJohaisetsuJutenSanpuBlocks_(jutenSanpuBlocks: SanpuBlockRaw[]): SanpuBlock[] {
  return jutenSanpuBlocks.map(e => {
    return {
      ...e,
      use_start_date: dateStrToDate(e.use_start_date),
      use_end_date: dateStrToDate(e.use_end_date),
    };
  });
}
function convJohaisetsuEnsuiPlants_(ensuiPlants: EnsuiPlantRaw[]): EnsuiPlant[] {
  return ensuiPlants.map(e => {
    return {
      ...e,
      use_start_date: dateStrToDate(e.use_start_date),
      use_end_date: dateStrToDate(e.use_end_date),
    };
  });
}
export async function waitForJohaisetsuMasters(): Promise<void> {
  loadJohaisetsuMasters();
  await window.johaisetsuMaster.$promise;
  window.johaisetsuMaster.sagyouJoukyouRoadNameDirections =
    getJohaisetsuSagyouJoukyouRoadNameDirectionsShutokou();
}

export const isMatchingKey = (key: string, areaName: string | null): boolean => {
  if (!areaName) return false;
  const keyWithoutSuffix = normalizeKey(key);
  return keyWithoutSuffix.endsWith(areaName);
};
export const normalizeKey = (key: string): string => key.replace(/(_driver|_default)$/, '');

export function filterReportsByJohaisetsuType(reports: JohaisetsuReportIndexResponse): JohaisetsuReport[] {
  const filteredReports = reports.filter(report => {
    return isValidWorkType(report.johaisetsu_type, report.work_type);
  });
  return filteredReports;
}

export function getReportsWithBaseArrivalTimestamp(reports: JohaisetsuReportIndexResponse): JohaisetsuReport[] {
  return reports.filter(report => !!report.base_arrival_ts);
}

export function isValidWorkType(johaisetsuType: string | null, workType: string | null): boolean {
  if (!johaisetsuType || !workType) return false;

  const validItems = validWorkTypesMap.get(johaisetsuType);
  if (!validItems) return false;

  return validItems.has(workType);
}
