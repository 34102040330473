import { computed, ComputedRef, getCurrentInstance } from '@vue/composition-api';
import VueRouter, { Route } from 'vue-router';

export function useRoute(): { route: ComputedRef<Route>; router: VueRouter } {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error(`Should be used in setup().`);
  }
  // reactiveにするため
  return {
    route: computed(() => instance.proxy.$route),
    router: instance.proxy.$router,
  };
}
