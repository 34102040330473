export const VIEW_MODE_REALTIME = 'realtime';
export const VIEW_MODE_REPORT = 'report';
export const PANE_SIDE_HARD_MAX_WIDTH = 380;
export const LIST_HARD_MAX_HEIGHT = 200;
export const VERTICAL_PANE_PADDING_NUMBER = 36;
export const PANE_SIDE_MIN_WIDTH_RATE = 0.20;
export const PANE_SIDE_MAX_WIDTH_RATE = 0.30;
export const CAR_LIST_MIN_HEIGHT_RATE = 0.20;
export const CAR_LIST_MAX_HEIGHT_RATE = 0.38;
export const REPORT_LIST_MIN_HEIGHT_RATE = 0.60;
export const REPORT_LIST_MAX_HEIGHT_RATE = 1.0;

export const statusChoices = ['全て', '移動中', '作業中', '停止中'];

export const JOHAISETSU_CAR_MAX_MTXS = 1000;
export const JOHAISETSU_REPORT_MAX_MTXS = 1000;
