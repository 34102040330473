import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { AdminGetRawHistoriesParams } from '@/models/apis/cyzenHistory/adminCyzenHistoryRequest';
import { AdminGetRawHistories } from '@/models/apis/cyzenHistory/adminCyzenHistoryResponse';

export default {
  adminGetRawHistories(params: AdminGetRawHistoriesParams): Promise<AxiosResponse<AdminGetRawHistories>> {
    return axios.get(utils.getApiUrl('/admin/cyzen_histories'), {
      params: params || {},
    });
  },
};
