import { reactive } from '@vue/composition-api';
import { PaneState } from '@/models/johaisetsu/johaisetsuMap';

interface UsePaneControlParams {
  paneStyleLimitMap?: {
    listMinHeightRate: number;
    listMaxHeightRate: number;
  };
}
interface UsePaneControlResult {
  paneState: PaneState;
  onChangeShowCarIcons: () => void;
}

export default function usePaneControl(params: UsePaneControlParams): UsePaneControlResult {
  const paneState = reactive<PaneState>({
    showCarIcons: true,
    paneStyleLimitMap: {
      listMinHeightRate: params.paneStyleLimitMap?.listMinHeightRate || 0,
      listMaxHeightRate: params.paneStyleLimitMap?.listMaxHeightRate || 0,
    },
  });

  const onChangeShowCarIcons = () => {
    paneState.showCarIcons = !paneState.showCarIcons;
  };

  return {
    paneState,
    onChangeShowCarIcons,
  };
}
