


import {
  reactive,
  defineComponent,
  PropType,
  onMounted,
  ref,
} from '@vue/composition-api';
import { dtFormat } from '@/lib/dateHelper';
import {
  ChartData,
  ChartDataSets,
  ChartLegendLabelItem,
} from 'chart.js';
import {
  WeatherObservatoryDataPoint,
  WeatherObservatoryWithDataPoints,
} from 'src/models/apis/weatherObservatory/weatherObservatoryResponse';
import { Line as LineChart } from 'vue-chartjs';
import {
  CHART_LINE_COLOR_NUM,
  CHART_LINE_COLOR_CREATE_STEP,
  CHART_LINE_COLOR_SHOW_STEP,
  ChartModalState,
  dataLabelMap,
  dataUnitMap,
  dataStepMap,
  getInitChartModalState,
  isDisabledData,
  getInitSelectedData,
  CHART_LINE_GREEN_ADJUST_STEP,
  CHART_LINE_BLUE_ADJUST_STEP,
  CHART_PERCENT_MAX,
  CHART_MIN_VALUE_ZERO,
  CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX,
  CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX_STEP,
  CHART_TEMPERATURE_STEP,
} from './utils';

export default defineComponent({
  name: 'data-points-chart-modal',
  props: {
    observatories: {
      type: Array as PropType<WeatherObservatoryWithDataPoints[]>,
      required: true,
    },
    tsTo: {
      type: Date,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<ChartModalState>(getInitChartModalState());
    const refLine = ref<LineChart>();
    const closeChartModal = (): void => {
      emit('close-chart-modal');
    };
    const createTimeLabels = (): void => {
      const tsFrom = new Date(props.tsTo.getTime() - 12 * 60 * 60 * 1000);
      for (let i = 0; i < 73; i++) {
        const ts = new Date(tsFrom.getTime() + i * 10 * 60 * 1000);
        state.timeLabels.push(dtFormat(ts, 'HH:MM'));
      }
    };
    const createChartColorList = (): void => {
      // 31刻みで512組の色を作成する。おおよそ3色ごとに色相が変わる
      const chartColors = [];
      for (let i = 0; i < CHART_LINE_COLOR_NUM; i++) {
        const red = (i * CHART_LINE_COLOR_CREATE_STEP) % 256;
        const green = (i * CHART_LINE_COLOR_CREATE_STEP + CHART_LINE_GREEN_ADJUST_STEP) % 256;
        const blue = (i * CHART_LINE_COLOR_CREATE_STEP + CHART_LINE_BLUE_ADJUST_STEP) % 256;
        chartColors.push(`rgb(${red}, ${green}, ${blue}, 0.8)`);
      }
      // 隣接したグラフの色の色相が近くならないように6色刻みで色を並び替える
      const sortedChartColors = [];
      for (let index = 0; index < CHART_LINE_COLOR_NUM; index++) {
        const colorIndex = index * CHART_LINE_COLOR_SHOW_STEP % CHART_LINE_COLOR_NUM;
        sortedChartColors.push(chartColors[colorIndex]);
      }
      state.chartColors = sortedChartColors;
    };
    const updateChartData = (): void => {
      const datasets = getDatasets();
      const dataMaxRange = getDataMaxRange(datasets);
      const dataMinRange = getDataMinRange(datasets);
      const dataStep = dataStepMap[state.selectedData];

      if (refLine.value === undefined) return;
      refLine.value.renderChart({
        labels: state.timeLabels,
        datasets,
      },
      {
        animation: undefined,
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontSize: 18,
          fontStyle: 'normal',
          text: `${dataLabelMap[state.selectedData]}(${dataUnitMap[state.selectedData]})`,
        },
        legend: {
          position: 'bottom',
          labels: {
            generateLabels: function(chart: { data: ChartData }): ChartLegendLabelItem[] {
              const labels: ChartLegendLabelItem[] = [];
              if (!chart.data.datasets) return labels;
              chart.data.datasets.forEach((dataset: ChartDataSets, idx: number) => {
                return labels.push({
                  text: dataset.label,
                  fillStyle: dataset.backgroundColor?.toString() ?? '',
                  strokeStyle: dataset.borderColor?.toString() ?? '',
                  pointStyle: 'rectRounded',
                  hidden: dataset.hidden,
                  datasetIndex: idx,
                });
              });
              return labels;
            },
            usePointStyle: true,
          },
        },
        scales: {
          yAxes: [{
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            ticks: {
              max: dataMaxRange,
              min: dataMinRange,
              stepSize: dataStep,
              callback: function(value: string) {
                return value.toString() + dataUnitMap[state.selectedData];
              },
            },
          }],
          xAxes: [{
            gridLines: {
              display: true,
              drawTicks: true,
            },
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 30,
            },
          }],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: (items: { index: number }[], data: ChartData) => {
              const item = items[0];
              const dataLabel = data.labels ? data.labels[item.index].toString() : '';
              if (!dataLabel) return dataLabel;
              const [hour, minute] = dataLabel.split(':');
              const ts = new Date(props.tsTo.getTime());
              ts.setHours(Number(hour), Number(minute));
              if (ts.getTime() > props.tsTo.getTime()) {
                ts.setDate(ts.getDate() - 1);
              }
              return dtFormat(ts, 'yyyy-mm-dd HH:MM');
            },
            label: (item: { datasetIndex: number; yLabel: string }, data: ChartData) => {
              const dataLabel = data.datasets ? data.datasets[item.datasetIndex].label : '';
              const suffix = dataUnitMap[state.selectedData];
              return `${dataLabel}: ${item.yLabel}${suffix}`;
            },
          },
        },
      });
    };
    const getDatasets = (): ChartDataSets[] => {
      let ret: ChartDataSets[] = [];
      props.observatories.forEach((observatory, index) => {
        const chartData: (number | null)[] = [];
        state.timeLabels.forEach(timeLabel => {
          const dataPoint = observatory.weather_observatory_data_points.find(data => {
            return dtFormat(data.timestamp, 'HH:MM') === timeLabel;
          });
          const param = state.selectedData as keyof WeatherObservatoryDataPoint;
          chartData.push(dataPoint && dataPoint[param] !== null ? Number(dataPoint[param]) : null);
        });
        const lineColor = state.chartColors[index];
        ret.push({
          type: 'line',
          label: observatory.name,
          data: chartData,
          borderColor: lineColor,
          backgroundColor: lineColor,
          fill: false,
          spanGaps: false,
          lineTension: 0,
          yAxisID: 'y-axis-1',
        });
      });
      return ret;
    };

    const getDataMaxRange = (datasets: ChartDataSets[]): number => {
      if (state.selectedData === 'humidity') return CHART_PERCENT_MAX;
      // 全てのdataがnullの場合はグラフを選択できないので考慮しない
      const firstData = datasets.flatMap(dataset => dataset.data as number[]).find(d => d !== null) ?? 0;
      const maxData = datasets.reduce((acc, dataset) => {
        const data = (dataset.data as number[]).filter(d => d !== null);
        const max = Math.max(...data);
        return max > acc ? max : acc;
      }, firstData);
      // 積雨量、積雪量はMAX(30mm, データ内最大値+5mm)を最大値とする
      if (
        state.selectedData === 'ten_min_accumulated_rainfall' ||
        state.selectedData === 'snow_depth'
      ) {
        return maxData > CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX
          ? maxData + CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX_STEP
          : CHART_RAINFALL_SNOWDEPTH_AMOUNT_MAX;
      }
      // 気温、路温は最大値をデータ内最大値+1にする
      return Math.round(maxData * 10 + CHART_TEMPERATURE_STEP * 10) / 10;
    };
    const getDataMinRange = (datasets: ChartDataSets[]): number => {
      // 湿度、積雨量、積雪量は最小値を0にする
      if (
        state.selectedData === 'humidity' ||
        state.selectedData === 'ten_min_accumulated_rainfall' ||
        state.selectedData === 'snow_depth'
      ) return CHART_MIN_VALUE_ZERO;
      // 全てのdataがnullの場合はグラフを選択できないので考慮しない
      const firstData = datasets.flatMap(dataset => dataset.data as number[]).find(d => d !== null) ?? 0;
      const minData = datasets.reduce((acc, dataset) => {
        if (!dataset.data) return acc;
        const data = (dataset.data as number[]).filter(d => d !== null);
        const min = Math.min(...data);
        return min < acc ? min : acc;
      }, firstData);
      // 気温、路温は最小値をデータ内最小値-1にする
      return Math.round(minData * 10 - CHART_TEMPERATURE_STEP * 10) / 10;
    };

    onMounted(() => {
      state.selectedData = getInitSelectedData(props.observatories);
      createTimeLabels();
      createChartColorList();
      updateChartData();
    });
    return {
      state,
      refLine,
      dataLabelMap,
      closeChartModal,
      isDisabledData,
      updateChartData,
    };
  },
  components: { LineChart },
});
