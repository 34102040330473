import { GeoItemMeta, GIComment } from '@/models/geoItem';
import { Tab } from '@/models/tab';
import ExtremeMapCommentLayerManager from '@/lib/ExtremeMapCommentLayerManager';

export function getEnsuredLayerItem(layerManager: ExtremeMapCommentLayerManager, data: GIComment): GIComment {
  const tmpResourceMap = layerManager.getResourceMap();
  let targetResource = tmpResourceMap[data.id];
  if (!targetResource) {
    // 対象レイヤーの現在の検索条件にマッチしているとは限らないので、
    // なければ埋め込む. (削除済のやつを埋め込む可能性が若干あるが...)
    layerManager.addLayerItem(data);
    targetResource = data;
  }
  targetResource.isSelected = true;
  return targetResource;
}

export function showWaitSpinnerTabs(tabs: Tab[]): void {
  for (const tab of tabs) {
    if (tab.dataName === 'comment') {
      continue;
    }
    tab.resources.ready = false;
  }
}

export function createNewTabs(tabs: Tab[], reqItems: GeoItemMeta[]): Tab[] {
  const newTabs = [];
  for (const item of reqItems) {
    if (!item.isTabPanel || tabs.map(e => e.dataName).includes(item.name)) {
      continue;
    }
    const itemTab = {
      dataName: item.name,
      label: item.tabLabel,
      resources: {
        ready: false,
        elements: [],
      },
    };
    newTabs.push(itemTab);
  }
  return newTabs;
}

export function prepareTabResources(tabs: Tab[], metaItem: GeoItemMeta, data: GIComment[]): void {
  const tab = tabs.find(e => e.dataName === metaItem.name);
  // 新着の付箋は他のところで制御しているため、ここではtabの内容を更新しない
  if (!tab || tab.dataName === 'comment') {
    return;
  }
  tab.resources.elements = metaItem.manager.filterTabPaneElements(data);
  tab.resources.ready = true;
}
