import {
  JohaisetsuReportJohaisetsuStartUpdateParams,
  JohaisetsuReportJohaisetsuEndUpdateParams,
  JohaisetsuReportBaseArrivalUpdateParams,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportRequest';
import {
  JohaisetsuReport,
  JohaisetsuSettouReport,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { JohaisetsuCar } from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import {
  JohaisetsuCarReportExt,
  CreateOrUpdateJohaisetsuCarParam,
  JohaisetsuReportCommonState,
} from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import johaisetsuReportApi from '@/apis/johaisetsu/settouSagyouReport/johaisetsu_report';
import johaisetsuCarApi from '@/apis/johaisetsu/johaisetsu_car';
import {
  timeIntegerToSeconds,
  unpackTimeInteger,
} from '@/lib/dateTimeHelper';

export const STATUS_RUNNING = 'running';
export const STATUS_WORKING = 'working';
export const STATUS_STOPPED = 'stopped';
export const CAR_NEW_DATA = 1;
export const CAR_OLD_DATA = 0;
export const WORK_TYPE_NAME_DEFAULT = '作業';
export const statusDispMap: Record<string, string> = {
  [STATUS_RUNNING]: '移動中',
  [STATUS_WORKING]: '作業中',
  [STATUS_STOPPED]: '停止中',
};

export const initJohaisetsuReportCommonState = (): JohaisetsuReportCommonState => {
  return {
    displayJohaisetsuHan: '',
    isReady: false,
    isRequesting: false,

    isMoving: false,
    isWorking: false,
    isSuspending: false,
    isWorkSituationEditing: false,
    isBacking: false,

    status: '',

    currentLocation: null,
    geolocationErrors: [],
    geolocationOpts: {
      enableHighAccuracy: true,
      timeout: 5 * 1000,
      maximumAge: 0,
    },

    johaisetsuTypes: [],
    johaisetsuTypeMap: {},

    selectedJohaisetsuType: null,

    errorObj: {},

    baseDepartureTime: null,
    currentTime: null,
    workStartTime: null,
    workEndTime: null,

    showGeolocationGeneralErrorMsg: false,
    showGeolocationPermissionErrorModal: false,

    errorModalMsg: '',
    showErrorModal: false,

    geolocationWatchHandler: null,

    johaisetsuCarUpdateTimer: null,
    workElapsedTimeNotifyTimer: null,

    johaisetsuCarUpdateTimerSec: 10, // seconds
    workElapsedTimeNotifyTimerSec: 1, // seconds
    johaisetsuReportId: -1,
  };
};

export const initJohaisetsuCarReportExt = (): JohaisetsuCarReportExt => {
  return {
    id: null,
    deviceId: null,
    baseDepartureTs: null,
    startTs: null,
    endTs: null,
    baseArrivalTs: null,
    johaisetsuCompanyId: null,
    johaisetsuHanName: null,
  };
};

export const notifyWorkElapsedTimeSpeech = (
  workElapsedTimeInt: number,
  workTypeName: string,
  doSpeech: ((text: string) => void),
): void => {
  // 15分毎に経過時間を通知する
  const thres = 900; // seconds
  const workElapsedTimeSec = timeIntegerToSeconds(workElapsedTimeInt);
  if (workElapsedTimeSec === 0) return;
  if (workElapsedTimeSec % thres > 0) return;

  const [h, m, s] = unpackTimeInteger(workElapsedTimeInt);
  let text = `${workTypeName}開始から`;
  if (h > 0) {
    text += `${h}時間`;
  }
  if (m > 0) {
    text += `${m}分`;
  }
  if (s > 0) {
    text += `${s}秒`;
  }
  text += 'が経過しました。';
  doSpeech(text);
};

export const updateJohaisetsuStart = async(johaisetsuCarId: number, startTs: Date): Promise<JohaisetsuReport | null> => {
  const reqObj: JohaisetsuReportJohaisetsuStartUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    start_ts: startTs,
  };
  const { data } = await johaisetsuReportApi.updateJohaisetsuStart(reqObj);
  return data;
};
export const updateJohaisetsuEnd = async(johaisetsuCarId: number, endTs: Date): Promise<JohaisetsuReport | null> => {
  const reqObj: JohaisetsuReportJohaisetsuEndUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    end_ts: endTs,
  };
  const { data } = await johaisetsuReportApi.updateJohaisetsuEnd(reqObj);
  return data;
};
export const updateBaseArrival = async(johaisetsuCarId: number): Promise<JohaisetsuReport | null> => {
  const reqObj: JohaisetsuReportBaseArrivalUpdateParams = {
    johaisetsu_car_id: johaisetsuCarId,
    base_arrival_ts: new Date(),
  };
  const { data } = await johaisetsuReportApi.updateJohaisetsuBaseArrival(reqObj);
  return data;
};
export const createOrUpdateJohaisetsuCar = async(
  carParam: CreateOrUpdateJohaisetsuCarParam,
  isNewData: number,
): Promise<JohaisetsuCar> => {
  if (!carParam.currentLocation || !carParam.selectedJohaisetsuType) {
    throw new Error('Failed to create or update content.');
  }
  try {
    const { lat, lon } = carParam.currentLocation;
    const reqObj = {
      lat: lat,
      lon: lon,
      status: carParam.status,
      status_disp: carParam.statusDisp,
      johaisetsu_type: carParam.selectedJohaisetsuType,
      ts_is_new: isNewData,
    };
    const { data } = await johaisetsuCarApi.createOrUpdate(reqObj);
    return data;
  } catch (e) {
    throw new Error('Failed to create or update content.');
  }
};
export const initJohaisetsuReportCommonExt = (): JohaisetsuCarReportExt => {
  return {
    id: null,
    deviceId: null,
    baseDepartureTs: null,
    startTs: null,
    endTs: null,
    baseArrivalTs: null,
    johaisetsuCompanyId: null,
    johaisetsuHanName: null,
  };
};
export const toJohaisetsuReportCommonExt = (johaisetsuReport: JohaisetsuReport | JohaisetsuSettouReport): JohaisetsuCarReportExt => {
  return {
    id: johaisetsuReport.id,
    deviceId: johaisetsuReport.device_id,
    baseDepartureTs: johaisetsuReport.base_departure_ts,
    startTs: johaisetsuReport.start_ts,
    endTs: johaisetsuReport.end_ts,
    baseArrivalTs: johaisetsuReport.base_arrival_ts,
    johaisetsuCompanyId: johaisetsuReport.johaisetsu_company_id,
    johaisetsuHanName: johaisetsuReport.johaisetsu_han_name,
  };
};
