import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { CleaningCarCreateOrUpdateParams, CleaningCarUpdateReportIdParams } from '@/models/apis/cleaning/cleaningCarRequest';
import {
  CleaningCarCreateOrUpdateResponse,
  CleaningCarsGetResponse, CleaningCarShowResponse,
} from '@/models/apis/cleaning/cleaningCarResponse';

export default {
  // SP画面から新規作成or更新
  createOrUpdate(params: CleaningCarCreateOrUpdateParams): Promise<AxiosResponse<CleaningCarCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/cleaning_cars'), params || {});
  },
  updateReportId(id: number, params: CleaningCarUpdateReportIdParams): Promise<AxiosResponse<CleaningCarCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl(`/cleaning_cars/${id}`), params || {});
  },
  async getMyCleaningCar(): Promise<CleaningCarShowResponse | null> {
    const { data } = await axios.get<CleaningCarShowResponse | null>(utils.getApiUrl('/cleaning_cars/me'));
    return data;
  },
  getCleaningCars(): Promise<AxiosResponse<CleaningCarsGetResponse>> {
    return axios.get(utils.getApiUrl('/cleaning_cars'));
  },
};
