import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import { EmptyResponse } from '@/models/index';
import {
  CommonHeader,
  JoukyouInfoRaw,
  Snapshot,
  JohaisetsuDetail,
  SagyouJoukyouDetailRaw,
} from '@/models/apis/johaisetsu/johaisetsuCommon';
import {
  Johaisetsu_joukyouIndexParams,
  Johaisetsu_joukyouShowMainParams,
  Johaisetsu_joukyouShowParams,
} from '@/models/apis/johaisetsu/johaisetsuJoukyouRequest';

export default {
  index(params: Johaisetsu_joukyouIndexParams): Promise<AxiosResponse<CommonHeader[]>> {
    return axios.get(utils.getApiUrl(
      `/johaisetsu_sagyou_joukyou/${params.taskForceId}`,
    ));
  },
  show(mainParams: Johaisetsu_joukyouShowMainParams, params: Johaisetsu_joukyouShowParams = {}): Promise<AxiosResponse<JoukyouInfoRaw<SagyouJoukyouDetailRaw>>> {
    return axios.get(utils.getApiUrl(
      `/johaisetsu_sagyou_joukyou/${mainParams.taskForceId}/${mainParams.headerId}`,
    ), { params });
  },
  // 以下、操作対象はtaskForceId内のtype=currentなheaderレコード配下のみ
  updateDetails(taskForceId: number, params: JohaisetsuDetail): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_sagyou_joukyou/${taskForceId}/details`,
    ), params);
  },
  createSnapshot(taskForceId: number, params: Snapshot): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_sagyou_joukyou/${taskForceId}/create_snapshot`,
    ), params);
  },
};
