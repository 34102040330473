

















import {
  defineComponent,
  ref,
} from '@vue/composition-api';
export default defineComponent({
  name: 'confirm-modal',
  props: {
    title: {
      type: String,
      default: 'キャンセル',
    },
  },
  setup(_, { emit }) {
    const canAction = ref<boolean>(true);

    const onConfirmed = () => {
      canAction.value = false;
      emit('confirm');
    };
    const onDismissed = () => {
      canAction.value = false;
      emit('dismiss');
    };
    return {
      canAction,
      // methods
      onConfirmed,
      onDismissed,
    };
  },
});
