





























































































































































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  PropType,
} from '@vue/composition-api';
import Vue from 'vue';

import deviceApi from '@/apis/device';
import { dtFormat } from '@/lib/dateHelper';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { Car } from '@/models/apis/cars/carResponse';
import { isNumber } from '@/lib/stringUtil';

import { MtxExt, MtxMovieExt, LayerParams } from '@/lib/ExtremeMapDebugMTXMovieLayerManager';

interface SearchParams {
  device_id: string;
  start_ts: string;
  end_ts: string;
  searchedDeviceId?: number;
}
interface Disp {
  mtxs: boolean;
  mtxTimestamps: boolean;
  movies: boolean;
  movieGeoIndices: boolean;
}

interface TopDebugComponentTool1State {
  carNameToId: Record<string, number>;
  deviceIdToId: Record<string, number>;
  search: SearchParams;
  searchParamsToRestore: SearchParams | null;
  searchParamCandidacies: SearchParams[];
  disp: Disp;
  errorMsg: string;
  activeTab: string;
  mtxs: MtxExt[];
  movies: MtxMovieExt[];
}

const localStorageKey = 'rtstrm-debug';
export default defineComponent({
  name: 'top-debug-component-tool1',
  props: {
    refExtremeMap: {
      type: Object as PropType<InstanceType<typeof ExtremeMap>>,
      required: true,
    },
    cars: {
      type: Array as PropType<Car[]>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<TopDebugComponentTool1State>({
      carNameToId: {},
      deviceIdToId: {},
      search: {
        device_id: '',
        start_ts: '',
        end_ts: '',
      },
      searchParamsToRestore: null,
      searchParamCandidacies: [],
      disp: {
        mtxs: true,
        mtxTimestamps: true,
        movies: true,
        movieGeoIndices: false,
      },
      errorMsg: '',
      activeTab: 'mtxs',
      mtxs: [],
      movies: [],
    });

    watch(() => props.cars, () => {
      const carNameToId: Record<string, number> = {};
      const deviceIdToId: Record<string, number> = {};
      props.cars.forEach(car => {
        if (!car.device) { return; }
        carNameToId[car.device.car_name] = car.device.id;
        deviceIdToId[car.device.device_id] = car.device.id;
      });
      state.carNameToId = carNameToId;
      state.deviceIdToId = deviceIdToId;
    });

    onMounted(() => {
      if (Vue.prototype.$isDevelopment()) {
        state.search.device_id = 'RTSTRM_TAKI001';
        state.search.start_ts = '2018-11-02 13:40:00';
        state.search.end_ts = '2018-11-02 13:50:00';
      }
      try {
        const obj = JSON.parse(localStorage.getItem(localStorageKey) || '');
        if (obj.searchParamCandidacies) {
          state.searchParamCandidacies = obj.searchParamCandidacies;
        }
      } catch (e) {}
    });

    const showMTX = async() => {
      state.errorMsg = '';
      state.mtxs = [];
      state.movies = [];
      const search = state.search;
      if (!search.device_id || !search.start_ts || !search.end_ts) { return; }

      let searchId = -1;
      let deviceId = search.device_id;
      if (state.carNameToId[deviceId]) {
        searchId = state.carNameToId[deviceId];
      } else if (state.deviceIdToId[deviceId]) {
        searchId = state.deviceIdToId[deviceId];
      } else if (isNumber(deviceId)) {
        searchId = Number(deviceId);
      }
      state.search.searchedDeviceId = searchId;

      const dtReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      if (!search.start_ts.match(dtReg)) {
        state.errorMsg = '期間fromはyyyy-mm-dd hh:ii:ssで指定してください';
        return;
      }
      if (!search.end_ts.match(dtReg)) {
        state.errorMsg = '期間toはyyyy-mm-dd hh:ii:ssで指定してください';
        return;
      }

      let result;
      try {
        result = await deviceApi.adminGetActivities(
          searchId,
          { start_ts: search.start_ts, end_ts: search.end_ts },
        );
        result = result.data;
      } catch (e: any) {
        let msg = `status:${e.response.status}, `;
        msg += `statusText:${e.response.statusText}, `;
        msg += `data:${JSON.stringify(e.response.data)}`;
        state.errorMsg = msg;
        return;
      }
      if (result.mtxs.length === 0) {
        state.errorMsg = 'データが見つかりませんでした';
        return;
      }
      state.mtxs = result.mtxs.map(e => {
        return {
          ...e,
          placeNameDisp: e.place_name === 'main_line' ? '本線' : e.place_name,
          kpDisp: e.kp2 > 0 ? e.kp + '+' + e.kp2 : e.kp,
        };
      });
      state.movies = result.movies.map(e => {
        e.movie_geo_indices.sort((a, b) => {
          return a.ts_msec_diff < b.ts_msec_diff ? -1 : 1;
        });

        return {
          ...e,
          movie_geo_indices: e.movie_geo_indices.map(f => {
            return {
              ...f,
              placeNameDisp: f.place_name === 'main_line' ? '本線' : f.place_name,
              kpDisp: (f.kp2 > 0) ? (f.kp = '+' + f.kp2) : f.kp,
            };
          }),
        };
      });

      state.disp.mtxs = true;
      state.disp.movies = true;
      state.disp.movieGeoIndices = false;

      plotInfoOnMap();
    };

    const plotInfoOnMap = () => {
      const info: LayerParams = {
        mtxs: state.disp.mtxs ? state.mtxs : [],
        movies: [],
      };

      info.mtxs.forEach(mtx => {
        mtx.showTimestamp = state.disp.mtxTimestamps;
      });

      if (state.disp.movies) {
        info.movies = state.movies.slice().map(e => {
          const obj = Object.assign({}, e);
          if (!state.disp.movieGeoIndices) {
            obj.movie_geo_indices = [];
          }
          return obj;
        });
      }
      props.refExtremeMap.removeDebugMTXMovieLayer();
      props.refExtremeMap.showDebugMTXMovieLayer(info);
    };
    const removeMTX = async() => {
      state.disp.mtxs = false;
      state.disp.mtxTimestamps = false;
      state.disp.movies = false;
      state.disp.movieGeoIndices = false;
      props.refExtremeMap.removeDebugMTXMovieLayer();
    };
    const toggleMTXMapDisp = () => {
      state.disp.mtxTimestamps = state.disp.mtxs;
      plotInfoOnMap();
    };
    const toggleMTXMapTsDisp = () => {
      plotInfoOnMap();
    };
    const toggleMovieMapDisp = () => {
      state.disp.movieGeoIndices = state.disp.movies;
      plotInfoOnMap();
    };
    const toggleMGIMapDisp = () => {
      plotInfoOnMap();
    };

    const setNow = (prop: string) => {
      const formatDate = dtFormat(new Date(), 'yyyy-mm-dd HH:MM:SS');
      if (prop === 'start_ts') {
        state.search.start_ts = formatDate;
      } else {
        state.search.end_ts = formatDate;
      }
    };
    const saveLocalStorage = () => {
      const obj = {
        searchParamCandidacies: state.searchParamCandidacies,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(obj));
    };
    const saveSearchParams = () => {
      const maxLen = 50;
      state.searchParamCandidacies.unshift({
        device_id: state.search.device_id,
        start_ts: state.search.start_ts,
        end_ts: state.search.end_ts,
      });
      state.searchParamCandidacies =
        state.searchParamCandidacies.slice(0, maxLen);

      saveLocalStorage();
      alert(`検索条件を保存しました (最大${maxLen}件まで保存できます)`);
    };
    const restoreSearchParams = () => {
      if (!state.searchParamsToRestore) { return; }
      const params = state.searchParamsToRestore;
      state.search.device_id = params.device_id;
      state.search.start_ts = params.start_ts;
      state.search.end_ts = params.end_ts;
    };
    return {
      ...toRefs(state),
      // methods
      showMTX,
      plotInfoOnMap,
      removeMTX,
      toggleMTXMapDisp,
      toggleMTXMapTsDisp,
      toggleMovieMapDisp,
      toggleMGIMapDisp,
      setNow,
      saveLocalStorage,
      saveSearchParams,
      restoreSearchParams,
      dtFormat,
    };
  },
});
