import Vue from 'vue';

export interface NotifyOptions {
  duration: number;
}

export function notifySuccess(title: string, msg: string, opts: NotifyOptions = {} as NotifyOptions): void {
  Vue.prototype.$notify({
    group: 'main',
    title: title,
    text: msg,
    type: 'info',
    duration: opts.duration || 2500,
  });
}
