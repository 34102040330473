






















































import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs,
  PropType,
} from '@vue/composition-api';

interface ModalState {
  modalBodyMaxHeight: number;
}

export default defineComponent({
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showTopDismissButton: {
      type: Boolean,
      default: false,
    },
    topDismissButtonLabel: {
      type: String,
      default: 'x',
    },
    topDismissButtonClass: {
      type: String,
      default: 'close',
    },
    isCloseButtonBusy: {
      type: Boolean,
      default: false,
    },
    isCloseButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showBottomCloseButton: {
      type: Boolean,
      default: true,
    },
    bottomCloseButtonLabel: {
      type: String,
      default: 'OK',
    },
    bottomCloseButtonClass: {
      type: String,
      default: 'btn-primary',
    },
    showBottomCloseButtonIcon: {
      type: Boolean,
      default: false,
    },
    bottomCloseButtonIconClass: {
      type: String,
      default: 'fa-trash',
    },
    showBottomDismissButton: {
      type: Boolean,
      default: true,
    },
    bottomDismissButtonLabel: {
      type: String,
      default: 'キャンセル',
    },
    maxWidth: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  setup(props) {
    const modalDialog = ref<HTMLElement>();
    const modalHeader = ref<HTMLElement>();
    const modalFooter = ref<HTMLElement>();
    const state = reactive<ModalState>({
      modalBodyMaxHeight: 350,
    });
    const maxWidthStr = computed<string | null>(() => {
      return props.maxWidth !== null ? props.maxWidth + '%' : null;
    });
    onMounted(() => {
      const windowH = window.innerHeight;
      const dialogOffsetTop = modalDialog.value?.offsetTop || 0;
      const modalHeaderH = modalHeader.value?.clientHeight || 0;
      const modalFooterH = modalFooter.value?.clientHeight || 0;
      state.modalBodyMaxHeight =
        windowH - modalHeaderH - modalFooterH - dialogOffsetTop * 2;
    });
    const shouldCloseButtonDisabled = computed(() => {
      return props.isCloseButtonBusy || props.isCloseButtonDisabled;
    });
    return {
      ...toRefs(state),
      maxWidthStr,
      modalDialog,
      modalHeader,
      modalFooter,
      shouldCloseButtonDisabled,
    };
  },
});
