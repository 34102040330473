




















import {
  defineComponent,
  PropType,
} from '@vue/composition-api';
import TopDebugComponentTool1 from '@/components/Top/TopDebugComponent/tool1.vue';
import TopDebugComponentTool2 from '@/components/Top/TopDebugComponent/tool2.vue';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { Car } from '@/models/apis/cars/carResponse';

export default defineComponent({
  name: 'top-debug-component',
  props: {
    refExtremeMap: {
      type: Object as PropType<InstanceType<typeof ExtremeMap>>,
      required: true,
    },
    cars: {
      type: Array as PropType<Car[]>,
      required: true,
    },
  },
  setup() {
    return {};
  },
  components: {
    TopDebugComponentTool1,
    TopDebugComponentTool2,
  },
});
