
import LegendTemperature from '@/components/lib/dataLayerLegend/legendTemperature.vue';
import LegendSalinity from '@/components/lib/dataLayerLegend/legendSalinity.vue';
import LegendSnowfall from '@/components/lib/dataLayerLegend/legendSnowfall.vue';
import LegendFrozen from '@/components/lib/dataLayerLegend/legendFrozen.vue';
import LegendStallRiskPoint from '@/components/lib/dataLayerLegend/legendStallRiskPoint.vue';
import LegendSnowMountain from '@/components/lib/dataLayerLegend/legendSnowMountain.vue';
import LegendJhWorkStatus from '@/components/lib/dataLayerLegend/legendJhWorkStatus.vue';
import LegendTairyu from '@/components/lib/dataLayerLegend/legendTairyu.vue';
import LegendShowSanpuNumType from '@/components/lib/dataLayerLegend/johaisetsu/legendShowSanpuNumType.vue';
import legendOtherType from '@/components/lib/dataLayerLegend/johaisetsu/legendOtherType.vue';

export default {
  LegendTemperature,
  LegendSalinity,
  LegendSnowfall,
  LegendFrozen,
  LegendStallRiskPoint,
  LegendSnowMountain,
  LegendJhWorkStatus,
  LegendTairyu,
  LegendShowSanpuNumType,
  legendOtherType,
};
