import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class snowfallHelper extends AbstractGeoItem<number, number, number, string> {
  dataStep(): number {
    return 5;
  }
  dataToObjKey(inputData: number): number {
    // 過渡期のため、スカラーもしくはオブジェクトの両方がありうる.
    // 落ち着いたら全部オブジェクトになるので消す.
    // (2018/11/18 なぜオブジェクトがあり得るのか忘れた. スカラーでよいのでは???)
    let data: DataContent = { v: Number.MAX_VALUE };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    const step = this.dataStep();
    const ret = Math.floor(data.v as number / step) * step;
    return ret;
  }
  objKeyToColor(intputObjKey: number): string {
    const objKey = parseInt(intputObjKey.toString());
    let ret = '#b1b1b1';
    if (objKey === 0) {
      ret = ''; // [0, 0, 0, 0] // なしは表示しない
    } else if (objKey === 5) {
      ret = '#18FFFF';
    } else if (objKey === 10) {
      ret = '#81D4FA';
    } else if (objKey === 15) {
      ret = '#42A5F5';
    } else if (objKey === 20) {
      ret = '#0000FF';
    } else if (objKey === 25) {
      ret = '#A001C3';
    } else if (objKey === 30) {
      ret = '#FFFF00';
    } else if (objKey === 35) {
      ret = '#FF0000';
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
