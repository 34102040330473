import { RootState } from '@/hooks/useStore';
import { ExtParams } from 'src/models';
import {
  Store,
  ActionContext,
  ActionTree,
  GetterTree,
  MutationTree,
  CommitOptions,
  DispatchOptions,
  Module,
} from 'vuex';

const localStorageName = 'rt_streaming_ap';

export type CarFilterGroupScope = 'g2' | 'g3' | null;

export interface Preference {
  car_filter_group_scope: CarFilterGroupScope;
}

export interface LocalStorageState {
  serverToken: string;
  preferences: Preference;
  extParams: ExtParams;
}

// the definition of initial state is required.
const state: LocalStorageState = {
  serverToken: '',
  preferences: {
    car_filter_group_scope: null,
  },
  extParams: {},
};

const cpProps: Array<keyof LocalStorageState> = [
  'serverToken',
  'preferences',
  'extParams',
];
try {
  const storedItem = localStorage.getItem(localStorageName);
  if (storedItem) {
    const config = JSON.parse(storedItem);
    cpProps.forEach(key => {
      if (config[key]) {
        state[key] = config[key];
      }
    });
  }
} catch (e) {}

export enum LocalStorageGetterTypes {
  SERVER_TOKEN = 'serverToken',
  PREFERENCES = 'preferences',
  EXT_PARAMS = 'extParams',
}

export interface LocalStorageGetters<S = LocalStorageState> {
  [LocalStorageGetterTypes.SERVER_TOKEN](state: S): string;
  [LocalStorageGetterTypes.PREFERENCES](state: S): Preference;
  [LocalStorageGetterTypes.EXT_PARAMS](state: S): ExtParams;
}

const getters: GetterTree<LocalStorageState, RootState> & LocalStorageGetters = {
  [LocalStorageGetterTypes.SERVER_TOKEN]: (state) => state.serverToken,
  [LocalStorageGetterTypes.PREFERENCES]: (state) => state.preferences,
  [LocalStorageGetterTypes.EXT_PARAMS]: (state) => state.extParams,
};
export enum LocalStorageMutationTypes {
  SET_LOCALSTORAGE = 'SET_LOCALSTORAGE',
}

type LocalStorageKeys = 'serverToken' | 'preferences' | 'extParams';
type LocalStorageValTypes = string | Preference | ExtParams;

type KeyVal = {
  key: LocalStorageKeys;
  val: LocalStorageValTypes;
};

interface MutationPayload {
  data: {
    serverToken?: string;
    preferences?: Preference;
    extParams?: ExtParams;
  };
}

export type LocalStorageMutations<S = LocalStorageState> = {
  [LocalStorageMutationTypes.SET_LOCALSTORAGE](state: S, payload: MutationPayload): void;
};

const mutations: MutationTree<LocalStorageState> & LocalStorageMutations = {
  [LocalStorageMutationTypes.SET_LOCALSTORAGE](state, payload): void {
    state.serverToken = payload.data.serverToken ?? state.serverToken;
    state.preferences = payload.data.preferences ?? state.preferences;
    state.extParams = payload.data.extParams ?? state.extParams;
    localStorage.setItem(localStorageName, JSON.stringify(state));
  },
};

export enum LocalStorageActionTypes {
  GET = 'get',
  SET = 'set',
  SET_PREFERENCE = 'setPreference',
}

type AugmentedActionContext = {
  commit<K extends keyof LocalStorageMutations>(
    key: K,
    payload?: Parameters<LocalStorageMutations[K]>[1]
  ): ReturnType<LocalStorageMutations[K]>;
  getters: {
    [K in keyof LocalStorageGetters]: ReturnType<LocalStorageGetters[K]>
  };
} & Omit<ActionContext<LocalStorageState, RootState>, 'commit' | 'getters'>;

export interface LocalStorageActions {
  [LocalStorageActionTypes.GET]({ commit, getters }: AugmentedActionContext, param: { key: LocalStorageKeys }): Promise<LocalStorageValTypes>;
  [LocalStorageActionTypes.SET]({ commit }: AugmentedActionContext, data: KeyVal): void;
  [LocalStorageActionTypes.SET_PREFERENCE]({ commit }: AugmentedActionContext, data: { key: 'car_filter_group_scope'; val: 'g2' | 'g3' | null }): void;
}

const actions: ActionTree<LocalStorageState, RootState> & LocalStorageActions = {
  [LocalStorageActionTypes.GET](_, { key }): Promise<LocalStorageValTypes> {
    return Promise.resolve(state[key]);
  },
  [LocalStorageActionTypes.SET]({ commit }, { key, val }): void {
    const data = { [key]: val };
    commit(LocalStorageMutationTypes.SET_LOCALSTORAGE, { data });
  },
  [LocalStorageActionTypes.SET_PREFERENCE]({ commit }, { key, val }): void {
    const pref = Object.assign({}, state.preferences);
    pref[key] = val;
    const data = { preferences: pref };
    commit(LocalStorageMutationTypes.SET_LOCALSTORAGE, { data });
  },
};

export type LocalStorageStore<S = LocalStorageState> = Omit<Store<S>, 'getters' | 'commit' | 'dispatch'>
& {
  getters: {
    [K in keyof LocalStorageGetters]: ReturnType<LocalStorageGetters[K]>
  };
} & {
  commit<K extends keyof LocalStorageMutations, P extends Parameters<LocalStorageMutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<LocalStorageMutations[K]>;
} & {
  dispatch<K extends keyof LocalStorageActions>(
    key: K,
    payload?: Parameters<LocalStorageActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<LocalStorageActions[K]>;
};

export const store: Module<LocalStorageState, RootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
