import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class cyzen_josetsuHelper extends AbstractGeoItem<boolean, number, number, number[]> {
  dataToObjKey(inputData: boolean): number {
    let data: DataContent = { v: false };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    return data.v ? 1 : 0;
  }
  objKeyToColor(intputObjKey: number): number[] {
    let ret;
    const objKey = parseInt(intputObjKey.toString());
    if (!objKey) {
      ret = [0, 0, 0, 0]; // なしは表示しない
    } else {
      ret = [100, 145, 44, 1];
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
