


import {
  defineComponent,
  reactive,
  PropType,
} from '@vue/composition-api';
import useSettouReport from '@/components/Sp/Johaisetsu/SettouPatrol/composables/useSettouReport';
import { JohaisetsuCarExt } from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import SaveWorkSituation from '@/components/Sp/Johaisetsu/SettouPatrol/SettouReportCommon/SaveWorkSituation/index.vue';
import {
  JohaisetsuSettouPatrolUpdateParamsCommon,
} from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';
import {
  JohaisetsuSettouOnsiteManagementUpdateParams,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementRequest';

interface State {
  workType?: string | null;
  roadNameDisp?: string | null;
  workArea?: string | null;
}

export default defineComponent({
  props: {
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
      roadNames,
      johaisetsuTypeMap,
    } = useSettouReport({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const state = reactive<State>({
      workType: johaisetsuTypes.find((type) => type.work_type === props.johaisetsuCar.report.workType)?.key,
      roadNameDisp: props.johaisetsuCar.report.roadNameDisp,
      workArea: props.johaisetsuCar.report.workArea,
    });
    const displayJohaisetsuHan = props.johaisetsuCar.report.johaisetsuHanName;

    const showErrorMsg = (msg: string) => {
      emit('showErrorOnCommon', msg);
    };
    const tryRevertEditWorkSituation = () => {
      emit('tryRevertEditWorkSituationOnCommon');
    };
    const saveWorkSituation = async(johaisetsuSettouPatrolUpdateParams: JohaisetsuSettouPatrolUpdateParamsCommon) => {
      if (!state.workType) {
        emit('showErrorOnCommon', '作業種別が必須です。');
        return;
      }
      const johaisetsuSettouOnsiteManagementUpdateParams: JohaisetsuSettouOnsiteManagementUpdateParams = {
        ...johaisetsuSettouPatrolUpdateParams,
        road_name_disp: state.roadNameDisp,
        work_area: state.workArea,
        work_type: johaisetsuTypeMap[state.workType].work_type,
      };
      emit('saveWorkSituationOnCommon', johaisetsuSettouOnsiteManagementUpdateParams, state.workType);
    };

    return {
      state,
      // computed
      johaisetsuTypes,
      roadNames,
      isShowJohaisetsuTypeSelect,
      displayJohaisetsuHan,
      // methods
      saveWorkSituation,
      showErrorMsg,
      tryRevertEditWorkSituation,
    };
  },
  components: {
    SaveWorkSituation,
  },
});
