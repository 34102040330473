const getDayCount = (year: number, month: number): number => {
  const dict = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 1) {
    if ((year % 400 === 0) || (year % 4 === 0 && year % 100 !== 0)) {
      return 29;
    }
  }
  return dict[month];
};
const siblingsMonth = (v: Date, n: number): Date => {
  return new Date(v.getFullYear(), v.getMonth() * 1 + n);
};
const getYearMonth = (year: number, month: number): {
  year: number;
  month: number;
} => {
  if (month > 11) {
    year++;
    month = 0;
  } else if (month < 0) {
    year--;
    month = 11;
  }
  return {year: year, month: month};
};
const parse = (str: string, failureValue: Date | null = new Date()): Date | null => {
  if (!str) { return failureValue; }
  let ret: Date;
  const retFunc = () => {
    return isNaN(ret.getFullYear()) ? failureValue : ret;
  };
  let m: RegExpMatchArray | null;
  m = str.match(/^(\d{4})[^\d](\d{2})[^\d](\d{2})$/);
  if (m) {
    // yyyy/mm/dd的な
    ret = new Date(parseInt(m[1]), parseInt(m[2]) - 1, parseInt(m[3]));
    return retFunc();
  }
  m = str.match(/^(\d{2})[^\d](\d{2})[^\d](\d{4})$/);
  if (m) {
    // dd/mm/yyyy的な
    ret = new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]));
    return retFunc();
  }
  // 他の入力フォーマットも対応したかったらここに追加する

  return failureValue;
};
const detectDateEmitFormat = (str: string): string => {
  let m: RegExpMatchArray | null;
  m = str.match(/^(\d{4})[^\d](\d{2})[^\d](\d{2})$/);
  if (m) {
    return 'yyyy/mm/dd';
  }
  m = str.match(/^(\d{2})[^\d](\d{2})[^\d](\d{4})$/);
  if (m) {
    return 'mm/dd/yyyy';
  }
  m = str.match(/^([^\d]{2})(\d+)年(\d+)月(\d+)日$/);
  if (m) {
    return 'g年m月d日';
  }
  return 'yyyy-mm-dd';
};

export {
  getDayCount,
  siblingsMonth,
  getYearMonth,
  parse,
  detectDateEmitFormat,
};
