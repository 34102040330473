
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsu/johaisetsuHelper';
import johaisetsuPlanningApi from '@/apis/johaisetsu/johaisetsu_planning';
import { getTaskForceInfos, isDataReadOnly, onChangeUrlPathParam } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  preparePlanningBlocks,
  recalcPlanningBlockHaisetsuKeisan,
  blockIdxs,
  hoursPerBlock,
  PREPARE_TYPE_HAISETSU_KEISAN,
} from '@/lib/johaisetsu/johaisetsuPlanningUtil';
import { notifySuccess } from '@/lib/notificationUtil';
import { emptyToNull } from '@/lib/stringUtil';
import { numberFormat } from '@/lib/utils';
import { PlanningBlock, TaskForce } from '@/models/johaisetsu/johaisetsuCommon';
import { CommonHeader,
  ProgressTableTimeBlock,
  JoukyouInfo } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsu/johaisetsuRoleHelper';

interface HaisetsuKeisanInputState {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;
  planningBlocks: PlanningBlock[];

  taskForces: TaskForce[];
  showTaskForceSelect: boolean;
  selectedTaskForce: TaskForce;

  planningHeaders: CommonHeader[];
  selectedPlanningInfo: JoukyouInfo | null;

  showInputTutorial: boolean;

  blockIdxs: number[];
  hoursPerBlock: number;

  columnDispMode: number;
  blockDispMode: number;
}
export default defineComponent({
  name: 'johaisetsu-haisetsu-keisan-input',
  setup() {
    const state = reactive<HaisetsuKeisanInputState>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,
      planningBlocks: [],

      taskForces: [],
      showTaskForceSelect: false,
      selectedTaskForce: { id: -1, name: '' },

      planningHeaders: [],
      selectedPlanningInfo: null,

      showInputTutorial: false,

      blockIdxs: blockIdxs,
      hoursPerBlock: hoursPerBlock,

      columnDispMode: 100,
      blockDispMode: 100,
    });
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });

    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const showSekisetsuInputOnNewTab = () => {
      const routeObj = {
        name: 'JohaisetsuSekisetsuInput',
        params: {
          taskForceId: taskForceId.value.toString(),
          headerId: headerId.value,
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };
    const onAllocatedGroupChanged = (planningBlock: PlanningBlock, blockNum: number) => {
      const inputMap = planningBlock.inputMap;
      if (!inputMap) { return; }
      const timeBlock1 = inputMap.time_blocks1[blockNum];

      const numGroups = timeBlock1.allocated_groups.toString();
      if (numGroups === null || numGroups === '' || !numGroups.match(/^\d+$/)) {
        timeBlock1.allocated_groups = 0;
      } else {
        timeBlock1.allocated_groups = parseInt(numGroups);
      }
      recalcPlanningBlockHaisetsuKeisan(planningBlock);
    };
    const onReopeningPriorityChanged = (planningBlockIdx: number) => {
      // 画面表示の都合で路線_区間ごとに先頭行のtextinputしか出してないので、
      // 次行にコピる.
      const pb = state.planningBlocks[planningBlockIdx];
      if (!pb.firstInRoadNameDispKukan) { return; }
      const pbNext = state.planningBlocks[planningBlockIdx + 1];

      // 一応整数かどうか見る
      if (!pb.inputMap) { return; }
      let valTmp = pb.inputMap.reopening_priority;
      let val: number | null = null;
      if (valTmp !== null) {
        val = valTmp.match(/^\d+$/) ? parseInt(valTmp) : null;
      }
      pb.inputMap.reopening_priority = val?.toString() || null;
      if (!pbNext.inputMap) { return; }
      pbNext.inputMap.reopening_priority = val?.toString() || null;
      // このパラメータは今の所計算に使ってないので再計算は必要ない.
    };
    const onAdjustedRequiredHoursPerKmGroupChanged = (planningBlockIdx: number) => {
      // 画面表示の都合で路線_区間ごとに先頭行のtextinputしか出してないので、
      // 次行にコピる.
      const pb = state.planningBlocks[planningBlockIdx];
      if (!pb.firstInRoadNameDispKukan) { return; }
      const pbNext = state.planningBlocks[planningBlockIdx + 1];

      if (!pb.inputMap) { return; }
      let valTmp = pb.inputMap.adjusted_required_hours_per_km_group;
      let val: number | null = null;
      if (valTmp !== null) {
        val = valTmp.match(/^\d+(\.\d+)?$/) ? parseFloat(valTmp) : null;
      }
      pb.inputMap.adjusted_required_hours_per_km_group = val?.toString() || null;
      if (!pbNext.inputMap) { return; }
      pbNext.inputMap.adjusted_required_hours_per_km_group = val?.toString() || null;

      recalcPlanningBlockHaisetsuKeisan(pb);
      recalcPlanningBlockHaisetsuKeisan(pbNext);
    };
    const saveItems = async() => {
      if (state.isRequesting) { return; }

      const details = state.planningBlocks.map(pb => {
        return {
          planning_block_id: pb.id,
          reopening_priority: emptyToNull(pb.inputMap?.reopening_priority || null),
          required_hours_per_km_group: emptyToNull(pb.inputMap?.required_hours_per_km_group || null),
          adjusted_required_hours_per_km_group: emptyToNull(pb.inputMap?.adjusted_required_hours_per_km_group || null),
          time_blocks1: pb.inputMap?.time_blocks1.map(timeBlock => {
            return {
              time_block_idx: timeBlock.time_block_idx,
              allocated_groups: timeBlock.allocated_groups,
              haisetsu_hours: timeBlock.haisetsu_hours,
              haisetsu_distance: timeBlock.haisetsu_distance,
              haisetsu_remaining_distance: timeBlock.haisetsu_remaining_distance,
            };
          }) || [],
          time_blocks2: pb.inputMap?.time_blocks2.map(timeBlock => {
            return {
              time_block_idx: timeBlock.time_block_idx,
              haisetsu_start_offset: timeBlock.haisetsu_start_offset,
            };
          }) || [],
        };
      });

      state.isRequesting = true;
      await johaisetsuPlanningApi.updateHaisetsuKeisan(
        state.selectedTaskForce.id,
        { details: details },
      );
      notifySuccess('', 'データを保存しました');
      // 再取得
      await getSelectedPlanningInfo();
      state.isRequesting = false;
    };
    const getSelectedPlanningInfo = async() => {
      const { data: selectedPlanningInfo } = await johaisetsuPlanningApi.show({
        taskForceId: state.selectedTaskForce.id,
        headerId: headerId.value,
      });
      state.selectedPlanningInfo = selectedPlanningInfo;

      state.planningBlocks = preparePlanningBlocks(
        state.selectedTaskForce,
        state.selectedPlanningInfo,
        johaisetsuRole.value,
        PREPARE_TYPE_HAISETSU_KEISAN,
      );
    };
    const setHaisetsuStartParamsInner = (rows: PlanningBlock[], blockIdx: number, mutationFunc: (timeBlock2: ProgressTableTimeBlock) => void) => {
      // blockIdxを含めそれより右側の開始時刻を変更する
      rows.forEach(targetRow => {
        const timeBlocks2 = targetRow.inputMap?.time_blocks2;
        if (!timeBlocks2) { return; }
        for (let i = blockIdx, len = timeBlocks2?.length || 0; i < len; i++) {
          mutationFunc(timeBlocks2[i]);
        }
      });
    };
    const offsetHaisetsuStartHour = (blockIdx: number, offset: number) => {
      const func = (timeBlock2: ProgressTableTimeBlock) => {
        const origOffset = timeBlock2.haisetsu_start_offset || 0;
        const calculatedOffset = Math.max(0, Math.min(hoursPerBlock - 1, origOffset + offset));
        const offsetDiff = calculatedOffset - origOffset;
        if (timeBlock2.haisetsu_start_offset !== undefined) {
          timeBlock2.haisetsu_start_offset += offsetDiff;
        }
        if (timeBlock2.haisetsu_start_hour !== undefined) {
          timeBlock2.haisetsu_start_hour += offsetDiff;
        }
      };
      setHaisetsuStartParamsInner(state.planningBlocks, blockIdx, func);
    };
    const onChangeHaisetsuStartHour = (row: PlanningBlock, blockIdx: number) => {
      let startHour = row.inputMap?.time_blocks2[blockIdx].haisetsu_start_hour;
      if (startHour !== undefined && isNaN(startHour) && row.inputMap) {
        // 元に戻して終了
        const timeBlock2 = row.inputMap.time_blocks2[blockIdx];
        const offset = timeBlock2.haisetsu_start_offset || 0;
        timeBlock2.haisetsu_start_hour = timeBlock2.time_block_idx * hoursPerBlock + offset;
        return;
      }
      const minHour = blockIdx * hoursPerBlock;
      const maxHour = minHour + (hoursPerBlock - 1);
      const calculatedStartHour = Math.min(maxHour, Math.max(minHour, startHour || 0));
      const calculatedOffset = calculatedStartHour - minHour;
      const func = (timeBlock2: ProgressTableTimeBlock) => {
        timeBlock2.haisetsu_start_offset = calculatedOffset;
        timeBlock2.haisetsu_start_hour = timeBlock2.time_block_idx * hoursPerBlock + calculatedOffset;
      };
      setHaisetsuStartParamsInner([row], blockIdx, func);
    };
    onMounted(async() => {
      state.isRequesting = true;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;

      // 待たなくていい
      johaisetsuPlanningApi.index({
        taskForceId: state.selectedTaskForce.id,
      }).then(({ data: planningHeaders }) => {
        state.planningHeaders = planningHeaders;
      });

      await getSelectedPlanningInfo();
      state.isRequesting = false;

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedPlanningInfo?.header,
        johaisetsuRole.value,
      );

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      // methods
      onSelectedTaskForceChange,
      showSekisetsuInputOnNewTab,
      onAllocatedGroupChanged,
      onReopeningPriorityChanged,
      onAdjustedRequiredHoursPerKmGroupChanged,
      saveItems,
      getSelectedPlanningInfo,
      setHaisetsuStartParamsInner,
      offsetHaisetsuStartHour,
      onChangeHaisetsuStartHour,
      numberFormat,
    };
  },
});
