import EMEventNames from '@/consts/extreme_map_event_names';
import { Icon, Style } from 'ol/style';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { GIComment } from '@/models/geoItem';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { FeatureLike } from 'ol/Feature';
import Vue from 'vue';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import ExtremeMap from '@/lib/ExtremeMap/index.vue';
import GICommentManager from './geo_item/GICommentManager';

export interface InitArgs {
  dataName?: string;
  giManager?: GICommentManager | null;
}

export default class ExtremeMapCommentLayerManager extends ExtremeMapAbstractLayerManager {
  commentTypeToIconType: Record<string, string>;
  resourceMap: Record<string, GIComment>;
  dataName: string;
  giManager?: GICommentManager | null;
  emitter: Vue;
  emListenEventNames: string[];
  isConnectedWithExtremeMap: boolean;

  constructor({ dataName, giManager }: InitArgs = {}) {
    super();
    this.resourceMap = {};

    this.dataName = dataName || ''; // リソース名称(commentとか)
    this.giManager = giManager; // 使うかどうかはわからないがとりあえずもらっておく

    this.emitter = new Vue();
    this.isConnectedWithExtremeMap = false;
    this.commentTypeToIconType = {
      share: 'blue',
      report: 'lgreen',
      indication: 'yellow',
      other: 'gray',
    };
    this.emListenEventNames = [EMEventNames.EM_EVENT_CLICK];
  }

  getIconPath_(comment: GIComment): { iconPath: string; selectedFramePath: string } {
    const iconPath = comment.iconPath || '/static/img/comment_icon_04.png';
    const selectedFramePath = `/static/img/pin_selected.png`;
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(comment: GIComment): Style[] {
    const ret = [];
    const { iconPath, selectedFramePath } = this.getIconPath_(comment);
    if (comment.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
      }));
    }
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
    }));
    if (comment.angle !== null) {
      ret.push(new Style({
        image: new Icon({
          src: '/static/img/arrow-small1.png',
          anchor: [0.5, 0.5 + 1.34], // anchorの計算がされてからrotateされる
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          rotation: comment.angle / 180.0 * Math.PI,
        }),
      }));
    }
    return ret;
  }

  getResourceFeatures_(comment: GIComment): Feature[] {
    const coord = this.convCoord({
      lon: parseFloat(comment.lon.toString()),
      lat: parseFloat(comment.lat.toString()),
    });
    const feat = new Feature(new Point(coord));
    feat.setId(comment.id);
    feat.setStyle(this.getResourceStyles_(comment));
    return [feat];
  }

  getBaseDataForEmit(): { dataName: string } {
    // layerManager子クラスでemitするときに必ず投げるデータ
    return { dataName: this.dataName };
  }

  onClickFeature_(targetFeat: FeatureLike): void {
    const targetFeatId = targetFeat.getId();
    if (!targetFeatId) {
      return;
    }
    const targetResource = this.resourceMap[targetFeatId];

    // 地図上の見た目を調整
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected =
        tmpResource.id === targetResource.id &&
        !tmpResource.isSelected;
      if (currentIsSelected !== tmpResource.isSelected) {
        const feat = layerSource.getFeatureById(tmpResource.id);
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }

    // イベント発火
    const obj: { dataName: string; data?: GIComment } = this.getBaseDataForEmit();
    obj.data = targetResource;
    this.emitter.$emit(EMEventNames.EM_EVENT_CLICK, obj);
  }

  createLayer_(resources: GIComment[]): void {
    const feats: Feature[] = [];
    resources.forEach(resource => {
      feats.push(...this.getResourceFeatures_(resource));
    });
    const layer = new NamedVectorLayer(this.dataName, {
      source: new VectorSource({features: feats}),
    });
    this.layer = layer;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (!feature) {
        return;
      }
      this.onClickFeature_(feature);
    };
  }

  getRelativeComments(comment: GIComment): GIComment[] {
    const results: GIComment[] = [];
    if (comment.isParentComment) {
      // 親付箋の場合、子付箋を返す
      // return Object.values(this.resourceMap).filter((e: Comment) => e.parent_id === comment.id);
      Object.values(this.resourceMap).forEach(e => {
        if (e.parent_id === comment.id) {
          results.push(e);
        }
      });
      return results;
    }

    // 子付箋の場合、(自身を除き)親付箋または同じ親の付箋を返す
    Object.values(this.resourceMap).forEach(e => {
      if (e.id !== comment.id && (e.id === comment.parent_id || e.parent_id === comment.parent_id)) {
        results.push(e);
      }
    });
    return results;
  }

  getGeoItemByIndexDiff(current: GIComment, diff: number): GIComment {
    const relativeComments = this.getRelativeComments(current);
    const candidateComments = Object.values(this.resourceMap)
      .filter(e => !relativeComments.includes(e))
      .sort((a, b) => a.ts && b.ts && a.ts < b.ts ? -1 : a.ts && b.ts && a.ts > b.ts ? 1 : 0);
    const currentIdx = candidateComments.indexOf(current);
    // ないはずはないが、念の為救済
    if (currentIdx === -1) { return current; }

    const modBase = candidateComments.length;
    const move = modBase + diff % modBase;
    const targetIdx = (currentIdx + move) % modBase;
    return candidateComments[targetIdx];
  }

  prepareLayer(resources: Array<GIComment>): LayerWithInfo {
    this.resourceMap = resources.reduce<Record<string, GIComment>>((acc, e) => {
      acc[e.id] = e; return acc;
    }, {});
    this.createLayer_(resources);
    return this.getLayer();
  }

  getResourceMap(): Record<string, GIComment> {
    return this.resourceMap;
  }

  addLayerItem(resource: GIComment): void {
    this.resourceMap[resource.id] = resource;
    const feats = this.getResourceFeatures_(resource);
    if (!this.layer) { return; }
    this.layer.getSource().addFeatures(feats);
  }

  updateLayerItem(resource: GIComment): void {
    this.resourceMap[resource.id] = resource;
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    const feat = layerSource.getFeatureById(resource.id);
    feat.setStyle(this.getResourceStyles_(resource));
  }

  deleteLayerItem(resource: GIComment): void {
    delete this.resourceMap[resource.id];
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    const feat = layerSource.getFeatureById(resource.id);
    layerSource.removeFeature(feat);
  }

  deselectAll(): void {
    if (!this.layer) { return; }
    const layerSource = this.layer.getSource();
    for (const ent of Object.entries(this.resourceMap)) {
      const tmpResource = ent[1];
      const currentIsSelected = tmpResource.isSelected;
      tmpResource.isSelected = false;
      if (currentIsSelected !== tmpResource.isSelected) {
        const feat = layerSource.getFeatureById(tmpResource.id);
        if (!feat) { continue; }
        feat.setStyle(this.getResourceStyles_(tmpResource));
      }
    }
  }

  connectWithExtremeMap(em: InstanceType<typeof ExtremeMap>): void {
    // ExtremeMapの参照をもらい、このクラスでイベントが発火したら
    // ExtremeMapの対応するタイプのメソッドが呼ばれるようにする.
    if (this.isConnectedWithExtremeMap) { return; }
    this.isConnectedWithExtremeMap = true;
    this.emListenEventNames.forEach(evtName => {
      const tmpStr = evtName[0].toUpperCase() + evtName.slice(1);
      const funcName = 'handleLayerManagerEvent' + tmpStr;
      this.emitter.$on(evtName, (data: any) => {
        if (!em.hasOwnProperty(funcName)) { return; }
        em[funcName](data);
      });
    });
  }

  destroy(): void {
    this.emitter.$off();
    this.isConnectedWithExtremeMap = false;
    // override in child class if you want to explicitly
    // release some objects or so.
  }
}
