import { KilopostsMaster } from '@/models/apis/master/masterResponse';
import { RoadNameDirectionShortcut, RoadNameDirectionMix } from '@/models/index';
import { GIKilopost } from '@/models/geoItem';
import { Kilopost } from '@/models/apis/device/adminDeviceResponse';

export function convKiloposts_(obfuscatedKpObj: KilopostsMaster): Map<string, Map<string, GIKilopost[]>> {
  const kps = deobfuscateKps(obfuscatedKpObj);
  const ret: Map<string, Map<string, GIKilopost[]>> = new Map();
  for (const kp of kps) {
    // 路線名、方向、本線/それ以外 でまとめる.
    // それ以外については、さらに場所名でまとめる.
    const k1 = `${kp.road_name}#${kp.direction}`;
    const k2 = kp.road_section_type === '1' ? 'main_line' : kp.place_name;
    if (!k2) { continue; }

    if (!ret.has(k1)) { ret.set(k1, new Map()); }
    const obj1 = ret.get(k1);
    if (obj1 !== undefined) {
      if (!obj1.has(k2)) { obj1.set(k2, []); }
      const arr = obj1.get(k2);
      const giKp: GIKilopost = {
        ...kp,
        kp: parseFloat(kp.kp),
        kp2: kp.kp2 ? parseInt(kp.kp2.toString()) : kp.kp2,
        kp_calc: parseFloat(kp.kp_calc),
        lat: parseFloat(kp.lat),
        lon: parseFloat(kp.lon),
        place_name_disp: kp.place_name === 'main_line' ? '' : kp.place_name,
      };
      if (arr !== undefined) { arr.push(giKp); }
    }
  }

  for (const ent1 of ret) {
    const obj1 = ent1[1];
    for (const [place, arr] of obj1) {
      if (place === 'main_line') {
        // 本線
        arr.sort((a: GIKilopost, b: GIKilopost) => {
          const v1 = a.kp_calc;
          const v2 = b.kp_calc;
          return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
        });
      } else {
        // それ以外
        // (少なくとも今の所、road_section_typeが'1'以外だったら
        // place_nameに場所名が入っている)
        arr.sort((a: GIKilopost, b: GIKilopost) => {
          if (a.place_name < b.place_name) {
            return -1;
          } else if (a.place_name > b.place_name) {
            return 1;
          }
          return a.kp_calc < b.kp_calc ? -1 : (a.kp_calc > b.kp_calc ? 1 : 0);
        });
      }
    }
  }
  return ret;
}

function getVal(service: any, val: string, defaultVal: string): any {
  let ret = service[val];
  if (ret === undefined) { ret = service[defaultVal]; }
  return ret;
}

export function deobfuscateKps(origKpObj: KilopostsMaster): Kilopost[] {
  // サーバ側で短縮されているものを元に戻す.
  // property名、あとroad_nameとroad_name_disp.
  const kps = origKpObj.kiloposts;
  const kpValMap = origKpObj.kilopost_val_map;

  const results: Kilopost[] = [];
  kps.forEach(kp => {
    results.push({
      kp_uid: kp.a,
      road_name_disp: kpValMap['b'] ? kpValMap['b'][kp.b] : '',
      road_name: kpValMap['c'] ? kpValMap['c'][kp.c] : '',
      direction: kpValMap['d'] ? kpValMap['d'][kp.d] : kp.d,
      road_section_type: kpValMap['e'] ? kpValMap['e'][kp.e] : kp.e,
      place_name: kpValMap['f'] ? kpValMap['f'][kp.f] : kp.f,
      kp_prefix: kpValMap['g'] ? kpValMap['g'][kp.g] : kp.g,
      kp: kpValMap['h'] ? kpValMap['h'][kp.h] : kp.h,
      kp2: kpValMap['i'] ? kpValMap['i'][kp.i] : kp.i,
      kp_calc: kpValMap['j'] ? kpValMap['j'][kp.j] : kp.j,
      lat: kpValMap['k'] ? kpValMap['k'][kp.k] : kp.k,
      lon: kpValMap['l'] ? kpValMap['l'][kp.l] : kp.l,
      bikou1: kpValMap['m'] && kp.m ? kpValMap['m'][kp.m] : kp.m,
      kp_set_id: kpValMap['n'] ? kpValMap['n'][kp.n] : kp.n,
      kp_disp: kpValMap['o'] && kp.o ? kpValMap['o'][kp.o] : kp.o,
      angle: kpValMap['p'] ? kpValMap['p'][kp.p] : kp.p,
    });
  });

  return results;
}

// convKiloposts_で加工したものを引数にもらい、画面で利用する
// 路線名#方向の一覧を生成する、と思ったけどソート順とか考えるの
// めんどいから全部ベタ書きにする.
class RoadNameDirectionsService {
  get getRoadNameDirections_shutokou() {
    const srcArr = [
      ['t-west', 't-01ueno', '1号上野線', '上,下', '高速1号上野線'],
      ['t-west', 't-01haneda', '1号羽田線', '上,下', '高速1号羽田線'],
      ['t-west', 't-02meguro', '2号目黒線', '上,下', '高速2号目黒線'],
      ['t-west', 't-03shibuya', '3号渋谷線', '上,下', '高速3号渋谷線'],
      ['t-west', 't-04shinjuku', '4号新宿線', '上,下', '高速4号新宿線'],
      ['t-west', 't-05ikebukuro-W', '5号池袋線(西)', '上,下', '高速5号池袋線（東京西地区）'],
      ['t-east', 't-05ikebukuro-E', '5号池袋線(東)', '上,下', '高速5号池袋線（東京東地区）'],
      ['t-east', 't-06mukoujima', '6号向島線', '上,下', '高速6号向島線'],
      ['t-east', 't-06misato', '6号三郷線', '上,下', '高速6号三郷線'],
      ['t-east', 't-07komatsugawa', '7号小松川線', '上,下', '高速7号小松川線'],
      ['t-east', 't-09fukagawa', '9号深川線', '上,下', '高速9号深川線'],
      ['t-east', 't-10harumi', '10号晴海線', '上,下', '高速10号晴海線'],
      ['t-east', 't-11daiba', '11号台場線', '上,下', '高速11号台場線'],
      ['t-west', 't-yaesu', '八重洲線', '南,北', '高速八重洲線'],
      ['t-west', 't-tkan', '都心環状線', '内,外', '高速都心環状線'],
      ['t-west', 't-ckan-W', '中央環状線(西)', '内,外', '高速中央環状線（東京西地区）'],
      ['t-east', 't-ckan-E', '中央環状線(東)', '内,外', '高速中央環状線（東京東地区）'],
      ['dummy-hr1', '------------'],
      ['t-east', 's-kawaguchi', '川口線', '上,下', '高速川口線'],
      ['t-east', 's-ohmiya', '埼玉大宮線', '上,下', '高速埼玉大宮線'],
      ['t-east', 's-shintoshin', '埼玉新都心線', '上,下', '高速埼玉新都心線'],
      ['dummy-hr2', '------------'],
      ['kanagawa', 'k-01yokohane', '神1号横羽線', '上,下', '高速神奈川1号横羽線'],
      ['kanagawa', 'k-02mitsuzawa', '神2号三ツ沢線', '上,下', '高速神奈川2号三ツ沢線'],
      ['kanagawa', 'k-03kariba', '神3号狩場線', '上,下', '高速神奈川3号狩場線'],
      ['kanagawa', 'k-05daikoku', '神5号大黒線', '上,下', '高速神奈川5号大黒線'],
      ['kanagawa', 'k-06kawasaki', '神6号川崎線', '上,下', '高速神奈川6号川崎線'],
      ['kanagawa', 'k-07yokokita', '神7号横北線', '上,下', '高速神奈川7号横浜北線'],
      ['kanagawa', 'k-07yokohokusei', '神7号横北西線', '上,下', '高速神奈川7号横浜北西線'],
      ['dummy-hr3', '------------'],
      ['t-east', 'b-wangan-t', '湾岸線(東)', '西,東', '高速湾岸線（東京地区）'],
      ['kanagawa', 'b-wangan-k', '湾岸線(神)', '西,東', '高速湾岸線（神奈川地区）'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_hokuriku() {
    const srcArr = [
      ['area-all', 'R7', 'R7', '上,下', 'R7'],
      ['area-all', 'R7(新)', 'R7(新)', '上,下', 'R7(新)'],
      ['area-all', 'R8', 'R8', '上,下', 'R8'],
      ['area-all', 'R49', 'R49', '上,下', 'R49'],
      ['area-all', 'R113', 'R113', '上,下', 'R113'],
      ['area-all', 'R116', 'R116', '上,下', 'R116'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_arc() {
    const srcArr = [
      ['area-all', 'セントレアライン', 'セントレアライン', '上,下', 'R7'],
      ['area-all', '知多半島道路', '知多半島道路', '上,下', '知多半島道路'],
      ['area-all', '南知多道路', '南知多道路', '上,下', '南知多道路'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_shikoku() {
    const srcArr = [
      ['area-all', '高松．坂出支線', '高松．坂出支線', '上,下', '高松．坂出支線'],
      ['area-all', '高松自動車道', '高松自動車道', '上,下', '高松自動車道'],
      ['area-all', '高松東道路', '高松東道路', '上,下', '高松東道路'],
      ['area-all', '松山自動車道', '松山自動車道', '上,下', '松山自動車道'],
      ['area-all', '徳島自動車道', '徳島自動車道', '上,下', '徳島自動車道'],
      ['area-all', '高知自動車道', '高知自動車道', '上,下', '高知自動車道'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_fukuoka_kitakyushu() {
    const srcArr = [
      ['area-all', '1号線', '1号線', '上,下', '1号線'],
      ['area-all', '2号線', '2号線', '上,下', '2号線'],
      ['area-all', '3号線', '3号線', '上,下', '3号線'],
      ['area-all', '4号線', '4号線', '上,下', '4号線'],
      ['area-all', '5号線', '5号線', '上,下', '5号線'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_hiroshima_airport() {
    const srcArr = [
      ['area-all', 'RW', 'RW', '西,東', 'RW'],
      ['area-all', 'P', 'P', '西,東', 'P'],
      ['area-all', 'T1', 'T1', '南,北', 'T1'],
      ['area-all', 'T2', 'T2', '南,北', 'T2'],
      ['area-all', 'T3', 'T3', '南,北', 'T3'],
      ['area-all', 'T4', 'T4', '南,北', 'T4'],
      ['area-all', 'T5', 'T5', '南,北', 'T5'],
      ['area-all', 'T6', 'T6', '南,北', 'T6'],
      ['area-all', 'AP1', 'AP1', '西,東', 'AP1'],
      ['area-all', 'AP2', 'AP2', '西,東', 'AP2'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_shinchitose_airport() {
    const srcArr = [
      ['area-all', 'A-RWY', 'A-RWY', '上,下', 'A-RWY'],
      ['area-all', 'B-RWY', 'B-RWY', '上,下', 'B-RWY'],
      ['area-all', 'D1', 'D1', '上,下', 'D1'],
      ['area-all', 'D2', 'D2', '上,下', 'D2'],
      ['area-all', 'D3', 'D3', '上,下', 'D3'],
      ['area-all', 'D4', 'D4', '上,下', 'D4'],
      ['area-all', 'D5', 'D5', '上,下', 'D5'],
      ['area-all', 'D6', 'D6', '上,下', 'D6'],
      ['area-all', 'D7', 'D7', '上,下', 'D7'],
      ['area-all', 'D8', 'D8', '上,下', 'D8'],
      ['area-all', 'D9', 'D9', '上,下', 'D9'],
      ['area-all', 'D10', 'D10', '上,下', 'D10'],
      ['area-all', 'D11', 'D11', '上,下', 'D11'],
      ['area-all', 'D12', 'D12', '上,下', 'D12'],
      ['area-all', 'A1', 'A1', '上,下', 'A1'],
      ['area-all', 'A2', 'A2', '上,下', 'A2'],
      ['area-all', 'A3', 'A3', '上,下', 'A3'],
      ['area-all', 'A4', 'A4', '上,下', 'A4'],
      ['area-all', 'A5', 'A5', '上,下', 'A5'],
      ['area-all', 'A6', 'A6', '上,下', 'A6'],
      ['area-all', 'A7', 'A7', '上,下', 'A7'],
      ['area-all', 'A8', 'A8', '上,下', 'A8'],
      ['area-all', 'A9', 'A9', '上,下', 'A9'],
      ['area-all', 'A9S', 'A9S', '上,下', 'A9S'],
      ['area-all', 'A10', 'A10', '上,下', 'A10'],
      ['area-all', 'A11', 'A11', '上,下', 'A11'],
      ['area-all', 'A12', 'A12', '上,下', 'A12'],
      ['area-all', 'B2', 'B2', '上,下', 'B2'],
      ['area-all', 'B3', 'B3', '上,下', 'B3'],
      ['area-all', 'B4', 'B4', '上,下', 'B4'],
      ['area-all', 'B5', 'B5', '上,下', 'B5'],
      ['area-all', 'B7', 'B7', '上,下', 'B7'],
      ['area-all', 'B9', 'B9', '上,下', 'B9'],
      ['area-all', 'B9S', 'B9S', '上,下', 'B9S'],
      ['area-all', 'B10', 'B10', '上,下', 'B10'],
      ['area-all', 'B10S', 'B10S', '上,下', 'B10S'],
      ['area-all', 'B12', 'B12', '上,下', 'B12'],
      ['area-all', 'H1', 'H1', '上,下', 'H1'],
      ['area-all', 'H2', 'H2', '上,下', 'H2'],
      ['area-all', 'H3', 'H3', '上,下', 'H3'],
      ['area-all', 'H4', 'H4', '上,下', 'H4'],
      ['area-all', 'H6', 'H6', '上,下', 'H6'],
      ['area-all', 'H7', 'H7', '上,下', 'H7'],
      ['area-all', 'Q1', 'Q1', '上,下', 'Q1'],
      ['area-all', 'Q2', 'Q2', '上,下', 'Q2'],
      ['area-all', 'J1', 'J1', '上,下', 'J1'],
      ['area-all', 'J2', 'J2', '上,下', 'J2'],
      ['area-all', 'J3', 'J3', '上,下', 'J3'],
      ['area-all', 'J4', 'J4', '上,下', 'J4'],
      ['area-all', 'J5', 'J5', '上,下', 'J5'],
      ['area-all', 'J6', 'J6', '上,下', 'J6'],
      ['area-all', 'J7', 'J7', '上,下', 'J7'],
      ['area-all', 'J8', 'J8', '上,下', 'J8'],
      ['area-all', 'T1', 'T1', '上,下', 'T1'],
      ['area-all', 'T1-1', 'T1-1', '上,下', 'T1-1'],
      ['area-all', 'T1-2', 'T1-2', '上,下', 'T1-2'],
      ['area-all', 'T1-3', 'T1-3', '上,下', 'T1-3'],
      ['area-all', 'T4', 'T4', '上,下', 'T4'],
      ['area-all', 'T4-1', 'T4-1', '上,下', 'T4-1'],
      ['area-all', 'T4-2', 'T4-2', '上,下', 'T4-2'],
      ['area-all', 'T4-3', 'T4-3', '上,下', 'T4-3'],
    ];
    const ret: RoadNameDirectionMix[] = [];
    srcArr.forEach(e => {
      if (e[0].indexOf('dummy') !== 0) {
        const area = e[0];
        const roadName = e[1];
        const roadNameDisp = e[2];
        const roadNameReal = e[4];
        const directions = e[3].split(',').map(e => {
          return {
            direction: e,
            selected: false,
          };
        });
        ret.push({
          area,
          roadName,
          roadNameDisp,
          roadNameReal,
          directions,
          isDummy: false,
        });
      } else {
        ret.push({
          key: e[0],
          disp: e[1],
          isDummy: true,
        });
      }
    });
    return ret;
  }
  get getRoadNameDirections_default() {
    return [];
  }
}

export function getRoadNameDirections_(kpSetName: string): RoadNameDirectionMix[] {
  const funcName = 'getRoadNameDirections_' + kpSetName;
  const service = new RoadNameDirectionsService();
  return getVal(service, funcName, 'getRoadNameDirections_default');
}

class RoadNameDirectionShortcutsService {
  get getRoadNameDirectionShortcuts_shutokou() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '西', key: 't-west' },
          { name: '東', key: 't-east' },
          { name: '神', key: 'kanagawa' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '南', key: '南' },
          { name: '西', key: '西' },
          { name: '内', key: '内' },
          { name: '下', key: '下' },
          { name: '北', key: '北' },
          { name: '東', key: '東' },
          { name: '外', key: '外' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_hokuriku() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '下', key: '下' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_arc() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '下', key: '下' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_shikoku() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '下', key: '下' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_fukuoka_kitakyushu() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '下', key: '下' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_hiroshima_airport() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '南', key: '南' },
          { name: '西', key: '西' },
          { name: '北', key: '北' },
          { name: '東', key: '東' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_shinchitose_airport() {
    return [
      {
        key: 'area',
        label: 'エリア',
        arr: [
          { name: '全て', key: 'area-all' },
        ],
      },
      {
        key: 'direction',
        label: '方向',
        arr: [
          { name: '上', key: '上' },
          { name: '下', key: '下' },
        ],
      },
    ];
  }
  get getRoadNameDirectionShortcuts_default() {
    return [];
  }
}
export function getRoadNameDirectionShortcuts_(kpSetName: string): RoadNameDirectionShortcut[] {
  const funcName = 'getRoadNameDirectionShortcuts_' + kpSetName;
  const service = new RoadNameDirectionShortcutsService();
  return getVal(service, funcName, 'getRoadNameDirectionShortcuts_default');
}

class RoadNameIgnoresService {
  get getRoadNameIgnores_default() {
    return [];
  }

  get getRoadNameIgnores_shutokou() {
    return [
      '高速神奈川6号川崎線-大師JCT(A)',
      '高速神奈川6号川崎線-大師JCT(B)',
      '高速神奈川6号川崎線-大師JCT(C)',
      '高速神奈川6号川崎線-大師JCT(D)',
      '高速神奈川6号川崎線-大師JCT(E)',
      '高速神奈川6号川崎線-大師JCT(F)',
      '高速神奈川7号横浜北西線-青葉JCT(K)',
      '高速神奈川7号横浜北西線-青葉JCT(L)',
      '高速神奈川7号横浜北西線-青葉JCT(M)',
      '高速神奈川7号横浜北西線-青葉JCT(N)',
      '高速神奈川7号横浜北西線-青葉JCT(O)',
      '高速神奈川7号横浜北西線-青葉JCT(P)',
    ];
  }
}

export function getRoadNameIgnores_(kpSetName: string): string[] {
  // 路線別画面用.
  // 実際はJCTや出入口だがデータ上は本線扱いとなっている路線名の配列.
  const funcName = 'getRoadNameIgnores_' + kpSetName;
  const service = new RoadNameIgnoresService();
  return getVal(service, funcName, 'getRoadNameIgnores_default');
}

export function isKpIncreaseDirection(roadName: string, direction: string): boolean {
  if (roadName === '高速10号晴海線') {
    // 晴海線は例外として下方向でKPが減少、上方向でKPが増加する.
    return !_isKpIncreaseDirection(direction);
  }
  if (roadName === '高速神奈川5号大黒線') {
    // 大黒線は例外として下方向でKPが減少、上方向でKPが増加する.
    return !_isKpIncreaseDirection(direction);
  }
  return _isKpIncreaseDirection(direction);
}

export function _isKpIncreaseDirection(direction: string): boolean {
  const set = new Set(['下', '南', '西', '外']);
  return set.has(direction);
}
