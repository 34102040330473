


import {
  defineComponent,
  PropType,
  reactive,
  computed,
} from '@vue/composition-api';
import { useRoute } from '@/hooks/useRoute';
import { useStore } from '@/hooks/useStore';
import useSettouReport from '@/components/Sp/Johaisetsu/SettouPatrol/composables/useSettouReport';
import { Location } from '@/models/index';
import { JohaisetsuSettouPatrolCreateParamsCommon } from '@/models/apis/johaisetsu/settouPatrol/johaisetsuSettouPatrolRequestCommon';

interface State {
  displayJohaisetsuHan: string;
  managerName: string;
  phoneNumber: string;
}

export default defineComponent({
  props: {
    displayJohaisetsuHan: {
      type: String,
      required: true,
    },
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    currentLocation: {
      type: Object as PropType<Location>,
    },
    isRequesting: {
      type: Boolean,
      required: true,
    },
    isValidInput: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      displayJohaisetsuHan: props.displayJohaisetsuHan,
      managerName: '',
      phoneNumber: '',
    });
    const store = useStore();
    const userState = store.state.user;
    const { route } = useRoute();
    const {
      state: useSettouReportState,
      johaisetsuTypeGroupName,
      workType,
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
      workStartLabel,
    } = useSettouReport({ selectedJohaisetsuType: props.selectedJohaisetsuType });
    const startMoving = async() => {
      const johaisetsuRoleMap = window.master.johaisetsuRoles.vals ?? '';
      const johaisetsuSettouPatrolCreateParams: JohaisetsuSettouPatrolCreateParamsCommon = {
        // device_idはCommon側でセットする
        device_id: '',
        base_departure_ts: new Date(),
        johaisetsu_company_id: johaisetsuRoleMap.filter(e => e.key === userState.johaisetsu_role)[0].id,
        johaisetsu_han_name: props.displayJohaisetsuHan,
        johaisetsu_type: johaisetsuTypeGroupName,
        work_type: workType,
        manager_name: state.managerName,
        phone_number: state.phoneNumber,
      };

      emit(
        'startMovingOnCommon',
        johaisetsuSettouPatrolCreateParams,
        useSettouReportState.selectedJohaisetsuType,
      );
    };
    const saveLocalStorage = () => {
      const obj = { selectedJohaisetsuType: useSettouReportState.selectedJohaisetsuType };
      const localStorageKey = route.value.name;
      if (!localStorageKey) return;
      localStorage.setItem(localStorageKey, JSON.stringify(obj));
    };
    const isStartButtonDisabled = computed(() => {
      return props.isRequesting || !props.isValidInput;
    });

    return {
      state,
      useSettouReportState,
      johaisetsuTypes,
      isShowJohaisetsuTypeSelect,
      workStartLabel,
      saveLocalStorage,
      startMoving,
      isStartButtonDisabled,
    };
  },
});
