









































import {
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  PropType,
} from '@vue/composition-api';
import { ImageCandidateBase } from '@/models/apis/image/imageRequest';
import { dtFormat } from '@/lib/dateHelper';

type ImageCandidate = ImageCandidateBase & {
  file: File;
};

interface State {
  showCapturedPhotoConfirmModal: boolean;
  pendingPhotoCandidate: ImageCandidate | null;
}

export default defineComponent({
  name: 'photo-taker',
  props: {
    fileNamePrefix: {
      type: String,
      default: '清掃作業',
    },
    onPhotoCaptured: {
      type: Function as PropType<(file: File) => Promise<void>>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<State>({
      showCapturedPhotoConfirmModal: false,
      pendingPhotoCandidate: null,
    });

    const currentInstance = getCurrentInstance();
    const uid = currentInstance?.uid;
    const takePhotoId = `take-photo-${uid}`;

    const tryConfirmCapturedPhoto = async(evt: Event) => {
      const fileList = (evt.target as HTMLInputElement).files;
      if (!fileList || fileList.length === 0) { return; }
      const file = fileList[0];
      state.pendingPhotoCandidate = {
        file: file,
        src: URL.createObjectURL(file),
      };
      state.showCapturedPhotoConfirmModal = true;
    };

    const takePhotoInputRef = ref<HTMLInputElement>();
    const retakePhoto = () => {
      if (takePhotoInputRef.value == null) return;
      state.showCapturedPhotoConfirmModal = false;
      clearPendingPhotoCandidate();
      takePhotoInputRef.value.click();
    };

    const clearPendingPhotoCandidate = async() => {
      if (state.pendingPhotoCandidate == null) return;
      URL.revokeObjectURL(state.pendingPhotoCandidate.src);
      state.pendingPhotoCandidate = null;
    };

    const onCapturedPhotoConfirmed = async() => {
      if (state.pendingPhotoCandidate?.file == null) return;
      state.showCapturedPhotoConfirmModal = false;
      // iPhoneのみ真っ白な画像になってしまうため、onPhotoCapturedの処理が終わるまで待つ
      // emitイベントも同じ問題が発生するため、関数渡すに変更した
      await props.onPhotoCaptured(state.pendingPhotoCandidate.file);
      // 撮影した画像は端末に残しておきたいという要望があったため、強制的にダウンロードさせる
      await downloadPhoto();
      await clearPendingPhotoCandidate();
    };

    const downloadPhoto = async() => {
      if (state.pendingPhotoCandidate == null) return;
      const link = document.createElement('a');
      link.href = state.pendingPhotoCandidate.src;
      const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
      link.download = `${props.fileNamePrefix}_${timestamp}.jpg`;
      document.body.appendChild(link);
      link.click();
      const linkParentElement = link.parentElement;
      if (linkParentElement == null) return;
      linkParentElement.removeChild(link);
    };

    return {
      ...toRefs(state),
      takePhotoInputRef,
      takePhotoId,
      retakePhoto,
      clearPendingPhotoCandidate,
      tryConfirmCapturedPhoto,
      onCapturedPhotoConfirmed,
    };
  },
});
