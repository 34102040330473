import geoItemApi from '@/apis/geo_item';
import { GetGeoItemsParams } from '@/models/apis/geoItem/geoItemRequest';
import { DataType, RoadGeoItemData } from '@/models/apis/geoItem/geoItemResponse';
import { GetGeoItemsParamsRaw } from '@/models/route';
import { LayerInfo, MasterData } from '@/models';
import { GeoItemMeta } from 'src/models/geoItem';
import { UserState } from 'src/store/modules/user';

interface Options {
  useGIManager: boolean;
  user?: UserState;
  masters?: MasterData;
}

export async function getGeoItems(params: GetGeoItemsParamsRaw): Promise<{ [key in DataType]?: RoadGeoItemData}> {
  const reqObj: GetGeoItemsParams = {
    start_ts: params.startTs,
    end_ts: params.endTs,
    data_types: params.dataTypes,
  };
  if (params.roadNames) {
    reqObj.road_names = params.roadNames;
  }
  if (params.direction) {
    reqObj.direction = params.direction;
  }
  if (params.optByDataType) {
    reqObj.opt_by_data_type = params.optByDataType;
  }

  let geoItemMap: { [key in DataType]?: RoadGeoItemData} = {};
  try {
    const { data } = await geoItemApi.getGeoItems(reqObj);
    geoItemMap = data;
  } catch (e) {}

  return geoItemMap;
}

export function getGeoItemMeta(layerInfoList: LayerInfo[], options: Options = {useGIManager: false}): Record<string, GeoItemMeta> {
  const ret: Record<string, GeoItemMeta> = {};
  layerInfoList.forEach(info => {
    try {
      const tmpStr = info.name[0].toUpperCase() + info.name.slice(1);
      let giManager = null;
      if (options.useGIManager && options.masters) {
        const lovs = options.masters.lovs;
        const commentTypeMap = options.masters.commentType.map;
        const GIMgrClass = require(`@/lib/geo_item/GI${tmpStr}Manager`).default;
        giManager = new GIMgrClass({ user: options.user, lovs, commentTypeMap });
      }
      const EMLayerMgrClass =
        require(`@/lib/ExtremeMap${tmpStr}LayerManager`).default;
      const layerManager = new EMLayerMgrClass({ dataName: info.name, giManager });
      const item = {
        name: info.name,
        dispName: info.dispName,
        manager: giManager,
        layerManager: layerManager,
        hideToggleCheckbox: !!info.hideToggleCheckbox,
        isCreatable: !!info.isCreatable,
        isTabPanel: !!info.isTabPanel,
        tabLabel: info.tabLabel || '',
        geoItemSearchRefName: 'refGISearch' + info.name.charAt(0).toUpperCase() + info.name.slice(1),
      };
      ret[item.name] = item;
    } catch (e) {
      console.warn(`failure requiring geo item manager for '${info.name}'`);
      console.warn(e);
    }
  });

  return ret;
}
