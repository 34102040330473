import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import { JohaisetsuEnsuiPlantParams } from '@/models/apis/ensui/johaisetsuEnsuiPlantRequest';
import { EnsuiPlant } from '@/models/apis/ensui/johaisetsuEnsuiPlantResponse';

export default {
  index(params: JohaisetsuEnsuiPlantParams): Promise<AxiosResponse<EnsuiPlant[]>> {
    return axios.get(utils.getApiUrl('/johaisetsu_ensui_plants'), {
      params: params || {},
    });
  },
  downloadCSV(params: JohaisetsuEnsuiPlantParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_ensui_plants/csv`), {
      params: params,
      responseType: 'blob',
    });
  },
};
