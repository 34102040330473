export const calendarDefaults = {
  lang: 'ja',
  format: 'yyyy/mm/dd',
  dayHeaderFormat: 'yyyy年m月',
  pane: 1,
  inputClearButton: false,
  readonly: false,
  disableSwitchMonthView: true,
};

interface CalendarLang {
  daysOfWeek: string[];
  months: string[];
  today: string;
  clear: string;
  close: string;
}

export const calendarLangFunc = (lang: string): CalendarLang => {
  let ret = {
    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    months: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
    today: 'Today',
    clear: 'Clear',
    close: 'Close',
  };
  if (lang === 'ja') {
    ret = {
      daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
      months: [
        '1', '2', '3', '4', '5', '6',
        '7', '8', '9', '10', '11', '12',
      ],
      today: '今日',
      clear: 'クリア',
      close: '閉じる',
    };
  }

  return ret;
};
