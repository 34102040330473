import { CarInfo, CarKindChoice, MoviePlayerSize, StyleState, TopState } from '@/models/top';
import MovieViewStatusManager, { MovieDialogInfo } from '@/lib/MovieViewStatusManager';
import { Car } from '@/models/apis/cars/carResponse';
import { MInfo } from '@/models/apis/movie/movieResponse';
import { Route } from 'vue-router';

const groupScopeChoices = [
  { val: null, label: '全て' },
  { val: 'g2', label: '事業所内' },
  { val: 'g3', label: '部署内' },
];

const colorSet: Array<[number, number, number]> = [
  [255, 0, 0], // red
  [0, 0, 255], // blue
  [0, 255, 0], // green
  [255, 255, 0], // yellow
  [205, 0, 255], // purple
  [0, 255, 255], // cyan
  [188, 143, 143], // rosybrown
  [255, 140, 0], // darkorange
  [255, 105, 180], // hotpink
];

const initStyleState = (): StyleState => {
  return {
    paneSideMinWidth: '23%',
    paneSideMaxWidth: '30%',
    paneCenterMinWidth: '40%',
    paneCenterMaxWidth: '54%',
    carListMinHeight: '200px',
    carListMaxHeight: '200px',
    tabContainerMinHeight: '40%',
    tabContentMaxHeight: '40%',
  };
};

export const initTopState = (): TopState => {
  return {
    extremeMapEssentials: null,

    decidedSearchConds: { carName: '', status: '' },
    searchConds: { carName: '', status: '' },
    carKindChoices: [],
    statusChoices: ['全て', '移動中', '停止中'],
    cars: [],
    carMap: {},
    playedMovies: [],
    roadNameDirectionShortcuts: [],
    roadNameDirectionShortcutMap: {},
    roadNameDirections: [],
    operationMode: 'normal',
    movieDialogMap: {},
    carsUpdatedAt: new Date(),
    showCarIcons: true,
    groupScopeChoices: groupScopeChoices,
    carFilterGroupScope: null,

    colors: colorSet,
    colorIdxIncrementer: 0,
    usedColorIndexMap: {},

    moviePlayerDialogIncrementer: 0,
    moviePlayerDialogMax: 4,
    moviePlayerDefaultSize: {
      movieW: 640,
      movieH: 360,
      controlBarH: 22,
      minW: 280,
      w: 0,
      h: 0,
      span: 0,
    },
    carUpdateTimer: null,

    geoItemSearchConds: {
      timeFromOffset: -3600 * 1000 * 1,
      timeToOffset: 0,
    },
    geoItemTimeChoices: [],
    // vueの管理下に置かないため、ここでinitしない
    // geoItemLayerData
    geoItemLayer: {
      show: {
        temperature: false,
        salinity: false,
        snowfall: false,
        frozen: false,
        snow_mountain: false,
        jh_work_status: false,
        tairyu: false,
      },
      order: {},
    },
    geoItemSearchTime: {
      start: null,
      end: null,
    },

    showWaitSpinner: true,

    showRoadNameDirectionListRows: false,

    showModal: false,
    modalTitle: '',
    modalMsg: '',

    movieViewStatusMgr: new MovieViewStatusManager(),

    isDownloadingMovieFile: false,

    editingJohaisetsuCar: null,

    timers: {
      carUpdateTimer: null,
    },
    styles: initStyleState(),
  };
};

interface CarExt extends Car {
  isMoving: boolean;
  shouldShow: boolean;
  isSelected: boolean;
  color: number[] | null;
  colorIdx: number | null;
}

interface CurrentCarInfoMap {
  currentCarInfoMap: Record<string, CarInfo>;
}
interface ConvCarDataResult {
  carsExt: Array<CarExt>;
  carKindChoices: Array<CarKindChoice>;
}

export const convData = (
  cars: Car[],
  stateCarKindChoices: CarKindChoice[],
  { currentCarInfoMap }: CurrentCarInfoMap,
): ConvCarDataResult => {
  const carKindMap = window.master.lovs.car_kind.map;
  const carKindChoicesMap: Record<string, string> = {};

  const carsExt = cars.map(car => {
    const carKind = carKindMap[car.device.car_kind] || {};
    carKindChoicesMap[carKind.key] = carKind.val;

    const carKindDisp = carKind.val || '';
    let isMoving: boolean;
    if (!car.is_external_car) {
      // landApから取ってきた車両
      isMoving = car.status_disp === '移動中';
    } else {
      // 外部(今の所、cyzen)から取ってきた車両
      isMoving = car.ts_is_new;
    }
    const tmpCar = currentCarInfoMap[car.device_id];
    let isSelected: boolean;
    let color: number[] | null;
    let colorIdx: number | null;
    if (tmpCar) {
      isSelected = tmpCar.isSelected;
      color = tmpCar.color;
      colorIdx = tmpCar.colorIdx;
    } else {
      isSelected = false;
      color = [];
      colorIdx = -1;
    }
    return {
      ...car,
      isMoving: isMoving,
      shouldShow: false,
      isSelected: isSelected,
      color: color,
      colorIdx: colorIdx,
      device: {
        ...car.device,
        carKindDisp: carKindDisp,
      },
    };
  });
  const carKindChoices = Object.entries(carKindChoicesMap)
    .filter(ent => !!ent[1])
    .map(ent => ({
      key: ent[0],
      val: ent[1],
      selected: stateCarKindChoices.find(e => e.key === ent[0])?.selected ?? true,
    }));
  return { carsExt, carKindChoices };
};

interface GetMoviePlayerDimensionsParams {
  moviePlayerDialogMax: number;
  moviePlayerDefaultSize: MoviePlayerSize;
  movieDialogs: Array<MovieDialogInfo>;
}

interface MoviePlayerDimensions {
  w: number;
  h: number;
  x: number;
  y: number;
}

export const getMoviePlayerDimensions = (params: GetMoviePlayerDimensionsParams): MoviePlayerDimensions => {
  const w = params.moviePlayerDefaultSize.w;
  const h = params.moviePlayerDefaultSize.h;
  const span = params.moviePlayerDefaultSize.span;
  let x = calcAlign({
    width: w,
    span,
    offsetX: span,
    moviePlayerDialogMax: params.moviePlayerDialogMax,
    moviePlayerDefaultSize: params.moviePlayerDefaultSize,
    movieDialogs: params.movieDialogs,
  });
  x = Math.min(x, window.innerWidth - span - w);
  // +18pxがぴったりだが、わずかに隙間を開ける
  const y = (window.innerHeight - 56 + (18 - 1)) - h;
  return { w, h, x, y };
};

interface CalcAlignParams extends GetMoviePlayerDimensionsParams {
  width: number;
  span: number;
  offsetX?: number;
}

const calcAlign = (params: CalcAlignParams): number => {
  const offsetX = params.offsetX || 0;
  const idxs = Array.from({length: params.moviePlayerDialogMax}, (e, i) => i);
  const slots = idxs.map(idx => {
    return offsetX + idx * (params.moviePlayerDefaultSize.w + params.span);
  }).reduce((acc: Record<number, boolean>, e) => {
    acc[e] = true; return acc;
  }, {});

  for (const dialog of params.movieDialogs) {
    // 枠の位置にあったらフラグ落とす
    if (slots[dialog.options.left]) {
      slots[dialog.options.left] = false;
    }
  }

  const emptySlots = Object.keys(slots)
    .map(k => parseInt(k))
    .filter(k => slots[k])
    .sort((a, b) => { return a < b ? -1 : 1; });
  return emptySlots[0];
};

export const urlParamsToMInfos = (): Array<MInfo> => {
  let locSearch = window.location.search;
  if (!locSearch) { return []; }
  locSearch = decodeURIComponent(locSearch);
  locSearch = locSearch.replace(/^\?/, '');
  return locSearch.split('&')
    .filter(e => e.startsWith('m[]='))
    .map(e => {
      const mParams = e.replace(/^m\[\]=/, '').split(',');
      const [mType, mId, x, y, w, h, colorIdx, ...others] = mParams;
      const mInfo: MInfo = {
        mType,
        mId,
        colorIdx: parseInt(colorIdx),
        pos: {
          x: parseInt(x),
          y: parseInt(y),
          w: parseInt(w),
          h: parseInt(h),
        },
      };
      if (mInfo.mType === 'l') {
        // nothing to do
      } else {
        mInfo.playStartMsec = parseInt(others[0]);
      }
      return mInfo;
    });
};

export const saveMInfosToUrlParams = (mInfos: MInfo[], route: Route): void => {
  if (mInfos.length === 0) {
    history.replaceState({}, '', route.path);
    return;
  }
  const arr = mInfos.map(mInfo => {
    const ret = [mInfo.mType, mInfo.mId];
    ret.push(mInfo.pos.x.toString(), mInfo.pos.y.toString(), mInfo.pos.w.toString(), mInfo.pos.h.toString());
    ret.push((mInfo.colorIdx ?? 0).toString());
    if (mInfo.mType === 'l') {
      // nothing to do
    } else {
      ret.push((mInfo.playStartMsec || 0).toString());
    }
    return ret;
  });
  const search = '?' + arr.map(e => 'm[]=' + e).join('&');
  history.replaceState({}, '', route.path + search);
};

export const dialogInfoToMInfo = (dialogInfo: MovieDialogInfo): MInfo => {
  const v = dialogInfo;
  const retBase = v.car
    ? { mType: 'l', mId: v.car.device_id, colorIdx: v.car.colorIdx }
    : { mType: 's', mId: v.movie.id.toString(), colorIdx: v.movie.colorIdx ?? 0, playStartMsec: v.movie.playStartMsec };
  const pos = {
    x: v.options.left,
    y: v.options.top,
    w: v.options.width,
    h: v.options.height,
  };
  return { ...retBase, pos };
};

interface MoviePlayerPosition {
  x: number;
  y: number;
  w: number;
  h: number;
}

export const getMoviePlayersPositions = (moviePlayerDefaultSize: MoviePlayerSize): Array<MoviePlayerPosition> => {
  const parentH = window.innerHeight - 56 + 18;
  const parentW = window.innerWidth;
  const defaultMovieH = moviePlayerDefaultSize.movieH;
  const defaultMovieW = moviePlayerDefaultSize.movieW;
  const movieRatio = defaultMovieW / defaultMovieH;

  const margin = 16;
  let h = (parentH - margin) / 2;
  let w = h * movieRatio;
  if (w > (parentW - margin) / 2) {
    w = (parentW - margin) / 2;
    h = w / movieRatio;
  }

  h = parseInt(h.toString());
  w = parseInt(w.toString());
  const positions = [
    { x: parentW - w, y: 0, h, w }, // right,top
    { x: parentW - w, y: parentH - h, h, w }, // right,bottom
    { x: 0, y: parentH - h, h, w }, // left,bottom
    { x: 0, y: 0, h, w }, // left,top
  ];

  return positions;
};
