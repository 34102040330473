export function packToTimeInteger(h: number, m: number, s: number): number {
  return h * 10000 + m * 100 + s;
}

export function unpackTimeInteger(timeInt: number): number[] {
  let tmp = timeInt;
  const h = Math.trunc(timeInt / 10000);
  tmp -= h * 10000;
  const m = Math.trunc(tmp / 100);
  const s = tmp % 100;
  return [h, m, s];
}

export function unpackTimeIntegerToString(timeInt: number | null): string[] {
  if (timeInt === null) { return ['', '', '']; }
  const [h, m, s] = unpackTimeInteger(timeInt);
  const strH = ('0' + h).slice(-2);
  const strM = ('0' + m).slice(-2);
  const strS = ('0' + s).slice(-2);
  return [strH, strM, strS];
}

export function timeDifferenceInSeconds(t1: number, t2: number): number {
  const [h1, m1, s1] = unpackTimeInteger(t1);
  const [h2, m2, s2] = unpackTimeInteger(t2);
  const sumSec1 = h1 * 3600 + m1 * 60 + s1;
  const sumSec2 = h2 * 3600 + m2 * 60 + s2;
  return sumSec2 - sumSec1;
}

export function secondsToTimeInteger(seconds: number): number {
  // 一旦正に戻してから計算
  const sign = seconds < 0 ? -1 : 1;
  seconds *= sign;

  let h = seconds / 3600;
  h = Math.min(h, 99); // digit guard
  const tmp = seconds % 3600;
  const m = tmp / 60;
  const s = tmp % 60;

  const strH = ('0' + h).slice(-2);
  const strM = ('0' + m).slice(-2);
  const strS = ('0' + s).slice(-2);
  return parseInt(`${strH}${strM}${strS}`) * sign;
}

export function timeIntegerToSeconds(tm: number): number {
  // 一旦正に戻してから計算
  const sign = tm < 0 ? -1 : 1;
  tm *= sign;

  const h = tm / 10000;
  const tmp = tm % 10000;
  const m = tmp / 100;
  const s = tmp % 100;

  return (h * 3600 + m * 60 + s) * sign;
}

export function timeIntegerAdd(tm1: number, tm2: number): number {
  const sec1 = timeIntegerToSeconds(tm1);
  const sec2 = timeIntegerToSeconds(tm2);
  return secondsToTimeInteger(sec1 + sec2);
}

export function parseDateTimeString(dateTimeString: string): Date {
  let parsedTimestamp = Date.parse(dateTimeString);
  if (!isNaN(parsedTimestamp)) {
    return new Date(parsedTimestamp);
  }
  // safariは'yyyy-mm-dd'に対応していないため、 /に置き換える
  parsedTimestamp = Date.parse(dateTimeString.replace(/-/g, '/'));
  if (!isNaN(parsedTimestamp)) {
    return new Date(parsedTimestamp);
  }

  // それでも対応できない場合、エラーを投げることで気が付く
  throw Error(`parseDateTimeString: invalid date string "${dateTimeString}"`);
}
