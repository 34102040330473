import { ABILITY_MESSAGE_BOARD, ABILITY_CLEANING_MAP } from '@/consts/ability';
export const USER_JOHAISETSU_ROLE = 'johaisetsu_role';
export const USER_IS_JOHAISETSU_ONLY = 'is_johaisetsu_only';
export const USER_CLEANING_ROLE = 'cleaning_role';
export const USER_CLEANING_HAN = 'cleaning_han';
export const USER_JOHAISETSU_HAN = 'johaisetsu_han';
export const USER_IS_CLEANING_ONLY = 'is_cleaning_only';
export const USER_ABILITY_JOHAISETSU = 'johaisetsu_ability_';
export const USER_ABILITY_JOHAISETSU_MESSAGE_BOARD = USER_ABILITY_JOHAISETSU + ABILITY_MESSAGE_BOARD;
export const USER_ABILITY_CLEANING = 'cleaning_ability_';
export const USER_ABILITY_CLEANING_MAP = USER_ABILITY_CLEANING + ABILITY_CLEANING_MAP;

export const DEFAULT_PAGE_PC_TOP = 'pc_top';
