import { onBeforeMount, onMounted, reactive } from '@vue/composition-api';
import { Position, Location } from '@/models';

export type GeolocationResult = {
  currentLocation: Location | null;
  showGeolocationPermissionErrorModal: boolean;
  showGeolocationGeneralErrorMsg: boolean;
};

interface State {
  showGeolocationPermissionErrorModal: boolean;
  showGeolocationGeneralErrorMsg: boolean;
  geolocationErrors: GeolocationPositionError[];
  geolocationOpts: PositionOptions;
  geolocationWatchHandler: number | null;
  currentLocation: Location | null;
}

export default function useGeolocation(errorHandler: () => void): GeolocationResult {
  const state = reactive<State>({
    showGeolocationPermissionErrorModal: false,
    showGeolocationGeneralErrorMsg: false,
    geolocationErrors: [],
    geolocationOpts: {
      enableHighAccuracy: true,
      timeout: 5 * 1000,
      maximumAge: 0,
    },
    geolocationWatchHandler: null,
    currentLocation: null,
  });

  const startGettingCurrentLocation = () => {
    clearGeolocationWatch();
    state.geolocationWatchHandler = navigator.geolocation.watchPosition(
      setCurrentLocation,
      onGeolocationError,
      state.geolocationOpts,
    );
  };
  const clearGeolocationWatch = () => {
    if (!state.geolocationWatchHandler) {
      return;
    }
    navigator.geolocation.clearWatch(state.geolocationWatchHandler);
    state.geolocationWatchHandler = null;
    clearGeolocationErrors();
    state.currentLocation = null;
  };
  const setCurrentLocation = (position: Position) => {
    console.log('lat: ', position.coords.latitude, 'lon: ', position.coords.longitude);
    let { latitude, longitude } = position.coords;
    // 小数点第6位まで
    const base = 1000000;
    latitude = Math.floor(latitude * base) / base;
    longitude = Math.floor(longitude * base) / base;
    state.currentLocation = { lat: latitude, lon: longitude };

    // 取得に成功したらエラーを消す
    clearGeolocationErrors();
  };
  const onGeolocationError = (evt: GeolocationPositionError) => {
    console.error('geolocation error', evt);
    const code = evt.code;
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
    // 1 PERMISSION_DENIED
    // 2 POSITION_UNAVAILABLE
    // 3 TIMEOUT
    if (code === 1) {
      // 位置情報の利用が許可されていない場合
      stopGettingCurrentLocation();
      state.showGeolocationPermissionErrorModal = true;
    } else {
      // 位置情報の取得に失敗した場合

      // エラーの内容が1(PERMISSION_DENIED)以外であれば、
      // 位置情報の利用自体は許可されているはず.
      state.geolocationErrors.push(evt);
      if (state.geolocationErrors.length > 5) {
        // エラーが何回か続いたら、画面下にエラーメッセージ出す
        state.showGeolocationGeneralErrorMsg = true;
      }
    }
  };

  const stopGettingCurrentLocation = async() => {
    // call stopAll.
    errorHandler();
    clearGeolocationWatch();
  };
  const clearGeolocationErrors = () => {
    state.geolocationErrors = [];
  };

  onMounted(() => {
    startGettingCurrentLocation();
  });

  onBeforeMount(() => {
    clearGeolocationWatch();
  });

  return state;
}
