


import {
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';

import { CleaningCarExt } from '@/models';
import { dtFormat } from '@/lib/dateHelper';
import { PaneStyle } from '@/models/cleaningMap';
import { PANE_SIDE_HARD_MAX_WIDTH, LIST_HARD_MAX_HEIGHT } from '@/components/CleaningMap/consts/cleaning_map';
import useCleaningCars from '@/components/CleaningMap/composables/useCleaningCars';

export default defineComponent({
  name: 'realtime-mode-pane',
  props: {
    showCarIcons: {
      type: Boolean,
      default: true,
    },
    showCleaningPhotoIcons: {
      type: Boolean,
      default: true,
    },
    showDefectPhotoIcons: {
      type: Boolean,
      default: true,
    },
    paneStyle: {
      type: Object as PropType<PaneStyle>,
      default: () => ({
        paneSideMinWidth: PANE_SIDE_HARD_MAX_WIDTH,
        paneSideMaxWidth: PANE_SIDE_HARD_MAX_WIDTH,
        listMinHeight: LIST_HARD_MAX_HEIGHT,
        listMaxHeight: LIST_HARD_MAX_HEIGHT,
      }),
    },
  },
  setup(props, { emit }) {
    const { carState } = useCleaningCars();
    const checkedShowCarIcons = ref(true);
    const checkedShowCleaningPhotoIcons = ref(true);
    const checkedShowDefectPhotoIcons = ref(true);

    // モード変更をして表示のチェックボックスが初期化された際に
    // propsの値を正しく反映させるために、watchで監視する
    watch(() => props.showCarIcons, () => {
      checkedShowCarIcons.value = props.showCarIcons;
    });
    watch(() => props.showCleaningPhotoIcons, () => {
      checkedShowCleaningPhotoIcons.value = props.showCleaningPhotoIcons;
    });
    watch(() => props.showDefectPhotoIcons, () => {
      checkedShowDefectPhotoIcons.value = props.showDefectPhotoIcons;
    });

    const onClickCarOnCarList = (car: CleaningCarExt) => {
      emit('click-car-on-car-list', car);
    };
    const onChangeShowCarIcons = () => {
      emit('change-show-car-icons');
    };
    const onChangeShowCleaningPhotoIcons = () => {
      emit('change-show-cleaning-photo-icons');
    };
    const onChangeShowDefectPhotoIcons = () => {
      emit('change-show-defect-photo-icons');
    };
    return {
      // refs
      carState,
      checkedShowCarIcons,
      checkedShowCleaningPhotoIcons,
      checkedShowDefectPhotoIcons,
      // methods
      onClickCarOnCarList,
      onChangeShowCarIcons,
      onChangeShowCleaningPhotoIcons,
      onChangeShowDefectPhotoIcons,
      dtFormat,
    };
  },
});
