import { Style, Stroke } from 'ol/style';
import { hslToRgb } from '@/lib/colorHelper';
import { DataContent } from '@/models/geoItem';
import { LocalGroupInfo } from '@/store/modules/user';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class TemperatureHelper extends AbstractGeoItem<number, number, number, number[]> {
  dataStep(userGroupInfo: LocalGroupInfo | null = null): number {
    let ret = 3;
    if (userGroupInfo && userGroupInfo.g1_name.startsWith('首都高')) {
      ret = 2;
    }
    return ret;
  }
  dataToObjKey(inputData: number, userGroupInfo: LocalGroupInfo | null = null): number {
    // 過渡期のため、スカラーもしくはオブジェクトの両方がありうる.
    // 落ち着いたら全部オブジェクトになるので消す.
    // (2018/11/18 なぜオブジェクトがあり得るのか忘れた. スカラーでよいのでは???)
    let data: DataContent = { v: 0 };
    if (typeof inputData !== 'object') { data = {v: inputData}; }

    const step = this.dataStep(userGroupInfo);
    return Math.floor(data.v as number / step) * step;
  }
  // 温度が低いものを地図表示上は上に持ってきたい
  featureSorter = (objKeyA: string, objKeyB: string): number => {
    return objKeyA > objKeyB ? -1 : 1;
  };
  /* 案1
    * 青から赤に向けて色相環をたどるやつ
    * 0度付近の色が緑らへんで1,2,3度あたりの差がわからんということでボツ
  objKeyToColorOld(objKey) {
    objKey = parseInt(objKey)
    let x = objKey
    const min = -30
    const max = 40
    const xShift = 30
    const shiftedMax = max + xShift
    // -30 <= x <= 40
    x = Math.min(Math.max(x, min), max)
    // 0 <= x <= 70
    x += xShift
    // 0.8 >= h >= 0.0
    const h = 0.8 - x / shiftedMax * 0.8
    return hslToRgb(h, 1.0, 0.5)
  },
  */
  /* 案2
    * 元々青から赤までの色相変化みたいな感じでやってたが、
    * 3度以上と未満の境界で体制が変わるらしく、表示もそれに合わせた。
    * 首都高は案3だが、これをデフォルトにしておこう.
    */
  objKeyToColorDefault(inpuObjKey: number): number[] {
    const objKey = parseInt(inpuObjKey.toString());
    let x = objKey;
    let h = 0.0;
    const s = 1.0;
    let l = 0.5;
    if (x < 3) {
      // 青っぽいエリア
      const min = -10;
      const xShift = 10;
      const shiftedMax = 3 + xShift;
      // -10 <= x < 3
      x = Math.max(x, min);
      // 0 <= x < 13
      x += xShift;
      // 0.65 >= h >= 0.50
      h = 0.65 - x / shiftedMax * 0.15;
      // 0.50 <= l <= 0.48
      l = 0.50 - x / shiftedMax * 0.02;
    } else if (x < 6) {
      // 黄色エリア
      // 3 <= x < 6
      const xShift = -3;
      const shiftedMax = 6 + xShift;
      // 0 <= x < 3
      x += xShift;
      // 0.16 >= h >= 0.15
      h = 0.16 - x / shiftedMax * 0.01;
      l = 0.48;
    } else {
      // 赤っぽいエリア (黄色と赤の間のオレンジの見づらいとこを飛ばした)
      const max = 25;
      const xShift = -3;
      const shiftedMax = max + xShift;
      // 3 <= x <= 25
      x = Math.min(x, max);
      // 0 <= x <= 22
      x += xShift;
      // 0.12 >= h >= 0.00
      h = 0.10 - x / shiftedMax * 0.10;
      // 0.50 <= l <= 0.70
      l = 0.50 + x / shiftedMax * 0.20;
    }
    return hslToRgb(h, s, l);
  }
  /* 案3
    * 青 < 0.0
    * 0.0 <= 水 < 2.0
    * 2.0 <= 黄緑 < 4.0
    * 4.0 <= 黄 < 6.0
    * 6.0 <= 赤
    * 個人的にはやばい色だが、今の所首都高はこれ.
    */
  objKeyToColorShutokou(inputObjKey: number): number[] {
    const objKey = parseInt(inputObjKey.toString());
    const x = objKey;
    let rgb = [0, 0, 0];
    if (x < 0.0) {
      // #2e75b6
      rgb = [46, 117, 182];
    } else if (x < 2.0) {
      // #00b0f0
      rgb = [0, 176, 240];
    } else if (x < 4.0) {
      // #91d051
      rgb = [145, 208, 81];
    } else if (x < 6.0) {
      // #ffff00
      rgb = [255, 255, 0];
    } else {
      // #dc0000
      rgb = [220, 0, 0];
    }
    return rgb;
  }
  objKeyToColor(objKey: number, userGroupInfo:LocalGroupInfo | null = null): number[] {
    if (userGroupInfo && userGroupInfo.g1_name.startsWith('首都高')) {
      return this.objKeyToColorShutokou(objKey);
    } else {
      return this.objKeyToColorDefault(objKey);
    }
  }
  objKeyToFeatureStyle(objKey: number, userGroupInfo: LocalGroupInfo | null = null): Style {
    const color = this.objKeyToColor(objKey, userGroupInfo);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
