


import {
  defineComponent,
  reactive,
  PropType,
  onMounted,
} from '@vue/composition-api';
import { timeInteger } from '@/lib/dateTimeHelper';
import johaisetsuReportApi from '@/apis/johaisetsu/settouSagyouReport/johaisetsu_report';
import useSettouSagyou from '@/components/Sp/Johaisetsu/SettouSagyou/composables/useSettouSagyou';
import { JohaisetsuCarExt } from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import { sanpuNums } from '@/lib/johaisetsu/johaisetsuCommonUtil';

interface State {
  showConfirmContentAndRoadModal: boolean;
  isRequesting: boolean;
  sanpuNum?: number | null;
  bikou1?: string | null;
  workType: string;
}

export default defineComponent({
  props: {
    selectedJohaisetsuType: {
      type: String,
      required: true,
    },
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      showConfirmContentAndRoadModal: false,
      isRequesting: false,
      workType: props.selectedJohaisetsuType,
      sanpuNum: props.johaisetsuCar.report.sanpuNum,
      bikou1: props.johaisetsuCar.report.bikou1,
    });
    const {
      johaisetsuTypeGroupName,
      johaisetsuTypes,
      showSanpuNum,
      johaisetsuTypeNameDisp,
      setJohaisetsuType,
    } = useSettouSagyou({ selectedJohaisetsuType: state.workType });
    const displayJohaisetsuHan = props.johaisetsuCar.report.johaisetsuHanName;
    const tryShowConfirmContentAndRoadModal = () => {
      state.showConfirmContentAndRoadModal = true;
    };
    const tryRevertEditContentAndRoad = () => {
      emit('tryRevertEditContentAndRoadOnCommon');
    };
    const saveContentAndRoad = async() => {
      if (!props.johaisetsuCar.id) return;
      state.isRequesting = true;
      try {
        if (!props.johaisetsuCar.report.id) {
          emit('showErrorOnCommon', 'レポートIDの取得に失敗しました。');
          return;
        }
        if (!state.sanpuNum && showSanpuNum) {
          emit('showErrorOnCommon', '散布回数を設定してください。');
          return;
        }
        setJohaisetsuType(state.workType);
        const johaisetsuReportRoadParams = {
          johaisetsu_car_id: props.johaisetsuCar.id,
          work_type: johaisetsuTypeNameDisp.value,
          data_value: showSanpuNum && state.sanpuNum ? state.sanpuNum.toString() : '',
          bikou1: state.bikou1 ? state.bikou1 : '',
        };
        const { data } = await johaisetsuReportApi.updateJohaisetsuContent(
          johaisetsuReportRoadParams,
        );
        if (data) {
          emit('saveContentAndRoadOnCommon', data, state.workType);
        } else {
          // data がない場合にエラーメッセージを返す
          emit('showErrorOnCommon', 'レポートデータの更新に失敗しました。');
          return;
        }
      } catch (e) {
        emit('showErrorOnCommon', 'レポートデータの更新に失敗しました。');
        return;
      } finally {
        state.isRequesting = false;
        state.showConfirmContentAndRoadModal = false;
      }
    };
    onMounted(async() => {
      state.isRequesting = true;
      if (props.johaisetsuCar.report.id) {
        const { data } = await johaisetsuReportApi.get(
          props.johaisetsuCar.report.id,
        );
        state.sanpuNum = data.data_value ?? state.sanpuNum;
        state.bikou1 = data.bikou1 ?? state.bikou1;
      } else {
        // レポートIDがない場合にエラーメッセージを返す
        emit('showErrorOnCommon', 'レポートデータの更新に失敗しました。');
      }
      state.isRequesting = false;
    });

    return {
      state,
      // computed
      johaisetsuTypeGroupName,
      johaisetsuTypes,
      showSanpuNum,
      sanpuNums,
      displayJohaisetsuHan,
      // methods
      saveContentAndRoad,
      timeInteger,
      tryShowConfirmContentAndRoadModal,
      tryRevertEditContentAndRoad,
    };
  },
});
