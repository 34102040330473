import { getCurrentInstance } from '@vue/composition-api';
import { LocalStorageState, LocalStorageStore } from '@/store/modules/localStorage';
import { UserState, UserStore } from '@/store/modules/user';

export interface RootState {
  user: UserState;
  localStorage: LocalStorageState;
}

export type Store
  = UserStore<Pick<RootState, 'user'>>
  & LocalStorageStore<Pick<RootState, 'localStorage'>>;

export function useStore(): Store {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error(`Should be used in setup().`);
  }
  return instance.proxy.$store;
}
