


import {
  defineComponent,
  reactive,
  PropType,
} from '@vue/composition-api';
import { JohaisetsuCarExt } from '@/models/johaisetsu/settouSagyouReport/spJohaisetsuReport';
import { updateBaseArrival } from '@/lib/johaisetsu/settouSagyouReport/johaisetsuReportUtil';

interface State {
  showConfirmBaseArrivalModal: boolean;
  isRequesting: boolean;
}

export default defineComponent({
  props: {
    johaisetsuCar: {
      type: Object as PropType<JohaisetsuCarExt>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      showConfirmBaseArrivalModal: false,
      isRequesting: false,
    });
    const editContentAndRoad = () => {
      emit('editContentAndRoadOnCommon');
    };
    const showConfirmBaseArrivalModal = () => {
      state.showConfirmBaseArrivalModal = true;
    };
    const baseArrival = async() => {
      if (!props.johaisetsuCar.id || !props.johaisetsuCar.report.id) return;
      state.isRequesting = true;
      try {
        await updateBaseArrival(props.johaisetsuCar.id);
      } catch (e) {
        emit('showErrorOnCommon', 'レポートデータの更新に失敗しました。');
        return;
      }
      emit('baseArrivalOnCommon');
    };
    return {
      state,
      baseArrival,
      editContentAndRoad,
      showConfirmBaseArrivalModal,
    };
  },
});
