import { reactive } from '@vue/composition-api';

import cleaningReportApi from '@/apis/cleaning_report';

import { ReportState, SearchState } from '@/models/cleaningMap';
import {
  deselectReportItems,
  convSearchParamTsTo,
  convReport,
  loadCleaningMtxs,
} from '@/components/CleaningMap/utils';
import { CleaningReportExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { createInjection } from '@/lib/createInjection';
import { CLEANING_REPORT_MAX_MTXS } from '@/components/CleaningMap/consts/cleaning_map';

interface InjectedState {
  reportState: ReportState;
  searchState: SearchState;
}

export interface UseCleaningReportsResult {
  reportState: ReportState;
  searchReports: () => Promise<void>;
  selectReport: (reportId: number) => Promise<void>;
  deselectReport: (reportId: number) => boolean;
  getSelectedReport: () => CleaningReportExt | null;
  deselectSelectedReportPhotos: () => void;
  clearReports: () => void;
}

const { provide, inject } = createInjection<InjectedState>('cleaningMap/useCleaningReports');

export function provideCleaningReports(searchState: SearchState): InjectedState {
  const reportState = reactive<ReportState>({
    reports: [],
  });
  const injectedState = { reportState, searchState };
  provide(injectedState);
  return injectedState;
}

export function useCleaningReports(defaultInjectedState?: InjectedState): UseCleaningReportsResult {
  const injectedState = defaultInjectedState ?? inject();
  const reportState = injectedState.reportState;
  const searchState = injectedState.searchState;

  const searchReports = async(): Promise<void> => {
    // 管轄を選んだ時には、その管轄の清掃会社のみを検索対象にする
    const cleaningCompanyIds: number[] = [];
    if (searchState.searchConds.cleaningCompanyId) {
      cleaningCompanyIds.push(searchState.searchConds.cleaningCompanyId);
    } else if (searchState.searchConds.cleaningArea) {
      cleaningCompanyIds.push(...searchState.cleaningCompanyAreaMap[searchState.searchConds.cleaningArea]);
    }

    const reqParams = {
      ts_from: searchState.searchConds.dateFrom,
      ts_to: convSearchParamTsTo(searchState.searchConds.dateTo),
      cleaning_company_ids: cleaningCompanyIds,
      cleaning_han_name: searchState.searchConds.cleaningHanName,
    };
    const { data: cleaningReports } = await cleaningReportApi.index(reqParams);
    reportState.reports = cleaningReports
      .map(report => {
        return convReport(report, searchState.cleaningCompanies);
      });
  };

  const getSelectedReport = (): CleaningReportExt | null => {
    return reportState.reports.find(e => e.isSelected) ?? null;
  };

  const selectReport = async(reportId: number): Promise<void> => {
    for (const report of reportState.reports) {
      report.isSelected = report.id === reportId;
      if (!report.isSelected) continue;

      if (!report.isMtxsLoaded) {
        await loadCleaningMtxs([report], CLEANING_REPORT_MAX_MTXS);
      }
    }
  };

  const deselectReport = (reportId: number): boolean => {
    const report = getSelectedReport();
    if (!report || report.id !== reportId) return false;

    report.isSelected = false;
    deselectReportItems(report);
    return true;
  };

  const deselectSelectedReportPhotos = (): void => {
    const report = getSelectedReport();
    if (!report) return;

    deselectReportItems(report);
  };

  const clearReports = (): void => {
    reportState.reports.forEach(report => {
      report.photosExt.forEach(async(photo) => {
        if (!photo.savedImage) return;
        return URL.revokeObjectURL(photo.savedImage);
      });
    });
    reportState.reports = [];
  };

  return {
    reportState,
    searchReports,
    getSelectedReport,
    selectReport,
    deselectReport,
    deselectSelectedReportPhotos,
    clearReports,
  };
}
