var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header1"},[_c('h2',{staticClass:"left-side"},[_vm._v("積雪量入力")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskForces.length > 0),expression:"taskForces.length > 0"}],staticClass:"right-side"},[(!_vm.showTaskForceSelect)?[_c('span',[_vm._v(_vm._s(_vm.selectedTaskForce.name))]),_c('button',{staticClass:"btn btn-secondary btn-sm ml4",attrs:{"type":"button"},on:{"click":function($event){_vm.showTaskForceSelect = true}}},[_c('i',{staticClass:"fas fa-exchange-alt"})])]:[_c('select',{staticClass:"ib form-control select-task-force",domProps:{"value":_vm.selectedTaskForce.id},on:{"input":function($event){return _vm.onSelectedTaskForceChange($event.target.value)}}},_vm._l((_vm.taskForces),function(e){return _c('option',{key:e.id,domProps:{"value":e.id}},[_vm._v(" "+_vm._s(e.name)+" ")])}),0)]],2)]),(_vm.isReady && !_vm.isReadOnly)?_c('div',{staticClass:"header2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.isRequesting},on:{"click":_vm.saveItems}},[_vm._v("保存")])]):_vm._e(),(_vm.planningBlocks.length > 0)?_c('div',{staticClass:"main1 mt12 mb24 ml16"},[_vm._m(0),_c('div',{staticClass:"tbl"},[_c('div',{staticClass:"header-row fw-bold g-area a1 bb br bl bt"},[_vm._v("路線")]),_c('div',{staticClass:"header-row fw-bold g-area a2 bb br bt"},[_vm._v("区間")]),_c('div',{staticClass:"header-row fw-bold g-area a3 bb br bt"},[_vm._v("方向")]),_c('div',{staticClass:"header-row fw-bold g-area a4 bb br bt"},[_vm._v("積雪量 (cm)")]),_vm._l((_vm.planningBlocks),function(row){return [(row.firstInRoadNameDispKukan)?_c('div',{staticClass:"body-row g-area a1 bb br bl align-left",class:{
              'importance1': row.importance === 1,
              'importance2': row.importance === 2,
              'importance3': row.importance === 3,
            }},[_vm._v(" "+_vm._s(row.road_name_disp)+" ")]):_vm._e(),(row.firstInRoadNameDispKukan)?_c('div',{staticClass:"body-row g-area a2 bb br align-left",class:{
              'importance1': row.importance === 1,
              'importance2': row.importance === 2,
              'importance3': row.importance === 3,
            }},[_vm._v(" "+_vm._s(row.kukan_name)+" ")]):_vm._e(),_c('div',{staticClass:"body-row g-area a3 bb br align-left",class:{
              'bb-dotted': row.firstInRoadNameDispKukan,
              'importance1': row.importance === 1,
              'importance2': row.importance === 2,
              'importance3': row.importance === 3,
            }},[_vm._v(" "+_vm._s(row.direction)+" ")]),(row.firstInRoadNameDispKukan)?_c('div',{staticClass:"body-row g-area a4 bb br align-right",class:{
              'is-editable': !_vm.isReadOnly && row.firstInRoadNameDispKukan,
            }},[(_vm.isReadOnly)?[_c('span',{staticStyle:{"font-size":"1.5rem"}},[_vm._v(_vm._s(row.inputMap.snow_height_cm))]),(row.inputMap.snow_height_cm !== null)?_c('span',{staticClass:"ib ml2 mt5"},[_vm._v("cm")]):_vm._e()]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.inputMap.snow_height_cm),expression:"row.inputMap.snow_height_cm"}],staticClass:"form-control",staticStyle:{"width":"50px","text-align":"right","font-size":"1.2rem"},attrs:{"type":"text","maxlength":"2"},domProps:{"value":(row.inputMap.snow_height_cm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row.inputMap, "snow_height_cm", $event.target.value)}}}),_c('span',{staticClass:"ib ml4"},[_vm._v("cm")])]],2):_vm._e()]})],2)]):_vm._e(),(_vm.isReady && !_vm.isReadOnly)?_c('div',{staticClass:"footer1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.isRequesting},on:{"click":_vm.saveItems}},[_vm._v("保存")])]):_vm._e(),(!_vm.isReady)?_c('load-wait-spinner'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hanrei mb4"},[_c('span',{staticClass:"mr4"},[_vm._v("【凡例】")]),_c('span',{staticStyle:{"color":"#fe0000"}},[_vm._v("最重要路線")]),_vm._v("、 "),_c('span',{staticStyle:{"color":"#ffbf00"}},[_vm._v("除排雪優先1")]),_vm._v("、 "),_c('span',{staticStyle:{"color":"#00b050"}},[_vm._v("除排雪優先2")])])}]

export { render, staticRenderFns }