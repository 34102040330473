import { WeatherObservatoryDataPoint } from '@/models/apis/weatherObservatory/weatherObservatoryResponse';

export const getInitDataPoint = (observatoryId: number, timestamp: string): WeatherObservatoryDataPoint => {
  return {
    id: 0,
    weather_observatory_id: observatoryId,
    timestamp: timestamp,
    air_temperature: null,
    humidity: null,
    road_temperature: null,
    ten_min_accumulated_rainfall: null,
    snow_depth: null,
  };
};
