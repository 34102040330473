import { Coordinate } from 'ol/coordinate';
import { fromLonLat, transform } from 'ol/proj';
import {
  Location,
} from 'src/models';
import { CoordinateTransformOptions, NamedVectorLayer } from '@/lib/OlMapWrapper';
import { LayerEventHandler } from '@/lib/OlMapManager';
import { Extent } from 'ol/extent';

export interface EMLayerInfo {
  onLayerClick?: LayerEventHandler;
  extent: Extent | null;
}
export interface LayerWithInfo {
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
}
export default abstract class ExtremeMapAbstractLayerManager {
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
  constructor() {
    if (this.constructor === ExtremeMapAbstractLayerManager) {
      throw new TypeError('Abstract class "ExtremeMapAbstractLayerManager" cannot be instantiated directly.');
    }

    this.layer = null;
    this.layerInfo = {
      extent: null,
    };
  }

  isArrayAllSame(a: any[], b: any[]): boolean {
    const aLen = a.length;
    const bLen = b.length;
    if (aLen !== bLen) { return false; }
    a = a.slice().sort();
    b = b.slice().sort();
    for (let i = 0; i < aLen; i++) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  coordFromLonLat(lon: number, lat: number, opt: CoordinateTransformOptions = {}): Coordinate {
    const destProj = opt.destProj || 'EPSG:3857';
    return fromLonLat([lon, lat], destProj);
  }

  convCoord({ lat, lon }: Location, opt: CoordinateTransformOptions = {}): Coordinate {
    const srcProj = opt.srcProj || 'EPSG:4326';
    const destProj = opt.destProj || 'EPSG:3857';
    return transform([lon, lat], srcProj, destProj);
  }

  getLayer(): LayerWithInfo {
    const layer = this.layer;
    const layerInfo = this.layerInfo;
    return { layer, layerInfo };
  }

  getLayerVisible(): boolean {
    if (!this.layer) { return false; }
    return this.layer.getVisible();
  }

  toggleLayerVisible(): void {
    if (!this.layer) { return; }
    const visible = this.layer.getVisible();
    this.layer.setVisible(!visible);
  }
}
