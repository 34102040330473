import { Style, Fill, RegularShape, Stroke, Text } from 'ol/style';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Places, Place } from '@/models/index';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import { getFeatureColor } from '@/lib/other_data_layer/stallRiskPointHelper';

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}

export default class ExtremeMapStallRiskPointsLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  opts: Options;
  constructor(opts: Options = {}) {
    super();
    this.layerInfo.onLayerClick = () => {};
    this.layerName = 'stall_risk_points';
    this.opts = opts;
  }

  getPointFeature_({ lat, lon, type, name, direction }: Place): Feature {
    const { fillColor, strokeColor } = getFeatureColor(type);

    const coord = this.convCoord({
      lon: lon,
      lat: lat,
    });
    const feat = new Feature({
      geometry: new Point(coord),
    });
    const style = new Style({
      image: new RegularShape({
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: 3,
        }),
        points: 5,
        radius: 10,
        radius2: 5,
        angle: 0,
      }),
    });

    const textFillColor = [255, 255, 255];
    const textStrokeColor = [20, 20, 20, 1.0];
    const text = new Text({
      text: `${name}(${direction})`,
      font: '10px sans-serif',
      fill: new Fill({
        color: textFillColor,
      }),
      stroke: new Stroke({
        color: textStrokeColor,
        width: 3,
      }),
      offsetX: 0,
      offsetY: 12,
    });
    style.setText(text);

    feat.setStyle(style);
    return feat;
  }

  getStallRiskPointFeatures_(place: Place): Feature[] {
    const feats: Feature[] = [];
    feats.push(this.getPointFeature_(place));
    return feats;
  }

  createStallRiskLayer_(data: Places): void {
    const feats: Feature[] = [];
    data.places.forEach(place => {
      feats.push(...this.getStallRiskPointFeatures_(place));
    });
    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: feats}),
    });
    layer.name = this.layerName;
    this.layer = layer;
  }

  prepareLayer(): LayerWithInfo {
    const data: Places = require('@/data/layer_stall_risk_points.json');
    this.createStallRiskLayer_(data);
    return this.getLayer();
  }

  getStallRiskPointsLayerName(): string {
    return this.layerName;
  }
}
