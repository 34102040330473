import {
  WeatherObservatoryDataPoint,
  WeatherObservatoryWithDataPoints,
} from '@/models/apis/weatherObservatory/weatherObservatoryResponse';

export interface SearchParams {
  tsToDate: Date;
  tsToTime: string;
}
export interface ConvWeatherObservatory extends WeatherObservatoryWithDataPoints {
  areaName: string;
  dataPointsByHour: WeatherObservatoryDataPoint[];
  showAirTemperature: boolean;
  showHumidity: boolean;
  showRoadTemperature: boolean;
  showTenMinAccumulatedRainfall: boolean;
  showSnowDepth: boolean;
}
export interface ConvWeatherObservatoriesByArea {
  [key: string]: ConvWeatherObservatory[];
}
export interface WeatherObservatorydDataPointsTableState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  searchParams: SearchParams;

  searchTimeChoices: string[];

  areas: string[];
  observatories: WeatherObservatoryWithDataPoints[];
  observatoriesByArea: ConvWeatherObservatoriesByArea;
  selectedObservatoryIds: number[];
  timestamps: Date[];
  timeLabels: string[];

  showChartModal: boolean;
  showErrorModal: boolean;
  errorBody: string;
}

export const origAreas = [
  { id: 't-west', name: '東京西' },
  { id: 't-east', name: '東京東' },
  { id: 'kanagawa', name: '神奈川' },
];
export const ONE_DAY_IN_SECONDS = 86400;
export const MAX_SEARCH_DATE_RANGE = 180;
