



import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  watch,
  ref,
  PropType,
} from '@vue/composition-api';

import { ChartData, ChartDataSets } from 'chart.js';
import { Line as LineChart, Bar } from 'vue-chartjs';

import { RoadTemperaturesByTimeRange } from '@/models/route';

interface RouteChartState {
  roadTemperatureDispModes: Record<string, string>;
}
export default defineComponent({
  props: {
    roadTemperatures: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    roadTemperaturesByTimeRange: {
      type: Array as PropType<RoadTemperaturesByTimeRange[]>,
      default: () => [],
    },
    salinities: {
      type: Array as PropType<number[]>,
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      required: true,
    },
    roadTemperatureDispMode: {
      type: String,
      default: () => 'single',
    },
  },
  extends: Bar,
  setup(props) {
    const state = reactive<RouteChartState>({
      roadTemperatureDispModes: {
        'SINGLE': 'single',
        'MULTIPLE': 'multiple',
      },
    });
    const refLine = ref<LineChart>();
    onMounted(() => {
      updateChartData();
    });
    watch(() => [props.roadTemperatures, props.roadTemperaturesByTimeRange, props.salinities], () => {
      updateChartData();
    });

    const isRoadTemperatureDispModeSingle = computed<boolean>(() => {
      return props.roadTemperatureDispMode === state.roadTemperatureDispModes.SINGLE;
    });
    const isRoadTemperatureDispModeMultiple = computed<boolean>(() => {
      return props.roadTemperatureDispMode === state.roadTemperatureDispModes.MULTIPLE;
    });

    const updateChartData = () => {
      const minTemperature =
        Math.min(...props.roadTemperatures.filter(e => e !== null));
      const maxTemperature =
        Math.max(...props.roadTemperatures.filter(e => e !== null));
      const minTemperatureDisp = Math.min(0, minTemperature);
      const maxTemperatureDisp = Math.max(25, maxTemperature);
      let temperatureStep = 5;
      if (maxTemperatureDisp - minTemperatureDisp > 70) {
        temperatureStep = 10.0;
      }

      if (refLine.value === undefined) { return; }
      refLine.value.renderChart({
        labels: props.labels,
        datasets: [
          ...getRoadTemperatures(),
          {
            type: 'bar',
            label: '塩分濃度',
            data: props.salinities,
            borderColor: 'rgba(54,164,235,0.8)',
            backgroundColor: 'rgba(54,164,235,0.5)',
            spanGaps: false,
            lineTension: 0.2,
            yAxisID: 'y-axis-2',
          },
        ],
      },
      {
        animation: undefined,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          labels: {
            filter: function(label: { text: string }) {
              if (label.text === '路温') {
                return true;
              }
              if (label.text === '塩分濃度') {
                return true;
              }
              return false;
            },
          },
        },
        scales: {
          yAxes: [{
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            ticks: {
              suggestedMax: maxTemperatureDisp,
              suggestedMin: minTemperatureDisp,
              stepSize: temperatureStep,
              callback: function(value: string) {
                return value.toString() + '℃';
              },
            },
          }, {
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            ticks: {
              suggestedMax: 25,
              suggestedMin: 0,
              stepSize: 5,
              callback: function(value: string) {
                return value.toString() + '%';
              },
            },
          }],
          xAxes: [{
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          }],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (item: { datasetIndex: number; yLabel: string }, data: ChartData) => {
              let suffix = '';
              const dataLabel = data.datasets ? data.datasets[item.datasetIndex].label : '';
              if (dataLabel?.indexOf('路温') !== -1) {
                suffix = '℃';
              } else if (dataLabel === '塩分濃度') {
                suffix = '%';
              }
              return `${dataLabel}: ${item.yLabel}${suffix}`;
            },
          },
        },
      });
    };
    const getRoadTemperatures = () => {
      let ret: ChartDataSets[] = [];
      if (isRoadTemperatureDispModeSingle.value) {
        ret = [{
          type: 'line',
          label: '路温',
          data: props.roadTemperatures,
          borderColor: 'rgba(204,204,132,0.8)',
          backgroundColor: 'rgba(204,204,132,0.8)',
          fill: false,
          spanGaps: false,
          lineTension: 0.2,
          yAxisID: 'y-axis-1',
        }];
      } else if (isRoadTemperatureDispModeMultiple.value) {
        ret = [{
          type: 'line',
          label: '路温',
          data: [] as number[],
          borderColor: '#ffffff',
          backgroundColor: '#ffffff',
          fill: false,
          spanGaps: false,
          lineTension: 0.2,
          yAxisID: 'y-axis-1',
        }];
        ret.push(...props.roadTemperaturesByTimeRange.map(e => {
          const label = getRoadTemperatureLabel(e.t);
          const color = getRoadTemperatureColor(e.t);
          return {
            type: 'line',
            label: label,
            data: e.data as number[],
            borderColor: color,
            backgroundColor: color,
            fill: false,
            spanGaps: false,
            lineTension: 0.2,
            yAxisID: 'y-axis-1',
          };
        }));
      }
      return ret;
    };
    const getRoadTemperatureLabel = (t: number) => {
      // t = parseInt(t);
      let ret = '路温';
      if (t > 0) {
        ret = `路温(${t}〜${t + 1}時間前)`;
      } else {
        ret = '路温(現在〜1時間前)';
      }
      return ret;
    };
    const getRoadTemperatureColor = (t: number) => {
      // t = parseInt(t);
      let ret = 'rgba(204,204,132,0.8)';
      if (t === 0) {
        ret = '#1f77b4';
      } else if (t === 1) {
        ret = '#ff7f0e';
      } else if (t === 2) {
        ret = '#2ca02c';
      } else if (t === 3) {
        ret = '#d62728';
      } else if (t === 4) {
        ret = '#9467bd';
      } else if (t === 5) {
        ret = '#8c564b';
      } else if (t === 6) {
        ret = '#e377c2';
      } else if (t === 7) {
        ret = '#cf17be';
      } else if (t === 8) {
        ret = '#bcbd22';
      } else if (t === 9) {
        ret = '#17becf';
      } else if (t === 10) {
        ret = '#488de8';
      } else if (t === 11) {
        ret = '#ffbb78';
      } else if (t === 12) {
        ret = '#98df8a';
      } else if (t === 13) {
        ret = '#ff9896';
      } else if (t === 14) {
        ret = '#c5b0d5';
      } else if (t === 15) {
        ret = '#c49c94';
      } else if (t === 16) {
        ret = '#f7b6d2';
      } else if (t === 17) {
        ret = '#e59eda';
      } else if (t === 18) {
        ret = '#dbdb8d';
      } else if (t === 19) {
        ret = '#768ee6';
      } else if (t === 20) {
        ret = '#1f46b4';
      } else if (t === 21) {
        ret = '#d69027';
      } else if (t === 22) {
        ret = '#7ea02c';
      } else if (t === 23) {
        ret = '#8c4b7b';
      }
      return ret;
    };
    return {
      ...toRefs(state),
      refLine,
      // computed
      isRoadTemperatureDispModeSingle,
      isRoadTemperatureDispModeMultiple,
      // methods
      updateChartData,
      getRoadTemperatures,
      getRoadTemperatureLabel,
      getRoadTemperatureColor,
    };
  },
  components: { LineChart },
});

