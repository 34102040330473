import { computed, ComputedRef, reactive } from '@vue/composition-api';
import { JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU } from '@/consts/johaisetsu_car';
import { JohaisetsuTypeMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { createInjection } from '@/lib/createInjection';

export interface SettouSagyouState {
  ability: number;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
}

export interface UseSettouSagyouParams {
  selectedJohaisetsuType: string | null;
}

interface State {
  selectedJohaisetsuType: string | null;
}

const { provide, inject } = createInjection<SettouSagyouState>('settouSagyouReport/useSettouSagyou');

export function provideSettouSagyou(state: SettouSagyouState): SettouSagyouState {
  const injectedState = reactive<SettouSagyouState>(state);
  provide(injectedState);
  return injectedState;
}

export interface UseSettouSagyouResult {
  state: State;
  johaisetsuTypeGroupName: string;
  johaisetsuTypeNameDisp: ComputedRef<string>;
  johaisetsuTypeMap: { [key: string]: JohaisetsuTypeMap };
  johaisetsuTypes: JohaisetsuTypeMap[];
  showSanpuNum: boolean;
  setJohaisetsuType: (johaisetsuType: string) => void;
}

export default function useSettouSagyou(params: UseSettouSagyouParams): UseSettouSagyouResult {
  const state = reactive<State>({
    selectedJohaisetsuType: params.selectedJohaisetsuType,
  });
  const settouSagyou = inject();
  if (!settouSagyou) {
    throw new Error('SettouSagyou is not provided');
  }
  const johaisetsuTypeGroupName = settouSagyou.johaisetsuTypeGroupName;
  const showSanpuNum = settouSagyou.johaisetsuTypeGroup === JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU;
  const johaisetsuTypeMap = window.johaisetsuMaster.johaisetsuTypeMap;
  const johaisetsuTypes = window.johaisetsuMaster.johaisetsuTypes.filter(e => {
    return e.key.endsWith(settouSagyou.johaisetsuTypeGroup);
  });
  const johaisetsuTypeNameDisp = computed<string>(() => {
    let ret = '';
    if (johaisetsuTypes.length >= 1) {
      const johaisetsuTypeObj = johaisetsuTypeMap[state.selectedJohaisetsuType || ''];
      ret = johaisetsuTypeObj ? johaisetsuTypeObj.work_type : '';
    }
    return ret;
  });

  const setJohaisetsuType = (johaisetsuType: string) => {
    state.selectedJohaisetsuType = johaisetsuType;
  };

  return {
    state,
    johaisetsuTypeGroupName,
    johaisetsuTypeNameDisp,
    johaisetsuTypeMap,
    johaisetsuTypes,
    showSanpuNum,
    setJohaisetsuType,
  };
}
