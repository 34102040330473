import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { UserLoginParams } from '@/models/apis/user/userRequest';
import { UserLoginResponse, UserGetMeResponse, UserLogoutResponse } from '@/models/apis/user/userResponse';
import {
  AdminUserIndexParams,
  AdminUserCreateParams,
  AdminUserUpdateParams,
  AdminUserPasswordUpdateParams,
} from '@/models/apis/user/adminUserRequest';
import {
  AdminUserIndexResponse,
  AdminUserCreateResponse,
  AdminUserUpdateResponse,
  AdminUserDestroyResponse,
  AdminUserUploadCsvResponse,
} from '@/models/apis/user/adminUserResponse';

export default {
  login(data: UserLoginParams): Promise<AxiosResponse<UserLoginResponse>> {
    return axios.post(utils.getApiUrl('/users/login'), data);
  },
  logout() : Promise<AxiosResponse<UserLogoutResponse>> {
    return axios.delete(utils.getApiUrl('/users/logout'));
  },
  getMe() : Promise<AxiosResponse<UserGetMeResponse>> {
    return axios.get(utils.getApiUrl('/users/me'));
  },
  adminIndex(params?: AdminUserIndexParams): Promise<AxiosResponse<AdminUserIndexResponse>> {
    return axios.get(utils.getApiUrl('/admin/users'), {
      params: params || {},
    });
  },
  adminCreate(params: AdminUserCreateParams): Promise<AxiosResponse<AdminUserCreateResponse>> {
    return axios.post(utils.getApiUrl('/admin/users'), params || {});
  },
  adminUpdate(id: number, params: AdminUserUpdateParams): Promise<AxiosResponse<AdminUserUpdateResponse>> {
    return axios.put(utils.getApiUrl(`/admin/users/${id}`), params || {});
  },
  adminPasswordUpdate(id: number, params: AdminUserPasswordUpdateParams): Promise<AxiosResponse<AdminUserUpdateResponse>> {
    return axios.put(utils.getApiUrl(`/admin/users/password/${id}`), params || {});
  },
  adminDestroy(id: number): Promise<AxiosResponse<AdminUserDestroyResponse>> {
    return axios.delete(utils.getApiUrl(`/admin/users/${id}`));
  },
  adminDownloadCSV(params: AdminUserIndexParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/admin/users/csv`), {
      params: params,
      responseType: 'blob',
    });
  },
  adminUploadCSV(formData: FormData): Promise<AxiosResponse<AdminUserUploadCsvResponse>> {
    return axios.post(utils.getApiUrl(`/admin/users/csv`), formData);
  },
};
