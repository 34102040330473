import johaisetsuMtxApi from '@/apis/johaisetsu/johaisetsu_mtx';
import { JohaisetsuCarsGetResponse } from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import { JohaisetsuCarExt, JohaisetsuDevice } from '@/models/johaisetsu/johaisetsuCar';
import {
  JohaisetsuCarReport,
  JohaisetsuReportExt,
} from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { JohaisetsuCompany } from '@/models/apis/johaisetsu/johaisetsuCommon';
import {
  CarInfo,
  JohaisetsuArea,
  SearchConds,
  ItemSearchConds,
  GeoPaneState,
  ReportMapKinds,
  SearchState,
  CarKindChoice,
  GeoItemLayerShow,
} from '@/models/johaisetsu/johaisetsuMap';
import { Lov } from '@/models/apis/master/masterResponse';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import {
  layers,
  SETTOU_ONSITE_MANAGEMENT_DISPLAY_NAME,
  ONE_DAY_IN_MILLISECONDS,
  workTypes,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';

const CAR_WORKING_STATUS_DISP = '作業中';
const CAR_MOVING_STATUS_DISP = '移動中';
const maxDateRange = 31;

interface ConvDataParams {
  cars: JohaisetsuCarsGetResponse;
  currentCarInfoMap: Record<string, CarInfo>;
  carKindMap: Record<string, Lov>;
  johaisetsuCompanies: JohaisetsuCompany[];
}
interface CurrentReportItemInfo {
  selectedMtxTs: string | Date;
  loadedMtxs: JohaisetsuMtxExt[];
}

export function initSearchConds(
  areaChoices: JohaisetsuArea[],
  johaisetsuCompanies: JohaisetsuCompany[],
): SearchConds {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return {
    johaisetsuArea: areaChoices.length === 1 ? areaChoices[0].name : null,
    johaisetsuCompanyId: johaisetsuCompanies.length === 1 ? johaisetsuCompanies[0].id : null,
    johaisetsuHanName: null,
    status: '全て',
    dateFrom: new Date(now.getTime() - ONE_DAY_IN_MILLISECONDS * maxDateRange),
    dateTo: now,
  };
}

export function isValidSearchDateRange(searchConds: SearchConds): boolean {
  if (!searchConds.dateFrom || !searchConds.dateTo) return false;

  const searchRangeMsec = searchConds.dateTo.getTime() - searchConds.dateFrom.getTime();
  return searchRangeMsec >= 0 && searchRangeMsec < (maxDateRange + 1) * ONE_DAY_IN_MILLISECONDS;
}

export function createInitialCarsData(recentData: JohaisetsuCarsGetResponse): JohaisetsuCarExt[] {
  return recentData.map(car => {
    return {
      ...car,
      isMoving: false,
      isWorking: false,
      shouldShow: true,
      isSelected: false,
      pos: null,
      johaisetsuCompanyName: '',
      reportExt: null,
    };
  });
}

function convDeviceExt(carKind: Lov): JohaisetsuDevice {
  return {
    car_kind_disp: carKind.val || '',
    car_kind: carKind.key || '',
  };
}

export function convData(params: ConvDataParams): JohaisetsuCarExt[] {
  const carsExt = params.cars.map(car => {
    const reportExt = car.report ? convReport(
      car.report,
      params.johaisetsuCompanies,
      {
        selectedMtxTs: params.currentCarInfoMap[car.id]?.selectedMtxTs ?? '',
        loadedMtxs: params.currentCarInfoMap[car.id]?.loadedMtxs ?? [],
      },
    ) : null;
    const johaisetsuCompanyName = params.johaisetsuCompanies
      ?.find(e => e.id === car.user_johaisetsu_company_id)
      ?.val ?? '';
    const isWorking = car.status_disp === CAR_WORKING_STATUS_DISP;
    // ts_is_newがfalseの場合は、停止中とみなす
    const isMoving = car.ts_is_new && (isWorking || car.status_disp === CAR_MOVING_STATUS_DISP);

    // posはLayerManagerで変換して設定する
    return {
      ...car,
      isMoving,
      isWorking,
      shouldShow: true,
      isSelected: params.currentCarInfoMap[car.id]?.isSelected ?? false,
      pos: null,
      johaisetsuCompanyName,
      device: convDeviceExt(params.carKindMap[car?.car_kind]),
      reportExt,
    };
  });
  return carsExt;
}

export function getCarKindChoices(
  cars: JohaisetsuCarExt[],
  currentCarKindChoices: CarKindChoice[],
  carKindMap: Record<string, Lov>,
): CarKindChoice[] {
  const carKindChoicesMap: Record<string, string> = {};
  cars.forEach(car => {
    const carKind = carKindMap[car.car_kind] || {};
    carKindChoicesMap[carKind.key] = carKind.val;
  });
  const carKindChoices = Object.entries(carKindChoicesMap)
    .filter(ent => !!ent[1])
    .map(ent => ({
      key: ent[0],
      val: ent[1],
      selected: currentCarKindChoices.find(e => e.key === ent[0])?.selected ?? true,
    }));
  return carKindChoices;
}

export function shouldShowCar(
  car: JohaisetsuCarExt,
  decidedSearchConds: SearchConds,
  johaisetsuCompanyAreaMap: Record<string, number[]>,
  johaisetsuCompanies: JohaisetsuCompany[],
  carKindChoices: CarKindChoice[],
): boolean {
  // 検索条件に照らして車を表示すべきか判定
  const companyId = car.user_johaisetsu_company_id;
  const normalizeKey = (key: string) => key.replace(/(_driver|_default)$/, '');
  const carCompanyKey = johaisetsuCompanies.find(e => e.id === companyId)?.key;
  if (!carCompanyKey) {
    return false;
  }
  if (decidedSearchConds.johaisetsuArea) {
    const johaisetsuCompanyIds = johaisetsuCompanyAreaMap[decidedSearchConds.johaisetsuArea];
    if (!johaisetsuCompanyIds) {
      return false;
    }
    if (!johaisetsuCompanyIds.includes(companyId)) {
      return false;
    }
  }

  const searchCompanyId = decidedSearchConds.johaisetsuCompanyId;
  if (searchCompanyId) {
    const searchCompanyKey = johaisetsuCompanies.find(e => e.id === searchCompanyId)?.key;
    if (!searchCompanyKey) {
      return false;
    }
    if (normalizeKey(carCompanyKey) !== normalizeKey(searchCompanyKey)) {
      return false;
    }
  }

  if (decidedSearchConds.johaisetsuHanName &&
    car.user_johaisetsu_han_name !== decidedSearchConds.johaisetsuHanName) {
    return false;
  }
  if (decidedSearchConds.status !== '' && decidedSearchConds.status !== '全て') {
    if (car.status_disp !== decidedSearchConds.status) {
      return false;
    }
  }
  const carKindChoice = carKindChoices.find(e => e.key === car.car_kind);
  if (carKindChoice) {
    return carKindChoice.selected;
  }
  return true;
}

export const initSearchState = (): SearchState => {
  return {
    areaChoices: [],
    johaisetsuCompanies: [],
    johaisetsuCompanyAreaMap: {},
    johaisetsuHanMasters: [],
    johaisetsuCompanyMaster: [],
    searchConds: {
      johaisetsuArea: null,
      johaisetsuCompanyId: null,
      johaisetsuHanName: null,
      status: '全て',
      dateFrom: new Date(),
      dateTo: new Date(),
    },
    decidedSearchConds: {
      johaisetsuArea: null,
      johaisetsuCompanyId: null,
      johaisetsuHanName: null,
      status: '全て',
      dateFrom: new Date(),
      dateTo: new Date(),
    },
    johaisetsuType: '',
    workType: '',
  };
};

export const initItemSearchConds = (): ItemSearchConds => {
  return {
    selectCarKind: null,
    geoItemSearchConds: {
      timeFromOffset: -3600 * 1000 * 1,
      timeToOffset: 0,
    },
    itemShow,
    geoItemSearchTime: {
      start: null,
      end: null,
    },
    editingJohaisetsuCar: null,
    styles: {
      paneSideMinWidth: '23%',
      paneSideMaxWidth: '30%',
      paneCenterMinWidth: '40%',
      paneCenterMaxWidth: '54%',
      carListMinHeight: '200px',
      carListMaxHeight: '200px',
      tabContainerMinHeight: '40%',
      tabContentMaxHeight: '40%',
    },
    moviePlayerDefaultSize: {
      movieW: 640,
      movieH: 360,
      controlBarH: 22,
      minW: 280,
      w: 0,
      h: 0,
      span: 0,
    },
    moviePlayerDialogMax: 4,
    settouReportLayerShow: {
      settouPatrolReports: false,
      settouProgressManagerReports: false,
      settouOnsiteManagerReports: false,
    },
  };
};

const itemShow: GeoItemLayerShow = {
  temperature: false,
  salinity: false,
  snowfall: false,
  frozen: false,
  snow_mountain: false,
  jh_work_status: false,
  tairyu: false,
  ensui_sanpu: false,
  shitsuen_sanpu: false,
  kokeizai_sanpu: false,
  kyokusho_sanpu: false,
  other_sanpu: false,
  sweeper_soukou: false,
  other_soukou: false,
  kisei: false,
  josetsu: false,
  haisetsu: false,
  settouPatrolReports: false,
  settouProgressManagerReports: false,
  settouOnsiteManagerReports: false,
};

export const initGeoPaneState = (): GeoPaneState => {
  return {
    show: {
      stallRiskPoints: false,
      ensuiPlants: false,
    },
    itemShow,
    itemOrder: {},
    geoItemSearchConds: {
      timeFromOffset: -3600 * 1000 * 1,
      timeToOffset: 0,
    },
    geoItemTimeChoices: [],
    roadNameDirections: [],
    roadNameDirectionShortcuts: [],
    roadNameDirectionShortcutMap: {},
    showRoadNameDirectionListRows: false,
    layers: layers,
    carKinds: [],
    checkedShowCarIcons: true,
  };
};

export const initReportMapKinds = (): ReportMapKinds => {
  return {
    workType: workTypes,
  };
};

export const convReport = (
  report: JohaisetsuCarReport,
  johaisetsuCompanies: JohaisetsuCompany[],
  currentReportItemInfo?: CurrentReportItemInfo,
): JohaisetsuReportExt => {
  const johaisetsuCompanyName = window.johaisetsuMaster.companyList.find(e =>
    e.id === report.johaisetsu_company_id,
  )?.name ?? '';
  const loadedMtxs = currentReportItemInfo?.loadedMtxs || [];
  const isPatrolReport = report.johaisetsu_type === '雪凍パトロール報告書';
  const isOnsiteManageMentReport = report.johaisetsu_type === SETTOU_ONSITE_MANAGEMENT_DISPLAY_NAME;
  const isMtxsLoaded = loadedMtxs.length > 0 || isPatrolReport || isOnsiteManageMentReport;
  const reportMapKind = workTypes.find(x => x.type === report.johaisetsu_type);
  const workType = (() => {
    if (reportMapKind?.items.length === 1 && report.johaisetsu_type === report.work_type) return '';

    return `(${report.work_type})`;
  })();
  const contentsDisp = report.johaisetsu_type ? `${report.johaisetsu_type}${workType}` : '';
  const reportExt: JohaisetsuReportExt = {
    ...report,
    johaisetsuCompanyName,
    mtxsExt: loadedMtxs,
    isSelected: false,
    isMtxsLoaded,
    contentsDisp,
  };
  return reportExt;
};

export const loadJohaisetsuMtxs = async(reports: JohaisetsuReportExt[], maxMtx: number): Promise<void> => {
  const { data: mtxs } = await johaisetsuMtxApi.getJohaisetsuMtxs({
    johaisetsuReportIds: reports.map(report => report.id),
    maxMtx,
  });
  reports.forEach(report => {
    if (!mtxs[report.id]) return;
    report.mtxsExt = mtxs[report.id].map(mtx => {
      return {
        ...mtx,
        isSelected: false,
        johaisetsuCompanyName: report.johaisetsuCompanyName,
        johaisetsuHanName: report.johaisetsu_han_name,
      };
    });
    report.isMtxsLoaded = true;
  });
};

export const deselectReportItems = (report: JohaisetsuReportExt): void => {
  report.mtxsExt.forEach(mtx => {
    mtx.isSelected = false;
  });
};

export const convSearchParamTsTo = (dateTo: Date): Date => {
  return new Date(dateTo.getTime() + ONE_DAY_IN_MILLISECONDS - 1);
};
