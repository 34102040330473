





import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'map-elem-info-none',
  setup() {
    return {};
  },
});
